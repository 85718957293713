import React from 'react';
import PassTemplateCodes from './PassTemplateCodes';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import androidBtn from '../../assets/images/android-btn.jpg';
import appleBtn from '../../assets/images/apple-btn.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";

class ViewAllTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            totalPages: 0,
            currentPage: 0,
            allTemplatesCode: []
        };
    }

    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        this.getCodesData(); // Get Member templates
    }

    getCodesData(props = this.props) {
        this.setState({ loader: true });
        var PostData = { access_token: functions.getAccessToken() };
        api.getCodesData(PostData)
            .then(res => {
                if (res.data.status == 1) {
                    this.setState({
                        loader: false,
                        allTemplatesCode: res.data.data.passTemplateCodes.templatesChunkedData,
                        totalPages: res.data.data.passTemplateCodes.templatesChunkedData.length,
                    })
                } else {
                    // toast.error(res.data.message, { hideProgressBar: true })
                }
            }).catch(err => {
                this.setState({ loader: false })
            });
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let loader = functions.getLoader(this.state.loader);
        return (
            <div id="dashboard" className={this.props.CurrentTab == 'view-all-templates' ? 'tab-pane fade in active show' : 'tab-pane fade in'}>
                <h3>Pass Template Code</h3>
                <Link to="/dashboard" className="back-btn">
                    <i className="fas fa-arrow-left"></i> Back
                </Link>

                <div className="codes-three-section-boxes blockElement">
                    <div className="row">
                        {
                            this.state.totalPages > 0 ? this.state.allTemplatesCode[this.state.currentPage].map((val, key) => (
                                <div className="col-md-4">
                                    <div className="single-code-section-tabs pass-tm-code-part blockElement" ref={"templatePrint" + key}>
                                        <div className="single-code-section-heading blockElement">
                                            <h5>{val.name}</h5>
                                        </div>
                                        <div className="main-pass-tm-code-single-section blockElement">
                                            <div className="code-section-codeimage blockElement">
                                                <img className="templete-qr-code" src={val.qrcode_path} alt="" />

                                            </div>
                                            <a className="code-url" href={val.distributionLink}>{val.distributionLink}</a>
                                            <div className="buttons-group-code code-box-single-btns-o blockElement">
                                                <ReactToPrint
                                                    trigger={() => <button className="cmn-btn">Print Code</button>}
                                                    content={() => this.refs['templatePrint' + key]}
                                                />
                                                <a href={val.qrcode_path} download={val.name + '.png'}><button className="cmn-btn">Download Code</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null
                        }
                        <div className="custom-div-over-pagination blockElement">
                            <ul className="custom-pagination">
                                <li><a href="javascript:void(0)" className={this.state.currentPage > 0 ? "left-arrow" : "left-arrow disabled"} onClick={() => this.state.currentPage > 0 ? this.setState({ currentPage: this.state.currentPage - 1 }) : null} ></a></li>
                                {
                                    this.state.allTemplatesCode.map((val, key) => (
                                        <li key={key}>
                                            <a href="javascript:void(0)" className={this.state.currentPage == key ? "current-page-pagi" : "page-url-no"} onClick={() => this.setState({ currentPage: key })} >{key + 1}</a>
                                        </li>
                                    ))
                                }
                                <li><a href="javascript:void(0)" className={this.state.totalPages - 1 > this.state.currentPage ? "right-arrow" : "right-arrow disabled"} onClick={() => this.state.totalPages - 1 > this.state.currentPage ? this.setState({ currentPage: this.state.currentPage + 1 }) : null}></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ViewAllTemplates;
