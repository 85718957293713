module.exports = {
  "COMING SOON": "Kemur bráðlega",
  "SignIn": "Innskráning",
  "SignUp": "Nýskráning",
  "Cancel": "Hætta við",
  "Save": "Save",
  "Delete": "Eyða",
  "Back": "Til baka",
  "Upgrade": "Uppfæra",
  "Order": "Panta",
  "No": "Nei",
  "Yes": "Já!",
  "Deactivate": "Óvirkja",
  "Activate": "Virkja",
  "Name": "Nafn",
  "Email": "Netfang",
  "Send": "Senda",
  "Password": "Lykilorð",
  "Confirm password": "Staðfestu lykilorð",
  "Confirm": "Staðfesta",
  "user": "notandi",
  "Supported by:":"Supported by:",
  "users": "notendur",
  "Basic": "venjuleg",
  "Full": "mikil",
  "Welcome": "Velkomin/n",
  "Status": "Staða",
  "Email address": "Email address",
  "Date added": "Dagsetning bætt við",
  "Download list": "Download list",
  "Browse": "Fletta upp",
  "Select": "Velja",
  "here": " hér",
  "Edit": "Breyta",
  "left": "left",
  "Add": "Bæta við",
  "days": " daga",
  "day": " day",
  "next": "next",
  "Great": "Frábært",
  "Upload": "Upload",
  "DEMO CONTENT": "Kynning á gögnum",
  "Column": "Dálkur",
  "Row Data": "Row Data",
  "Valid": "Valid",
  "Invalid": "Invalid",
  "Redeemed": "Redeemed",
  "Used": "Used",
  "Deleted": "Deleted",
  "Expired": "Expired",
  "Restricted": "Restricted",
  "punched": "klippa",
  "verified": "verified",
  "used": "used",
  "Times": "Fjöldi",
  "FREE": "Frítt",
  "Start plan": "Kaupa áskrift",
  "Upgrade now": "Viltu uppfæra",
  "template": "sniðmát",
  "templates": "sniðmát",
  "type": "tegund",
  "types": "tegundir",
  "Change payment method": "Breyta greiðslumáta",
  "Your order": "Pöntunin þín",
  "Browse for your list": "Flettu upp skránni þinni",

  //Days
  "Monday": "Mánudagur",
  "Tuesday": "Þriðjudagur",
  "Wednesday": "Miðvikudagur",
  "Thursday": "Fimmtudagur",
  "Friday": "Föstudagur",
  "Saturday": "Laugardagur",
  "Sunday": "Sunnudagur",
  "Usages": "Notkun:",

  //Error Messages 
  "This field is required": "Vinsamlegast fylltu út í þennan reit",
  "The field is required": "Vinsamlegast fylltu út í þennan reit",
  "Passwords do not match!": "Lykilorðin eru ekki eins!",
  "This is not a recognized email": "Óþekkt netfang",
  "There was an error please try again.": "Villa kom upp, reyndu aftur!",

  //Sign In && Forgot Password
  "Sign in": "Innskrá",
  "Forgot your password": "Gleymt lykilorð",
  "Don't have an account": "Ekki með aðgang",
  "Sign Up": "Nýskráning",
  "Signup": "Nýskrá",
  "If you haven’t created an account you can do it": "Ef þú ert ekki með aðgang, getur þú stofnað hann",
  "There is no account associated with this email address. Please make sure that it is spelled correctly.": "Ekki er til aðgangur með þessu netfangi Vinsamlegast athugaðu hvort rétt netfang hafi verið slegið inn",
  "Write down your email so we can send you a new one": "Vinsamlegast sláðu inn netfangið þitt svo við getum sent þér nýtt lykilorð",
  "Wrong password":"Vitlaust lykilorð",

  //Sign Up && Confirm Email
  "Sign up to continue": "Nýskráðu þig til að halda áfram",
  "Sign up to_invited": "Nýskráðu þig á ",
  "Smartpages": "Smartpages",
  "Sign up": "Nýskráning",
  "I agree to the": "Ég samþykki",
  "Terms and conditions": "skilmála og skilyrði",
  "Your Invitation is expired": "Your Invitation is expired",
  "The email has already been confirmed": "The email has already been confirmed",
  "Your email confirmation is expired": "Your email confirmation is expired",
  "Checking your email confirmation token": "Checking your email confirmation token",
  "Thank you for signing up": "Takk fyrir að nýskrá þig",
  "Sign up confirmation email sent": "Staðfesting á nýskráningu hefur verið send á netfangið þitt",
  "An email has been sent to your address": "Tölvupóstur hefur verið sendur á netfangið þitt",
  "Please click the link in the email to confirm your sign up": "vinsamlegast smelltu á hlekkinn í tölvupóstinum til þess að staðfesta nýskráningu",
  "Your contact name must consist of at least 2 characters":"Nafn tengiliðs þarf að vera að minnsta kosti 2 stafir",
  "Please enter a valid email address":"Vinsamlegast sláðu inn gilt netfang",
  "Your company name must consist of at least 2 characters":"Nafn fyrirtækis þarf að vera að minnsta kosti 2 stafir",
  "Your company id must be consist 10 characters":"Kennitala fyrirtækis þarf að vera 10 tölur",
  "Your password must be at least 8 characters long":"Lykilorð þarf að vera að minnsta kosti 8 stafir",
  "One special character is required":"Lykilorðið þarf að innihalda að minnsta kosti 1 tákn",
  "Passwords do not match!":"Lykilorðin eru ekki eins!",

  //Reset Password
  "Reset password": "Endurstilla lykilorð",
  "Reset": "Endurstilla",
  "Password reset": "Lykilorðinu þínu hefur verið breytt",
  "Password reset link has been  sent": "Hlekkur hefur verið sendur til að breyta lykilorðinu",

  //Navigation
  "Profile": "Prófíll",
  "Subscription": "Áskrift",
  "Add scanner": "Add scanner",
  "My Scanners": "My Scanners",
  "Scanner": "Scanner",
  "Distribution": "Dreifing",
  "License holders": "License holders",
  "Users": "Notendur",
  "Codes": "Kóðar",
  "Statistics": "Tölfræði",
  "Select subscribtion":"Veldu áskrift",

  // Header

  "Hello": "Salut",
  "My Pass Templates": "Passa sniðmátin mín",
  "My Profile": "Mínar síður",
  "Sign Out": "Skrá út",
  "My Scanners": "My Scanners",

  // Footer
  "Contact us": "Hafðu samband",
  "Location: Katrinartun 4, 105 Reykjavík Iceland": "Staðsetning: Katrínartún 4, 105 Reykjavík Ísland",
  "Id": "Kt.",
  "Privacy and Cookie Policy": "Persónuverndarstefna og kökur",
  "General Terms and Conditions of Service": "Notkunar skilmálar",
  "Smart Solutions ehf.": "Smart Solutions ehf",

  // My Pass Template 
  "Start by ordering a Pass Template": "Byrjaðu á því að panta passasniðmát",
  "Order a pass template": "Panta passasniðmát",
  "Order a Pass Template": "Panta nýtt passasniðmát",
  "Order a new": "Order a new",
  "Pass Template": "Pass Template",
  "Ordered": "Ordered",
  "New Pass": "New Pass",
  "Template pending": "Template pending",
  "Pass status will be shown here": "Pass status will be shown here",
  "Date issued": "Date issued",
  "You can only order a Pass Template build of existing type": "Þú getur pantað nýtt passasniðmát af núverandi tegundum",
  "If you want to order a Pass Template of another type, you have to upgrade your subscription": "Ef þú villt panta sniðmát af annarri gerð þarftu að uppfæra áskriftina.",
  "No result found": "Eingar niðurstöður fundust",

  //Pass Statistics Tab 
  "Need more statistics": "Need more statistics",
  "Order a Pass Template to get started with Smart Solutions": "Pantaðu passa sniðmát til að hefja ferlið með Smart Solutions",
  "Status of pass limit used": "Staða fjölda notaðra passa",
  "Times used with SmartScanner": "Fjöldi notaðra passa með SmartScanner",
  "Times used with self service": "Fjöldi notaðra passa með sjálfsafgreiðslu kóða",
  "Number of passes used": "Fjöldi notaðra passa",
  "Number of passes downloaded": "Fjöldi niðurhalaðra passa",
  "Usage on time per day": "Notkun eftir tíma dags",
  "Passes remaining": "Passar sem eftir eru",
  "Color of button changes to match the color reached by the gauge": "Litur hnappsins breytist í samræmi við mælikvarðann",
  "Usage per day": "Notkun á dag",
  "Usage by location": "Notkun eftir staðsetningu",
  "Number of rewards recieved": "Fjöldi fríðinda afhend",
  "Number of rewards unused": "Fjöldi fríðinda ónotuð",
  "Number of rewards used": "Fjöldi fríðinda notuð",
  "Summary of times used": "Notaðir passar",
  "Summary of times verified": "Fjöldi passa staðfestir",
  "Summary of times punched": "Samtals klippur",
  "Times verified with SmartScanner": "Fjöldi passa staðfestir með SmartScanner",
  "Times verified with Refresh in SmartWallet": "Fjöldi passa staðfestir með endurhlaðningu í SmartWallet",
  "with SmartScanner": " með SmartScanner",
  "with self service code": "með sjálfsafgreiðslu kóða",
  "with self service": "með sjálfsafgreiðslu kóða",
  "Number of passes left": "Fjöldi passa eftir",
  "Number of passes fully used": "Fjöldi fullnýttra passa",
  "Number of passes not fully used": "Fjöldi ókláraðra passa",
  "Number of passes unused": "Fjöldi ónotaðra passa",
  "Number of passes expired": "Fjöldi útrunninna passa",

  //Pass Template Distribution
  "Send pass to selected": "Sendu passa til valdra",
  "Add users": "Bæta við notendum",
  "Delete user list": "Eyða dreifingarlista",
  "Download list": "Niðurhlaða lista",
  "No users listed": "Engir notendur skráðir",
  "You need to upload a list or manually add new users to see them listed here.": "Þú verður að hlaða inn lista eða bæta handvirkt við notendum til þess að sjá þá hér.",
  "Upload list": "Upphlaða lista ",
  "Upload a .csv file": "UUpphlaða .csv skrá",
  "or simply drag and drop it here": "eða dragðu hana einfaldlega hingað",


  //Pass Template Add User
  "Add new user": "Bættu við nýjum notanda",
  "You can add multiple users by uploading a .csv file": "Þú getur bætt við mörgum notendum í einu með því að hlaða inn .csv skrá.",
  "or you can add a user manually": "eða þú getur bætt inn notendum handvirkt",

  //Pass Template User Tab
  "No results found, Please try different search parameters": "Engar niðurstöður fundust, Vinsamlegast reyndu aðra leit",
  "As soon as an user downloads your pass you will see them listed here": "As soon as an user downloads your pass you will see them listed here",

  //Code Tab 
  "Pass Template distribution code": "Dreifingarkóði fyrir passa sniðmát",
  "Share this code to distribute your pass to customers": "Birtu þennan kóða til að dreifa pössum til viðskiptavina",
  "You can share it any way you prefer. Online, in print or simply": "Þú getur deilt honum hvernig sem þér hentar, í gegnum netið, útprentað eða einfaldlega",
  "send it to your customers": "sent hann til viðskiptavina",
  "Right click on this code": "Hægri smelltu á kóðann",
  "to save it": "til að vista hann",
  "Customer self service code": "Sjálfsafgreiðslu kóði fyrir viðkskiptavini",
  "Display this code to your customer so they can scan this": "Sýndu viðskiptavinum þennan kóða svo þeir geta skannað og",
  "code to use their pass": "notað passann sinn",
  "Take care of how you store this code": "Farðu varlega hvernig þú geymir og notar þennan kóða",
  "Save image as": "Save image as",
  "Save image as": "Save image as",
  "will appear here": "will appear here",

  // Passowrd Tab
  "Want to change your password": "Viltu breyta lykilorðinu þínu",
  "Old password": "Gamla lykilorðið",
  "New password": "Nýtt lykilorð",
  "Confirm new password": "Staðfestu nýtt lykilorð",
  "Update passowrd": "Uppfæra lykilorð",

  //Subscription & Upgrade Subcription Tab
  "You have exceeded your subscription limit": "Þú hefur fullnýtt áskrift þína",
  "Start by selecting subscription": "Byrjaðu á því að velja áskrift",
  "Please upgrade your subscription if you want to add a Pass Template": "Vinsamlegast uppfærðu áskriftina ef þú vilt kaupa fleiri passa sniðmát.",
  "Please upgrade your subscription if you want to add a Scanner": "Please upgrade your subscription if you want to add a Scanner",
  "Before you can order a Pass Template you need to select subscription": "Áður en þú pantar gerð passa sniðmáts verður þú að velja þjónustuáskrift.",
  "Distribution Limit": "Hámarks fjöldi passa",
  "Pass Template Types": "Passa sniðmáta tegundir",
  "Pass Templates types": "Passa sniðmáta tegundir",
  "Pass Templates types_subscription": "passategundir",
  "Pass Templates": "Passa sniðmát",
  "Issuer Smartpages": "Aðgangar að SmartPages",
  "Usage Statistics": "Notkunar tölfræði",
  "Smartscanner app": "SmartScanner smáforrit",
  "Smartscanner licenses": "SmartScanner leyfi",
  "Smartwallet app": "SmartWallet smáforrit",
  "Customer Self Service": "Sjálfsafgreiðsla notanda",
  "Start FREE plan": "Byrjaðu FRÍTT!",
  "Your subscription": "Áskriftin þín",
  "Select subscription": "Veldu áskrift",
  "Cancel subscription": "Hætta við áskrift",
  "Subscription exceeded": "Áskrift þín er fullnýtt",
  "Use of distribution limit": "Notkun dreifingarmarka",
  "Payment method": "Greiðslumáti",
  "Paid with invoice": "Sett í reikning",
  "You will be billed monthly by invoice": "Reikningur verður sendur mánaðarlega til þín",
  "days remaining of your trial period": "dagar eru eftir af frírri áskrift ",
  "You have requested cancellation of your subscription! It will end on": "You have requested cancellation of your subscription! It will end on",
  "You can cancel or change your subscription anytime": "You can cancel or change your subscription anytime",
  "All prices do not include VAT": "All prices do not include VAT",
  "Are you sure you want to cancel": "Ertu viss um að þú viljir hætta í",
  "subscription": "áskrift",
  "This will deactivate all of your Pass Templates at": "Þetta mun gera öll þín passa sniðmát óvirk í",
  "end of this month. This means your customers": "enda mánaðar Viðskiptavinir þínir",
  "will not be able to use their passes": "munu þá ekki geta notað passana sína",
  "Monthly for the next": "Mánaðarlega fyrir næstu",
  "Subscription plans for you": "Subscription plans for you",
  "Our services are sold by monthly subscription": "Our services are sold by monthly subscription",
  "All of our subscription plans include": "All of our subscription plans include",
  "For the first three months": "For the first three months",
  "Monthly for the first three months": "Mánaðarlega fyrir fyrstu þrjá mánuðina",
  "first three months": "first three months",
  "Negotiable": "Umsemjanlegt",

  //Downgrade Subscripton

  "Cannot change subscription": "Ekki er hægt að breyta áskrift",
  "You have too many active passes, Pass Template or Pass Template types": "Þú ert með of marga virka passa, passa sniðmát eða tegundir passa sniðsmáts.",
  "Please contact sales@smartsolutions.is to change subscription": "Vinsamlegast hafðu samband við sales@smartsolutions til að breyta áskriftinni.",
  "You do not have permission to change subscription": "Þú hefur ekki leyfi til að breyta áskrift",
  "Got it": "Náði því",

  // Payment Failed
  "Your payment failed": "Your payment failed",
  "We liked to inform you about the fact that you have unpaid invoices": "We liked to inform you about the fact that you have unpaid invoices",
  "or your account will be closed": "or your account will be closed",
  "Please enter a valid email.":"Vinsamlegast sláðu inn gilt netfang.",
  "Please pay your invoices within":"Please pay your invoices within",  "We liked to inform you about the fact that we were not able to bill your credit card": "We liked to inform you about the fact that we were not able to bill your credit card",
  "Please change your credit card information": "Please change your credit card information",
  "left to rectify this or your account will be closed ": "left to rectify this or your account will be closed",
  "You have":"You have",

  //Payment Form
  "Company": "fyrirtækis",
  "Card holder name": "Korthafi",
  "Card number": "Kortanúmer",
  "Valid thru": "Gildir til",
  "Change method": "Breyta greiðsluupplýsingum",
  "Total price with": "Samtals kostnaður með",
  "Pay using this credit card": "Greiða með þessu kreditkorti",
  "Pass Template build": "passa sniðmát",
  "VAT": "VSK",
  "monthly subscription discount": "Growing mánaðarlegur afsláttur",
  "Amount deducted due to previous subscription payment in current billing cycle": "Amount deducted due to previous subscription payment in current billing cycle",
  "monthly subscription": "mánaðarleg áskrift",

  //Order Confirm 
  "Your order confirmation": "Staðfesting á pöntun",
  "Thank you for your business": "Takk fyrir viðskiptin",
  "As soon as we will get your Pass Template order we will contact you to get your Pass Template material. If you have any question  please contact": "Um leið og við höfum fengið passa sniðmáts pöntun frá þér, munum við hafa samband við þig til að fá sniðmáts gögnin. Ef einhverjar spurningar vakna getur þú sent póst á netfangið",
  "A receipt has been sent to": "Greiðslukvittun hefur verið send á",

  //Profile Tab
  "Contact full name": "Nafn tengiliðs",
  "Contact full name_profile": "Fullt nafn tengiliðs",
  "Contact email": "Netfang tengiliðs",
  "Contact phone number": "Símanúmer tengiliðs",
  "Company name": "Nafn fyrirtækis",
  "Company ID number": "Kennitala fyrirtækis",
  "Company invoice email": "Netfang til að senda reikninga á",
  "Update profile": "Uppfæra prófíl",
  "Credit card token": "Credit card token",
  "Add your payment provider to sell passes":"Add your payment provider to sell passes",
  "Please add your payment provider information and the payments goes straight to your account":"Please add your payment provider information and the payments goes straight to your account",
  "Select your payment provider":"Select your payment provider",
  "Payment provider":"Payment provider",


  // Active Acount Tab
  "Active account users": "Virkir aðgangs notendur",
  "additional account users": "notenda aðgangar",
  "No additional account users": "Engir virkir aðgangs notendur",
  "Your subscription enables you to have": "Áskriftin þín inniheldur",
  "associated with your SmartPages": "aðganga að SmartPages",
  "activeuser_Account": " account",
  "activeuser_Accounts": " accounts",
  "To invite a new account user delete one below or upgrade your subscription": "Vinsamlegast eyddu einum virkum aðgang til þess að geta boðið nýjum aðila",
  "You can invite users in the input below!": "Þú getur boðið notendum aðgang hér fyrir neðan!",
  "Invitation pending": "Aðgangur á bið",
  "Upgrade subscription": "Uppfæra áskrift",
  "Manage permissions": "Manage permissions",
  "Enter email address to give access to your Smartpage": "Sláðu inn netfang þeirra sem eiga að fá aðgang að SmartPages",
  "Send invitation": "Senda aðgang",
  "You need to select a subscription plan to add more account users": "You need to select a subscription plan to add more account users",

  //Manager Permissions
  "Manage permissions": "Manage permissions",
  "Permission": "Permission",
  "Action": "Action",
  "Active Account": "Active Account",
  "Change Subscription": "Change Subscription",
  "Invite Users": "Invite Users",
  "Delete User": "Delete User",
  // Confirm Popup Text
  "USERS ADDED": "NOTENDUM BÆTT VIÐ",
  "REQUEST SENT": "BEIÐNI SEND",
  "PASSES SENT": "PASSAR SENDIR",
  "PASS SENT": "PASSAR SENDIR",
  "LICENSES SENT": "LICENSES SENT",
  "LICENSE SENT": "LICENSE SENT",
  "LICENSE HOLDERS ADDED": "LICENSE HOLDERS",
  "LICENSE HOLDER ADDED": "LICENSE HOLDER",

  "subscript_template": `<p>WFastagjaldið er 19.800 kr. fyrir uppsetningu á passa sniðmátinu þínu. <br /> Þá getur þú notað passasniðmátið þitt eins lengi og þú vilt dreifa passanum, frjálst.</p>`,

  //My Scanner & Scanner
  "Add a scanner": "Add a scanner",
  "After you have added scanners you will see them here": "After you have added scanners you will see them here",
  "all issuer templates are displayed here in a list": "all issuer templates are displayed here in a list",
  "Update scanner": "Update scanner",
  "Delete scanner": "Delete scanner",
  //Add Scanner
  "You do not have any template to create scanner": "You do not have any template to create scanner",
  "Name of scanner": "Name of scanner",
  "Allow all templates": "Allow all templates",
  //Scanner Distribution
  "No license holders listed": "No license holders listed",
  "As soon as a license holder authenticates his scanner you will see them listed here": "As soon as a license holder authenticates his scanner you will see them listed here",
  "You need to upload a list or manually add license holders to see them listed here": "You need to upload a list or manually add license holders to see them listed here",
  "Send license to selected": "Send license to selected",
  "Add License Holder": "Add License Holder",
  "Delete license holder list": "Delete license holder list",
  //license Holder
  "Activate this license holder": "Activate this license holder",
  "Deactivate this license holder": "Deactivate this license holder",
  "This license holder will be able to use the SmartScanner": "This license holder will be able to use the SmartScanner",
  "This license holder will not be able to use the SmartScanner": "This license holder will not be able to use the SmartScanner",

  //Add License Holder
  "Add license holder": "Add license holder",
  "You can add multiple license holder by uploading a .csv file": "You can add multiple license holder by uploading a .csv file",
  "What is a .csv file? You can download our template": "Hvað er .csv skrá? Þú getur sótt lista sniðmátið",
  "or you can add a license holder manually": "or you can add a license holder manually",

  //Send License and Passes
  "Sending passes": "Senda passa",
  "Send licenses": "Send licenses",
  "out of": "af",
  "customers selected": " viðskiptavinum valdir",
  "license holder selected": "license holder selected",
  "Subject": "Viðfangsefni",
  "Message": "Skilaboð",
  "Send Pass": "Senda passa",
  "Send license": "Send license",
  "License code will be recieved by email": "License code will be recieved by email",
  "Pass will be recieved by email": "Passi mun berast með tölvupósti",

  //Delete Popup
  "Delete your user list forever": "Eyða notendalistanum að eilífu",
  "Delete your active account user forever": "Eyða virkum aðgangi þínum endanlega",
  "Delete your scanner forever": "Delete your scanner forever",
  "Delete license holder list forever": "Delete license holder list forever",
  "Delete license holder forever": "Delete license holder forever",
  "This action cannot be undone": "Ekki er hægt að afturkalla þessa aðgerð.",
  "All licenses connected to this scanner will also be deleted": "All licenses connected to this scanner will also be deleted",
  "We recommend downloading the list before you delete it": "Við mælum með að niðurhala listanum áður honum er eytt.",
  "The license holder will not have a license to use SmartScanner anymore": "The license holder will not have a license to use SmartScanner anymore",
  "After you delete this list you will never be able to retrieve this list again": "Eftir að listanum hefur verið eytt muntu aldrei geta sótt hann aftur",
  "After you delete this scanner you will never be able to retrieve it again": "After you delete this scanner you will never be able to retrieve it again",
  "After you delete this license holder list you will never be able to retrieve it again": "After you delete this license holder list you will never be able to retrieve it again",
  "After you delete this license holder you will never be able to retrive it again": "After you delete this license holder you will never be able to retrive it again",

  //ContactUs
  "Contact Us": "Hafa samband",
  "Contact us about your customised subscription plan": "Hafðu samband við okkur um sérsniðna áskrift",
  "Go ahead, we're listening": "Gjörðu svo vel, við erum að hlusta",
  "Submit": "Senda inn",

  //Tool Tip
  "We build a Pass Template for you. Your customer personal pass is then made from that Pass Template": "We build a Pass Template for you. Your customer personal pass is then made from that Pass Template",
  "There are 4 different Pass Template types: coupon, ticket, punch and member": "There are 4 different Pass Template types: coupon, ticket, punch and member",
  "This is the maximum number of valid passes in circulation by your customers": "This is the maximum number of valid passes in circulation by your customers",
  "You don't need any hardware if you use the Customer self service functionality": "You don't need any hardware if you use the Customer self service functionality",
  "This functionality is only available in the Smartwallet app": "This functionality is only available in the Smartwallet app",


  //General Terms and Conditions of Service
  "Agreement": "Agreement",
  "Last update": "Last update",

 


  "Introduction": "Introduction",
  "This is the Privacy and Cookie Policy of Smart Solutions. These Policies are between you as a User (“you”, or “your”)": "This is the Privacy and Cookie Policy of Smart Solutions. These Policies are between you as a User (“you”, or “your”)",
  "And the legal entity that acts under the applicable laws and regulations as the Data Processor, and in that capacity is responsible for the processing of (personal) data": "And the legal entity that acts under the applicable laws and regulations as the Data Processor, and in that capacity is responsible for the processing of (personal) data",
  "Smart Solutions ehf (Company id 680995-2499) Vsk id 81978, Iceland (“we”, “us”, “our”)": "Smart Solutions ehf (Company id 680995-2499) Vsk id 81978, Iceland (“we”, “us”, “our”)",
  "We promise to implement the highest standards of privacy and data protection when we design our products and services, offering them to you. For us, the following three key principles play a central role in our privacy and data protection policies": "We promise to implement the highest standards of privacy and data protection when we design our products and services, offering them to you. For us, the following three key principles play a central role in our privacy and data protection policies",
  "You own your personal data": "You own your personal data",
  "We keep your data secure, and work only with parties that maintain the same high level of data security": "We keep your data secure, and work only with parties that maintain the same high level of data security",
  "We are always transparent about the way we treat your personal data": "We are always transparent about the way we treat your personal data",
  "If you visit our website and use our services, you acknowledge and agree that we store, collect and use your (personal) data in an automated way. We take all appropriate and necessary security measures in order to prevent unauthorized access to, disclosure or modification of all the data we collect and process, and we follow certain organizational procedures to adhere to all privacy and data protection laws that may apply": "If you visit our website and use our services, you acknowledge and agree that we store, collect and use your (personal) data in an automated way. We take all appropriate and necessary security measures in order to prevent unauthorized access to, disclosure or modification of all the data we collect and process, and we follow certain organizational procedures to adhere to all privacy and data protection laws that may apply",
  "If you have any doubts or questions about our privacy and data protection policies, or if you want to exercise your legal rights, please email us at": "If you have any doubts or questions about our privacy and data protection policies, or if you want to exercise your legal rights, please email us at",


  "Your Privacy": "Your Privacy",
  "This Privacy and Cookie Policy has been prepared based on multiple legislations for which we take full responsibility, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation), the California Consumer Privacy Act of 2018 and the US Children's Online Privacy Protection Act 1998 (COPPA)": "This Privacy and Cookie Policy has been prepared based on multiple legislations for which we take full responsibility, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation), the California Consumer Privacy Act of 2018 and the US Children's Online Privacy Protection Act 1998 (COPPA)",

  "All information requested by us is obligatory in order to use our services, and your refusal or failure to provide your (personal) data could hinder your access to our services. We may indicate that some specific (personal) data may not be necessary to provide in order to use our services. If you have questions about which personal data we collect, and is mandatory to provide, please request more information at": "All information requested by us is obligatory in order to use our services, and your refusal or failure to provide your (personal) data could hinder your access to our services. We may indicate that some specific (personal) data may not be necessary to provide in order to use our services. If you have questions about which personal data we collect, and is mandatory to provide, please request more information at",
  "We represent and warrant that we use industry standards to protect your (personal) data. However, you are aware that there is no method of electronic storage that is absolutely safe, and 100% secure on the internet": "We represent and warrant that we use industry standards to protect your (personal) data. However, you are aware that there is no method of electronic storage that is absolutely safe, and 100% secure on the internet",
  "As part of our business practice, we may authorize and appoint third-parties, including but not limited to (technical) service and hosting providers, mail carriers, agents and contractors that handle your (personal) data. Upon request, we will provide the most recent list of all the above-mentioned third-parties": "As part of our business practice, we may authorize and appoint third-parties, including but not limited to (technical) service and hosting providers, mail carriers, agents and contractors that handle your (personal) data. Upon request, we will provide the most recent list of all the above-mentioned third-parties",
  "We collect and process different types of information and (personal) data necessary in order to provide and improve our services to you": "We collect and process different types of information and (personal) data necessary in order to provide and improve our services to you",

  "Collection and Use": "Collection and Use",
  "Device ID": "Device ID",
  "Device Brand and Make": "Device Brand and Make",
  "Device Operating System (OS)": "Device Operating System (OS)",
  "Personal Data": "Personal Data",
  "We solely collect and process specific information and (personal) data, when": "We solely collect and process specific information and (personal) data, when",
  "You have given us your consent": "You have given us your consent",
  "It is necessary for the performance of a contractual or pre-contractual duty with you": "It is necessary for the performance of a contractual or pre-contractual duty with you",
  "We have to adhere to a legal obligation to which we are subject": "We have to adhere to a legal obligation to which we are subject",
  "The handling of (personal) data is carried out in the public interest": "The handling of (personal) data is carried out in the public interest",
  "When the handling of (personal) data is for our legitimate interests or those of third-parties": "When the handling of (personal) data is for our legitimate interests or those of third-parties",

  "Retention Time": "Retention Time",
  "Your (personal) data shall be stored for as long as the legitimate purpose requires it, typically for six (6) months. However, there are circumstances under which we retain (personal) data for a longer period of time, for example when you have not withdrawn your consent or in case of a legal obligation or judicial order. Once the retention period expires, we may delete your (personal) data permanently, and as a result your right to access, erase and amend your (personal) data cannot be exercised": "Your (personal) data shall be stored for as long as the legitimate purpose requires it, typically for six (6) months. However, there are circumstances under which we retain (personal) data for a longer period of time, for example when you have not withdrawn your consent or in case of a legal obligation or judicial order. Once the retention period expires, we may delete your (personal) data permanently, and as a result your right to access, erase and amend your (personal) data cannot be exercised",
  "Usage Data and Device Data": "Usage Data and Device Data",
  "Usage data we collect may include the date and time you logged in, app features you used, searches, click path and pages you visited. In addition we may collect Device data, which may include IP and MAC address, type of device and ID, specific apps settings, app crash reports, browser type and version, language settings, and your geolocation based on Bluetooth, Wi-Fi information and GPS": "Usage data we collect may include the date and time you logged in, app features you used, searches, click path and pages you visited. In addition we may collect Device data, which may include IP and MAC address, type of device and ID, specific apps settings, app crash reports, browser type and version, language settings, and your geolocation based on Bluetooth, Wi-Fi information and GPS",


  "Our Responsibility": "Our Responsibility",
  "General": "General",
  "We, our employees, agents, and affiliates are fully responsible for your personal information. We may also use the services of third-parties and may share your (personal) data with them.Some of these third-parties may be located outside Iceland, namely in the United States or the European Economic Area. In that case, the transfer of (personal) data to these third-parties will only be done after we have evaluated and fully assessed their privacy policies and they are in accordance with the highest standards of personal data protection and all necessary legal safeguards": "We, our employees, agents, and affiliates are fully responsible for your personal information. We may also use the services of third-parties and may share your (personal) data with them.Some of these third-parties may be located outside Iceland, namely in the United States or the European Economic Area. In that case, the transfer of (personal) data to these third-parties will only be done after we have evaluated and fully assessed their privacy policies and they are in accordance with the highest standards of personal data protection and all necessary legal safeguards",
  "Children’s Protection": "Children’s Protection",
  "Under no circumstances, persons under the age of thirteen (13) may use our Services or visit our Website according to the US Children's Online Privacy Protection Act 1998 (COPPA). Our services are restricted to Users who are eighteen (18) years of age or older, or an adult  according to the applicable law. We do not allow Users under the age of eighteen (18) on our website and platform and we do not collect and process personal information from anyone under the age of eighteen (18). If you suspect a User is under the age of thirteen (13) or eighteen (18), please report this immediately to us": "Under no circumstances, persons under the age of thirteen (13) may use our Services or visit our Website according to the US Children's Online Privacy Protection Act 1998 (COPPA). Our services are restricted to Users who are eighteen (18) years of age or older, or an adult  according to the applicable law. We do not allow Users under the age of eighteen (18) on our website and platform and we do not collect and process personal information from anyone under the age of eighteen (18). If you suspect a User is under the age of thirteen (13) or eighteen (18), please report this immediately to us",
  "Your Rights": "Your Rights",
  "You may exercise certain rights. You have the right to": "You may exercise certain rights. You have the right to",
  "Withdraw consent where you have previously given it to the storing and processing of your personal data": "Withdraw consent where you have previously given it to the storing and processing of your personal data",
  "Object to the storing and processing of your (personal) data": "Object to the storing and processing of your (personal) data",
  "Verify the correctness and accuracy of your (personal) data and ask for updates and corrections": "Verify the correctness and accuracy of your (personal) data and ask for updates and corrections",
  "Demand the full erasure of all of your data from us": "Demand the full erasure of all of your data from us",
  "Receive your data in a commonly used and machine-readable format and, have it transmitted to another controller without any hindrance": "Receive your data in a commonly used and machine-readable format and, have it transmitted to another controller without any hindrance",
  "Bring a claim before the competent, and national Data Protection Authority (DPA)": "Bring a claim before the competent, and national Data Protection Authority (DPA)",



  "Why we process your data": "Why we process your data",
  "Here we explain the exact reasons and purposes why we process your data": "Here we explain the exact reasons and purposes why we process your data",
  "To provide services to you": "To provide services to you",
  "To communicate with you by telephone and email": "To communicate with you by telephone and email",
  "To provide you with information relating to other services": "To provide you with information relating to other services",
  "To grant you access to our services, and specific features of our services": "To grant you access to our services, and specific features of our services",
  "To give customer support for technical matters": "To give customer support for technical matters",
  "To monitor the way you use our service": "To monitor the way you use our service",
  "To detect and prevent fraud or any other illegal or unauthorized activity": "To detect and prevent fraud or any other illegal or unauthorized activity",
  "To evaluate and improve our website, app and services": "To evaluate and improve our website, app and services",

  "Third-party controllers and processors": "Third-party controllers and processors",
  "We employ third-party service providers and companies with whom we share personal data. Those service providers will only have access to data to perform tasks on our behalf. The third parties that are mentioned in this Privacy and Cookie policy operate their respective services independently from us. You agree and acknowledge you have read and fully understand their respective privacy and data protection policies. We have no control whatsoever over it, and assume no liability for the privacy policies or related practices of any third-party website": "We employ third-party service providers and companies with whom we share personal data. Those service providers will only have access to data to perform tasks on our behalf. The third parties that are mentioned in this Privacy and Cookie policy operate their respective services independently from us. You agree and acknowledge you have read and fully understand their respective privacy and data protection policies. We have no control whatsoever over it, and assume no liability for the privacy policies or related practices of any third-party website",

  "Third-Party Service Providers": "Third-Party Service Providers",
  "Sub-Processing Activities": "Sub-Processing Activities",
  "Country of Operations": "Country of Operations",
  "Link to Privacy Policy": "Link to Privacy Policy",
  "Google Analytics (Google LLC)": "Google Analytics (Google LLC)",
  "Visitor’s/User’s data and analytics": "Visitor’s/User’s data and analytics",
  "Apple Pay (In-App Purchase) (Apple Payments Inc": "Apple Pay (In-App Purchase) (Apple Payments Inc",
  "Subscribed User’s data and analytics": "Subscribed User’s data and analytics",
  "Google Pay (Google LLC)": "Google Pay (Google LLC)",
  "Payment data processing": "Payment data processing",
  "Valitor hf.": "Valitor hf.",
  "Payment data processing": "Payment data processing",
  "Borgun hf": "Borgun hf",
  "Payment data processing": "Payment data processing",



  "Cookies": "Cookies",
  "We use cookies and other tracking technologies to monitor all activities on our website and in our app. Cookies are small files with data, containing a so-called anonymous identifier. Cookies can be sent to your browser from a website you visit, and subsequently stored on your computer or phone. You can change your browser settings in order to reject all cookies or to request a notification when a cookie is being sent. However, if you do not accept cookies, you may not be able to use our services": "We use cookies and other tracking technologies to monitor all activities on our website and in our app. Cookies are small files with data, containing a so-called anonymous identifier. Cookies can be sent to your browser from a website you visit, and subsequently stored on your computer or phone. You can change your browser settings in order to reject all cookies or to request a notification when a cookie is being sent. However, if you do not accept cookies, you may not be able to use our services",


  "Amendments to this Privacy Policy": "Amendments to this Privacy Policy",
  "We may amend our Privacy and Cookie Policy from time to time, which changes are effective when they are posted on this page. We advise you to review our Website and this Privacy Policy from time to time": "We may amend our Privacy and Cookie Policy from time to time, which changes are effective when they are posted on this page. We advise you to review our Website and this Privacy Policy from time to time",

  "Legal Information": "Legal Information",
  "This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). And the California Consumer Privacy Act of 2018. This Privacy and Cookie Policy relates solely to Smart Solutions Ehf., if not stated otherwise within this Privacy Policy": "This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). And the California Consumer Privacy Act of 2018. This Privacy and Cookie Policy relates solely to Smart Solutions Ehf., if not stated otherwise within this Privacy Policy",
};
