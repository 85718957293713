import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print"; //scan1
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import LogoQr from '../../assets/images/logo_Qr.png';
import DummyDistCode from '../../assets/images/scan3.png';
import DummySelfServCode from '../../assets/images/scan4.png';
import { Redirect } from 'react-router';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class CodesTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            authorizeScannerCode: [],
            passTemplateCodes: [],
            selfServiceCode: {}
        }
    }

    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        // this.getCodesData(); // Get Member templates
    }

    downloadCode(imgUrl) {
        window.open(imgUrl)
    }

    // getCodesData(props = this.props) {
    //     this.setState({loader:true})
    //     if (localStorage.getItem('loginISP_user_sesion') == null) {
    //         toast.error(Validation_Constants.Login_Session_Revoked, { hideProgressBar: true, loader: false });
    //         return <Redirect to='/signin' />
    //     }
    //     // this.setState({ loader: true });
    //     var PostData = { access_token: functions.getAccessToken() };
    //     api.getCodesData(PostData)
    //         .then(res => {
    //             if (res.data.status == 1) {
    //                 this.setState({
    //                     loader: false,
    //                     authorizeScannerCode: res.data.data.authorizeScannerCode.scannersData,
    //                     passTemplateCodes: res.data.data.passTemplateCodes.templatesData,
    //                     selfServiceCode: res.data.data.selfServiceCode
    //                 })
    //             } else {
    //                 // toast.error(res.data.message, { hideProgressBar: true })
    //             }
    //         }).catch(err => {
    //             this.setState({ loader: false })
    //         });
    // }
    handleClick = (e, data, target) => {
        // var anchor = document.createElement('a');
        // anchor.href = data.link;
        // anchor.download = data.link;
        // document.body.appendChild(anchor);
        // anchor.click();
        var link = document.createElement('a');
        link.href = data.link;
        var fileName = data.link.substring(data.link.lastIndexOf('/') + 1, data.link.length);
        link.download = fileName;
        window.open(data.link);
        // a.href = data.link;
        // window.self.set_header("Content-Disposition", 'attachment; filename=code.png')
        // a.target = "_blank";
        // a.download = 'code.png';
        // a.click();
        //   window.open(data.link + query);
    }

    render() {

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        const codes = this.props.Codes;
        const selfServiceCode = this.props.SelfServiceCode;
        let loader = functions.getLoader(this.state.loader);
        if (Object.keys(selfServiceCode).length > 0 || Object.keys(codes).length > 0) {
            return (
                <div>
                    <div className="same_status_section codes_section">
                        {loader}
                        <div className="codes_left">
                            <h3>{T("Pass Template distribution code")}</h3>
                            <span>{T("Share this code to distribute your pass to customers")}. <br /> {T("You can share it any way you prefer. Online, in print or simply")} <br /> {T("send it to your customers")}.</span>
                        </div>
                        <ContextMenuTrigger id="right_click_action_QR" >
                            <div className="codes_right">
                                <div className="codes-image_section">
                                    <span>{T("Right click on this code")}<br />{T("to save it")}</span>
                                    <img src={codes.qrcode_path} />
                                    <a href={codes.distributionLink} target="_blank" >{codes.distributionLink}</a>
                                </div>
                            </div>
                        </ContextMenuTrigger>
                    </div>

                    <div className="same_status_section codes_section codes-scaaner_two">
                        <div className="codes_left">
                            <h3>{T("Customer self service code")}</h3>
                            <span>{T("Display this code to your customer so they can scan this")} <br /> {T("code to use their pass")}. <br /> {T("Take care of how you store this code")}.</span>
                        </div>
                        <div className="codes_right">
                            {Object.keys(selfServiceCode).length > 0 &&
                                <ContextMenuTrigger id="right_click_action_SR" >
                                    <div className="codes-image_section">
                                        <span>{T("Right click on this code")}<br />{T("to save it")}</span>
                                        <img src={selfServiceCode.selfServiceCodeLink} />
                                        {/* <span className="code-name">{selfServiceCode.selfServiceCodeName}</span> */}
                                    </div>
                                </ContextMenuTrigger>
                            }
                            {Object.keys(selfServiceCode).length == 0 &&
                                <div className="self-code">
                                    <span>{T("Your self service code")} <br /> {T("will appear here")}</span>
                                </div>
                            }

                        </div>
                    </div>
                    <ContextMenu id="right_click_action_QR" className="custom-context-item">
                        <MenuItem data={{ link: codes.qrcode_path }} onClick={this.handleClick}>
                            {T("Save image as")} <i className="fa fa-save"></i>
                        </MenuItem>
                    </ContextMenu>
                    <ContextMenu id="right_click_action_SR" className="custom-context-item">
                        <MenuItem data={{ link: selfServiceCode.selfServiceCodeLink }} onClick={this.handleClick}>
                            {T("Save image as")} <i className="fa fa-save"></i>
                        </MenuItem>
                    </ContextMenu>
                    {/* <ContextMenu id="right_click_action_QR" className="custom-context-item">
                        <MenuItem>
                            <a href={codes.qrcode_path} download>Save <i className="fa fa-save"></i></a> 
                        </MenuItem>
                    </ContextMenu>
                    <ContextMenu id="right_click_action_SR" className="custom-context-item">
                        <MenuItem>
                            <a href={selfServiceCode.selfServiceCodeLink} download>Save <i className="fa fa-save"></i></a>
                        </MenuItem>
                    </ContextMenu> */}

                </div>
            );
        } else {
            return (
                <div>
                    <div className="same_status_section codes_section">
                        <div className="codes_left">
                            <h3>{T("Pass Template distribution code")}</h3>
                            <span>{T("Share this code to distribute your pass to customers")}. <br /> {T("You can share it any way you prefer. Online, in print or simply")} <br /> {T("send it to your customers")}.</span>
                        </div>
                        <div className="codes_right">
                            <div className="codes-image_section">
                                <span>{T("Right click on this code")}<br />{T("to save it")}</span>
                                <div className="qrcodeImg"><img src={DummyDistCode} /></div>
                                <a href="#0">https://d.snjallveskid.io/t/BbnFsTOSG</a>
                            </div>
                        </div>
                    </div>
                    <div className="same_status_section codes_section codes-scaaner_two">
                        <div className="codes_left">
                            <h3>{T("User self service code")}</h3>
                            <span>{T("Display this code to your customer so they can scan this")} <br /> {T("code to use their pass")}. <br /> {T("Take care of how you store this code")}.</span>
                        </div>
                        <div className="codes_right">
                            <div className="codes-image_section">
                                <span>{T("Right click on this code")}<br />{T("to save it")}</span>
                                <div className="selfservicecodeImg"><img src={DummySelfServCode} /></div>
                                <span className="code-name">{T("Self Service Code Name")}</span>
                            </div>
                        </div>
                    </div>
                    {/* OVER LAY FOR DUMMY CONTENT*/}
                    <div className="dummy_data-content">
                        <div className="dummy_data-content-logo">
                            <img src={LogoQr} alt="" />
                            <h3>{T("DEMO CONTENT")}</h3>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default CodesTab;
