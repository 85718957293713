import React from "react";
import { Constants } from '../config';
import axios from 'axios';
import { functions } from '../functions';
import $ from 'jquery';
import { func } from "prop-types";
export const api = {
    login,
    signUp,
    resetPassword,
    setNewPassword,
    changePassword,
    getAccountData,
    getIssuerTemplates,
    getScannersList,
    getScannerTemplateList,
    accountUpdate,
    getSubscriptionData,
    getMemberTemplates,
    addMembersToDB,
    changeMemberStatus,
    emailData,
    getPassStatisticsData,
    getCodesData,
    saveSubscriptionToDB,
    cancelSubscription,
    confirmIssuerEmail,
    getTemplateStats,
    getTemplateUser,
    getScannerStats,
    SaveCardDetailWithPayments,
    cancelSubscription,
    addNewUserToDist,
    sendSelectedPasses,
    sendSelectedLicense,
    addLicenseHolderCsv,
    addScanner,
    addLicenseHolder,
    updateScanner,
    deleteScanner,
    deleteLicenseHolderList,
    changeActivationStatus,
    changeLicenseHolderStatus,
    deleteLicenseHolder,
    deleteDistributeList,
    IsAuthenticatedUser,
    ContactUsRequest,
    getActiveAccountUsers,
    setActiverUserPermission,
    inviteActiveUser,
    getInviteUserInfo,
    deleteActiveUsers,
    logout

}

// change pass activation status
function logout() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.Logout_API, { "headers": headers }
    )
        .then(function (response) {
            return response;
        })
        .catch(err => {

        })
}

// to authenticate user session
function IsAuthenticatedUser() {
    let token = functions.getUserToken()
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.IsAuthenticatedUser, { "headers": headers })
        .then(response => {
            let err = { "error": response }
            functions.checkUnAuthenticated(response);
            return response
        })
        .catch(error => {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(error.response);
            return error.response;
        })
}

// to send contact us email
function ContactUsRequest(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.ContactUs_API,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            return error;
        })
}

// to delete template distribution list
function deleteDistributeList(passId = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.Delete_Distribute_List + passId, { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            functions.checkUnAuthenticated(error.response);
            return error;
        })
}

// change user activation status
function changeActivationStatus(passid = 0, PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Change_Pass_Activation_Status + passid,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        })
}

// chagne license holder  status
function changeLicenseHolderStatus(scannerId = 0, scannerUserId = 0, PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Change_License_Holder_Status + scannerId + '/' + scannerUserId,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        })
}

// delete single license holder
function deleteLicenseHolder(scannerId = 0, scannerUserId = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.Delete_License_Holder_API + scannerId + '/' + scannerUserId, { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        })
}
//for send users id
function sendSelectedPasses(PostData = {}, template_id = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Send_Passes_To_Customers + template_id,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            $('body').removeClass('modal-open-again modal-open-again-order')
            $('.modal-backdrop').remove()
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        })
}

// to send scanner for license holder
function sendSelectedLicense(PostData = {}, template_id = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Send_License_To_Customers + template_id,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            $('body').removeClass('modal-open-again modal-open-again-order')
            $('.modal-backdrop').remove()
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        })
}

//For User Login
function login(PostData = {}) {
  
    let data= functions.Client(PostData);
    let Content=functions.D_Client(data)
    return axios.post(Constants.Login_API,
        functions.Client(PostData)
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
            //alert(Validation_Constants.API_Server_Not_Responding);
        });
}

//For Forgot Password
function resetPassword(PostData = {}) {
    const headers = {
        'clientEndLocale':localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang'):'en',
    }
    return axios.post(Constants.ResetPassword_API,
        functions.Client(PostData),{'headers':headers}
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
            //alert(Validation_Constants.API_Server_Not_Responding);
        });
}

//For Set New Password
function setNewPassword(PostData = {}) {
    return axios.post(Constants.SetNewPassword_API,
        functions.Client(PostData)
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
            //alert(Validation_Constants.API_Server_Not_Responding);
        });
}

//For change Password
function changePassword(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token    }
    return axios.post(Constants.ChangePassword_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return err;
        });
}

//For issuer email confirmation 
function confirmIssuerEmail(PostData = {}) {
    const headers = {
        'clientEndLocale':localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang'):'en',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + PostData.key
    }
    return axios.get(Constants.ConfirmEmail_API,
         { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//For user Signup
function signUp(PostData = {}, accountActivationKey) {
    const headers = {
        'clientEndLocale':localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang'):'en',
    }
    let api = accountActivationKey != "" ? Constants.Signup_API + '/' + accountActivationKey : Constants.Signup_API;
    return axios.post(api,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
}

// For get Account Data 
function getAccountData(PostData = {}) {
    let token = functions.getUserToken();
    let access_token = functions.getAccessToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Account_Data_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

// To get Issuer templates 
function getIssuerTemplates() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Issuer_Templates_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return err;
        });
}

// get single scanner data
function getScannerStats(scannerId = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Get_Scanner_Data + scannerId, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}

// get all scanner list of issuer
function getScannersList() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Scanner_List_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return err;
        });
}
// get issuer template list to create scanner
function getScannerTemplateList(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Scanner_Template_List_Api, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return err;
        });
}

// add new scanner
function addScanner(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Add_Scanner_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error.response;
        });
}

// to add a new license holder
function addLicenseHolder(PostData = {}, currentScannerId = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Add_License_Holder_API + currentScannerId,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error.response;
        });
}

// to add multiple licenseholder with csv
function addLicenseHolderCsv(PostData = {}, currentScannerId = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Add_License_Holder_Csv_API + currentScannerId,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error.response;
        });
}

//  to update single scanner
function updateScanner(PostData = {}, scanner_id) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Update_Scanner_API + scanner_id,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error.response;
        });
}

// to delete scanner
function deleteScanner(scanner_id = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.Delete_Scanner_API + scanner_id, { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            functions.checkUnAuthenticated(error.response);
            return error;
        })
}

//delete all list of license holder
function deleteLicenseHolderList(scanner_id = 0) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.Delete_License_Holder_List_API + scanner_id, { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            functions.checkUnAuthenticated(error.response);
            return error;
        })
}


// For account Update
function accountUpdate(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Account_Update_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

// For get Subscription Data 
function getSubscriptionData() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Subscription_Data_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error.response;
        });
}

// get active account user list of admin issuer
function getActiveAccountUsers() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.ActiveUserData_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error
        });
}

// set account permission for subIssuer
function setActiverUserPermission(subIssuerId = -1, PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Set_Active_User_Permission_API + subIssuerId,
        functions.Client(PostData), { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            functions.checkUnAuthenticated(error.response);
            return error;
        })
}

// invite a new sub Iusser 
function inviteActiveUser(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'clientEndLocale':localStorage.getItem('rtc-lang') && localStorage.getItem('rtc-lang') == 'is' ? 'is':'en',
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.InviteActiveUser_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error
        });
}

// delete invited sub Issuer
function deleteActiveUsers(subIssuerId = -1) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.delete(Constants.DeleteActiveUser_API + subIssuerId, { "headers": headers })
        .then(response => {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(error => {
            functions.checkUnAuthenticated(error.response);
            return error;
        })
}

// get Invited user Info
function getInviteUserInfo(accountActivationKey) {
    const headers = {
        'Content-Type': 'application/json',
    }
    return axios.get(Constants.InviteUserInfo_API + accountActivationKey,
        { "headers": headers }
    )
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error
        });
}

// Save to DB
function SaveCardDetailWithPayments(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'clientEndLocale':localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang'):'en',
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.SaveCardDetailWithPayments_Data_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error.response;
        });
}

//For get Member templates data
function getMemberTemplates(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.MemberTemplate_Data_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//Get Template statistics
function getTemplateStats(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Template_Stats_API + PostData.tempId, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}

// get template use list
function getTemplateUser(PostData = {}, tempId=-1) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    // return axios.get(Constants.Template_Users_API + tempId +'?page=' + PostData.page + '&pageSize=' + PostData.pageSize + '&searchTerm=' + PostData.searchTerm +'&sortVal='+PostData.sortVal +'&sortName='+PostData.sortName +'&isDynamicColumn='+PostData.isDynamicColumn, { "headers": headers }
    return axios.post(Constants.Template_Users_API + tempId, functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}

//For adding Members to DB
function addMembersToDB(templateid, PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.AddMembers_DB_API + templateid,
        functions.Client(PostData), { "headers": headers })
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}

//For changing the member status
function changeMemberStatus(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Change_Member_Status_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//For sending the email
function emailData(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Send_Member_Pass_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//For getting general statistics for issuer
function getPassStatisticsData(PostData = {}) {
    return axios.post(Constants.Pass_Statistics_Data_API,
        functions.Client(PostData)
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//For getting all the codes
function getCodesData(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Codes_Data_API,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}

//For saving subscription to database
function saveSubscriptionToDB(PostData = {}) {
    return axios.post(Constants.Save_Subscription_API,
        functions.Client(PostData)
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//For cancellation of subscription
function cancelSubscription() {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
    return axios.get(Constants.Cancel_Subscription_API, { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

//Add new User to Distribution list
function addNewUserToDist(PostData = {}) {
    let token = functions.getUserToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': 'Bearer ' + token
    }
    return axios.post(Constants.Add_User_Dist + '/' + PostData.id,
        functions.Client(PostData), { "headers": headers }
    )
        .then(function (response) {
            functions.checkUnAuthenticated(response);
            return response;
        })
        .catch(function (error) {
            $('body').removeClass('modal-open-again modal-open-again-order')
            $('.modal-backdrop').remove()
            let err = { "error": error.response }
            functions.checkUnAuthenticated(err.error);
            return error;
        });
}
export default api;