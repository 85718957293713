import React from 'react';
import { functions } from "../../functions";
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import 'react-toastify/dist/ReactToastify.css';
export default class ActiveUserPermissions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            allChecked: false,
            invite_users: false,
            upgrade_subscription: false,
            active_status: false,
            userId: -1,
            currentActiveAccount: {},
            subIssuerid: -1
        };
    }
    componentDidMount() {
        if ((Object.keys(this.props.activeAccountUsers).length) > 0) {
            let activeAccountUsers = this.props.activeAccountUsers
            let activeAccountKey = this.props.activeAccountKey
            let currentActiveAccount = activeAccountUsers[activeAccountKey]
            this.setState({
                currentActiveAccount: currentActiveAccount,
                userId: currentActiveAccount.id ? currentActiveAccount.id : -1,
                active_status: currentActiveAccount.status && currentActiveAccount.status == '1' ? true : false,
                upgrade_subscription: currentActiveAccount.issueraccountuserpermission && currentActiveAccount.issueraccountuserpermission.upgrade_subscription == '1' ? true : false,
                invite_users: currentActiveAccount.issueraccountuserpermission && currentActiveAccount.issueraccountuserpermission.invite_users == '1' ? true : false,
            }, () => {
                this.setState({
                    allChecked: this.state.invite_users && this.state.upgrade_subscription && this.state.active_status ? true : false
                })
            })
        }

    }

    changePermissionAll(action) {
        this.setState({
            invite_users: action.target.checked,
            upgrade_subscription: action.target.checked,
            active_status: action.target.checked,
            allChecked: action.target.checked
        })
    }

    changePermission(event) {
        this.setState({
            [event.target.name]: event.target.checked,
        }, () => {
            this.setState({
                allChecked: this.state.invite_users && this.state.upgrade_subscription && this.state.active_status ? true : false
            })
        })

    }

    setUserPermisson() {
        toast.dismiss();
        this.setState({ loader: false })
        var PostData = {
            // access_token: functions.getAccessToken(),
            status: this.state.active_status,
            invite_users: this.state.invite_users,
            upgrade_subscription: this.state.upgrade_subscription,
        };
        api.setActiverUserPermission(this.state.userId, PostData)
            .then(res => {
                if (res.data && res.data.status == 1) {
                    setTimeout(() => {
                    toast.success(res.data.message, { hideProgressBar: true })
                },300)
                    this.props.modalClose('permission')
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true })
                    },300)
                }
                this.setState({ loader: false })
            })
            .catch(err => {
                this.setState({ loader: false })
                setTimeout(() => {
                    toast.error(err.message, { hideProgressBar: true })
                },300)
            })
    }
    render() {

        let loader = functions.getLoader(this.state.loader)

        return (
            <div class="modal-dialog modal-dialog-centered">
                {this.state.currentActiveAccount &&
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                            <h4>{T("Manage permissions")}</h4>
                        </div>
                        <div class="modal-body">
                            <div className="user_detail">
                                {this.state.currentActiveAccount.user != null &&
                                    <div className="user_name"><h6>{T("Name")}: </h6> <span> {this.state.currentActiveAccount.user.first_name} </span></div>
                                }
                                <div className="user_email"><h6>{T("Email")}:</h6><span> {this.state.currentActiveAccount.email} </span></div>
                            </div>
                            <div className="user_permissions">
                                <table>
                                    <thead><tr>
                                        <th>{T("Permission")}</th><th><span>{T("Action")}</span><div><label class="switch"><input onClick={(e) => { this.changePermissionAll(e) }} type="checkbox" checked={this.state.allChecked} value="all" /> <span class="slider round"></span></label></div></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {/* {
                                            this.state.permissions.map((val, key) => {
                                                return (
                                                    <tr>
                                                        <td>{val.permission}</td>
                                                        <td><label class="switch"><input key={val.id} onClick={(e) => { this.changePermission(e) }} type="checkbox" checked={val.action} value={key} /><span class="slider round"></span></label></td>
                                                    </tr>
                                                )
                                            })
                                        } */}
                                        <tr>
                                            <td>{T("Active Account")}</td> <td><label class="switch"><input name="active_status" checked={this.state.active_status} onClick={(e) => this.changePermission(e)} type="checkbox" /><span class="slider round" /></label></td>
                                        </tr>
                                        <tr>
                                            <td>{T("Change Subscription")}</td> <td> <label class="switch"><input name="upgrade_subscription" checked={this.state.upgrade_subscription} onClick={(e) => this.changePermission(e)} type="checkbox" /><span class="slider round" /></label></td>
                                        </tr>
                                        <tr>
                                            <td>{T("Invite Users")}</td> <td> <label class="switch"><input type="checkbox" name="invite_users" checked={this.state.invite_users} onClick={(e) => this.changePermission(e)} /><span class="slider round" /></label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div className="deleteBtns permission_delete_btn">
                                {this.state.currentActiveAccount.user == null &&
                                    <button disabled={this.state.loader} type="button" onClick={(id) => { this.props.updateState(this.state.currentActiveAccount.id) }} class="btn btn-danger btn_delete" data-toggle="modal" data-target="#AssociatedDelete">{T("Delete User")}</button>
                                }
                                {this.state.currentActiveAccount.user != null &&
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">{T("Cancel")}</button>
                                }
                                <button type="button" disabled={this.state.loader} onClick={(e) => { this.setUserPermisson(e) }}>{T("Save")}</button>

                            </div>
                        </div>
                        {loader}
                    </div>
                }
            </div>

        )
    }
}