import React from 'react';
import { functions } from "../../functions";
import NumberFormat from 'react-number-format';
import 'react-toastify/dist/ReactToastify.css';
import Redirect from 'react-router/Redirect';
import { Constants, Validation_Constants } from '../../config';
import moment from 'moment';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

import $ from 'jquery';
export default class OrderConfirmationPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loader: false,
        };
    }

    redirectToSubscription() {
        this.setState({
            redirect: true
        })
    }

    render() {
        let loader = functions.getLoader(this.state.loader);
        const orderData = this.props.OrderData;
        const tempOrder = this.props.orderTemp;
        const DiscountPeriodExpired = this.props.DiscountPeriodExpired;

        let priceDeducted = 0;
        let totalPrice=0;
        let VAT = 0;
        if (this.props.SubscriptionState && this.props.SubscriptionState.subscription_due_date) {
            priceDeducted = parseInt(DiscountPeriodExpired ? functions.calculatePrice(this.props.SubscriptionState.trailPeriodStartOn,this.props.SubscriptionState.subscription_due_date, this.props.SubscriptionState.price) : functions.calculatePrice(this.props.SubscriptionState.trailPeriodStartOn,this.props.SubscriptionState.subscription_due_date, this.props.SubscriptionState.price));
        }
        // let totalPrice = orderData && !DiscountPeriodExpired ? ((orderData.offer_price - priceDeducted) * 1.24) : (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 1.24) : 0);
        // let VAT = orderData && !DiscountPeriodExpired ? ((orderData.offer_price - priceDeducted) * 0.24) : (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 0.24) : 0);
            if(parseInt(this.props.subscription_discount)>0){
                totalPrice=(orderData && ((orderData.price-parseInt(orderData.price*this.props.subscription_discount/100)) > 0) ? (((orderData.price - parseInt(orderData.price*this.props.subscription_discount/100)) - priceDeducted) * 1.24) : 0)
                VAT = (orderData && ((orderData.price-parseInt(orderData.price*this.props.subscription_discount/100)) > 0) ? (((orderData.price - parseInt(orderData.price*this.props.subscription_discount/100)) - priceDeducted) * 0.24) : 0)
            }
            else if(orderData && !DiscountPeriodExpired ){
                totalPrice=((orderData.offer_price - priceDeducted) * 1.24)
                VAT = ((orderData.offer_price - priceDeducted) * 0.24)
            }else{
                totalPrice=(orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 1.24) : 0)
                VAT = (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 0.24) : 0)
            }
        if (tempOrder == '1') {
            totalPrice = totalPrice + (this.props.template_price * 1.24);
            //    VAT = VAT + (this.props.template_price * 0.24);
        }

        if (this.state.redirect) {
            //        return (<Redirect to="/subscription" />)
            window.location.reload();
        }
        // let totalPrice = orderData && !DiscountPeriodExpired ? (orderData.offer_price * 1.24) : (orderData && (orderData.price > 0) ? (orderData.price * 1.24) : 0);

        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.redirectToSubscription()}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <p className="modal_body_haeding">{T("Your order confirmation")}</p>
                        <p className="date-selection"> {moment(new Date()).format('MMMM Do YYYY')}</p>
                        <div className="toatl_vat_price">
                            <div className="toatl_vat_price_left">
                                <span className="total_cost_vat">{T("Total price with")} {T('VAT')}</span>
                            </div>
                            <div className="toatl_vat_price_rgt">
                                {/* <span>ISK {parseInt(totalPrice)}</span> */}
                                <span>ISK {functions.toCurrency(totalPrice)}</span>
                                {/* <span>ISK <NumberFormat value={parseInt(totalPrice)} displayType={'text'} thousandSeparator={true} /></span> */}
                            </div>
                        </div>
                        <div className="receipt_section">
                            <p>{T("A receipt has been sent to")} <span>{functions.getInvoiceEmail()}</span></p>
                            <p>{T("As soon as we will get your Pass Template order we will contact you to get your Pass Template material. If you have any question  please contact")} <a href="mailto:sales@smartsolutions.is">sales@smartsolutions.is</a></p>
                            <p className="thakyou_msg">{T("Thank you for your business")}.</p>
                        </div>
                        <input type="button" className="btn btn-default submit_btn" value={T("Great")+'!'} data-dismiss="modal" onClick={() => this.redirectToSubscription()} />
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>

            </div>

        )
    }
}