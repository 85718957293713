import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from "../../api";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


export default class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false
        };
    }
    componentDidMount() {

    }
    render() {
        let loader = functions.getLoader(this.state.loader);
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" >&times;</button>
                        <h1>{T("Contact Us")}</h1>
                    </div>
                    <div className="modal-body">
                        <h3>{T("Contact us about your customised subscription plan")}</h3>

                        <Formik
                            initialValues={{ enquiry: '' }}
                            onSubmit={(values, { setSubmitting, resetForm, setErrors, setStatus }) => {
                                this.setState({ loader: true });
                                // toast.dismiss();
                                var PostData = {
                                    // access_token: functions.getAccessToken(),
                                    enquiry: values.enquiry,
                                };
                                api.ContactUsRequest(PostData)
                                    .then((response) => {
                                        if (response.data.status == 1) {
                                            setStatus({ enquiry: response.data.message })
                                            this.props.modalClose();
                                            this.setState({ loader: false });
                                        } else {
                                            setErrors({ enquiry: T('There was an error please try again.') });
                                            this.setState({ loader: false });
                                        }
                                    })
                                    .catch(err => {
                                        setErrors({ enquiry: T('There was an error please try again.') });
                                        this.setState({ loader: false })
                                    })
                                    this.setState({ loader: false });
                                setSubmitting(false)
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    enquiry: Yup.string().required(T('This field is required')),
                                })}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    dirty,
                                    isSubmitting,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset,
                                    resetForm,
                                } = props;
                                return (
                                    <div>
                                        <form
                                            className="contactus-form"
                                            id="contactus-form"
                                            onSubmit={handleSubmit}
                                        >
                                            {this.state.loader &&
                                                <div className="empty_loading_div"></div>
                                            }
                                            {!this.state.loader &&
                                                <div className="form-group">
                                                    <div>
                                                        <textarea
                                                            name="enquiry"
                                                            id="enquiry"
                                                            placeholder={T("Go ahead, we're listening")}
                                                            value={values.enquiry}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                    <div className="error-main">
                                                        {errors.enquiry && touched.enquiry && (
                                                            <p className="error">{errors.enquiry}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            {!this.state.loader &&
                                                <div className="form-group-btn">
                                                    <input className={!this.state.loader && values.enquiry != "" ? "contactus_btn_active" : "contactus_btn_deactivate"} type="submit" value={T("Submit")} disabled={values.enquiry == ""} />
                                                    <input className="btn btn-danger" id="contactus_btn_reset" type="button" style={{ display: 'none' }} value={T("Back")} onClick={() => { resetForm() }} />
                                                </div>
                                            }
                                        </form>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                    <div className="modal-footer">
                    </div>
                    {loader}
                </div>

            </div>
        )
    }
}