import React from 'react';
import SmartSolutionInnerLogo from '../assets/images/smart-solution-inner-logo.png';
import { Link } from 'react-router-dom';
import { Constants } from '../config';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class FrontPage extends React.Component {

    render() {
       
        var fullDate = new Date();
        var currentYear = fullDate.getFullYear();
        $(document.body).addClass('first_front_page');
        return (
            <div className="comming_soon">
                <div className="bgimg">
                    <div className="topleft">
                        {/* <img src={SmartSolutionInnerLogo} /> */}
                        <img src={Constants.SmartSolutionLogoWitText}/>
                    </div>
                    <div className="middle">
                        <h2>{T("COMING SOON")}</h2>
                        <hr />
                        <p id="demo" style={{ fontSize: '30px' }}>
                            <Link className="btn btn-primary" to="/signin">{T("SignIn")}</Link>
                            &nbsp;&nbsp;&nbsp;
                            <Link className="btn btn-primary" to="/signup">{T("SignUp")}</Link>
                        </p>
                    </div>
                    <div className="bottomleft">
                        <p>&copy; {currentYear}</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default FrontPage;
