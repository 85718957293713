import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Profile',
            loader: false,
            formSubmit: true
        };
    }
componentDidMount(){
    api.IsAuthenticatedUser();
    if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
        return <Redirect to='/signin' />
    }
}
    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let currentYear = parseInt(new Date().getFullYear().toString().substr(-2));

        let years = [];
        for (var i = currentYear; i < currentYear + 25; i++) {
            years.push(i);
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);

        const AccountData = this.props.AccountData;
        setTimeout(() => {
            $("#password , #oldPassword ,#confirmPassword").attr('readonly', false);
            this.setState({loader:false})
        },1000)
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    oldPassword: '',
                    password: '',
                    confirmPassword: '',
                }}
                onSubmit={(values, { setSubmitting, setErrors, resetForm, setStatus }) => {
                    this.setState({ errors: '', loader: true, formSubmit: false });
                    toast.dismiss();
                    var PostData = {
                        password: values.password,
                        password_confirmation: values.confirmPassword,
                        oldPassword: values.oldPassword,
                        // access_token: functions.getAccessToken(),
                    };
                    if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
                        return (<Redirect to='/signin' />)
                    }
                    api.changePassword(PostData)
                        .then(res => {
                                if (res.data && res.data.status == 1) {
                                    this.setState({ loader: false, formSubmit: true })
                                    resetForm({});
                                    $('#passwordBack').trigger('click');
                                    setStatus({ msg: Validation_Constants.Changed_Password });
                                    setTimeout(() => {
                                        setStatus({ msg: '' })
                                    }, 3000);
                                    // toast.success(Validation_Constants.Changed_Password, { hideProgressBar: true });
                                    // window.location.reload();
                                } else {
                                    this.setState({ loader: false, formSubmit: true })
                                    setErrors({ oldPassword: res.error.data.message });
                                    // toast.error(({ closeToast }) => <div>{res.error.data.message}</div>, { hideProgressBar: true })
                                }
                        }).catch(function (err) {
                            this.setState({ loader: false, formSubmit: true })
                        });
                    setSubmitting(false);

                }}
                validationSchema={Yup.object().shape({
                    oldPassword: Yup.string()
                        .required(Validation_Constants.Empty_OldPassword),
                    password: Yup.string()
                        .required(Validation_Constants.Empty_NewPassword)
                        .min(8, Validation_Constants.Min_Password)
                        .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                        .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                        .max(20, Validation_Constants.Max_Password),
                        // .test('passwords-match', "New and old password can not be same ", function (value) {
                        //     return this.parent.oldPassword != value;
                        // }),
                    confirmPassword: Yup.string()
                        .required(Validation_Constants.Passwords_Not_Match_Empty)
                        .test('passwords-match', Validation_Constants.Invalid_NewRePassword, function (value) {
                            return this.parent.password === value;
                        }),
                })}
            >
                {
                    props => {
                        const {
                            values,
                            touched,
                            errors,
                            status,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            resetForm,
                            handleReset,
                        } = props;

                        return (
                            <div id="password" className={this.props.CurrentTab == 'change_password' ? 'tab-pane fade in active show password_sec same_profile_tab_style' : 'tab-pane fade in password_sec same_profile_tab_style'}>
                                {loader}
                                <form onSubmit={handleSubmit}>
                                    <h3>{T("Want to change your password")}?</h3>
                                    <div className="form-group">
                                        <div className="floating-label_group">
                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                id="oldPassword"
                                                type="password"
                                                value={values.oldPassword || ''}
                                                placeholder=" "
                                                readOnly={true}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.oldPassword && touched.oldPassword ? 'floating-input inputText error' : 'floating-input inputText'
                                                }
                                            />
                                            <span className="highlight"></span>
                                            <label for="oldPassword">{T("Old password")}</label>
                                            {errors.oldPassword &&
                                                touched.oldPassword && <p className="error">{errors.oldPassword}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="floating-label_group">
                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                id="password"
                                                type="password"
                                                readOnly={true}
                                                value={values.password || ''}
                                                placeholder=" "
                                                onChange={(event)=>{
                                                    handleChange(event)
                                                    handleBlur(event)  
                                                } }
                                                onBlur={handleBlur}
                                                className={
                                                    errors.password && touched.password ? 'floating-input inputText error' : 'floating-input inputText'
                                                }
                                            />
                                            <span className="highlight"></span>
                                            <label for="password">{T("New password")}</label>

                                            {errors.password &&
                                                touched.password && <p className="error">{errors.password}</p>
                                            }

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="floating-label_group">
                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                id="confirmPassword"
                                                type="password"
                                                readOnly={true}
                                                value={values.confirmPassword || ''}
                                                placeholder=" "
                                                onChange={(event)=>{
                                                    handleChange(event)
                                                    handleBlur(event)
                                                     
                                                } }
                                                onBlur={handleBlur}
                                                className={
                                                    errors.confirmPassword && touched.confirmPassword ? 'floating-input inputText error' : 'floating-input inputText'
                                                }
                                            />
                                            <span className="highlight"></span>
                                            <label for="confirmPassword">{T("Confirm new password")} </label>

                                            {errors.confirmPassword &&
                                                touched.confirmPassword && <p className="error">{errors.confirmPassword}</p>
                                            }
                                            {status && status.msg &&
                                                <p className="success">{status.msg}</p>}

                                        </div>
                                    </div>
                                    <div className="profile-form_btn">
                                        {/* <input type="submit" name="" className={(JSON.stringify(errors)=="{}")?'isActive':'isNotActive'} disabled={!this.state.formSubmit}  value="Update password" /> */}
                                        <input className="" id="passwordBack"  type="button" style={{display:'none',visibility:'hidden'}} value="" onClick={()=>{resetForm()}} />
                                        <input type="submit" name="" disabled={(JSON.stringify(errors) != "{}") ||values.oldPassword == "" || values.password == "" || values.confirmPassword == ""} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.oldPassword !== "" && values.password !== "" && values.confirmPassword !== "" ? 'isActive' : 'isNotActive'} value={T("Update passowrd")} />
                                    </div>
                                </form >
                            </div >
                        );
                    }
                }
            </Formik >

        );
        //    }
    }
};

export default ChangePassword;
