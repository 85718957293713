import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultUserPng from '../../assets/images/user.png';
import SmartSolutionInnerLogo from '../../assets/images/smart-solution-inner-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { functions } from "../../functions";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import { Constants } from '../../config';
import { Redirect } from 'react-router';
import TermsCondition from '../../pages/TermsCondition';
import { api } from '../../api';
import $ from 'jquery';
import EnglishCurrencyFlagsPng from '../../../locale/flags/english.png';
import IcelandCurrencyFlagsPng from '../../../locale/flags/iceland.png';


class UserHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogOut: false,
            usersMenuShow: false,
            languageMenuShow: false,
            activeStickyClass: false,
            loader: false,
            language: localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang') : 'en',

        };
        this.Logout = this.Logout.bind(this);
        this.ToggleMenu = this.ToggleMenu.bind(this);
        this.Togglelanugage = this.Togglelanugage.bind(this);
        this.ToggleMenuMobile = this.ToggleMenuMobile.bind(this);
    }
    Logout(e) {
        api.logout()
            .then(res => {
                e.preventDefault();
                localStorage.removeItem('loginISP_user_sesion');
                toast.success("Logout successfully.", { hideProgressBar: true });
                this.setState({ isLogOut: true, usersMenuShow: false, usersMenuMobileShow: false });
                setTimeout(() => {
                    toast.dismiss();
                }, 1000)
                window.location.href = Constants.introductionPage;
            })
    }
    ToggleMenu(e) {
        this.setState(prevState => ({
            usersMenuShow: !prevState.usersMenuShow
        }));
    }
    Togglelanugage(e) {
        this.setState(prevState => ({
            languageMenuShow: !prevState.languageMenuShow
        }));
    }
    ToggleMenuMobile(e) {
        this.setState(prevState => ({
            usersMenuMobileShow: !prevState.usersMenuMobileShow
        }));
    }
    changeLanguage(value) {
        this.setState({ language: value });
        this.Togglelanugage();
    }
    componentDidMount() {
        window.addEventListener('scroll', () => {
            let activeStickyClass = true;
            if (window.scrollY === 0) {
                activeStickyClass = false;
            }
            this.setState({ activeStickyClass });
        });
    }
    render() {
        if (this.state.usersMenuMobileShow) {
            $('body').addClass('body_mobile_menu_show')
        } else {
            $('body').removeClass('body_mobile_menu_show')
        }
        // if (this.state.isLogOut) { // If isLogOut
        //     window.location.href = Constants.introductionPage;
        // }
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If unauthrized user
            return <Redirect to='/signin' />
        }
        const CurrentTab = functions.getUrlSlice('/', '1'); //Get Current 
        let hideBackToTop = { "display": "none" };
        let showBackToTop = { "display": "block" }
        //Get User Name
        let CompanyName = '';
        if (localStorage.getItem('loginISP_user_sesion') != null) { // If authrized user
            CompanyName = functions.getCompanyName();
        }
        //END Get User Name
        let loader = functions.getLoader(this.state.loader);
        return (
            // <!-- Header -->
            <div>
                <header className={this.state.activeStickyClass ? "header_login padding_container block_element bg_color_section sticky" : "header_login padding_container block_element bg_color_section"}>
                    <div className="container">
                        {loader}
                        <div className="headerInside">
                            <a href="#" id="scroll" style={{}}><span></span></a>
                            <div className="header_logo">
                                {/* <Link to="/">
                        <img src={SmartSolutionInnerLogo} />
                        <img src={Constants.SmartSolutionLogoWitText}/>
                    </Link> */}
                                {(localStorage.getItem('loginISP_user_sesion') != null)
                                    &&
                                    <Link to="/dashboard">
                                        {/* <img src={SmartSolutionInnerLogo} /> */}
                                        <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                    </Link>
                                }
                                {(localStorage.getItem('loginISP_user_sesion') == null)
                                    &&
                                    <a href={Constants.introductionPage}>
                                        <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                    </a>
                                }

                            </div>
                            <div className="header-right-alignment">
                                {/* If User is login */}
                                {localStorage.getItem('loginISP_user_sesion') != null &&
                                    <div className="user_account">
                                        <div className="user_current" onClick={this.ToggleMenu}>
                                            <span>{CompanyName}</span>
                                            <img src={DefaultUserPng} alt="" />
                                            <i className={this.state.usersMenuShow ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                                        </div>
                                        <ul className={this.state.usersMenuShow ? "users show" : "users"}>
                                            <li className={CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard' ? 'active' : ''} onClick={this.ToggleMenu}><Link to="/my_pass_templates">{T("My Pass Templates")}</Link></li>
                                            <li className={CurrentTab == 'my_scanner' || CurrentTab == 'scanner' ? 'active' : ''} onClick={this.ToggleMenu}><Link to="/my_scanner">{T("My Scanners")}</Link></li>
                                            <li className={CurrentTab == 'profile' || CurrentTab == 'subscription' || CurrentTab == 'change_password' ? 'active' : ''} onClick={this.ToggleMenu}><Link to="/profile">{T("My Profile")}</Link></li>
                                            <li><a href="#Logout" onClick={this.Logout}>{T("Sign Out")}</a></li>
                                        </ul>
                                    </div>
                                }
                                {/* END If User is login */}

                                {/* <div className="language_selector">
                                    <div className="current_language" onClick={this.Togglelanugage}>
                                        <img src={this.state.language == 'is' ? IcelandCurrencyFlagsPng : EnglishCurrencyFlagsPng} /> <i className={this.state.languageMenuShow ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                                    </div>
                                    <ul className={this.state.languageMenuShow ? "users show" : "users"}>
                                        <li onClick={() => { this.changeLanguage('en') }}><img src={EnglishCurrencyFlagsPng} /></li>
                                        <li onClick={() => { this.changeLanguage('is') }}><img src={IcelandCurrencyFlagsPng} /></li>

                                    </ul>
                                    <LanguageList Language={this.state.language} Theme="Dropdown" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>

                {/* Mobile header -------------------------------------------------------- */}

                <header className={this.state.activeStickyClass ? "header_login mobile_header padding_container block_element bg_color_section sticky" : "mobile_header header_login padding_container block_element bg_color_section"}>
                    {loader}
                    <div className="headerInside">
                        <a href="#" id="scroll" style={{}}><span></span></a>
                        <div className="header_logo">
                            {(localStorage.getItem('loginISP_user_sesion') != null)
                                &&
                                <Link to="/dashboard">
                                    <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                </Link>
                            }
                            {(localStorage.getItem('loginISP_user_sesion') == null)
                                &&
                                <a href={Constants.introductionPage}>
                                    <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                </a>
                            }

                            {localStorage.getItem('loginISP_user_sesion') != null &&
								<div className="mobile-right">
									{/* <div className="language_selector mobile_language_selector">
                                        <div className="current_language" onClick={this.Togglelanugage}>
                                            <img src={this.state.language == 'is' ? IcelandCurrencyFlagsPng : EnglishCurrencyFlagsPng} /> <i className={this.state.languageMenuShow ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                                        </div>
                                        <ul className={this.state.languageMenuShow ? "users show" : "users"}>
                                            <li onClick={() => { this.changeLanguage('en') }}><img src={EnglishCurrencyFlagsPng} /></li>
                                            <li onClick={() => { this.changeLanguage('is') }}><img src={IcelandCurrencyFlagsPng} /></li>

                                        </ul>
                                        <LanguageList Language={this.state.language} Theme="Dropdown" />
                                    </div> */}
                                <div className="mobile_toggle_section">
                                    <div className={this.state.usersMenuMobileShow ? "mobile_header_toggle_menu menu_toggled" : "menu_untoggled mobile_header_toggle_menu"}
                                        onClick={() => {
                                            this.ToggleMenuMobile()
                                        }}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
								</div>
                            }
                        </div>
                        {localStorage.getItem('loginISP_user_sesion') != null &&
                            <div className={this.state.usersMenuMobileShow ? "mobile_menu_list show" : "mobile_menu_list hide"}>
                                <div className="mobile_menu_nav">
                                    <div className="mobile_menu_tab_list">
                                        <ul>
                                            <li onClick={() => {
                                                this.ToggleMenuMobile()
                                            }} className={CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard' ? 'active' : ''} ><Link to="/my_pass_templates">{T("My Pass Templates")}</Link></li>
                                            <li onClick={() => {
                                                this.ToggleMenuMobile()
                                            }} className={CurrentTab == 'scanner' || CurrentTab == 'my_scanner' ? 'active' : ''} ><Link to="/my_scanner">{T("My Scanners")}</Link></li>
                                            <li onClick={() => {
                                                this.ToggleMenuMobile()
                                            }} className={CurrentTab == 'profile' || CurrentTab == 'subscription' || CurrentTab == 'change_password' ? 'active' : ''}><Link to="/profile">{T("My Profile")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="mobile_menu_signout">
                                        <button type="button" onClick={() => {
                                            this.ToggleMenuMobile()
                                        }}><a href="#Logout" onClick={this.Logout}>{T("Sign Out")}</a></button>
                                    </div>
                                    <div className="mobile_menu_footer_list">
                                        <ul>
                                            <li id="header_contact_us" onClick={() => { $('#contactus_btn_reset').trigger('click') }} data-toggle="modal" data-target="#modalcontactus">{T("Contact us")}</li>
                                            <li onClick={() => {
                                                this.ToggleMenuMobile()
                                            }} id="termcondition_toggle_span" data-toggle="modal" data-target="#termsCondition">{T("General Terms and Conditions of Service")}</li>
                                            <li onClick={() => {
                                                this.ToggleMenuMobile()
                                            }}><Link to="/privacy-policy">{T("Privacy and Cookie Policy")}</Link></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </header>
                <div className="modal term-popup" id="termsCondition">
                    <TermsCondition />
                </div>
            </div>
        );
    }
}

export default UserHeader;
