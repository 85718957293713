import React from 'react';
import { functions } from "../../functions";
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import $ from 'jquery';
import { touch } from 'redux-form';
export default class ActivatePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    handleClick = () => {
        this.setState({ loader: true });
        let scannerUserId = this.props.scannerUserId
        let scannerId = this.props.currentScannerId;
        let PostData = {
            isLicenseHolderActive:this.props.isLicenseHolderActive,
            // access_token: functions.getAccessToken()
        };
        toast.dismiss();
        api.changeLicenseHolderStatus(scannerId,scannerUserId,PostData)
            .then(res => {
                if (res.data.status == 1) {
                    toast.success(res.data.message, { hideProgressBar: true });
                    // this.props.updateState();
                    // this.props.scannerList()
                    this.props.modalClose("changeHolderStatus");
                    this.setState({ loader: false })
                } else {
                    toast.error(res.data.message, { hideProgressBar: true });
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })
    }

    render() {
        let modalType = this.props.modalType;
        let isLicenseHolderActive = this.props.isLicenseHolderActive;
        let loader = functions.getLoader(this.state.loader);
        return (
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={() => { this.props.modalClose('deleteUser') }} >&times;</button>
                        <h4 class="modal-title">{isLicenseHolderActive == true ? T("Activate this license holder")+"?" : T("Deactivate this license holder")+"?"}</h4>

                    </div>
                    <div class="modal-body">
                        <p>{isLicenseHolderActive == true ? T("This license holder will be able to use the SmartScanner")+"." : T("This license holder will not be able to use the SmartScanner")+"."}</p>
                    </div>
                    <div class="modal-footer">
                        <div className="deleteBtns">
                        <button type="button" id="cancelDeleteUser" class="btn btn-default" onClick={() => { this.props.modalClose('deleteUser') }} data-dismiss="modal">{T("Cancel")}</button>
                            <button type="button" className={isLicenseHolderActive == true ? "activeStatus" : "deactuvateStatus"} id="deleteUserBtn" onClick={() => { this.handleClick() }}>{isLicenseHolderActive == true ? T("Activate") : T("Deactivate")}</button>
                            
                        </div>
                    </div>
                    {loader}
                </div>

            </div>

        )
    }
}