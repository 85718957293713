const Data_Constants = {
    //Footer copyright notice
    FooterNotice: 'All contents &copy; 1902-' + new Date().getFullYear() + ' <strong>Turner Construction Company</strong>. All rights reserved.<br>Turner Construction Company is an equal employment opportunity employer: M/F/D/V',
    FooterContactUsDatainDays: "Monday to Friday",
    FooterContactUsDatainTime: "08:00 to 17:00 EST",
    FooterContactUsDatabyMob: "+1 (212) 229-6466",
    FooterContactUsDatabyAddress: "Turner Strategic Marketing<br>375 Hudson Street, 6th Floor<br>New York, NY 10014<br>USA",
};

export default Data_Constants;
