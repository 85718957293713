import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddPng from '../../assets/images/add.png';
import DummyHeader from '../../assets/images/noodle_house-logo.png';
import DummyStrip from '../../assets/images/noodle_house-banner-img.png';
import UniversalClubPng from '../../assets/images/universal_club.png';
import UniversalWomenPng from '../../assets/images/universal_women.png';
import demoTemplate from '../../assets/images/demoTemplate.png';
import demoTemplate_Icelandic from '../../assets/images/demoTemplate_icelandic.png'
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; //Allows for server-side rendering.
import { CodesTab, ViewAllTemplates, ViewAllScanners, PassStatisticsTab, MembersTab, UsersTab } from '../Dashboard';
import UpgradeSubscription from './UpgradeSubscription';
import $ from 'jquery';
import OrderPopup from './OrderPopup';
import OrderConfirmationPopup from './OrderConfirmationPopup';
import { Link } from 'react-router-dom';
import DowngradePlan from './DowngradePlan';
import TemplateLayout from './TemplateLayout';
import moment from 'moment';
import { Redirect } from 'react-router';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import TemplateTypeLimit from './TemplateTypeLimitPopup'
import ContactUs from './ContactUs';
import PaymentFailedPopup from './PaymentFailedPopup';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

class MyPassTemplates extends React.Component {
    interValIdNext = 0;
    interValIdPrev = 0;
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'My Pass Templates',
            loader: false,
            templatesData: [],
            locationStats: [],
            dayStats: [],
            hourLabel: [],
            hourCount: [],
            hourStats: {},
            distributedUser: {},
            codesData: {},
            selfServiceCode: {},
            issuerFields: [],
            tempStats: [],
            selected: 0,
            SubscriptionData: [],
            planId: '',
            totalPasses: 0,
            subscriptionState: {},
            cardInfo: {},
            orderedTemplates: [],
            templatePassUsers: {},
            DiscountPeriodExpired: false,
            singleTemplateLayout: {},
            mouseIsDown: true,
            orderingTemplate: false,
            usage_statistics: 'B',
            usagesChartSeries: [],
            usagesChartOptions: {},
            subRedirect:false,
            usagesTotalLocations: 0,
            usagesLocationsList: [],
            usagesDaysList: [],
            daysChartSeries: [],
            daysChartOptions: {},
            isLicensePassTemplate: false,
            subscriptionPaymentType: '0',
            isVinnueftirlitioLicensePassTemplate:false,
            template_price: 19800,
            subscription_discount: 0,
            paymentFailedType:'Card',
            paymentDaysLeft:7,
        };
    }

    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        this.getSubscriptionData()
        this.setState({ loader: false });
        api.getIssuerTemplates()
            .then(res => {
                if (res.data && res.data.status == 1) {
                    this.setState({
                        templatesData: res.data.data.templates,
                        orderedTemplates: res.data.data.orderedTemplates,
                    }, () => {
                        if (this.state.templatesData.length > 0) {
                            this.getTemplateStats()
                        }
                    })
                }
                // this.setState({
                //     loader: false,
                //     AccountData: res.data.data
                // });
            })
            .catch(err => {
            });
        this.setState({ loader: false })
        if (localStorage.getItem('PaymentFailed') && localStorage.getItem('PaymentFailed') != null && localStorage.getItem('PaymentFailed') != ''){
            let content=JSON.parse(localStorage.getItem('PaymentFailed'));
            this.setState({
                paymentFailedType:content.paymentType,
                paymentDaysLeft: content.daysLeft
            },()=>{
                $('#paymentFailedBtn').click();
                localStorage.removeItem('PaymentFailed')
            })
        }
    }
    getDayName = (dateStr, locale) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: 'long' });
    }
    paymentModalClose=()=>{
        $('#paymentFailedBtn').click()
        this.setState({subRedirect:true})
        
    }
    getTemplateStats() {
        this.setState({ loader: true });
        let tempId = this.state.templatesData[this.state.selected].id;
        var PostData = {
            // access_token: functions.getAccessToken(),
            tempId: tempId
        };
        api.getTemplateStats(PostData)
            .then(res => {
                if (res.data && res.data.status == 1) {
                    let responsive = [{
                        breakpoint: 5000,
                        options: {
                            chart: {
                                height: '350',
                            },
                            legend: {
                                fontSize: '12px',
                                width: 120,
                                markers: {
                                    width: 20,
                                    height: 12,
                                },
                            },
                        },
                    },
                    {
                        breakpoint: 1300,

                        options: {
                            chart: {
                                height: '400',
                            },
                            dataLabels: {
                                style: {
                                    fontSize: '8px',
                                },
                            },
                            legend: {
                                fontSize: '10px',
                                width: 'autofill',
                                markers: {
                                    width: 12,
                                    height: 9,
                                },
                            },
                        },
                    }, {
                        breakpoint: 1200,

                        options: {
                            chart: {
                                height: '350',
                            },
                            legend: {
                                position: 'top',
                                height: '80',
                                width: 'autofill',
                            },
                            plotOptions: {
                                bar: {
                                    columnWidth: '25%',
                                    barHeight: '70%',
                                    distributed: true
                                },
                            },
                        }
                    },
                    {
                        breakpoint: 1024,
                        options: {
                            tooltip: {
                                style: {
                                    fontSize: '10px',
                                }
                            }
                        }
                    },
                    {
                        breakpoint: 767,
                        options: {
                            chart: {
                                height: '300',
                            },
                        },
                    },
                    {
                        breakpoint: 550,
                        options: {
                            dataLabels: {
                                style: {
                                    fontSize: '6px',
                                }
                            },
                            tooltip: {
                                style: {
                                    fontSize: '7px'
                                }
                            }
                        }
                    },
                    {
                        breakpoint: 414,
                        options: {
                            tooltip: {
                                dataLabels: {
                                    style: {
                                        fontSize: '4px',
                                    }
                                },
                                style: {
                                    fontSize: '5px'
                                }
                            },
                            legend: {
                                height: '60',
                            }
                        },
                    }]

                    let usagesTotalLocations = 0;
                    let locationCounts = [];
                    let locations = [];
                    res.data.data.locationStatistics && res.data.data.locationStatistics.map(val => {
                        usagesTotalLocations = usagesTotalLocations + val.count;
                        locations.push(val.location_name);
                        locationCounts.push(val.count);
                        this.setState({
                            usagesLocationsList: locations,
                            usagesTotalLocations: usagesTotalLocations
                        })

                    });

                    let usagesChartOptions = {
                        chart: {
                            width: '100%',
                            height: '400',
                        },
                        tooltip: {
                            fixed: {
                                enabled: false,
                            }
                        },
                        noData: {
                            text: T("No result found"),
                            offsetX: 0,
                            offsetY: 0,
                            align: 'middle',
                            verticalAlign: 'middle',
                            style: {
                                color: "#1B3F71",
                                fontSize: '20px',
                            }
                        },
                        legend: {
                            show: true,
                            position: 'left',
                            labels: {
                                colors: ['#000000']
                            }
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: '15%',
                                barHeight: '100%',
                                distributed: true
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '10px',
                            }
                        },
                        xaxis: {
                            categories: this.state.usagesLocationsList,
                            labels: {
                                show: false,
                                hideOverlappingLabels: true,
                                trim: true,
                                hideOverlappingLabels: true,
                                rotate: '0',
                                style: {
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                            },

                        },
                        yaxis: {
                            tickAmount: 6,
                            min: function (min) { return Math.round(min) },
                            max: function (max) { return Math.round(max) },

                            labels: {
                                formatter: (value) => this.state.usagesTotalLocations > 0 ? Math.round((value / this.state.usagesTotalLocations) * 100) + '%' : '',
                                style: {
                                    color: '#97937F'
                                }
                            },
                        },
                        responsive: responsive
                    }

                    let daysCounts = [];
                    let usagesDays = [];
                    res.data.data.locationStatisticsByWeek && res.data.data.locationStatisticsByWeek.map(val => {
                        usagesDays.push(this.getDayName(val.date, "nI-NL"));
                        daysCounts.push(val.count);
                        this.setState({
                            usagesDaysList: usagesDays,
                        })

                    });
                    let maxDayCount = Math.max(...daysCounts);
                    let daysChartOptions = {
                        fill: {
                            colors: ['#1B3F71', '#266F99', '#339D91', '#AFCD7E', '#97937F', '#F28051', '#F16772']
                        }
                        ,
                        chart: {
                            width: '80%',
                            height: '400',
                        },
                        tooltip: {
                            marker: {
                                show: false,
                            },
                            fixed: {
                                enabled: false,
                            },
                            x: {
                                formatter: (value) => T(value),
                            },
                            y: {
                                title: {
                                    formatter: (value) => T(value),
                                },
                            },

                        },
                        noData: {
                            text: T("No result found"),
                            offsetX: 0,
                            offsetY: 0,
                            align: 'middle',
                            verticalAlign: 'middle',
                            style: {
                                color: "#1B3F71",
                                fontSize: '20px',
                            }
                        },
                        legend: {
                            show: true,
                            position: 'left',
                            labels: {
                                colors: ['#000000']
                            },
                            formatter: (value) => T(value),
                            markers: {
                                fillColors: ['#1B3F71', '#266F99', '#339D91', '#AFCD7E', '#97937F', '#F28051', '#F16772']
                            }
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: '30%',
                                barHeight: '100%',
                                distributed: true
                            }
                        },
                        labels: {
                            style: {
                                fontSize: '14px'
                            }
                        },

                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '10px',
                            }
                        },
                        xaxis: {
                            categories: this.state.usagesDaysList,
                            labels: {
                                show: false,
                                hideOverlappingLabels: true,
                                trim: true,
                                hideOverlappingLabels: true,
                                rotate: '0',
                                style: {
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    cssClass: 'apexcharts-xaxis-label',
                                },
                            },
                        },
                        yaxis: {
                            tickAmount: maxDayCount < 6 ? maxDayCount : 6,
                            min: function (min) { return Math.round(min) },
                            max: function (max) { return Math.round(max) },
                            labels: {
                                formatter: (value) => Math.round(value),
                                style: {
                                    color: '#97937F'
                                }
                            },
                        },
                        responsive: responsive
                    }

                    let usagesChartSeries = [{
                        name: 'Total passes used on this locations',
                        data: locationCounts
                    }]

                    let daysChartSeries = [{
                        name: 'Usages',
                        data: daysCounts
                    }]

                    let hourLabel = [];
                    let hourCount = [];
                    res.data.data.locationStatisticsByHour.map((val, key) => {
                        hourLabel.push(val.label);
                        hourCount.push(val.count);
                    })
                    this.setState({
                        usagesChartOptions: usagesChartOptions,
                        usagesChartSeries: usagesChartSeries,
                        daysChartOptions: daysChartOptions,
                        daysChartSeries: daysChartSeries,
                        tempStats: res.data.data.templateStatistics,
                        locationStats: res.data.data.locationStatistics ? res.data.data.locationStatistics : [],
                        dayStats: res.data.data.locationStatisticsByWeek ? res.data.data.locationStatisticsByWeek : [],
                        hourStats: res.data.data.locationStatisticsByHour ? res.data.data.locationStatisticsByHour : {},
                        codesData: res.data.data.templateCodes,
                        selfServiceCode: res.data.data.selfServiceCode,
                        issuerFields: res.data.data.formForDistributed,
                        distributedUser: res.data.data.distributedUser,
                        templatePassUsers: res.data.data.templatePassUsers,
                        isLicensePassTemplate: res.data.data.isLicensePassTemplate ? res.data.data.isLicensePassTemplate : false,
                        isVinnueftirlitioLicensePassTemplate:res.data.data.isVinnueftirlitioLicensePassTemplate ? res.data.data.isVinnueftirlitioLicensePassTemplate:false,
                        hourLabel: hourLabel,
                        hourCount: hourCount
                    })
                    this.setState({ loader: false })
                }
                else {
                    this.setState({ loader: false })
                }
                this.setState({
                    AccountData: res.data.data
                }, () => {
                    if (functions.getUrlSlice('/', '1') == 'distribution') {
                        this.child.componentDidMount()
                    }
                    if (functions.getUrlSlice('/', '1') == 'users-list') {
                        this.child.componentDidMount()
                    }
                });
            })
            .catch(err => {
                this.setState({ loader: false })

            });
    }

    showTemplates() {
        let templates = this.state.templatesData.map((val, key) => (
            <div className={key == this.state.selected ? val.full_pass_path ? "template_without_image item current_template" : "template_without_image item current_template" : val.full_pass_path ? "template_without_image item" : "template_without_image item"}
                onClick={() => {
                    this.setState({ selected: key }, () => this.getTemplateStats())
                }}
                key={"temp" + key}
            >
                <TemplateLayout singleTemplateLayout={val} templateSelected={key == this.state.selected ? true : false} />
            </div>
        ));
        return templates;
    }
    addNewTemplate() {
        toast.dismiss();
        let percentPasses = 0;
        let CurrentTemplateType = {};
        this.state.templatesData.map((val, key) => {
            if (val.pass_type == "pass.snjall.memberpass") {
                CurrentTemplateType[val.pass_type] = true;
            }
            if (val.pass_type == "pass.snjall.coupon") {
                CurrentTemplateType[val.pass_type] = true;

            }
            if (val.pass_type == "pass.snjall.punchcard") {
                CurrentTemplateType[val.pass_type] = true;

            }
            if (val.pass_type == "pass.snjall.eventticket") {
                CurrentTemplateType[val.pass_type] = true;

            }
        })
        if (this.state.subscriptionState && this.state.subscriptionState) {
            percentPasses = (Math.round((this.state.subscriptionState.distributedPasses * 100) / this.state.subscriptionState.maxPasses));
        }
        this.setState({
            orderingTemplate: true,
        }, this.child.updateOrdering ? () => { this.child.updateOrdering() } : () => { })
        let subscriptionData = functions.getSubscriptionData();
        if (functions.getActiveSubscription() == 0 || subscriptionData.subscription == 0) {
            $("#AddSubscriptionBtn").trigger({ type: "click" });
        } else {
            if ((functions.getActiveSubscription() == 0 || subscriptionData.subscription == 0) || this.state.templatesData.length >= this.state.subscriptionState.passTemplates || percentPasses >= 100) {
                $("#AddSubscriptionBtn").trigger({ type: "click" });
            }
            else if (Object.keys(CurrentTemplateType).length >= this.state.subscriptionState.passTemplatesTypes) {
                $("#templateTypeLimitPopupBtn").trigger({ type: "click" });
            }
            else {
                $('input#testback1').trigger('click');
                $(".OrderTempPopup").trigger({ type: "click" });
            }
        }

    }
    templateOrderedLimit() {
        toast.dismiss();
        setTimeout(() => {
            toast.error("You have already ordered template", { hideProgressBar: true });
        }, 300);
    }
    getSubscriptionData(props = this.props) {
        return api.getSubscriptionData()
            .then(res => {
                let flag = false;
                let result = [];
                let usage_statistics = 'B';
                let planId, totalPasses = 0;
                let template_price = 19800;
                let subscription_discount = 0;
                let subscriptionState, cardInfo = {};
                let discountPeriodExpired = false;
                let subscriptionPaymentType = '0';
                if (res.data && res.data.status == 1) {
                    result = res.data.data.subscriptions;
                    planId = res.data.data.subscription_id;
                    totalPasses = res.data.passesInSmartwallet;
                    discountPeriodExpired = res.data.data.discountPeriodExpired;
                    cardInfo = res.data.data.userPaymentInfo ? res.data.data.userPaymentInfo[0] : [];
                    if (res.data.data && res.data.data.subscription_status && res.data.data.subscription_status.status == 1) {
                        subscriptionState = res.data.data.subscription_status.data;
                        usage_statistics = res.data.data.subscription_status.data.usage_statistics ? res.data.data.subscription_status.data.usage_statistics : 'B';
                    }
                    flag = true;
                } else {
                    // toast.error(({ closeToast }) => <div>{res}</div>, { hideProgressBar: true })
                    flag = false;
                }
                this.setState({
                    loader: false,
                    usage_statistics: usage_statistics,
                    SubscriptionData: result,
                    planId: planId,
                    totalPasses: totalPasses,
                    subscriptionPaymentType: res.data && res.data.data &&  res.data.data.pay_with_invoice ? res.data.data.pay_with_invoice:'0',
                    template_price: res.data && res.data.data &&  res.data.data.template_price ? res.data.data.template_price:19800,
                    subscription_discount: res.data && res.data.data &&  res.data.data.subscription_discount ? res.data.data.subscription_discount:0,
                    subscriptionState: subscriptionState,
                    cardInfo: cardInfo,
                    discountPeriodExpired: discountPeriodExpired
                });
                return flag;
            })
            .catch(err => {
                // toast.error(err.message,{hideProgressBar:true})
                this.setState({ loader: false })
            });
    }

    MousePrevIsDown = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
        $('#banner-slider').removeClass('reached-end');
        this.interValIdPrev = setInterval(() => {
            $('.swiper-button-prev').trigger('click');
        }, 600)
    }

    MouseNextIsDown = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
        this.interValIdNext = setInterval(() => {
            $('.swiper-button-next').trigger('click');
        }, 600)
    }
    chooseModal = (event) => {
        $("#templateTypeLimitPopupBtn").trigger({ type: "click" });
        setTimeout(() => {
            if (event == 'upgrade_popup') {
                $("#AddSubscriptionBtn").trigger({ type: "click" });
            }
            else {
                $('input#testback1').trigger('click');
                $(".OrderTempPopup").trigger({ type: "click" });
            }
        }, 800);
    }
    MouseIsUp = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
    }
    render() {
        $('body').on('click', () => {
            $('.swiper-wrapper').on('mousedown', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-wrapper .swiper-slide').on('mousedown', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-prev').on('mouseout', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-next').on('mouseout', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-prev').on('mouseup', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-next').on('mouseup', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
        })
        let userPermissions = functions.getUserPermissions();
        let issuerRole = functions.getIssuerRole();

        //Page_title
        if(this.state.subRedirect){
            return <Redirect to='subscriptions'/>
        }
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        const CurrentTab = functions.getUrlSlice('/', '1'); //Get Current 
        functions.changePageTitle(this.state.pagetitle);
        // let getTrialDuration = [0, 0];
        let getTrialDuration = 0;
        let DiscountPeriod = this.state.subscriptionState && this.state.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.state.subscriptionState.reamainingDaysOfTrailPeriod) : 90;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }

        //Loader
        let loader = functions.getLoader(this.state.loader);

        const orderedTemplateDate = this.state.orderedTemplates && this.state.orderedTemplates[0] ? this.state.orderedTemplates[0].created_at : '';
        const params = {
            grabCursor: true,
            watchOverflow: true,
            init: true,
            updateOnWindowResize: true,
            on: {
                reachEnd: function () {
                    $('#banner-slider').addClass('reached-end');
                },
                slidePrevTransitionStart: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
                slidePrevTransitionEnd: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
                reachBeginning: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
            },
            lazyLoad: 'ondemand',
            lazy: true,
            slidesPerView: 'auto',
            spaceBetween: 0,
            ContainerEl: 'section',
            WrapperEl: 'section',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            renderPrevButton: () => <button onMouseDown={this.MousePrevIsDown} onMouseOut={this.MouseIsUp} onMouseUp={this.MouseIsUp} className="swiper-button-prev"></button>,
            renderNextButton: () => <button onMouseDown={this.MouseNextIsDown} onMouseOut={this.MouseIsUp} onMouseUp={this.MouseIsUp} className="swiper-button-next"></button>,
            breakpoints: {
                1024: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                768: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 'auto',
                    spaceBetween: 0
                }
            }
        }
        let subscriptionData = functions.getSubscriptionData();
        let activeSubscriptionSection = (subscriptionData.subscription != 0 && this.state.subscriptionState) ? true : false;
        return (
            <div className="wrapper block_element" className={this.props.CurrentTab == 'my_pass_templates' || this.props.CurrentTab == 'dashboard' || this.props.CurrentTab == 'codes' || this.props.CurrentTab == 'distribution' || this.props.CurrentTab == 'users-list' ? 'container tab-pane fade in active show my_pass_templates_page' : 'tab-pane fade in my_pass_templates_page container'}>
                {loader}
                {/* <div className={this.state.templatesData.length > 0 && this.state.orderedTemplates.length > 0 ? "bg_color_section block_element main_logo_heading_padding start_ordering_passes  my_pass_templates padding_container " : (this.state.templatesData.length > 0 && this.state.orderedTemplates.length == 0 ? "bg_color_section block_element main_logo_heading_padding start_ordering_passes  my_pass_templates padding_container single-dashboard" : "bg_color_section block_element main_logo_heading_padding start_ordering_passes padding_container my_pass_templates")}> */}
                <div className={this.state.templatesData.length > 0 && this.state.orderedTemplates.length > 0 ? "bg_color_section block_element main_logo_heading_padding start_ordering_passes  my_pass_templates padding_container " : (this.state.templatesData.length > 0 && this.state.orderedTemplates.length == 0 ? "bg_color_section block_element main_logo_heading_padding start_ordering_passes  my_pass_templates padding_container single-dashboard" : (this.state.templatesData.length == 0 && this.state.orderedTemplates.length > 0 ? "bg_color_section block_element main_logo_heading_padding start_ordering_passes padding_container my_pass_templates no-dashboard" : " bg_color_section block_element main_logo_heading_padding start_ordering_passes padding_container my_pass_templates "))}>

                    <h3>{this.state.templatesData.length > 0 ? T("My Pass Templates") : T("Start by ordering a Pass Template")}</h3>
                    <div className={this.state.templatesData.length > 0 ? "my_pass_main_section block_element slider_with_pending_temp" : "order_fornew_passes"}>
                        <div onClick={this.state.orderedTemplates.length > 0 ? () => this.templateOrderedLimit() : () => this.addNewTemplate()} className={this.state.templatesData.length == 0 && this.state.orderedTemplates.length > 0 ? "add_order_disable dashboard-order order_pass no-template" : (this.state.templatesData.length == 0 && this.state.orderedTemplates.length == 0 ? "dashboard-order order_pass no-template" : (this.state.templatesData.length > 0 && this.state.orderedTemplates.length == 0 ? "dashboard-order order_pass" : "add_order_disable dashboard-order order_pass"))}>
                            {/* <span className={this.state.orderedTemplates.length > 0 ? "add_order_disable add_order":"add_order"} onClick={() => this.addNewTemplate()}> */}
                            <span className={this.state.orderedTemplates.length > 0 ? "add_order_disable_span add_order" : "add_order"} >
                                <img src={AddPng} />
                            </span>
                            {this.state.templatesData.length == 0 &&
                                <p>{T("Order a pass template")}</p>
                            }
                            {this.state.templatesData.length > 0 &&
                                < p > {T("Order a new")} <br /> {T("Pass Template")}</p>
                            }
                        </div>
                        {this.state.orderedTemplates.length > 0 &&
                            <div className="order_pass  ordered_pending_passes">
                                <span className="order_dated">{T("Ordered")}: {moment(orderedTemplateDate.substring(0, orderedTemplateDate.indexOf(' '))).format("DD.MM.YYYY")}</span>
                                <span className="add_order">
                                    <i className="fa fa-history"></i>
                                </span>
                                <p>{T("New Pass")} <br /> {T("Template pending")}</p>
                            </div>
                        }
                        {this.state.templatesData.length == 0 &&
                            <div className="demo-pass-template">
                                <div className="pass-design-layout">
                                    <img src={localStorage.getItem('rtc-lang') && localStorage.getItem('rtc-lang') == 'is' ? demoTemplate_Icelandic : demoTemplate} />
                                </div>
                            </div>
                        }
                        {this.state.templatesData.length > 0 &&
                            <section id="banner-slider" className={this.state.orderedTemplates.length > 0 ? "banner-slider" : "banner-slider bigger-slider"}>
                                <Swiper {...params}>
                                    {this.showTemplates()}
                                </Swiper>
                            </section>
                        }
                    </div>
                    <ul className={Object.keys(this.state.tempStats).length > 0 ? "nav nav-tabs block_element" : "emptynavTabs nav nav-tabs block_element"}>
                        <li><Link className={CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard' ? "active show" : ''} to="/dashboard">{T("Statistics")}</Link></li>
                        <li>{/*<a data-toggle="tab" href="#menu1">Codes</a>*/}<Link className={CurrentTab == 'codes' ? "active show" : ''} to="/codes">{T("Codes")}</Link></li>
                        {!this.state.isLicensePassTemplate && <li><Link className={CurrentTab == 'distribution' ? "active show" : ''} to="/distribution">{T("Distribution")}</Link></li>}
                        <li><Link className={CurrentTab == 'users-list' ? "active show" : ''} to="/users-list">{T("Users")}</Link></li>
                    </ul>

                    <div className={CurrentTab == 'users-list' ? "tab-content tab_background tab_background_usertab insideProfile block_element" : "tab-content tab_background insideProfile block_element"} >
                        {
                            CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard' ?
                                <div className={this.state.templatesData.length > 0 ? "tab-pane fade in active show" : "tab-pane fade in active show Dummy-Data"}>
                                    <PassStatisticsTab CurrentTab={'passstatistics'}
                                        orderingTemplate={this.state.orderingTemplate}
                                        currentTempData={this.state.templatesData && this.state.templatesData.length > 0 ? this.state.templatesData[this.state.selected] : {}}
                                        subscriptionState={this.state.subscriptionState}
                                        Statistics={this.state.tempStats}
                                        LocationStats={this.state.locationStats}
                                        usagesChartSeries={this.state.usagesChartSeries}
                                        isLicensePassTemplate={this.state.isLicensePassTemplate}
                                        usagesChartOptions={this.state.usagesChartOptions}
                                        hourStats={this.state.hourStats}
                                        isLicensePassTemplate={this.state.isLicensePassTemplate}
                                        daysChartSeries={this.state.daysChartSeries}
                                        daysChartOptions={this.state.daysChartOptions}
                                        hourLabel={this.state.hourLabel}
                                        hourCount={this.state.hourCount}
                                        subscriptionPaymentType={this.state.subscriptionPaymentType}
                                        template_price={this.state.template_price}
                                        subscription_discount={this.state.subscription_discount}
                                        parentComponentDidMountFunction={() => { this.componentDidMount() }}
                                        ref={instance => {
                                            this.child = instance;
                                        }}
                                    />
                                </div>
                                : ''
                        }
                        {
                            CurrentTab == 'codes' ?
                                < div className={(Object.keys(this.state.codesData).length > 0) || (Object.keys(this.state.selfServiceCode).length > 0) ? "tab-pane fade codes_main_section in active show" : "tab-pane fade codes_main_section in active show Dummy-Data"}>
                                    <CodesTab CurrentTab={'codes'}
                                        Codes={this.state.codesData}
                                        SelfServiceCode={this.state.selfServiceCode}
                                        parentComponentDidMountFunction={() => { this.componentDidMount() }}
                                        ref={instance => {
                                            this.child = instance;
                                        }} />
                                </div>
                                : ''
                        }
                        {
                            CurrentTab == 'distribution' ?
                                <div className={this.state.templatesData.length > 0 ? "tab-pane fade distribution _section in active show" : "tab-pane fade distribution _section in active show Dummy-Data"}>
                                    <MembersTab CurrentTab={'members'}
                                        getTemplateStats={() => this.getTemplateStats()}
                                        contentTemplate={this.state.templatesData.length}
                                        issuerFields={this.state.issuerFields}
                                        subscriptionState={this.state.subscriptionState}
                                        singleTemplate={this.state.templatesData && this.state.templatesData.length > 0 ? this.state.templatesData[this.state.selected] : {}}
                                        currentTempId={this.state.templatesData && this.state.templatesData.length > 0 ? this.state.templatesData[this.state.selected].id : 0}
                                        userData={this.state.distributedUser}
                                        isLicensePassTemplate={this.state.isLicensePassTemplate}
                                        parentComponentDidMountFunction={(e) => this.componentDidMount()}
                                        ref={instance => {
                                            this.child = instance;
                                        }}
                                    />
                                </div>
                                : ''
                        }
                        {
                            CurrentTab == 'users-list' ?
                                <div className={this.state.templatesData.length > 0 ? "tab-pane fade codes_main_section in active show" : "tab-pane fade codes_main_section in active show Dummy-Data"}>
                                    <UsersTab CurrentTab={'users'}
                                        contentTemplate={this.state.templatesData.length}
                                        issuerFields={this.state.issuerFields}
                                        currentTempId={this.state.templatesData && this.state.templatesData.length > 0 ? this.state.templatesData[this.state.selected].id : 0}
                                        userData={this.state.distributedUser}
                                        templatePassUsers={this.state.templatePassUsers}
                                        isLicensePassTemplate={this.state.isLicensePassTemplate}
                                        isVinnueftirlitioLicensePassTemplate={this.state.isVinnueftirlitioLicensePassTemplate}
                                        parentComponentDidMountFunction={(e) => this.componentDidMount()}
                                        ref={instance => {
                                            this.child = instance;
                                        }}
                                    />
                                </div>
                                : ''
                        }
                    </div>
                    {
                        (activeSubscriptionSection && (CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard') && (this.state.usage_statistics == 'B'|| this.state.usage_statistics == 'Basic' || this.state.usage_statistics == 'basic') && Object.keys(this.state.tempStats).length > 0) &&
                        <div className="statisticsUpgrade">
                            <span>{T("Need more statistics")}? </span>
                            <button onClick={() => { this.setState({ orderingTemplate: false }, () => { this.child.updateOrdering() }) }} id="AddSubscription" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? "#DowngradePlan" : "#UpgradeSubscription"} >{T("Upgrade subscription")}</button>
                        </div>
                    }

                </div>
                <button id="AddSubscriptionBtn" data-toggle="modal" data-target="#UpgradeSubscription" style={{ display: 'none' }}></button>
                <button id="templateTypeLimitPopupBtn" data-toggle="modal" data-target="#templateTypeLimitPopup" style={{ display: 'none' }}></button>
                {/* <div className="modal modalcontactus fade " id="modalcontactus" role="dialog" ref="modalcontactus">
                    <ContactUs />
                </div> */}
                <div className="modal fade templateTypeLimitPopup" id="templateTypeLimitPopup" role="dialog">
                    <TemplateTypeLimit chooseModal={(event) => { this.chooseModal(event) }} />
                </div>
                <div className="modal fade select_subscription" tabIndex='-1' id="UpgradeSubscription" role="dialog">
                    <UpgradeSubscription subscription_discount={this.state.subscription_discount} orderTemp={this.state.orderingTemplate ? '1' : ''} SubscriptionState={this.state.subscriptionState} SubscriptionData={this.state.SubscriptionData} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                </div>
                {
                    this.state.SubscriptionData.length > 0 &&
                    <div>
                        {this.state.SubscriptionData.map((val, key) => (
                            <div key={"subs" + key}>
                                <button className="free_subsription_btns" data-toggle="modal" data-target={"#myOrderPopup" + key} style={{ display: 'none' }}>
                                    {val.offer_price == 0 ? 'Free' : 'Select'}
                                </button>
                                <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup" + val.id} data-target={"#OrderConfirmation" + val.id} style={{ display: 'none' }}>Open Modal</button>
                                <div className="modal fade myOrderPopup" id={"myOrderPopup" + key} role="dialog" >
                                    <OrderPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} subscriptionPaymentType={this.state.subscriptionPaymentType} OrderData={val} orderTemp={this.state.orderingTemplate ? '1' : ''} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                                </div>
                                <div className="modal orderConfirmModal fade" id={"OrderConfirmation" + val.id} role="dialog">
                                    <OrderConfirmationPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} OrderData={val} orderTemp={this.state.orderingTemplate ? '1' : ''} DiscountPeriodExpired={this.state.discountPeriodExpired} />
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <button type="button" data-toggle="modal" className="OrderTempPopup" data-target="#OrderTempPopup" style={{ display: 'none' }}>Open Modal</button>
                <div className="modal fade myOrderPopup" id={"OrderTempPopup"} role="dialog" >
                    <OrderPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} subscriptionPaymentType={this.state.subscriptionPaymentType} CardCred={this.state.cardInfo} orderTemp={this.state.orderingTemplate ? '1' : ''} />
                </div>
                <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup"} data-target={"#OrderConfirmation"} style={{ display: 'none' }}>Open Modal</button>
                <div className="modal orderConfirmModal fade" id={"OrderConfirmation"} role="dialog">
                    <OrderConfirmationPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} orderTemp={this.state.orderingTemplate ? '1' : ''} />
                </div>
                <div className="modal downgradePlan fade" id="DowngradePlan" role="dialog">
                    <DowngradePlan />
                </div>
                <button type="button" data-toggle="modal" data-target="#paymentFailed" className="paymentFailedBtn" id="paymentFailedBtn" style={{ display: 'none' }}></button>
                <div className="modal paymentFailed adduser fade" id="paymentFailed" role="dialog" ref="paymentFailed">
                    <PaymentFailedPopup paymentModalClose={() => { this.paymentModalClose() }}paymentDaysLeft={this.state.paymentDaysLeft} modalType={this.state.paymentFailedType} />
                </div>
            </div >
        );
        //    }
    }
};

export default MyPassTemplates;
