import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import TermsCondition from './TermsCondition';
import { Link, } from 'react-router-dom';
import $ from 'jquery';
class SignUpAdditionalUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Sign up',
            loader: false,
            isAddPaymentInfo: false,
            isRegisteredUser: false,
            termsPopup: false,
            formSubmit: true,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        let currentYear = parseInt(new Date().getFullYear().toString().substr(-2));
        let years = [];
        for (var i = currentYear; i < currentYear + 25; i++) {
            years.push(i);
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);
        if (this.state.isRegisteredUser) {
            return <Redirect to='/confirm-signup' />
        }
        if (localStorage.getItem('loginISP_user_sesion') != null) { // If authrized user
            return <Redirect to='/dashboard' />
        }

        // const key = functions.getParamValuesByName('key');
        // if(key==''){
        //     return (<Redirect to='/signin' />);
        // }

        setTimeout(() => {
            $('email').val('');
            $('password').val('');
        }, 600)
        return (
            <div>
                <Formik
                    initialValues={{ company_name: 'Jerrystoff', contact_name: '', email: 'tbhupender723@gmail.com', password: '', passwordd: '', terms: false }}
                    onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                        this.setState({ errors: '', loader: true, formSubmit: false });
                        toast.dismiss();
                        var PostData = {
                            company_name: values.company_name,
                            contact_name: values.contact_name,
                            email: values.email,
                            password: values.password,
                            isAddPaymentInfo: this.state.isAddPaymentInfo,
                        };

                        api.signUp(PostData)
                            .then(res => {
                                if (res.data && res.data.success) {
                                    localStorage.setItem('new_email_registered',
                                        JSON.stringify({
                                            email: PostData.email
                                        }));
                                    // document.getElementById('resetHistory').click();
                                    this.setState({
                                        isRegisteredUser: true
                                    })
                                } else {
                                    if (res.data.error && res.data.error.email) {
                                        setErrors({ email: res.data.error.email });
                                    } else if (res.data.error && res.data.error.company_name) {
                                        setErrors({ company_name: res.data.error.company_name });
                                    }
                                    // toast.error(`${res.data.error.email}`, { hideProgressBar: true })
                                }
                                this.setState({ loader: false, formSubmit: true });
                            })
                            .catch(err => {
                                this.setState({ loader: false, formSubmit: true })
                            });
                        setSubmitting(false);
                    }}

                    validationSchema={
                        Yup.object().shape({
                            company_name: Yup.string().trim()
                                .min('2', Validation_Constants.Min_Company_Name)
                                .max('50', Validation_Constants.Max_Company_Name)
                                .required(Validation_Constants.Required2),
                            contact_name: Yup.string().trim()
                                .min('2', Validation_Constants.Min_Contact_Name)
                                .max('50', Validation_Constants.Max_contact_Name)
                                .required(Validation_Constants.Required2),
                            email: Yup.string().trim()
                                .email(Validation_Constants.Invalid_Email)
                                .required(Validation_Constants.Required2),
                            password: Yup.string()
                                .min(8, Validation_Constants.Min_Password)
                                .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                                .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                                .max(20, Validation_Constants.Max_Password)
                                .required(Validation_Constants.Required2),
                            passwordd: Yup.string()
                                .required(Validation_Constants.Required2)
                                .test('passwords-match', Validation_Constants.Invalid_RePassword, function (value) {
                                    return this.parent.password === value;
                                }),
                            terms: Yup.bool()
                                .test(
                                    'terms',
                                    Validation_Constants.Empty_Terms,
                                    value => value === true
                                )
                                .required(Validation_Constants.Empty_Terms)
                        })
                    }
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            resetForm,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;

                        return (
                            <div className="wrapper block_element">
                                <div className="inner-pages block_element">
                                    <div className="container">
                                        {loader}
                                        <div className="bg_color_section block_element sign_up_page sign_up padding_container">
                                            <h2>Sign up to {values.company_name} Smartpages</h2>
                                            <form className="signupForm sign_up_form block_element" onSubmit={handleSubmit}>

                                                <div className="form-group">
                                                    <div className="floating-label_group">
                                                        <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            id="contact_name"
                                                            type="text"
                                                            value={values.contact_name}
                                                            placeholder="Please enter your name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.contact_name && touched.contact_name ? 'inputText error floating-input' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label>Contact full name</label>
                                                        <div className="error-main">
                                                            {errors.contact_name &&
                                                                touched.contact_name && <p className="error">{errors.contact_name}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="floating-label_group">
                                                        <input
                                                            title="You cannot edit this information"
                                                            id="email"
                                                            type="text"
                                                            value={values.email}
                                                            // autoComplete="off"
                                                            placeholder=" "
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.email && touched.email ? 'floating-input inputText error' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label>Contact email</label>
                                                        <div>
                                                            <div className="error-main">
                                                                {errors.email &&
                                                                    touched.email && <p className="error">{errors.email}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="floating-label_group">
                                                        <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            title="You cannot edit this information"
                                                            id="company_name"
                                                            type="text"
                                                            value={values.company_name}
                                                            placeholder=" "
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.company_name && touched.company_name ? 'floating-input inputText error' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label>Company name</label>
                                                        <div className="error-main">
                                                            {errors.company_name &&
                                                                touched.company_name && <p className="error">{errors.company_name}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="floating-label_group">
                                                        <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            id="password"
                                                            type="password"
                                                            value={values.password}
                                                            // autoComplete="off"
                                                            placeholder="Please enter your password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.password && touched.password ? 'floating-input inputText error' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label>Password</label>
                                                        <div className="error-main">
                                                            {errors.password &&
                                                                touched.password && <p className="error">{errors.password}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group confPassGroup">
                                                    <div className="floating-label_group">
                                                        <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            id="passwordd"
                                                            // autoComplete="off"
                                                            type="password"
                                                            value={values.passwordd}
                                                            placeholder="Please confirm your password"
                                                            onChange={(event) => {
                                                                handleChange(event)
                                                                handleBlur(event)
                                                            }}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.passwordd && touched.passwordd ? 'floating-input inputText error' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label>Confirm password</label>
                                                        <div className="error-main">
                                                            {errors.passwordd &&
                                                                touched.passwordd && <p className="error">{errors.passwordd}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="container-s"> I agree to the <a href="javascript::void(0);" data-toggle="modal" data-target="#termsCondition"> Terms and Conditions</a>
                                                    <input type="checkbox" //checked="checked"
                                                        id="terms"
                                                        name="terms"
                                                        onChange={handleChange}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <div className="error-main">
                                                        {errors.terms &&
                                                            touched.terms && <p className="error">{errors.terms}</p>
                                                        }
                                                    </div>
                                                </label>
                                                <div className="form-group-btn">
                                                    <input type="submit" name="" disabled={(JSON.stringify(errors) != "{}") || values.contact_name == "" || values.email == "" || values.company_name == "" || values.password == "" || values.passwordd == "" || values.terms != true} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.contact_name != "" && values.email != "" && values.company_name != "" &&  values.password != "" && values.passwordd != "" && values.terms == true ? 'isActive' : 'isNotActive'} value="Sign up" />
                                                </div>
                                            </form>

                                            {/* <div className="modal term-popup" id="termsCondition">
                                        <TermsCondition />
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            // {/* <!-- wrapper-end --> */ }
                        );
                    }
                    }
                </Formik >
            </div >
        );
    }
};

export default SignUpAdditionalUser;
