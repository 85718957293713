import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Router, HashRouter, Redirect, Switch } from 'react-router-dom';
import componentQueries from 'react-component-queries';
import { LayoutRoute, FrontLayout, UserLayout, UserOuterLayout, EmptyLayout, NoHeaderLayout } from './IssuerBackend/components/Layout';
import { FrontPage, SignIn, SignUp, SignUpAdditionalUser, ContactUs, LegalPages, Dashboard, AccountSettings, ForgetPassword, ThankYou, ConfirmEmail, PrivacyCookiePolicy, ResetEmailSent, NewPassword } from './IssuerBackend/pages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';//english //iceland
import EnglishCurrencyFlagsPng from './locale/flags/english.png';
import IcelandCurrencyFlagsPng from './locale/flags/iceland.png';
import FrenchCurrencyFlagsPng from './locale/flags/french.png';
const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

// END CUSTOM FOR ISP


Config.default = "en";

Config.list = {
    en: {
        text: "",
        icon: EnglishCurrencyFlagsPng,
        file: require('./locale/en.js')
    },
    is: {
        text: "",
        icon: IcelandCurrencyFlagsPng,
        file: require("./locale/is.js")
    },
};

class App extends Component {
    notify = () => toast("Wow so easy !");
    render() {
        return (
            <div>
                <div>
                    <ToastContainer />
                </div>
                <HashRouter basename={getBasename()}>
                    <Switch >
                        <LayoutRoute
                            exact
                            path="/"
                            layout={UserOuterLayout}
                            component={FrontPage}
                        // component={() => {
                        //     window.location.href = 'https://smartsolutions.is';
                        //     return null;
                        // }}
                        />

                        <LayoutRoute
                            exact
                            path="/signin"
                            layout={UserOuterLayout}
                            component={SignIn}
                        />

                        <LayoutRoute
                            exact
                            path="/confirm-signup"
                            layout={UserOuterLayout}
                            component={ThankYou}
                        />

                        <LayoutRoute
                            exact
                            path="/issuer-confirmation"
                            layout={UserOuterLayout}
                            component={ConfirmEmail}
                        />

                        <LayoutRoute
                            exact
                            path="/signup"
                            layout={UserOuterLayout}
                            component={SignUp}
                        />
                        <LayoutRoute
                            exact
                            path="/privacy-policy"
                            layout={localStorage.getItem('loginISP_user_sesion') == null ? UserOuterLayout : UserLayout}
                            component={PrivacyCookiePolicy}
                        />
                        <LayoutRoute
                            exact
                            path="/forgot-password"
                            layout={UserOuterLayout}
                            component={ForgetPassword}
                        />

                        <LayoutRoute
                            exact
                            path="/password-reset"
                            layout={UserOuterLayout}
                            component={ResetEmailSent}
                        />

                        <LayoutRoute
                            exact
                            path="/new-password"
                            layout={UserOuterLayout}
                            component={NewPassword}
                        />

                        <LayoutRoute
                            exact
                            path="/contact-us"
                            layout={FrontLayout}
                            component={ContactUs}
                        />

                        <LayoutRoute
                            exact
                            path="/legal"
                            layout={FrontLayout}
                            component={LegalPages}
                        />

                        <LayoutRoute
                            exact
                            path="/dashboard"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/codes"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/distribution"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/users-list"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/passstatistics"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/my_pass_templates"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/my_scanner"
                            layout={UserLayout}

                            component={Dashboard}
                        />
                        <LayoutRoute
                            exact
                            path="/scanner"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/license_holder"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/scanner_distribution"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/add_scanner"
                            layout={UserLayout}
                            component={Dashboard}
                        />
                        <LayoutRoute
                            exact
                            path="/profile"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/change_password"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/subscription"
                            layout={UserLayout}
                            component={Dashboard}
                        />
                        <LayoutRoute
                            exact
                            path="/subscriptions"
                            layout={UserLayout}
                            component={Dashboard}
                        />
                        <LayoutRoute
                            exact
                            path="/account-settings"
                            layout={UserLayout}
                            component={AccountSettings}
                        />

                        <LayoutRoute
                            exact
                            path="/view-all-templates"
                            layout={UserLayout}
                            component={Dashboard}
                        />

                        <LayoutRoute
                            exact
                            path="/view-all-scanners"
                            layout={UserLayout}
                            component={Dashboard}
                        />


                        <Redirect to="/signin" />

                    </Switch>
                </HashRouter>
            </div>
        );
    }
}

const query = ({ width }) => {
    if (width < 575) {
        return { breakpoint: 'xs' };
    }

    if (576 < width && width < 767) {
        return { breakpoint: 'sm' };
    }

    if (768 < width && width < 991) {
        return { breakpoint: 'md' };
    }

    if (992 < width && width < 1199) {
        return { breakpoint: 'lg' };
    }

    if (width > 1200) {
        return { breakpoint: 'xl' };
    }

    return { breakpoint: 'xs' };
};


function TranslatorApp() {
    return (
        <Translator>
            <App />
        </Translator>
    )
}

//export default componentQueries(query)(App);
export default TranslatorApp;
