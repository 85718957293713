import React from 'react';
import { functions } from "../../functions";
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { touch } from 'redux-form';
export default class PaymentFailedPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }

    render() {
        let modalType = this.props.modalType;
        let paymentDaysLeft = this.props.paymentDaysLeft;
        // let modalType = 'Invoice'
        let loader = functions.getLoader(this.state.loader);
        return (
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">{T("Your payment failed")}</h4>
                    </div>
                    <div class="modal-body">
                        {modalType == 'Invoice' &&
                            <p>{T("We liked to inform you about the fact that you have unpaid invoices")}.<br /><br />{T("Please pay your invoices within")} {paymentDaysLeft >= 0 ? paymentDaysLeft : 0}{paymentDaysLeft > 1 ? T('days') : T('day')} {T("or your account will be closed")}.</p>
                        }
                        {modalType == 'Card' &&

                            <p>{T("We liked to inform you about the fact that we were not able to bill your credit card")}.<br />{T("Please change your credit card information")}.<br /><br />{T("You have")} {paymentDaysLeft >= 0 ? paymentDaysLeft : 0}{paymentDaysLeft > 1 ? T('days') : T('day')}  {T("left to rectify this or your account will be closed")}.</p>
                        }
                    </div>
                    <div class="modal-footer">
                        <div className={modalType == 'Invoice' ? "paymentFailedbtnCard" : "paymentFailedbtnInvoice"}>
                            {modalType == 'Invoice' &&
                                 <button type="button" id="deletePopupDelete"  data-dismiss="modal">{modalType == 'Invoice' ? T("Got it") : T("Change payment method")}</button>
                            }
                            {modalType != 'Invoice' &&
                                <button type="button" id="deletePopupDelete" onClick={() => { this.props.paymentModalClose() }} >{modalType == 'Invoice' ? T("Got it") : T("Change payment method")}</button>
                            }
                        </div>
                    </div>
                    {loader}
                </div>

            </div>

        )
    }
}