import React from 'react';
import questionMark from '../../assets/images/questionMark.svg';
import { isMobileOnly } from 'react-device-detect';
export default class CustomToolTip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    toggleSubscriptionPlanToolTipMobile(toolTipId = '1') {
        let tooltipText = document.getElementById('toolTip_' + toolTipId)
        let tolltipbox = document.getElementsByClassName('tolltipbox')
        if (window.getComputedStyle(tooltipText).display === 'none' && document.getElementById('toolTip_' + toolTipId) !== undefined) {
            let tHead_ElementById = document.getElementById('toolTip_' + toolTipId);
            if (tHead_ElementById) {
                for (var i = 0; i < tolltipbox.length; i++) {
                    if (document.getElementById('toolTip_' + (i)) !== undefined) {
                        let tmp_tHead_ElementById = document.getElementById('toolTip_' + (i));
                        tmp_tHead_ElementById.style.display = "none";
                    }
                }
                let classes = tHead_ElementById.classList;
                classes.remove("disable"); // To Remove Class
                tHead_ElementById.style.display = "block";
            }
        }
        else if (window.getComputedStyle(tooltipText).display === 'block' && document.getElementById('toolTip_' + toolTipId) !== undefined) {
            let tHead_ElementById = document.getElementById('toolTip_' + toolTipId);
            if (tHead_ElementById) {
                tHead_ElementById.style.display = "none";
            }
        }
    }

    toggleSubscriptionPlanToolTip(toolTipId = '1', action = false) {
        if (action && document.getElementById('toolTip_' + toolTipId) !== undefined) {
            let tHead_ElementById = document.getElementById('toolTip_' + toolTipId);
            if (tHead_ElementById) {
                let classes = tHead_ElementById.classList;
                classes.remove("disable"); // To Remove Class
                tHead_ElementById.style.display = "block";
            }
        }
        else if (document.getElementById('toolTip_' + toolTipId) !== undefined) {
            let tHead_ElementById = document.getElementById('toolTip_' + toolTipId);
            if (tHead_ElementById) {
                tHead_ElementById.style.display = "none";
            }
        }
    }
    render() {
        return (
            <div className="tollTipMainSection" id={'tollTipMainSection_' + this.props.toolTipId}>
                {/* <div id={'toolTip_' + toolTipId} className="tollTipTextSection disable">
                {toolTipText}
                {(toolTipSubText != '') ? <span className="tollTipSubTextSection">{toolTipSubText}</span> : ''}
            </div> */}
                <div id={'toolTip_' + this.props.toolTipId} className="tollTipTextSection tolltipbox disable" style={{ display: "none" }}>
                    {this.props.toolTipText}
                    {(this.props.toolTipSubText != '') ? <span className="tollTipSubTextSection">{this.props.toolTipSubText}</span> : ''}
                </div>
                {!isMobileOnly &&
                    <div className="desktop_tooltip"><span className="tollTipTrigger" onMouseEnter={() => this.toggleSubscriptionPlanToolTip(this.props.toolTipId, true)} onMouseLeave={() => this.toggleSubscriptionPlanToolTip(this.props.toolTipId)}><img src={questionMark} /></span></div>
                }
                {isMobileOnly &&
                    <div className="mobile_tooltip"><span className="tollTipTrigger" onTouchStart={() => this.toggleSubscriptionPlanToolTipMobile(this.props.toolTipId)} ><img src={questionMark} /></span></div>
                }
            </div>
        )
    }
}