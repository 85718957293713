import React from 'react';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class TermsCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{T("General Terms and Conditions of Service")} <b>(“{T("Agreement")}”)</b></h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4><i>{T("Last update")}: January 31st 2020</i></h4>
                    </div>
                    <div className="modal-body">
                        <div>
                            <h2>1. Introduction</h2>
                            <p>
                                Welcome to Smart Solutions. This Agreement is between Issuer <b>(“you”</b>, or <b>“your”)</b>;
                                Smart Solutions ehf, a company incorporated under the laws of Iceland (Company ID: 680995-
                                2499) doing business on <a href="https://www.smartsolutions.is">https://www.smartsolutions.is</a> ,<b>(“Smart Solutions”, “we”, “us”,</b> or
                                <b>“our”)</b>. Smart Solutions ehf, and Issuer may be collectively referred to as the “Parties” or individually as
                                a “Party” in this Agreement.
                           </p>
                        </div>

                        <div>
                            <h2>2. Offer and Acceptance and Users</h2>

                            <h3>2.1. Definition of Issuer</h3>
                            <p>In this Agreement we define Issuer as either an individual (a natural person) or a legal entity.
                                You may subscribe to our Services as an individual or a Company, and you warrant that the
                                representations you make to us are truthful. </p>

                            <h3>2.2. Definition of Services</h3>
                            <p>Our Services consist of the creation of Pass Templates, and Passes <b>(“Pass”</b> or <b>“Passes”)</b> derived
                                from those templates. Passes are made and distributed in the .pkpass file format. In addition, our Services comprise the access to our Smartpages, a web application, on which you can manage and distribute Passes to your Customers’ device that can be run on Mobile Wallet Applications both on Apple iOS and Android designed for receiving .pkpass files. We
                                grant you the use of our Services and access to Smartpages in order to manage and distribute
                                Passes to your Customers. In addition, we grant you access under the applicable terms and conditions, the use of our
                                Smartscanner mobile application.</p>

                            <h3>2.3. Acceptance</h3>
                            <p>In order to use our Services, you must first agree to this Agreement, and any other terms that
                            may apply. If you do not accept this Agreement, you are not permitted to use our Services. The
                            way in which you agree with this Agreement is by registering to our Services, and ticking the “I
                            agree” box, and by subsequently registering. </p>

                            <h3>2.4. Legal Representative</h3>
                            <p>You accept this Agreement and agree on its terms either on your behalf or as legal
                                representative of the Company you act on behalf of.</p>

                            <h3>2.5. Authorized User(s)</h3>
                            <p>You shall be the principal Authorized User, and the rights in and to the Services are limited to
                                one Authorized User only, and cannot be shared or used by a third-party company or other
                                individuals (natural person) than Issuer.</p>

                            <h3>2.6. Legal Age</h3>
                            <p>In the jurisdiction in which you reside, you are of legal age (at least 18 years of age) and have
                                the right and authority to enter into this Agreement on either your behalf, or legally bind the
                                Company you act on behalf of.</p>
                        </div>

                        <div>
                            <h2>3. Use of our Services</h2>
                            <h3>3.1. Permitted Uses and Restrictions </h3>
                            <p>This Agreement sets forth the terms and conditions under which we agree to provide solely to
                                the Authorized Users as described in section 2.3 of this Agreement, (a) the subscription for and
                                access to the Services; (b) subscription for and access to all Pass Templates and Passes,
                                resulting from the use of our Services.

                                The meaning and scope of our Services shall always be according to the most recent definition
                                laid down in this Agreement. Unless otherwise designated in this Agreement, Smart Solutions
                                retains all right, title, and interest in and to our Services.</p>

                            <h3>3.2. Warranty of Originality</h3>
                            <p>We represent and warrant that we are the owner of our Services and of each and every
                            component thereof. In addition, we represent and warrant that our Services will be its own
                            original work, without incorporation of your software, texts, images, or any other assets
                            created by you or third-parties, except to the extent that you consent to such incorporation in
                            writing. </p>

                            <h3>3.3. No License</h3>
                            <p>Nothing in this Agreement shall be deemed to constitute the grant of a license of any sort or
                            any other right in Smart Solutions proprietary software, patents, trade and service marks,
                            rights, copyrights interests, (personal) data, or any other interest in the mobile and web
                            application or computer program, including source code, forms, schedules, manuals, other
                            proprietary items or intellectual property utilized or provided by Smart Solutions in connection
                            to this Agreement. </p>

                            <h3>3.4. Representations Issuer</h3>
                            <p>You represent and warrant that all information provided to us in connection with this
                            Agreement, and the issue of Pass Templates and Passes will be true, accurate, complete and up
                                to date. You will promptly notify us of any changes to such information.

                                You understand and agree that you will fully comply with your obligations under this
                                Agreement, and you assume full responsibility and liability for any actions undertaken by you in
                                relation to your Pass Template and Pass, the use by your Customers, and compliance with the
                                terms of this Agreement. </p>

                            <h3>3.5. Permitted Use of the Services</h3>
                            <p>You acknowledge and agree you will use our Services solely for the purposes expressly
                            permitted by this Agreement, and you undertake to comply with all laws and regulations that
                            may apply in your jurisdiction. The use of our Services for any illegal or unlawful activity will
                            lead to immediate termination of this Agreement in accordance with the Termination section in
                            this Agreement.

                                The Pass you issue to Customer and all related information will not violate, or infringe any
                                existing copyrights, trademarks, publicity rights, trade secrets, patents, or any other proprietary
                                rights, and you will be responsible for all activities you undertake in connection with our
                                Services and remain solely responsible and liable for all costs, expenses and losses incurred by
                                you. You acknowledge and agree that we reserve the right to review, approve and reject your
                                Pass Template material when submitted, and at any time during the Term of this Agreement. </p>
                        </div>

                        <div>
                            <h2>4. Passes</h2>
                            <h3>4.1. Distribution</h3>
                            <p>You may distribute your Passes to your Customers by mobile or web application, or any other
                                means we offer. We shall never be responsible for any costs, expenses, damages or losses you
                                may incur as a result of distributing your Pass Templates and Passes in the above-described
                                manner. In addition, you indemnify us from any costs, expenses, damages, losses you may incur
                                as a result of distributing your Pass Templates and Passes.</p>

                            <h3>4.2. Your Pass Terms</h3>
                            <p>You will be fully responsible and liable for all terms, representations and warranties included in
                            the Pass, and we will never be a party to your relationship with the customer, nor be liable for
                            assistance or customer support in relation to the Passes you issue. Your terms can be displayed
                            on the back of each pass. You are solely responsible for setting your own terms and conditions
                            for the use of the Passes you issue and make sure they are in accordance with the terms of our
                            Services. </p>

                            <h3>4.3. Intellectual Property</h3>
                            <p>In accordance with your right to use the Pass Templates and Passes to the extent permitted in
                            this Agreement, you hereby give us your unequivocal permission to use all trademarks, logos
                            and photos and images associated with your Pass Templates and Passes, including all
                            promotional information, and marketing materials. </p>
                        </div>

                        <div>
                            <h2>5. Disclaimer</h2>
                            <p>WE SHALL NOT BE LIABLE FOR ANY DAMAGES OR LOSSES ARISING FROM ANY USE, MISUSE,
                                INABILITY TO USE, INTERRUPTION OR SUSPENSION, OR TERMINATION OF OUR SERVICES, IOS
                                AND ANDROID SERVICES, YOUR PASS TEMPLATES OR PASSES, OR ANY OF OUR RELATED
                                SERVICES PROVIDED, INCLUDING BUT NOT LIMITED TO ANY LOSS OR FAILURE TO DISTRIBUTE
                                AND DISPLAY YOUR PASS IN APPLE IOS AND ANDROID OR ANY THIRD-PARTY CLAIMS RELATED
                                TO ALL THE ABOVEMENTIONED.</p>
                        </div>

                        <div>
                            <h2>6. Indemnification </h2>
                            <p>To the extent permitted by the applicable law or regulations, you agree to indemnify and hold
                                us, our directors, officers, independent contractors, employees, and agents harmless from any
                                and all claims, losses, damages, liabilities, expenses, tax obligations including without limitation
                                all attorneys' fees and court expenses that may arise from or related to your representations
                                warranties in this Agreement, including the violation or breach and any claims or legal action
                                that your Pass Template or Pass infringe on any third-party intellectual property rights,
                                including but not limited to any claims from end-users for example for payments made to you,
                                including related logos, trademarks, content and/or photo and images. </p>
                        </div>

                        <div>
                            <h2>7. Pricing and Renewal</h2>
                            <h3>7.1. Fees</h3>
                            <p>You acknowledge and agree to pay us the monthly fees for our Services we provide under this
                                Agreement, and as set forth on the following URL:<a href=" https://smartsolutions.is/subscription-plans"> https://smartsolutions.is/subscription-plans</a>. All fees paid by you remain non-refundable and may include the applicable Value Added Tax
                                (VAT). We reserve the right to amend fees and other charges for the subsequent renewal cycle.</p>

                            <h3>7.2. Chargeback</h3>
                            <p>If you initiate a chargeback or otherwise reverse a payment you have previously made, we may
                                in our sole discretion immediately terminate your account. All charges we make for purchases
                                remain nonrefundable, and we never make refunds for partially used periods. </p>
                        </div>

                        <div>
                            <h2>8. Changing Subscription Plans</h2>
                            <p>You may upgrade or downgrade your subscription. The old subscription will be cancelled on the
                                day you sign up to a new subscription plan. The new applicable subscription fee will be charged
                                from the same day until the end of the month. The subscription fees that already have been
                                paid that month will be set-off with the new fees according to the following stipulations.

                                When you change to a new plan we will charge you a total fee based on the daily rate according
                                to the following calculation scheme. The fee that has been charged that month is divided by the
                                number of days in the same month, and we charge you the number of full days you have used
                                the Services, and for the new plan the number of days that remain until the end of that month.

                                Each subscription is paid upfront on the beginning of each month, and we do not refund
                                upfront paid fees, in accordance with Section 9 (Term and Termination) in this Agreement.</p>
                        </div>

                        <div>
                            <h2>9. Term and Termination </h2>
                            <h3>9.1. Term</h3>
                            <p>The Term of this Agreement shall commence on the date you subscribe to our Services and has
                                no end date. All subscriptions shall automatically extend and your credit card shall be charged
                                for another month, unless you cancel your subscription before the 26th in the relevant month,
                                before 11:59pm GMT.

                                Once the Agreement has been terminated, you cannot use or issue your Pass Templates and
                                Passes. In addition, your Passes will become invalid which means your customer cannot use any
                                Passes that they have already received in their mobile wallet from you. We keep all Pass
                                Templates and Passes in our database for a period of six (6) months after termination of this
                                Agreement.</p>

                            <h3>9.2. Termination</h3>
                            <p>This Agreement, including our Services and all rights and obligations granted by us, will
                            terminate with immediate effect:

                                <ol>
                                    <li>We may terminate this Agreement, or suspend your rights to use our Services if you fail to
                                        accept any new Agreement;</li>
                                    <li>. If you fail to comply with any stipulation in this Agreement and you will not cure such
                                        breach within ten (10) days after we have become aware of such breach;</li>
                                    <li>If you fail to pay your due fees, your company dissolves, ceases or becomes insolvent, or
                                        files for bankruptcy; </li>
                                    <li>If you engage in any fraudulent, improper, unlawful or misleading act(s) in relation to this
                                        Agreement, and our Services.</li>
                                </ol></p>
                        </div>

                        <div>
                            <h2>10. No Warranty</h2>
                            <p>All our Services, including our mobile and web applications, may contain errors or inaccuracies
                                that could result in system failures or the loss of information.

                                Smart Solutions reserves the right to amend, suspend, remove, disable access to any Services at
                                any time without notice, or may remove our Services for an indefinite period of time, for which
                                we fully disclaim our liability.

                                YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF OUR SERVICES IS AT YOUR SOLE
                                RISK AND THAT THE FULL RISK AS TO SATISFACTORY PERFORMANCE, ACCURACY AND OVERALL
                                QUALITY LIES WITH YOU. THE SERVICES IS PROVIDED ON AN "AS-IS" AND "AS AVAILABLE"
                                BASIS, WITH ALL POSSIBLE ERRORS AND WITHOUT WARRANTY OF ANY KIND. <br />
                                <br />
                                WE HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO OUR SOFTWARE
                                AND SERVICES EITHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING AND WITHOUT
                                LIMITATION THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
                                SATISFACTORY QUALITY, AND FITNESS FOR A PARTICULAR PURPOSE, INCLUDING NON
                                INFRINGEMENT OF THIRD PARTY RIGHT, OR COMPATIBLENESS WITH FUTURE APPLE IOS,
                                ANDROID OR OTHER THIRD-PARTY PRODUCTS OR SERVICES.

                                YOU FULLY ACKNOWLEDGE AND AGREE THAT OUR SOFTWARE AND SERVICES ARE NOT
                                INTENDED OR SUITABLE FOR USE IN ENVIRONMENTS WHERE ERRORS, OR DELAYS IN THE
                                TRANSMISSION OR STORAGE OF DATA OR INFORMATION BY OR THROUGH OUR SOFTWARE OR
                                SERVICES, COULD LEAD TO DEATH, PERSONAL INJURY, OR FINANCIAL, PHYSICAL OR PROPERTY
                                DAMAGE.

                                WE DO NOT GUARANTEE THE AVAILABILITY, ACCURACY, COMPLETENESS, RELIABILITY OF
                                LOCATION DATA OR ANY OTHER DATA OR INFORMATION DISPLAYED BY ANY SERVICES OR
                                SOFTWARE.</p>
                        </div>

                        <div>
                            <h2>11. Notices </h2>
                            <p>Any notices relating to this Agreement shall be in writing. Notices will be deemed given by us
                                when you sent us such notification to the email address you provided us. All notices to us will
                                be deemed given when sent by you and received by us at <a href="mailto:legal@smartsolutions.is">legal@smartsolutions.is</a>. You consent
                                to receive notices by email. You may change your email or postal address by giving us written
                                notice to the above-mentioned email address.</p>
                        </div>

                        <div>
                            <h2>12. Amendments</h2>
                            <p>We may amend the terms of this Agreement at any time. In order to continue using our
                                Services, you must accept and agree to the new terms. If you do not agree to the new terms,
                                you may not use our Services, and your subscription will be suspended or terminated by us. </p>
                        </div>

                        <div>
                            <h2>13. Applicable Law and Dispute Resolution</h2>
                            <h3>13.1. Applicable Law and Forum.</h3>
                            <p>This Agreement shall be governed and construed in accordance with the laws of Iceland. Any
                                dispute, or claim arising out of or relating to this Agreement, shall be submitted to the
                                competent civil court in Iceland.</p>

                            <h3>13.2. Conflict Resolution</h3>
                            <p>Subject to the provisions of this Agreement regarding applicable law and competent court, the
                            Parties agree not to commence any legal proceedings with respect to any dispute that may
                            arise out of this Agreement. The Parties agree to take the following steps in the event of a
                            dispute, conflict or disagreement (“Dispute”).
<ol>
                                    <li>The Party who claims first there is a Dispute will send the other Party a written notice
                                        setting out the nature of this dispute;</li>
                                    <li>The Parties will subsequently make an attempt to resolve the Dispute through direct
                                        negotiation among the Parties, or by persons who have been assigned to resolve the
                                        dispute by means of direct negotiations;</li>
                                    <li>If the Parties are unable to resolve the dispute within fourteen (14) Business Days from the
                                        day of receipt of the notice referred to in subparagraph 1, the Dispute will be submitted to
                                        an independent person or institution that will deliver a non-binding opinion regarding the
                                        dispute;</li>
                                    <li>If the Parties are unable to resolve the dispute within ten (10) Business Days after receiving
                                        the non-binding opinion as described in the preceding subparagraph 3, the Dispute will be
                                        submitted to mediation or other alternative dispute resolution procedure agreed by the
                                        Parties.</li>
                                </ol>
                            </p>
                        </div>

                        <div>
                            <h2>14. Miscellaneous</h2>
                            <h3>14.1. Sufficient Level Data Protection</h3>
                            <p>You represent and warrant that you will implement the highest standards of privacy, cookie
                                and data protection policies, and for that purpose, you must accept our Data Processing
                                Agreement before you use our Services. If any provision contained in this Agreement is in
                                conflict, or inconsistent with any provision in our Privacy and Cookie Policy and/or Data
                                Processing Agreement with you, the provisions in the latter two documents shall prevail. </p>

                            <h3>14.2. Entire Agreement</h3>
                            <p>This Agreement constitutes the entire agreement between the Parties, you expressly agree that
                                this Agreement may be executed in counterparts and delivered by publicizing on the website,
                                PDF or in any other matter. </p>

                            <h3>14.3. Headings</h3>
                            <p>The headings used in this Agreement are included for the benefit of the parties and shall have
                                no effect whatsoever on the terms, or its interpretation or meaning. </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">{T("Close")}</button>
                    </div>
                </div>
            </div>
        )
    }
}