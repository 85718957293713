import React from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class ScannerLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            redirect: false
        };
    }
    handleClick = (e, data, target) => {
        var link = document.createElement('a');
        link.href = data.link;
        var fileName = data.link.substring(data.link.lastIndexOf('/') + 1, data.link.length);
        link.download = fileName;
        window.open(data.link);
    }
    render() {
        const scannerData = this.props.singleScannerLayout;
        return (
            <div>
                <div className="same_status_section codes_section">
                    <div className="codes_left">
                        <h3>{scannerData.name}</h3>
                    </div>
                    {/* <ContextMenuTrigger id={"right_click_action_QR"+scannerData.id} > */}
                        <div className="codes_right">
                            <div className="codes-image_section">
                                <span>{T("Right click on this code")}<br />{T("to save it")}</span>
                                <img src={scannerData.qrcode_path} />
                                <a href={scannerData.distribution_link} target="_blank" >{scannerData.distribution_link}</a>
                            </div>
                        </div>
                    {/* </ContextMenuTrigger> */}
                </div>
               
            </div>
        )
            }
};

export default ScannerLayout;