import React from 'react';
import MedeByLogo from '../../assets/images/imgpsh_fullsize.png';
import { Data_Constants, Constants } from '../../config';
import ContactUs from '../../components/Dashboard/ContactUs';
import { api } from "../../api";
import { functions } from "../../functions";
import TermsCondition from '../../pages/TermsCondition';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import RequestConfirmPopup from '../Dashboard/RequestConfirmPopup';
import SmartSolutionPng from '../../assets/images/smart-solution.png';
import taekin_logo from '../../assets/images/taekni-grey-logo.png';

let content;

class UserFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            language: localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang') : 'en',
        };
    }
    closeModal = () => {
        $(".modalcontactus [data-dismiss=modal]").trigger("click");
        $("#contactUsConfirmBtn").trigger({ type: "click" });
    }

    render() {
        let loader = functions.getLoader(this.state.loader);
        var fullDate = new Date();
        var currentYear = fullDate.getFullYear();

        return (
            <div>
                <footer className="padding_container block_element">
                    <div className="footer_block">
                        <div className="footer_logo block_element">
                            {/* <img src={SmartSolutionPng} /> */}

                            <img src={localStorage.getItem('rtc-lang') && localStorage.getItem('rtc-lang')=='is' ? Constants.SmartSolutionLogoFooterWitTextIs : Constants.SmartSolutionInvertedLogoWitText} />
    
                            <div class={localStorage.getItem('rtc-lang') && localStorage.getItem('rtc-lang')=='is'? "suportedAreaFooter suportedAreaFooterIs":"suportedAreaFooter"}>
                                <span>{T('Supported by:')} </span>
                                <img src={taekin_logo} alt="SmartSolutions" />
                            </div>
                        </div>
                        <div className="footer_content block_element">
                            <div className="footer-left">
                                <span>{T("Location: Katrinartun 4, 105 Reykjavík Iceland")}</span>
                                {/* <span><a href="mailto:sales@smartsolutions.is?subject=Enquiry from the website">sales@smartsolutions.is</a></span>
                                <span><a href="tel:+3545378300">+354 537 8300</a></span> */}
                                <span><a href="mailto:sales@smartsolutions.is">sales@smartsolutions.is</a></span>
                                <span><a href="tel:+354 537 8300">+354 537 8300</a></span>
                                <span>{T("Id")}: 680995-2499</span>
                            </div>
                            <div className="footer_rgt">
                                {/* <span id="contact_us_toggle_span">Contact us</span> */}
                                <span id="contact_us_toggle_span" onClick={() => { $('#contactus_btn_reset').trigger('click') }} data-toggle="modal" data-target="#modalcontactus">{T("Contact us")}</span>
                                {/* <span>FAQ</span> */}
                                <span id="termcondition_toggle_span" data-toggle="modal" data-target="#termsCondition">{T("General Terms and Conditions of Service")}</span>
                                {/* <span>Privacy and cookie policy</span> */}
                                <span><Link id="privacy-policy-link" to="/privacy-policy">{T("Privacy and Cookie Policy")}</Link></span>
                            </div>
                        </div>
                        <div className="copyright padding_container block_element">
                            <p>&copy; {currentYear} {T("Smart Solutions ehf")} </p>
                        </div>
                    </div>
                </footer>
                {/* <!-- footer-end --> */}

                <div className="modal modalcontactus fade " id="modalcontactus" role="dialog" ref="modalcontactus">
                    <ContactUs modalClose={this.closeModal} />
                </div>

                <div className="modal contactUsConfirm fade " id="contactUsConfirm" role="dialog" ref="contactUsConfirm">
                    <RequestConfirmPopup
                        confirmType="contactusRequest"
                        userList={[]}
                        userData={[]} />
                </div>
                <button type="button" data-toggle="modal" data-target="#contactUsConfirm" className="contactUsConfirmBtn" id="contactUsConfirmBtn" style={{ display: 'none' }} >Open Modal</button>
                <div className="modal term-popup" id="termsCondition">
                    <TermsCondition />
                </div>
            </div>
        );
    }
};

export default UserFooter;
