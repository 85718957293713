import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import $ from 'jquery';


class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Sign in',
            loader: false,
            formSubmit: true,
            submitErrorEmail: "",
        };
    }
    isFilled = () => {
        document.getElementById('submitButton').classList.add('isFilled');
    }
    render() {
        $(document.body).removeClass('first_front_page');
        if (localStorage.getItem('loginISP_user_sesion') != null ) { // If unauthrized user
            return <Redirect to='/dashboard' />
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);


        return (
            <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                    toast.dismiss();
                    this.setState({ errors: '', loader: true, formSubmit: false });
                    var PostData = {
                        type: "issuer",
                        email: values.email.trim(),
                        password: values.password,
                    };
                    api.login(PostData)
                        .then(res => {
                            if (res.data && res.data.status && res.data.status == 200) {
                                let resData = functions.D_Client(res.data.data)
                                // functions.authenticateUser(res.data);
                                functions.authenticateUser(resData);
                                toast.success(T("Welcome") +" " + resData.contact_name, { hideProgressBar: true });
                                if(resData.showPopup && resData.daysLeft && resData.daysLeft<=7){
                                    let content = {
                                        daysLeft: resData.daysLeft,
                                        paymentType:resData.pay_with_invoice && resData.pay_with_invoice=='1' ? 'Invoice':'Card',
                                    };
                                    localStorage.setItem('PaymentFailed',JSON.stringify(content))
                                }
                                // toast.success("Welcome " + res.data.contact_name, { hideProgressBar: true });
                                this.setState({ loader: false, formSubmit: true, submitErrorEmail: '' });
                            }
                            else if (res.data && res.data.error) {
                                if (res.data.error.email) {
                                    this.setState({
                                        submitErrorEmail: res.data.error.email
                                    })
                                    setErrors({ email: ' ', submitErrorEmail: res.data.error.email })
                                }
                                else if (res.data.error.password) {
                                    this.setState({
                                        submitErrorEmail: ''
                                    })
                                    setErrors({ password: res.data.error.password })
                                }
                                else {
                                    this.setState({ submitErrorEmail: '' })
                                    Object.keys(res.data.error).forEach(function (key) {
                                        toast.dismiss();
                                        setTimeout(() => {
                                            toast.error(res.data.error[key], { hideProgressBar: true });
                                        }, 500);
                                    });
                                }
                                // toast.error(res.data.error, { hideProgressBar: true })

                            }
                            else {
                                toast.dismiss();
                                setTimeout(() => {
                                    toast.error(res.message, { hideProgressBar: true })
                                }, 600);
                            }
                            this.setState({ loader: false, formSubmit: true });
                        }).catch(err => {
                            this.setState({ loader: false, formSubmit: true })
                        });
                    setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().trim()
                        .email(Validation_Constants.Invalid_Email)
                        .required(Validation_Constants.Required2),
                    password: Yup.string()
                        .required(Validation_Constants.Required2),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                    } = props;

                    return (
                        //  {/* <!-- Header-END --> */}
                        <div className="wrapper block_element">
                            <div className="inner-pages block_element">
                                <div className="container">
                                    {loader}
                                    <div className="bg_color_section block_element sign_up_page sign_in padding_container">
                                        <h2>{T("Sign in")}</h2>
                                        <form className="sign_up_form block_element" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="email"
                                                        type="text"
                                                        value={values.email}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        autoComplete="off"
                                                        onBlur={handleBlur}
                                                        placeholder=" "
                                                        className={
                                                            errors.email && touched.email ? 'floating-input inputText error' : 'floating-input inputText'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label for="email">{T("Email")}</label>
                                                    <div className="error-main">
                                                        {errors.email &&
                                                            touched.email && <p className="error">{errors.email}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group passwordformGroup">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="password"
                                                        autoComplete="off"
                                                        type="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder=" "
                                                        className={
                                                            errors.password && touched.password ? 'floating-input inputText error' : 'floating-input inputText'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label for="password">{T("Password")}</label>
                                                    <div className="error-main">
                                                        {errors.password &&
                                                            touched.password && <p className="error">{T(errors.password)}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="forgot_password block_element">
                                                <Link to="/forgot-password">{T("Forgot your password")}?</Link>
                                            </div>
                                            <div className="form-group-btn">
                                                <input type="submit" id="submitButton" name="" disabled={(JSON.stringify(errors) != "{}") || values.email == '' || values.password == ''} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.email != "" && values.password != "" ? 'isActive' : 'isNotActive'} value={T("Sign in")} />
                                            </div>
                                            <div className="block_element create_account">
                                                <span>{T("Don't have an account")}? <Link to="/signup">{T("Signup")}</Link></span>
                                            </div>
                                        </form>
                                        {errors.submitErrorEmail &&
                                            <div className="signinEmailErrorMsg">
                                                <span>{T(errors.submitErrorEmail)}</span>
                                                <span>{T("If you haven’t created an account you can do it")} <Link to="/signup">{T("here")}</Link></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        //  {/* <!-- wrapper-end --> */}
                    );
                }}
            </Formik>
        );
    }
};

export default SignIn;
