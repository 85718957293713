import React from 'react';
import LogoQr from '../assets/images/logo_Qr.png';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import backArrow from '../assets/images/back_arrow.png';
import { Constants } from '../config';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class ResetLinkSent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStickyClass: false
        };
    }
 
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="wrapper block_element">
               
                <div className="bg_color_section block_element thanku_page padding_container">
                    <Link to="/"><img src={LogoQr} /></Link>
                    <h2>{T("Password reset")}!</h2>
                    <div class="confirmation text">
                        <span>{T("Password reset link has been  sent")} </span><span class="circle"><i class="fa fa-check"></i></span>
                    </div>
                </div>
            </div>
        )

    }
}