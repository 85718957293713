module.exports = {
  "COMING SOON": "COMING SOON",
  "SignIn": "SignIn",
  "SignUp": "SignUp",
  "Cancel": "Cancel",
  "Save": "Save",
  "Delete": "Delete",
  "Back": "Back",
  "Upgrade": "Upgrade",
  "Select": "Select",
  "Order": "Order",
  "No": "No",
  "Yes": "Yes",
  "Deactivate": "Deactivate",
  "Activate": "Activate",
  "Name": "Name",
  "Email": "Email",
  "Send": "Send",
  "Password": "Password",
  "Confirm password": "Confirm password",
  "Confirm": "Confirm",
  "user": "user",
  "users": "users",
  "Basic": "Basic",
  "Full": "Full",
  "Supported by:":"Supported by:",
  "Welcome": "Welcome",
  "Status": "Status",
  "Email address": "Email address",
  "Date added": "Date added",
  "Activate": "Activate",
  "Download list": "Download list",
  "Browse": "Browse",
  "here": "here",
  "Edit": "Edit",
  "left": "left",
  "days": " days",
  "day": " day",
  "next": "next",
  "Great": "Great",
  "Upload": "Upload",
  "DEMO CONTENT": "DEMO CONTENT",
  "Column": "Column",
  "Row Data": "Row Data",
  "Valid": "Valid",
  "Invalid": "Invalid",
  "Redeemed": "Redeemed",
  "Used": "Used",
  "Deleted": "Deleted",
  "Expired": "Expired",
  "Restricted": "Restricted",
  "punched": "punched",
  "verified": "verified",
  "used": "used",
  "Times": "Times",
  "FREE": "FREE",
  "Start plan": "Start plan",
  "Upgrade now": "Upgrade now",
  "template": "template",
  "templates": "templates",
  "type": "type",
  "Add": "Add",
  "types": "types",
  "Change payment method": "Change payment method",
  "Your order": "Your order",
  "Browse for your list": "Browse for your list",

  //Days
  "Monday": "Monday",
  "Tuesday": "Tuesday",
  "Wednesday": "Wednesday",
  "Thursday": "Thursday",
  "Friday": "Friday",
  "Saturday": "Saturday",
  "Sunday": "Sunday",
  "Usages": "Usages:",
  "Select subscribtion":"Select subscribtion",


  //Error Messages 
  "This field is required": "This field is required",
  "The field is required": "The field is required",
  "Passwords do not match!": "Passwords do not match!",
  "This is not a recognized email": "This is not a recognized email",
  "There was an error please try again.": "There was an error please try again.",
  //Sign In && Forgot Password
  "Sign in": "Sign in",
  "Forgot your password": "Forgot your password",
  "Don't have an account": "Don't have an account",
  "Sign Up": "Sign Up",
  "Signup": "Sign up",
  "If you haven’t created an account you can do it": "If you haven’t created an account you can do it",
  "Write down your email so we can send you a new one": "Write down your email so we can send you a new one",
  "There is no account associated with this email address. Please make sure that it is spelled correctly.": "There is no account associated with this email address. Please make sure that it is spelled correctly",
  "Wrong password":"Wrong password",

  //Sign Up && Confirm Email
  "Sign up to continue": "Sign up to continue",
  "Sign up to_invited": "Sign up to",
  "Smartpages": "Smartpages",
  "Sign up": "Sign up",
  "I agree to the": "I agree to the",
  "Terms and conditions": "Terms and conditions",
  "Your Invitation is expired": "Your Invitation is expired",
  "The email has already been confirmed": "The email has already been confirmed",
  "Your email confirmation is expired": "Your email confirmation is expired",
  "Checking your email confirmation token": "Checking your email confirmation token",
  "Thank you for signing up": "Thank you for signing up",
  "Sign up confirmation email sent": "Sign up confirmation email sent",
  "An email has been sent to your address": "An email has been sent to your address",
  "Please click the link in the email to confirm your sign up": "Please click the link in the email to confirm your sign up",
  "Your contact name must consist of at least 2 characters":"Your contact name must consist of at least 2 characters",
  "Please enter a valid email address":"Please enter a valid email address",
  "Your company name must consist of at least 2 characters":"Your company name must consist of at least 2 characters",
  "Your company id must be consist 10 characters":"Your company id must be consist 10 characters",
  "Your password must be at least 8 characters long":"Your password must be at least 8 characters long",
  "One special character is required":"One special character is required",
  "Passwords do not match!":"Passwords do not match!",

  //Reset Password
  "Reset password": "Reset password",
  "Reset": "Reset",
  "Password reset": "Password reset",
  "Password reset link has been  sent": "Password reset link has been  sent",

  //Navigation
  "My Profile": "My Profile",
  "Profile": "Profile",
  "Subscription": "Subscription",
  "Password": "Password",
  "Add scanner": "Add scanner",
  "My Scanners": "My Scanners",
  "Scanner": "Scanner",
  "Distribution": "Distribution",
  "License holders": "License holders",
  "Users": "Users",
  "Codes": "Codes",
  "Statistics": "Statistics",

  // Header

  "Hello": "Hello",
  "My Pass Templates": "My Pass Templates",
  "My Profile": "My Profile",
  "Sign Out": "Sign Out",
  "My Scanners": "My Scanners",

  // Footer
  "Contact us": "Contact us",
  "Id": "id",
  "Location: Katrinartun 4, 105 Reykjavík Iceland": "Location: Katrinartun 4, 105 Reykjavík Iceland",
  "Privacy and Cookie Policy": "Privacy and Cookie Policy",
  "General Terms and Conditions of Service": "General Terms and Conditions of Service",
  "Smart Solutions ehf.": "Smart Solutions ehf",

  // My Pass Template 
  "My Pass Templates": "My Pass Templates",
  "VAT": "VAT",
  "Start by ordering a Pass Template": "Start by ordering a Pass Template",
  "Order a pass template": "Order a Pass Template",
  "Order a Pass Template": "Order a Pass Template",
  "Order a new": "Order a new",
  "Pass Template": "Pass Template",
  "Ordered": "Ordered",
  "New Pass": "New Pass",
  "Template pending": "Template pending",
  "Pass status will be shown here": "Pass status will be shown here",
  "Date issued": "Date issued",
  "You can only order a Pass Template build of existing type": "You can only order a Pass Template build of existing type",
  "If you want to order a Pass Template of another type, you have to upgrade your subscription": "If you want to order a Pass Template of another type, you have to upgrade your subscription",
  "No result found": "No result found",

  //Pass Statistics Tab 
  "Need more statistics": "Need more statistics",
  "Order a Pass Template to get started with Smart Solutions": "Order a Pass Template to get started with Smart Solutions",
  "Status of pass limit used": "Status of pass limit used",
  "Times used with SmartScanner": "Times used with SmartScanner",
  "Times used with self service": "Times used with self service",
  "Passes remaining": "Passes remaining",
  "Color of button changes to match the color reached by the gauge": "Color of button changes to match the color reached by the gauge",
  "Number of passes used": "Number of passes used",
  "Number of passes downloaded": "Number of passes downloaded",
  "Usage on time per day": "Usage on time per day",
  "Usage per day": "Usage per day",
  "Usage by location": "Usage by location",
  "Number of rewards recieved": "Number of rewards recieved",
  "Number of rewards unused": "Number of rewards unused",
  "Number of rewards used": "Number of rewards used",
  "Summary of times used": "Summary of times used",
  "Summary of times verified": "Summary of times verified",
  "Summary of times punched": "Summary of times punched",
  "Times verified with SmartScanner": "Times verified with SmartScanner",
  "Times verified with Refresh in SmartWallet": "Times verified with Refresh in SmartWallet",
  "with SmartScanner": "with SmartScanner",
  "with self service code": "with self service code",
  "with self service": "with self service",
  "Number of passes left": "Number of passes left",
  "Number of passes fully used": "Number of passes fully used",
  "Number of passes not fully used": "Number of passes not fully used",
  "Number of passes unused": "Number of passes unused",
  "Number of passes expired": "Number of passes expired",

  //Pass Template Distribution
  "Send pass to selected": "Send pass to selected",
  "Add users": "Add users",
  "Delete user list": "Delete user list",
  "Download list": "Download list",
  "No users listed": "No users listed",
  "You need to upload a list or manually add new users to see them listed here.": "You need to upload a list or manually add new users to see them listed here.",
  "Upload list": "Upload list",
  "Upload a .csv file": "Upload a .csv file",
  "or simply drag and drop it here": "or simply drag and drop it here",


  //Pass Template Add User
  "Add new user": "Add new user",
  "You can add multiple users by uploading a .csv file": "You can add multiple users by uploading a .csv file",
  "or you can add a user manually": "or you can add a user manually",

  //Pass Template User Tab
  "No results found, Please try different search parameters": "No results found, Please try different search parameters",
  "As soon as an user downloads your pass you will see them listed here": "As soon as an user downloads your pass you will see them listed here",

  //Code Tab 
  "Pass Template distribution code": "Pass Template distribution code",
  "Share this code to distribute your pass to customers": "Share this code to distribute your pass to customers",
  "You can share it any way you prefer. Online, in print or simply": "You can share it any way you prefer. Online, in print or simply",
  "send it to your customers": "send it to your customers",
  "Right click on this code": "Right click on this code",
  "to save it": "to save it",
  "Customer self service code": "Customer self service code",
  "Display this code to your customer so they can scan this": "Display this code to your customer so they can scan this",
  "code to use their pass": "code to use their pass",
  "Take care of how you store this code": "Take care of how you store this code",
  "Save image as": "Save image as",
  "Save image as": "Save image as",
  "will appear here": "will appear here",

  // Passowrd Tab
  "Want to change your password": "Want to change your password",
  "Old password": "Old password",
  "New password": "New password",
  "Confirm new password": "Confirm new password",
  "Update passowrd": "Update passowrd",

  //Subscription & Upgrade Subcription Tab
  "You have exceeded your subscription limit": "You have exceeded your subscription limit",
  "Start by selecting subscription": "Start by selecting subscription",
  "Please upgrade your subscription if you want to add a Pass Template": "Please upgrade your subscription if you want to add a Pass Template",
  "Please upgrade your subscription if you want to add a Scanner": "Please upgrade your subscription if you want to add a Scanner",
  "Before you can order a Pass Template you need to select subscription": "Before you can order a Pass Template you need to select subscription",
  "Distribution Limit": "Distribution Limit",
  "Pass Template Types": "Pass Template Types",
  "Pass Templates types": "Pass Templates types",
  "Pass Templates types_subscription": "Pass Templates types",
  "Pass Templates": "Pass Templates",
  "Issuer Smartpages": "Issuer Smartpages",
  "Usage Statistics": "Usage Statistics",
  "Smartscanner app": "Smartscanner app",
  "Smartscanner licenses": "Smartscanner licenses",
  "Smartwallet app": "Smartwallet app",
  "Customer Self Service": "Customer Self Service",
  "Start FREE plan": "Start FREE plan",
  "Your subscription": "Your subscription",
  "Select subscription": "Select subscription",
  "Cancel subscription": "Cancel subscription",
  "Subscription exceeded": "Subscription exceeded",
  "Use of distribution limit": "Use of distribution limit",
  "Payment method": "Payment method",
  "Paid with invoice": "Paid with invoice",
  "You will be billed monthly by invoice": "You will be billed monthly by invoice",
  "days remaining of your trial period": "days remaining of your trial period",
  "You have requested cancellation of your subscription! It will end on": "You have requested cancellation of your subscription! It will end on",
  "You can cancel or change your subscription anytime": "You can cancel or change your subscription anytime",
  "All prices do not include VAT": "All prices do not include VAT",
  "Are you sure you want to cancel": "Are you sure you want to cancel",
  "subscription": "subscription",
  "This will deactivate all of your Pass Templates at": "This will deactivate all of your Pass Templates at",
  "end of this month. This means your customers": "end of this month. This means your customers",
  "will not be able to use their passes": "will not be able to use their passes",
  "Monthly for the next": "Monthly for the next",
  "Subscription plans for you": "Subscription plans for you",
  "Our services are sold by monthly subscription": "Our services are sold by monthly subscription",
  "All of our subscription plans include": "All of our subscription plans include",
  "For the first three months": "For the first three months",
  "Monthly for the first three months": "Monthly for the first three months",
  "first three months": "first three months",
  "Negotiable": "Negotiable",


  //Downgrade Subscripton

  "Cannot change subscription": "Cannot change subscription",
  "You have too many active passes, Pass Template or Pass Template types": "You have too many active passes, Pass Template or Pass Template types",
  "Please contact sales@smartsolutions.is to change subscription": "Please contact sales@smartsolutions.is to change subscription",
  "You do not have permission to change subscription": "You do not have permission to change subscription",
  "Got it": "Got it",

  //Payment failed
  "Your payment failed": "Your payment failed",
  "We liked to inform you about the fact that you have unpaid invoices": "We liked to inform you about the fact that you have unpaid invoices",
  "or your account will be closed": "or your account will be closed",
  "Please pay your invoices within":"Please pay your invoices within",
  "Please enter a valid email.":"Please enter a valid email.",
  "We liked to inform you about the fact that we were not able to bill your credit card": "We liked to inform you about the fact that we were not able to bill your credit card",
  "Please change your credit card information": "Please change your credit card information",
  "left to rectify this or your account will be closed ": "left to rectify this or your account will be closed",
  "You have":"You have",
  //Payment Form
  "Company": "Company",
  "Company name": "Company name",
  "Card holder name": "Card holder name",
  "Card number": "Card number",
  "Valid thru": "Valid thru",
  "Change method": "Change method",
  "Total price with": "Total price with",
  "Pay using this credit card": "Pay using this credit card",
  "Pass Template build": "Pass Template build",
  "monthly subscription discount": "monthly subscription discount",
  "Amount deducted due to previous subscription payment in current billing cycle": "Amount deducted due to previous subscription payment in current billing cycle",
  "monthly subscription": "monthly subscription",

  //Order Confirm 
  "Your order confirmation": "Your order confirmation",
  "Thank you for your business": "Thank you for your business",
  "As soon as we will get your Pass Template order we will contact you to get your Pass Template material. If you have any question  please contact": "As soon as we will get your Pass Template order we will contact you to get your Pass Template material. If you have any question  please contact",
  "A receipt has been sent to": "A receipt has been sent to",

  //Profile Tab
  "Contact full name": "Contact full name",
  "Contact full name_profile": "Contact full name",
  "Contact email": "Contact email",
  "Contact phone number": "Contact phone number",
  "Company name": "Company name",
  "Company ID number": "Company ID number",
  "Company invoice email": "Company invoice email",
  "Update profile": "Update profile",
  "Credit card token": "Credit card token",
  "Add your payment provider to sell passes":"Add your payment provider to sell passes",
  "Please add your payment provider information and the payments goes straight to your account":"Please add your payment provider information and the payments goes straight to your account",
  "Select your payment provider":"Select your payment provider",
  "Payment provider":"Payment provider",


  // Active Acount Tab
  "Active account users": "Active account users",
  "additional account users": "additional account users",
  "No additional account users": "No additional account users",
  "Your subscription enables you to have": "Your subscription enables you to have",
  "associated with your SmartPages": "associated with your SmartPages",
  "activeuser_Account": " account",
  "activeuser_Accounts": " accounts",
  "To invite a new account user delete one below or upgrade your subscription": "To invite a new account user delete one below or upgrade your subscription",
  "You can invite users in the input below!": "You can invite users in the input below!",
  "Invitation pending": "Invitation pending",
  "Upgrade subscription": "Upgrade subscription",
  "Manage permissions": "Manage permissions",
  "Enter email address to give access to your Smartpage": "Enter email address to give access to your Smartpage",
  "Send invitation": "Send invitation",
  "You need to select a subscription plan to add more account users": "You need to select a subscription plan to add more account users",

  //Manager Permissions
  "Manage permissions": "Manage permissions",
  "Permission": "Permission",
  "Action": "Action",
  "Active Account": "Active Account",
  "Change Subscription": "Change Subscription",
  "Invite Users": "Invite Users",
  "Delete User": "Delete User",
  // Confirm Popup Text
  "USERS ADDED": "USERS ADDED",
  "REQUEST SENT": "REQUEST SENT",
  "PASSES SENT": "PASSES SENT",
  "PASS SENT": "PASS SENT",
  "LICENSES SENT": "LICENSES SENT",
  "LICENSE SENT": "LICENSE SENT",
  "LICENSE HOLDERS ADDED": "LICENSE HOLDERS",
  "LICENSE HOLDER ADDED": "LICENSE HOLDER",

  "subscript_template": `<p>We charge a standard fee of ISK 19,800 for building your pass template. <br /> You can then use your pass template as long as you want to distribute the passes, made from your pass template, freely.</p>`,

  //My Scanner & Scanner
  "Add a scanner": "Add a scanner",
  "After you have added scanners you will see them here": "After you have added scanners you will see them here",
  "all issuer templates are displayed here in a list": "all issuer templates are displayed here in a list",
  "Update scanner": "Update scanner",
  "Delete scanner": "Delete scanner",
  //Add Scanner
  "You do not have any template to create scanner": "You do not have any template to create scanner",
  "Name of scanner": "Name of scanner",
  "Allow all templates": "Allow all templates",
  //Scanner Distribution
  "No license holders listed": "No license holders listed",
  "As soon as a license holder authenticates his scanner you will see them listed here": "As soon as a license holder authenticates his scanner you will see them listed here",
  "You need to upload a list or manually add license holders to see them listed here": "You need to upload a list or manually add license holders to see them listed here",
  "Send license to selected": "Send license to selected",
  "Add License Holder": "Add License Holder",
  "Delete license holder list": "Delete license holder list",
  //license Holder
  "Activate this license holder": "Activate this license holder",
  "Deactivate this license holder": "Deactivate this license holder",
  "This license holder will be able to use the SmartScanner": "This license holder will be able to use the SmartScanner",
  "This license holder will not be able to use the SmartScanner": "This license holder will not be able to use the SmartScanner",

  //Add License Holder
  "Add license holder": "Add license holder",
  "You can add multiple license holder by uploading a .csv file": "You can add multiple license holder by uploading a .csv file",
  "What is a .csv file? You can download our template": "What is a .csv file? You can download our template",
  "or you can add a license holder manually": "or you can add a license holder manually",

  //Send License and Passes
  "Sending passes": "Sending passes",
  "Send licenses": "Send licenses",
  "out of": "out of",
  "customers selected": "customers selected",
  "license holder selected": "license holder selected",
  "Subject": "Subject",
  "Message": "Message",
  "Send Pass": "Send Pass",
  "Send license": "Send license",
  "License code will be recieved by email": "License code will be recieved by email",
  "Pass will be recieved by email": "Pass will be recieved by email",

  //Delete Popup
  "Delete your user list forever": "Delete your user list forever",
  "Delete your active account user forever": "Delete your active account user forever",
  "Delete your scanner forever": "Delete your scanner forever",
  "Delete license holder list forever": "Delete license holder list forever",
  "Delete license holder forever": "Delete license holder forever",
  "This action cannot be undone": "This action cannot be undone",
  "All licenses connected to this scanner will also be deleted": "All licenses connected to this scanner will also be deleted",
  "We recommend downloading the list before you delete it": "We recommend downloading the list before you delete it",
  "The license holder will not have a license to use SmartScanner anymore": "The license holder will not have a license to use SmartScanner anymore",
  "After you delete this list you will never be able to retrieve this list again": "After you delete this list you will never be able to retrieve this list again",
  "After you delete this scanner you will never be able to retrieve it again": "After you delete this scanner you will never be able to retrieve it again",
  "After you delete this license holder list you will never be able to retrieve it again": "After you delete this license holder list you will never be able to retrieve it again",
  "After you delete this license holder you will never be able to retrive it again": "After you delete this license holder you will never be able to retrive it again",

  //ContactUs
  "Contact Us": "Contact Us",
  "Contact us about your customised subscription plan": "Contact us about your customised subscription plan",
  "Go ahead, we're listening": "Go ahead, we're listening",
  "Submit": "Submit",

  //Tool Tip
  "We build a Pass Template for you. Your customer personal pass is then made from that Pass Template": "We build a Pass Template for you. Your customer personal pass is then made from that Pass Template",
  "There are 4 different Pass Template types: coupon, ticket, punch and member": "There are 4 different Pass Template types: coupon, ticket, punch and member",
  "This is the maximum number of valid passes in circulation by your customers": "This is the maximum number of valid passes in circulation by your customers",
  "You don't need any hardware if you use the Customer self service functionality": "You don't need any hardware if you use the Customer self service functionality",
  "This functionality is only available in the Smartwallet app": "This functionality is only available in the Smartwallet app",


  //General Terms and Conditions of Service
  "Agreement": "Agreement",
  "Last update": "Last update",

  //Privacy and cookie policy
  "Privacy and cookie policy": "Privacy and cookie policy",


  "Introduction": "Introduction",
  "This is the Privacy and Cookie Policy of Smart Solutions. These Policies are between you as a User (“you”, or “your”)": "This is the Privacy and Cookie Policy of Smart Solutions. These Policies are between you as a User (“you”, or “your”)",
  "And the legal entity that acts under the applicable laws and regulations as the Data Processor, and in that capacity is responsible for the processing of (personal) data": "And the legal entity that acts under the applicable laws and regulations as the Data Processor, and in that capacity is responsible for the processing of (personal) data",
  "Smart Solutions ehf (Company id 680995-2499) Vsk id 81978, Iceland (“we”, “us”, “our”)": "Smart Solutions ehf (Company id 680995-2499) Vsk id 81978, Iceland (“we”, “us”, “our”)",
  "We promise to implement the highest standards of privacy and data protection when we design our products and services, offering them to you. For us, the following three key principles play a central role in our privacy and data protection policies": "We promise to implement the highest standards of privacy and data protection when we design our products and services, offering them to you. For us, the following three key principles play a central role in our privacy and data protection policies",
  "You own your personal data": "You own your personal data",
  "We keep your data secure, and work only with parties that maintain the same high level of data security": "We keep your data secure, and work only with parties that maintain the same high level of data security",
  "We are always transparent about the way we treat your personal data": "We are always transparent about the way we treat your personal data",
  "If you visit our website and use our services, you acknowledge and agree that we store, collect and use your (personal) data in an automated way. We take all appropriate and necessary security measures in order to prevent unauthorized access to, disclosure or modification of all the data we collect and process, and we follow certain organizational procedures to adhere to all privacy and data protection laws that may apply": "If you visit our website and use our services, you acknowledge and agree that we store, collect and use your (personal) data in an automated way. We take all appropriate and necessary security measures in order to prevent unauthorized access to, disclosure or modification of all the data we collect and process, and we follow certain organizational procedures to adhere to all privacy and data protection laws that may apply",
  "If you have any doubts or questions about our privacy and data protection policies, or if you want to exercise your legal rights, please email us at": "If you have any doubts or questions about our privacy and data protection policies, or if you want to exercise your legal rights, please email us at",


  "Your Privacy": "Your Privacy",
  "This Privacy and Cookie Policy has been prepared based on multiple legislations for which we take full responsibility, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation), the California Consumer Privacy Act of 2018 and the US Children's Online Privacy Protection Act 1998 (COPPA)": "This Privacy and Cookie Policy has been prepared based on multiple legislations for which we take full responsibility, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation), the California Consumer Privacy Act of 2018 and the US Children's Online Privacy Protection Act 1998 (COPPA)",

  "All information requested by us is obligatory in order to use our services, and your refusal or failure to provide your (personal) data could hinder your access to our services. We may indicate that some specific (personal) data may not be necessary to provide in order to use our services. If you have questions about which personal data we collect, and is mandatory to provide, please request more information at": "All information requested by us is obligatory in order to use our services, and your refusal or failure to provide your (personal) data could hinder your access to our services. We may indicate that some specific (personal) data may not be necessary to provide in order to use our services. If you have questions about which personal data we collect, and is mandatory to provide, please request more information at",
  "We represent and warrant that we use industry standards to protect your (personal) data. However, you are aware that there is no method of electronic storage that is absolutely safe, and 100% secure on the internet": "We represent and warrant that we use industry standards to protect your (personal) data. However, you are aware that there is no method of electronic storage that is absolutely safe, and 100% secure on the internet",
  "As part of our business practice, we may authorize and appoint third-parties, including but not limited to (technical) service and hosting providers, mail carriers, agents and contractors that handle your (personal) data. Upon request, we will provide the most recent list of all the above-mentioned third-parties": "As part of our business practice, we may authorize and appoint third-parties, including but not limited to (technical) service and hosting providers, mail carriers, agents and contractors that handle your (personal) data. Upon request, we will provide the most recent list of all the above-mentioned third-parties",
  "We collect and process different types of information and (personal) data necessary in order to provide and improve our services to you": "We collect and process different types of information and (personal) data necessary in order to provide and improve our services to you",

  "Collection and Use": "Collection and Use",
  "Device ID": "Device ID",
  "Device Brand and Make": "Device Brand and Make",
  "Device Operating System (OS)": "Device Operating System (OS)",
  "Personal Data": "Personal Data",
  "We solely collect and process specific information and (personal) data, when": "We solely collect and process specific information and (personal) data, when",
  "You have given us your consent": "You have given us your consent",
  "It is necessary for the performance of a contractual or pre-contractual duty with you": "It is necessary for the performance of a contractual or pre-contractual duty with you",
  "We have to adhere to a legal obligation to which we are subject": "We have to adhere to a legal obligation to which we are subject",
  "The handling of (personal) data is carried out in the public interest": "The handling of (personal) data is carried out in the public interest",
  "When the handling of (personal) data is for our legitimate interests or those of third-parties": "When the handling of (personal) data is for our legitimate interests or those of third-parties",

  "Retention Time": "Retention Time",
  "Your (personal) data shall be stored for as long as the legitimate purpose requires it, typically for six (6) months. However, there are circumstances under which we retain (personal) data for a longer period of time, for example when you have not withdrawn your consent or in case of a legal obligation or judicial order. Once the retention period expires, we may delete your (personal) data permanently, and as a result your right to access, erase and amend your (personal) data cannot be exercised": "Your (personal) data shall be stored for as long as the legitimate purpose requires it, typically for six (6) months. However, there are circumstances under which we retain (personal) data for a longer period of time, for example when you have not withdrawn your consent or in case of a legal obligation or judicial order. Once the retention period expires, we may delete your (personal) data permanently, and as a result your right to access, erase and amend your (personal) data cannot be exercised",
  "Usage Data and Device Data": "Usage Data and Device Data",
  "Usage data we collect may include the date and time you logged in, app features you used, searches, click path and pages you visited. In addition we may collect Device data, which may include IP and MAC address, type of device and ID, specific apps settings, app crash reports, browser type and version, language settings, and your geolocation based on Bluetooth, Wi-Fi information and GPS": "Usage data we collect may include the date and time you logged in, app features you used, searches, click path and pages you visited. In addition we may collect Device data, which may include IP and MAC address, type of device and ID, specific apps settings, app crash reports, browser type and version, language settings, and your geolocation based on Bluetooth, Wi-Fi information and GPS",


  "Our Responsibility": "Our Responsibility",
  "General": "General",
  "We, our employees, agents, and affiliates are fully responsible for your personal information. We may also use the services of third-parties and may share your (personal) data with them.Some of these third-parties may be located outside Iceland, namely in the United States or the European Economic Area. In that case, the transfer of (personal) data to these third-parties will only be done after we have evaluated and fully assessed their privacy policies and they are in accordance with the highest standards of personal data protection and all necessary legal safeguards": "We, our employees, agents, and affiliates are fully responsible for your personal information. We may also use the services of third-parties and may share your (personal) data with them.Some of these third-parties may be located outside Iceland, namely in the United States or the European Economic Area. In that case, the transfer of (personal) data to these third-parties will only be done after we have evaluated and fully assessed their privacy policies and they are in accordance with the highest standards of personal data protection and all necessary legal safeguards",
  "Children’s Protection": "Children’s Protection",
  "Under no circumstances, persons under the age of thirteen (13) may use our Services or visit our Website according to the US Children's Online Privacy Protection Act 1998 (COPPA). Our services are restricted to Users who are eighteen (18) years of age or older, or an adult  according to the applicable law. We do not allow Users under the age of eighteen (18) on our website and platform and we do not collect and process personal information from anyone under the age of eighteen (18). If you suspect a User is under the age of thirteen (13) or eighteen (18), please report this immediately to us": "Under no circumstances, persons under the age of thirteen (13) may use our Services or visit our Website according to the US Children's Online Privacy Protection Act 1998 (COPPA). Our services are restricted to Users who are eighteen (18) years of age or older, or an adult  according to the applicable law. We do not allow Users under the age of eighteen (18) on our website and platform and we do not collect and process personal information from anyone under the age of eighteen (18). If you suspect a User is under the age of thirteen (13) or eighteen (18), please report this immediately to us",
  "Your Rights": "Your Rights",
  "You may exercise certain rights. You have the right to": "You may exercise certain rights. You have the right to",
  "Withdraw consent where you have previously given it to the storing and processing of your personal data": "Withdraw consent where you have previously given it to the storing and processing of your personal data",
  "Object to the storing and processing of your (personal) data": "Object to the storing and processing of your (personal) data",
  "Verify the correctness and accuracy of your (personal) data and ask for updates and corrections": "Verify the correctness and accuracy of your (personal) data and ask for updates and corrections",
  "Demand the full erasure of all of your data from us": "Demand the full erasure of all of your data from us",
  "Receive your data in a commonly used and machine-readable format and, have it transmitted to another controller without any hindrance": "Receive your data in a commonly used and machine-readable format and, have it transmitted to another controller without any hindrance",
  "Bring a claim before the competent, and national Data Protection Authority (DPA)": "Bring a claim before the competent, and national Data Protection Authority (DPA)",



  "Why we process your data": "Why we process your data",
  "Here we explain the exact reasons and purposes why we process your data": "Here we explain the exact reasons and purposes why we process your data",
  "To provide services to you": "To provide services to you",
  "To communicate with you by telephone and email": "To communicate with you by telephone and email",
  "To provide you with information relating to other services": "To provide you with information relating to other services",
  "To grant you access to our services, and specific features of our services": "To grant you access to our services, and specific features of our services",
  "To give customer support for technical matters": "To give customer support for technical matters",
  "To monitor the way you use our service": "To monitor the way you use our service",
  "To detect and prevent fraud or any other illegal or unauthorized activity": "To detect and prevent fraud or any other illegal or unauthorized activity",
  "To evaluate and improve our website, app and services": "To evaluate and improve our website, app and services",

  "Third-party controllers and processors": "Third-party controllers and processors",
  "We employ third-party service providers and companies with whom we share personal data. Those service providers will only have access to data to perform tasks on our behalf. The third parties that are mentioned in this Privacy and Cookie policy operate their respective services independently from us. You agree and acknowledge you have read and fully understand their respective privacy and data protection policies. We have no control whatsoever over it, and assume no liability for the privacy policies or related practices of any third-party website": "We employ third-party service providers and companies with whom we share personal data. Those service providers will only have access to data to perform tasks on our behalf. The third parties that are mentioned in this Privacy and Cookie policy operate their respective services independently from us. You agree and acknowledge you have read and fully understand their respective privacy and data protection policies. We have no control whatsoever over it, and assume no liability for the privacy policies or related practices of any third-party website",

  "Third-Party Service Providers": "Third-Party Service Providers",
  "Sub-Processing Activities": "Sub-Processing Activities",
  "Country of Operations": "Country of Operations",
  "Link to Privacy Policy": "Link to Privacy Policy",
  "Google Analytics (Google LLC)": "Google Analytics (Google LLC)",
  "Visitor’s/User’s data and analytics": "Visitor’s/User’s data and analytics",
  "Apple Pay (In-App Purchase) (Apple Payments Inc": "Apple Pay (In-App Purchase) (Apple Payments Inc",
  "Subscribed User’s data and analytics": "Subscribed User’s data and analytics",
  "Google Pay (Google LLC)": "Google Pay (Google LLC)",
  "Payment data processing": "Payment data processing",
  "Valitor hf.": "Valitor hf.",
  "Payment data processing": "Payment data processing",
  "Borgun hf": "Borgun hf",
  "Payment data processing": "Payment data processing",



  "Cookies": "Cookies",
  "We use cookies and other tracking technologies to monitor all activities on our website and in our app. Cookies are small files with data, containing a so-called anonymous identifier. Cookies can be sent to your browser from a website you visit, and subsequently stored on your computer or phone. You can change your browser settings in order to reject all cookies or to request a notification when a cookie is being sent. However, if you do not accept cookies, you may not be able to use our services": "We use cookies and other tracking technologies to monitor all activities on our website and in our app. Cookies are small files with data, containing a so-called anonymous identifier. Cookies can be sent to your browser from a website you visit, and subsequently stored on your computer or phone. You can change your browser settings in order to reject all cookies or to request a notification when a cookie is being sent. However, if you do not accept cookies, you may not be able to use our services",


  "Amendments to this Privacy Policy": "Amendments to this Privacy Policy",
  "We may amend our Privacy and Cookie Policy from time to time, which changes are effective when they are posted on this page. We advise you to review our Website and this Privacy Policy from time to time": "We may amend our Privacy and Cookie Policy from time to time, which changes are effective when they are posted on this page. We advise you to review our Website and this Privacy Policy from time to time",

  "Legal Information": "Legal Information",
  "This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). And the California Consumer Privacy Act of 2018. This Privacy and Cookie Policy relates solely to Smart Solutions Ehf., if not stated otherwise within this Privacy Policy": "This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). And the California Consumer Privacy Act of 2018. This Privacy and Cookie Policy relates solely to Smart Solutions Ehf., if not stated otherwise within this Privacy Policy",
};
