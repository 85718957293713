import React from 'react';
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import AddPng from '../../assets/images/add.png';
import ScannerTab from './ScannerTab';
import LicenseHolderTab from './LicenseHolderTab';
import ScannerDistributionTab from './ScannerDistributionTab';
import { api } from "../../api";
import AddScanner from './AddScanner';
import ScannerLayout from './ScannerLayout';
import UpgradeSubscription from './UpgradeSubscription';
import $ from 'jquery';
import OrderPopup from './OrderPopup';
import OrderConfirmationPopup from './OrderConfirmationPopup';
import Swiper from 'react-id-swiper';
import RequestConfirmPopup from './RequestConfirmPopup';
import 'swiper/css/swiper.css';
import { ToastContainer, toast } from 'react-toastify';
import { any } from 'prop-types';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


class MyScanners extends React.Component {
    interValIdNext = 0;
    interValIdPrev = 0;
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'My Scanners',
            loader: false,
            scannerData: [],
            scannerName: '',
            selected: 0,
            scannerList: [],
            fullAccess: false,
            distributedLicenseHolders: [],
            licenseHoldersList: [],
            showScanner: any,
            usage_statistics: 'B',
            SubscriptionData: [],
            planId: '',
            totalPasses: 0,
            subscriptionState: {},
            cardInfo: {},
            DiscountPeriodExpired: false,
            template_price:19800,
            subscription_discount:0,
            subscriptionPaymentType: '0',


        };
    }

    componentDidMount() {
        this.getScannersList();
        this.getSubscriptionData();
    }

    getScannersList(value = '') {
        this.setState({ loader: true })
        if (value == "deletescanner") {
            if (this.state.selected != 0) {
                this.setState({
                    selected: this.state.selected - 1
                })
            }
            window.location.reload();
        }
        api.getScannersList()
            .then(res => {
                if (res.data && res.data.status == 1) {
                    if (res.data.data && res.data.data.scanners) {
                        if (value == "scannerAdded") {
                            window.location.reload();
                            // $("#sendLicenseHolderConfirmBtn").trigger({ type: "click" });
                        }
                        this.setState({ scannerData: res.data.data.scanners },
                            () => {
                                this.getScannerStats()
                            })
                    }
                }
                this.setState({ loader: false })
            })
            .catch(err => {
                this.setState({ loader: false })
            });
    }

    getSubscriptionData(props = this.props) {
        return api.getSubscriptionData()
            .then(res => {
                let flag = false;
                let result = [];
                let usage_statistics = 'B';
                let planId, totalPasses = 0;
                let subscriptionState, cardInfo = {};
                let template_price=19800;
                let subscription_discount=0;
                let subscriptionPaymentType='0';
                let discountPeriodExpired = false;
                if (res.data && res.data.status == 1) {
                    result = res.data.data.subscriptions;
                    planId = res.data.data.subscription_id;
                    totalPasses = res.data.passesInSmartwallet;
                    discountPeriodExpired = res.data.data.discountPeriodExpired;
                    cardInfo = res.data.data.userPaymentInfo ? res.data.data.userPaymentInfo[0] : [];
                    if (res.data.data && res.data.data.subscription_status && res.data.data.subscription_status.status == 1) {
                        subscriptionState = res.data.data.subscription_status.data;
                        usage_statistics = res.data.data.subscription_status.data.usage_statistics ? res.data.data.subscription_status.data.usage_statistics : 'B';
                    }
                    flag = true;
                } else {
                    // toast.error(({ closeToast }) => <div>{res}</div>, { hideProgressBar: true })
                    flag = false;
                }
                this.setState({
                    loader: false,
                    usage_statistics: usage_statistics,
                    SubscriptionData: result,
                    planId: planId,
                    totalPasses: totalPasses,
                    subscriptionPaymentType: res.data && res.data.data &&  res.data.data.pay_with_invoice ? res.data.data.pay_with_invoice:'0',
                    template_price: res.data && res.data.data &&  res.data.data.template_price ? res.data.data.template_price:19800,
                    subscription_discount:res.data && res.data.data && res.data.data.subscription_discount ? res.data.data.subscription_discount:0,
                    subscriptionState: subscriptionState,
                    cardInfo: cardInfo,
                    discountPeriodExpired: discountPeriodExpired
                });
                return flag;
            })
            .catch(err => {
                // toast.error(err.message, { hideProgressBar: true })
                this.setState({ loader: false })
            });
    }

    getScannerStats() {
        // this.setState({ loader: true });
        if (this.state.scannerData.length > 0) {
            let scannerId = this.state.scannerData[this.state.selected].id;
            api.getScannerStats(scannerId)
                .then(res => {
                    if (res.data && res.data.status == 1) {
                        if (res.data.data && res.data.data.scanner) {
                            this.setState({
                                scannerName: res.data.data.scanner[0].name,
                                fullAccess: res.data.data.scanner[0].full_access == '0' ? false : true,
                                loader: false,
                                scannerList: res.data.data.scanner[0].allowedTemplate,
                                distributedLicenseHolders: res.data.data.distributedLicenseHolders,
                                licenseHoldersList: res.data.data.licenseHoldersList,
                            })
                            this.childscanner.componentDidMount();
                        }
                    }
                })
                .catch(err => {
                    this.setState({ loader: false })
                });
        }
        else {
            this.setState({
                scannerName: '',
                fullAccess: false,
                loader: false,
                scannerList: [],
            })
            setTimeout(() => {
                this.childscanner.componentDidMount();
            }, 300)
        }
    }

    MousePrevIsDown = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
        $('#banner-slider').removeClass('reached-end');
        this.interValIdPrev = setInterval(() => {
            $('.swiper-button-prev').trigger('click');
        }, 600)
    }

    MouseNextIsDown = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
        this.interValIdNext = setInterval(() => {
            $('.swiper-button-next').trigger('click');
        }, 600)
    }

    MouseIsUp = () => {
        window.clearInterval(this.interValIdNext)
        window.clearInterval(this.interValIdPrev)
    }

    showScanner() {
        let scanner = this.state.scannerData.map((val, key) => (
            <div className={key == this.state.selected ? "item current_template" : "item"}
                onClick={() => {
                    this.setState({ selected: key }, () => this.getScannerStats())
                }}
                key={"temp" + key}
            >
                <ScannerLayout singleScannerLayout={val} scannerSelected={key == this.state.selected ? true : false} />
            </div>
        ));
        return scanner;
    }

    render() {
        $('body').on('click', () => {
            $('.swiper-wrapper').on('mousedown', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-wrapper .swiper-slide').on('mousedown', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-prev').on('mouseout', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-next').on('mouseout', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-prev').on('mouseup', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
            $('.swiper-button-next').on('mouseup', () => {
                window.clearInterval(this.interValIdNext)
                window.clearInterval(this.interValIdPrev)
            })
        })
        let getTrialDuration = 0;
        let DiscountPeriod = this.state.subscriptionState && this.state.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.state.subscriptionState.reamainingDaysOfTrailPeriod) : 90;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }
        const params = {
            grabCursor: true,
            watchOverflow: true,
            on: {
                reachEnd: function () {
                    $('#banner-slider').addClass('reached-end');
                },
                slidePrevTransitionStart: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
                slidePrevTransitionEnd: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
                reachBeginning: function () {
                    $('#banner-slider').removeClass('reached-end');
                },
            },
            lazy: true,
            init: true,
            slidesPerView: 'auto',
            updateOnWindowResize: true,
            spaceBetween: 0,
            ContainerEl: 'section',
            WrapperEl: 'section',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            renderPrevButton: () => <button onMouseDown={this.MousePrevIsDown} onMouseOut={this.MouseIsUp} onMouseUp={this.MouseIsUp} className="swiper-button-prev"></button>,
            renderNextButton: () => <button onMouseDown={this.MouseNextIsDown} onMouseOut={this.MouseIsUp} onMouseUp={this.MouseIsUp} className="swiper-button-next"></button>,
            breakpoints: {
                1024: {
                    init: true,
                    updateOnWindowResize: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                768: {
                    init: true,
                    updateOnWindowResize: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                640: {
                    init: true,
                    updateOnWindowResize: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0
                },
                320: {
                    init: true,
                    updateOnWindowResize: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0
                }
            }
        }

        functions.changePageTitle(this.state.pagetitle);
        const CurrentTab = functions.getUrlSlice('/', '1'); //Get Current 
        let loader = functions.getLoader(this.state.loader);
        const scannerLimit =this.state.subscriptionState && this.state.subscriptionState.smartscanner_app ? this.state.subscriptionState.smartscanner_app : 0;
        return (
            <div className="wrapper block_element" className={this.props.CurrentTab == 'scanner' || this.props.CurrentTab == 'my_scanner' || this.props.CurrentTab == 'license_holder' || this.props.CurrentTab == 'scanner_distribution' || this.props.CurrentTab == 'add_scanner' ? 'container tab-pane fade in active show my_scanner_page' : 'tab-pane fade in my_scanner_page container'}>
                {loader}
                <div className={this.state.scannerData.length > 0 ? 'bg_color_section block_element main_logo_heading_padding start_ordering_scanner my_scanner padding_container' : 'bg_color_section block_element main_logo_heading_padding start_ordering_scanner my_scanner padding_container'}>
                    <h3>{this.props.CurrentTab == "add_scanner" ? T("Add scanner") : T("My Scanners")} </h3>
                    {this.props.CurrentTab != 'add_scanner' &&
                        <div className={this.state.scannerData.length > 0 ? "my_pass_main_section block_element slider_with_pending_temp" : "order_fornew_scanner"}>

                            {/* <Link onClick={this.state.scannerData.length >= scannerLimit ? () => {
                                $("#AddSubscriptionBtn").trigger({ type: "click" });
                            } : () => { }} to={this.state.scannerData.length >= scannerLimit ? '#' : "/add_scanner"}>
                                <div className={this.state.scannerData.length > 0 ? "dashboard-order order_scanner" : "dashboard-order order_scanner no-scanner"}>
                                    <span className="add_order">
                                        <img src={AddPng} />
                                    </span>
                                    <p>Add a scanner</p>
                                </div>
                            </Link> */}
                            <Link to="/add_scanner">
                                <div className={this.state.scannerData.length > 0 ? "dashboard-order order_scanner" : "dashboard-order order_scanner no-scanner"}>
                                    <span className="add_order">
                                        <img src={AddPng} />
                                    </span>
                                    <p>{T("Add a scanner")}</p>
                                </div>
                            </Link>

                            {this.state.scannerData.length > 0 &&
                                <section id="banner-slider" className="banner-slider bigger-slider">
                                    {/* {this.state.showScanner} */}
                                    <Swiper {...params}>
                                        {this.showScanner()}
                                    </Swiper>
                                </section>
                            }
                        </div>
                    }
                    {this.props.CurrentTab != 'add_scanner' &&
                        <ul className={this.state.scannerData.length > 0 ? "active_nav_tabs nav nav-tabs block_element" : "emptynavTabs nav nav-tabs block_element"}>
                            <li><Link className={CurrentTab == 'scanner' || CurrentTab == 'my_scanner' ? "active show" : ''} to="/scanner">{T("Scanner")}</Link></li>
                            <li><Link className={CurrentTab == 'scanner_distribution' ? "active show" : ''} to="/scanner_distribution">{T("Distribution")}</Link></li>
                            <li><Link className={CurrentTab == 'license_holder' ? "active show" : ''} to="/license_holder">{T("License holders")} </Link></li>
                        </ul>
                    }
                    {this.props.CurrentTab != 'add_scanner' &&
                        <div className={CurrentTab == 'scanner_distribution' ? 'tab-content tab_background tab_background_scanner_distribution insideScanner block_element' : 'tab-content tab_background insideScanner block_element'} >
                            {
                                CurrentTab == 'scanner' || CurrentTab == 'my_scanner' ?
                                    <div className="tab-pane fade in active show" >
                                        <ScannerTab
                                            scannerName={this.state.scannerName}
                                            fullAccess={this.state.fullAccess}
                                            scannerList={this.state.scannerList}
                                            currentScannerId={this.state.scannerData && this.state.scannerData.length > 0 ? this.state.scannerData[this.state.selected].id : 0}
                                            getScannersList={(value) => { this.getScannersList(value) }}
                                            parentComponentDidMountFunction={() => { this.componentDidMount() }}
                                            ref={instance => {
                                                this.childscanner = instance;
                                            }}
                                        />
                                    </div>
                                    : ''
                            }
                            {
                                CurrentTab == 'scanner_distribution' ?
                                    <div className="tab-pane fade in active show">
                                        <ScannerDistributionTab
                                            parentComponentDidMountFunction={() => { this.componentDidMount() }}
                                            getScannersList={(value) => { this.getScannersList(value) }}
                                            currentScannerId={this.state.scannerData && this.state.scannerData.length > 0 ? this.state.scannerData[this.state.selected].id : 0}
                                            distributedLicenseHolders={this.state.distributedLicenseHolders}
                                            ref={instance => {
                                                this.childscanner = instance;
                                            }} />
                                    </div>
                                    : ''
                            }
                            {
                                CurrentTab == 'license_holder' ?
                                    < div className="tab-pane fade in active show">
                                        <LicenseHolderTab
                                            parentComponentDidMountFunction={() => { this.componentDidMount() }}
                                            getScannersList={(value) => { this.getScannersList(value) }}
                                            currentScannerId={this.state.scannerData && this.state.scannerData.length > 0 ? this.state.scannerData[this.state.selected].id : 0}
                                            licenseHoldersList={this.state.licenseHoldersList}
                                            ref={instance => {
                                                this.childscanner = instance;
                                            }} />
                                    </div>
                                    : ''
                            }
                        </div>
                    }
                    {
                        CurrentTab == "add_scanner" ?
                            < div className="tab-pane fade in active show">
                                <AddScanner parentComponentDidMountFunction={(event) => { this.getScannersList(event) }}
                                    ref={instance => {
                                        this.childscanner = instance;
                                    }} />
                            </div>
                            : ''
                    }
                </div>
                <button type="button" data-toggle="modal" data-target="#scannerAdded" className="sendLicenseHolderConfirmBtn" id="sendLicenseHolderConfirmBtn" style={{ display: 'none' }} ></button>
                <div className="modal sendLicenseConfirm fade " id="scannerAdded" role="dialog" ref="scannerAdded">
                    <RequestConfirmPopup
                        confirmType="scannerAdded"
                        userList={[]}
                        userData={[]} />
                </div>
                <button id="AddSubscriptionBtn" data-toggle="modal" data-target="#UpgradeSubscription" style={{ display: 'none' }}></button>
                <div className="modal fade select_subscription" id="UpgradeSubscription" role="dialog">
                    <UpgradeSubscription subscription_discount={this.state.subscription_discount} upgradeType={'scanner'} orderTemp={this.state.orderingTemplate ? '1' : ''} SubscriptionState={this.state.subscriptionState} SubscriptionData={this.state.SubscriptionData} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                </div>
                {
                    this.state.SubscriptionData.length > 0 &&
                    <div>
                        {this.state.SubscriptionData.map((val, key) => (
                            <div key={"subs" + key}>
                                <button className="free_subsription_btns" data-toggle="modal" data-target={"#myOrderPopup" + key} style={{ display: 'none' }}>
                                    {val.offer_price == 0 ? 'Free' : 'Select'}
                                </button>
                                <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup" + val.id} data-target={"#OrderConfirmation" + val.id} style={{ display: 'none' }}>Open Modal</button>
                                <div className="modal fade myOrderPopup" id={"myOrderPopup" + key} role="dialog" >
                                    <OrderPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} subscriptionPaymentType={this.state.subscriptionPaymentType}  OrderData={val} orderTemp={this.state.orderingTemplate ? '1' : ''} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                                </div>
                                <div className="modal orderConfirmModal fade" id={"OrderConfirmation" + val.id} role="dialog">
                                    <OrderConfirmationPopup template_price={this.state.template_price} subscription_discount={this.state.subscription_discount} OrderData={val} orderTemp={this.state.orderingTemplate ? '1' : ''} DiscountPeriodExpired={this.state.discountPeriodExpired} />
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        );
    }
};

export default MyScanners;
