import React from 'react';
import barcode from '../../assets/images/barcode2.png';
import ticketCircle from '../../assets/images/ticketCircle.png';
import ticketCircleSelected from '../../assets/images/ticketCircleSelected.png';
import { Constants } from '../../config';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import moment from 'moment';
export default class TemplateLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            redirect: false,
            stripPath: '',
            logoPath: '',
            thumbnailPath: '',
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                thumbnailPath: this.props.singleTemplateLayout.thumbnail_path ? this.props.singleTemplateLayout.thumbnail_path + '?ver=' + performance.now() : '',
                stripPath: this.props.singleTemplateLayout.strip_path ? this.props.singleTemplateLayout.strip_path + '?ver=' + performance.now() : 'sdfdsf',
                logoPath: this.props.singleTemplateLayout.logo_path ? this.props.singleTemplateLayout.logo_path + '?ver=' + performance.now() : '',
            })
        });
    }

    render() {
        const templateData = this.props.singleTemplateLayout;
        let passdesign;
        var stripBackGroundImage = {
            marginRight: '85px',
            'height': '80px'
        };
        var stripwithoutImage = {
            'height': '80px'
        };
        let secondaryTotalWidth = templateData.thumbnail_path != null && templateData.pass_type == "pass.snjall.eventticket" ? 70 : 100;
        let secondaryCount = 0;
        let auxCount = 0;
        if (templateData.secondary_label1 || templateData.secondary_value1) {
            secondaryCount = 1;
        }
        if (templateData.secondary_label2 || templateData.secondary_value2) {
            secondaryCount = 2;
        }
        if (templateData.secondary_label3 || templateData.secondary_value3) {
            secondaryCount = 3;
        }
        if (templateData.auxilairy_label1 || templateData.auxilairy_value1) {
            auxCount = 1;
        }
        if (templateData.auxilairy_label2 || templateData.auxilairy_value2) {
            auxCount = 2;
        }
        if (templateData.auxilairy_label3 || templateData.auxilairy_value3) {
            auxCount = 3;
        }

        if (templateData.pass_type === 'pass.snjall.coupon') {
            passdesign =
                <div key="front">
                    <div className={templateData.logo_path != null ? "pass_layout generic-pass-layout generic-with-logo has-logo-image" : "pass_layout generic-pass-layout generic-without-logo"} style={{ 'background': templateData.pass_background_color }}>
                        <div className="template-headerSection d-flex justify-content-between">
                            <div className="template-logo">
                                {templateData.logo_path &&
                                    <img src={Constants.imgUrl + this.state.logoPath} alt="logo" />
                                }
                            </div>
                            <div className="template-logoText">
                                <div className="grab-input" style={{ 'textTransform': 'none' }}>
                                    <div style={{ 'position': 'relative', 'lineHeight': '27px', 'color': templateData.value_color }} className="template-logoTextInner">
                                        {templateData.logo_text}
                                    </div>
                                </div>
                            </div>
                            <div className="droppable headeField">
                                <div className="grab-input" style={{ textAlign: (templateData.header_align == 'natural') ? 'right' : templateData.header_align }} >
                                    <div style={{ 'position': 'relative' }} className="headeFieldInner">
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none' }}>{templateData.header_key ? templateData.header_key.toUpperCase() : ''}</div>
                                        <div className="drag-input value" style={{ 'color': templateData.value_color, 'fontSize': templateData.header_font_size ? templateData.header_font_size : '14px', 'pointerEvents': 'none' }}>{templateData.header_value}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="template-stripSection" style={{ 'backgroundImage': "url(" + Constants.imgUrl + this.state.stripPath + ")", color: templateData.strip_text_color ? templateData.strip_text_color : "#ffffff" }}>
                            <div className="primary-field">
                                <div className="field-covers">
                                    <div className="field-strip1"
                                        style={{
                                            'fontSize': templateData.strip_value_font_size,
                                            'pointerEvents': 'none'
                                        }}
                                    >
                                        {templateData.strip_value}
                                    </div>
                                    <div className="field-strip2"
                                        style={{
                                            'fontSize': templateData.strip_font_size,
                                        }}
                                    >
                                        {templateData.strip_label}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="droppable template-secondary">
                            <div className="grab-input">
                                <div style={{ 'position': 'relative' }} className="d-flex justify-content-between bd-highlight mb-3">
                                    <div className="hower-bodys">
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '', 'pointerEvents': 'none' }}>{templateData.secondary_label1 ? templateData.secondary_label1.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '',
                                                'pointerEvents': 'none'
                                            }}
                                        >{templateData.secondary_value1}</div></div>
                                    {
                                        secondaryCount > 1 &&
                                        <div className="hower-bodys">
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_2 ? templateData.textalign_2 : '' }}>{templateData.secondary_label2 ? templateData.secondary_label2.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_2 ? templateData.textalign_2 : ''
                                                }}
                                            >{templateData.secondary_value2}</div></div>
                                    }
                                    {
                                        secondaryCount > 2 &&
                                        <div className="hower-bodys">
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_3 ? templateData.textalign_3 : '' }}>{templateData.secondary_label3 ? templateData.secondary_label3.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_3 ? templateData.textalign_3 : ''
                                                }}
                                            >{templateData.secondary_value3}</div>
                                        </div>
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="template-barcodeSection" style={{ display: templateData.showPDF417 && templateData.showPDF417 == 1 ? '' : 'none' }} >
                            <div className="template-barcode">
                                <div className="template-image">
                                    <img src={templateData.barcode ? templateData.barcode : barcode} alt="barcode" height="60px" width="100%" />
                                </div>
                                <div className="template-altText">{T("Pass status will be shown here")}.</div>
                            </div>
                        </div>
                    </div>
                    {this.props.templateSelected &&
                        <div class="template_issue_date">{T("Date issued")}: {moment(templateData.created_at.substring(0, templateData.created_at.indexOf(' '))).format('DD.MM.YYYY')}</div>}
                </div>
        }
        if (templateData.pass_type == 'pass.snjall.punchcard') {
            passdesign =
                <div key="front">
                    <div className={templateData.logo_path != null ? "pass_layout punch-pass  punch-with-logo has-logo-image" : "pass_layout punch-pass punch-without-logo"} style={{ 'background': templateData.pass_background_color, 'borderRadius': '9px' }}>
                        <div className="template-headerSection d-flex justify-content-between bd-highlight mb-3">
                            <div className="template-logo">
                                {templateData.logo_path &&
                                    <img src={Constants.imgUrl + this.state.logoPath} alt="logo" />
                                }
                            </div>
                            <div className="template-logoText">
                                <div className="grab-input">
                                    <div style={{ 'color': templateData.value_color, 'position': 'relative' }} className="logoTextInner">
                                        {templateData.logo_text}
                                    </div>
                                </div>
                            </div>
                            <div className="droppable headeField">
                                <div className="grab-input" style={{ 'textTransform': 'none', textAlign: (templateData.header_align == 'natural') ? 'right' : templateData.header_align }}>
                                    <div style={{ 'position': 'relative' }} className="headeFieldInner">

                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none' }}>{templateData.header_key ? templateData.header_key.toUpperCase() : ''}</div>
                                        <div className="drag-input value" style={{ 'color': templateData.value_color, 'fontSize': templateData.header_font_size ? templateData.header_font_size : '14px', 'pointerEvents': 'none' }}>{templateData.header_value}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="template-stripSection template-stripSectionPunchPass" style={{ 'backgroundImage': "url(" + Constants.imgUrl + this.state.stripPath + ")" }}>
                            <div className="primary-field">
                                <div className="field-covers">
                                    <div className="field-strip1" style={{ 'fontSize': '66px', 'textAlign': 'center', 'pointerEvents': 'none' }}></div>
                                    <div className="field-strip2" style={{ 'textAlign': 'center' }}></div></div></div></div>
                        <div className="droppable template-secondary">
                            <div className="grab-input">
                                <div style={{ 'position': 'relative' }} className="d-flex justify-content-between bd-highlight mb-3">
                                    <div className="hower-bodys" style={{ textAlign: templateData.textalign_1 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '', 'pointerEvents': 'none' }}>{templateData.secondary_label1 ? templateData.secondary_label1.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '',
                                                'pointerEvents': 'none'
                                            }}
                                        >{templateData.secondary_value1}</div></div>
                                    {
                                        secondaryCount > 1 &&
                                        <div className="hower-bodys" style={{ textAlign: templateData.textalign_2 }}>
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_2 ? templateData.textalign_2 : '' }}>{templateData.secondary_label2 ? templateData.secondary_label2.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_2 ? templateData.textalign_2 : ''
                                                }}
                                            >{templateData.secondary_value2}</div></div>
                                    }
                                    {
                                        secondaryCount > 2 &&
                                        <div className="hower-bodys" style={{ textAlign: templateData.textalign_3 }}>
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_3 ? templateData.textalign_3 : '' }}>{templateData.secondary_label3 ? templateData.secondary_label3.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_3 ? templateData.textalign_3 : ''
                                                }}
                                            >{templateData.secondary_value3}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="template-barcodeSection" style={{ display: templateData.showPDF417 && templateData.showPDF417 == 1 ? '' : 'none' }}>
                            <div className="template-barcode">
                                <div className="template-image">
                                    <img src={templateData.barcode ? templateData.barcode : barcode} alt="barcode" width="100%" height="60px" />
                                </div>
                                <div className="template-altText">{T("Pass status will be shown here")}.</div>
                            </div>
                        </div>
                    </div>
                    {this.props.templateSelected &&
                        <div class="template_issue_date">{T("Date issued")}: {moment(templateData.created_at.substring(0, templateData.created_at.indexOf(' '))).format('DD.MM.YYYY')}</div>}
                </div>
        }

        if (templateData.pass_type == 'pass.snjall.memberpass') {
            passdesign =
                <div key="front">
                    <div className={templateData.logo_path ? "pass_layout member-pass  member-with-logo has-logo-image" : "pass_layout member-pass member-without-logo"} style={{ 'background': templateData.pass_background_color, 'borderRadius': '9px' }}>
                        <div className="template-headerSection d-flex justify-content-between">
                            <div className="template-logo">
                                {templateData.logo_path &&
                                    <img src={Constants.imgUrl + this.state.logoPath} alt="logo" />
                                }
                            </div>
                            <div className="template-logoText" style={{ 'color': templateData.value_color }}>
                                <div className="template-logoText_innerText">{templateData.logo_text}</div>
                            </div>
                            <div className="droppable headeField">
                                <div className="grab-input" style={{ 'textTransform': 'none', textAlign: (templateData.header_align == 'natural') ? 'right' : templateData.header_align }}>
                                    <div style={{ 'position': 'relative' }} className="headeFieldInner">

                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none' }}>{templateData.header_key ? templateData.header_key.toUpperCase() : ''}</div>
                                        <div className="drag-input value" style={{ 'color': templateData.value_color, 'fontSize': templateData.header_font_size ? templateData.header_font_size : '14px', 'pointerEvents': 'none' }}>{templateData.header_value}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <hr className="template-headerseperator" /> */}
                        {templateData.thumbnail_path &&
                            // <div className="template-thumbnail" style={{ 'backgroundImage': "url(" + Constants.imgUrl + this.state.thumbnailPath + ")" }}>
                            <div className="template-thumbnail square-ththumbnail">
                                {templateData.thumbnail_path &&
                                    <img src={Constants.imgUrl + this.state.thumbnailPath} alt="logo" className="template-thumbnail-img-inner" />
                                }
                            </div>
                        }
                        <div className="template-stripSection" style={templateData.strip_path ? {
                            'backgroundImage': "url(" + Constants.imgUrl + this.state.stripPath + ")",
                            'backgroundSize': '100% 100%',
                        } : templateData.thumbnail_path ? stripBackGroundImage : stripwithoutImage}>
                            <div className="primary-field">
                                <div className="field-strip2"
                                    style={{
                                        'marginTop': '0px',
                                        fontSize: 12,
                                        'textAlign': templateData.strip_alignment,
                                        color: templateData.label_color
                                    }}  >{templateData.strip_label ? templateData.strip_label.toUpperCase() : ''}</div>
                                <div className="field-strip1"
                                    style={{
                                        'height': '60px',
                                        'lineHeight': '30px',
                                        'pointerEvents': 'none',
                                        'textAlign': templateData.strip_alignment,
                                        'color': templateData.value_color,
                                        fontSize: templateData.strip_value_font_size
                                    }} >{templateData.strip_value}</div>
                            </div>
                        </div>
                        <div className="droppable template-secondary">
                            <div style={{ 'position': 'relative' }} className="d-flex justify-content-between bd-highlight mb-3">
                                <div className="hower-bodys" style={{ textAlign: templateData.textalign_1 }}>
                                    <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '', 'pointerEvents': 'none' }}>{templateData.secondary_label1 ? templateData.secondary_label1.toUpperCase() : ''}</div>
                                    <div className="drag-input value"
                                        style={{
                                            'color': templateData.value_color,
                                            'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                            'height': '34px',
                                            'marginTop': '-8px',
                                            'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '',
                                            'pointerEvents': 'none'
                                        }}
                                    >{templateData.secondary_value1}</div></div>
                                {
                                    secondaryCount > 1 &&
                                    <div className="hower-bodys" style={{ textAlign: templateData.textalign_2 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_2 ? templateData.textalign_2 : '' }}>{templateData.secondary_label2 ? templateData.secondary_label2.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'pointerEvents': 'none',
                                                'textAlign': templateData.textalign_2 ? templateData.textalign_2 : ''
                                            }}
                                        >{templateData.secondary_value2}</div></div>
                                }
                                {
                                    secondaryCount > 2 &&
                                    <div className="hower-bodys" style={{ textAlign: templateData.textalign_3 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_3 ? templateData.textalign_3 : '' }}>{templateData.secondary_label3 ? templateData.secondary_label3.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'pointerEvents': 'none',
                                                'textAlign': templateData.textalign_3 ? templateData.textalign_3 : ''
                                            }}
                                        >{templateData.secondary_value3}</div></div>
                                }
                            </div>
                        </div>
                        <div className="droppable template-auxiliary" >
                            <div style={{ 'position': 'relative' }} className="d-flex justify-content-between bd-highlight mb-3">
                                <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.auxilairy_alignment1 }}>
                                    <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.auxilairy_alignment1 ? templateData.auxilairy_alignment1 : '', 'pointerEvents': 'none' }}>{templateData.auxilairy_label1 ? templateData.auxilairy_label1.toUpperCase() : ''}</div>
                                    <div className="drag-input value"
                                        style={{
                                            'color': templateData.value_color,
                                            'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                            'height': '34px',
                                            'marginTop': '-8px',
                                            'textAlign': templateData.auxilairy_alignment1 ? templateData.auxilairy_alignment1 : '',
                                            'lineHeight': '28px',
                                            'pointerEvents': 'none'
                                        }}
                                    >{templateData.auxilairy_value1}</div>
                                </div>
                                {
                                    auxCount > 1 &&
                                    <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.auxilairy_alignment2 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.auxilairy_alignment2 ? templateData.auxilairy_alignment2 : '' }}>{templateData.auxilairy_label2 ? templateData.auxilairy_label2.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'lineHeight': '28px',
                                                'pointerEvents': 'none',
                                                'textAlign': templateData.auxilairy_alignment2 ? templateData.auxilairy_alignment2 : ''
                                            }}
                                        >{templateData.auxilairy_value2}</div>
                                    </div>
                                }
                                {
                                    auxCount > 2 &&
                                    <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.auxilairy_alignment3 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.auxilairy_alignment3 ? templateData.auxilairy_alignment3 : '' }}>{templateData.auxilairy_label3 ? templateData.auxilairy_label3.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'lineHeight': '28px',
                                                'pointerEvents': 'none',
                                                'textAlign': templateData.auxilairy_alignment3 ? templateData.auxilairy_alignment3 : ''
                                            }}
                                        >{templateData.auxilairy_value3}</div>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className="template-barcodeSection" style={{ display: templateData.showPDF417 && templateData.showPDF417 == 1 ? '' : 'none' }}>
                            <div className="template-barcode">
                                <div className="template-image">
                                    <img src={templateData.barcode ? templateData.barcode : barcode} alt="barcode" width="100%" height="60px" />
                                </div>
                                <div className="template-altText">{T("Pass status will be shown here")}.</div>
                            </div>
                        </div>
                    </div>
                    {this.props.templateSelected &&
                        <div class="template_issue_date">{T("Date issued")}: {moment(templateData.created_at.substring(0, templateData.created_at.indexOf(' '))).format('DD.MM.YYYY')}</div>}
                </div>
        }

        if (templateData.pass_type == 'pass.snjall.eventticket') {
            passdesign =
                <div key="front">
                    <div className={templateData.thumbnail_path != null ? "pass_layout ticket ticket-with-thumbnail" : "pass_layout ticket ticket-with-strip"} style={{ 'background': templateData.pass_background_color, 'borderRadius': '0px' }} >
                        <div className={templateData.logo_path != null ? "template-headerSection ticket-with-logo has-logo-image d-flex justify-content-between" : "template-headerSection ticket-without-logo"}>
                            <div className="ticketCircleImgMain">
                                <img src={this.props.templateSelected ? ticketCircleSelected : ticketCircle} className="ticketCircleImg" alt="ticketCircle" />
                            </div>

                            <div className="template-logo">
                                {templateData.logo_path &&
                                    <img src={Constants.imgUrl + this.state.logoPath} alt="logo" />
                                }
                            </div>
                            <div className="template-logoText" style={{ 'color': templateData.value_color }}>
                                <div className="template-logoText_inside">{templateData.logo_text}</div>
                            </div>
                            <div className="droppable headeField">
                                <div className="grab-input" style={{ 'textTransform': 'none', textAlign: (templateData.header_align == 'natural') ? 'right' : templateData.header_align }} >
                                    <div style={{ 'position': 'relative' }} className="headeFieldInner">
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none' }}>{templateData.header_key ? templateData.header_key.toUpperCase() : ''}</div>
                                        <div className="drag-input value" style={{ 'color': templateData.value_color, 'fontSize': templateData.header_font_size ? templateData.header_font_size : '14px', 'pointerEvents': 'none' }}>{templateData.header_value}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <hr className="template-headerseperator" style={{ "marginTop": "2px", "marginBottom": "0px" }} /> */}
                        {
                            templateData.thumbnail_path != null &&
                            <div className="template-thumbnail"
                                style={{
                                    'border': 'none',
                                    'backgroundImage': templateData.thumbnail_path ? "url(" + Constants.imgUrl + this.state.thumbnailPath + ")" : ''
                                }}>
                            </div>
                        }
                        <div className="template-stripSection"
                            style={{
                                'backgroundImage': templateData.strip_path ? "url(" + Constants.imgUrl + this.state.stripPath + ")" : '',
                                'marginRight': '102px',
                                'width': templateData.thumbnail_path == null ? '100%' : 'auto',
                                'height': templateData.thumbnail_path != null ? '102px' : '91px',
                                'pointerEvents': 'none'
                            }}>
                            <div className="primary-field">
                                <div style={{ 'height': '70px' }}>
                                    {templateData.thumbnail_path != null &&
                                        <div className="field-strip2"
                                            style={{
                                                'fontSize': '11px',
                                                'textAlign': 'left',
                                                'marginTop': '0px',
                                                'color': templateData.label_color
                                            }}>
                                            {templateData.strip_label}</div>
                                    }
                                    <div
                                        style={{
                                            'fontSize': templateData.thumbnail_path ? (templateData.strip_value_font_size ? templateData.strip_value_font_size : 20) : (templateData.strip_value_font_size ? templateData.strip_value_font_size : 33),
                                            'color': templateData.thumbnail_path != null ? templateData.value_color : (templateData.strip_text_color ? templateData.strip_text_color : "#ffffff"),
                                            'textAlign': 'left',
                                            'height': '32px',
                                            'lineHeight': '35px'
                                        }} className="field-strip1">{templateData.strip_value}</div>
                                </div>
                            </div>
                        </div>
                        <div className="droppable template-secondary ticket-pass" style={{ 'position': 'relative' }}>
                            <div className="droppable template-secondary-inner" style={{ 'height': '40px', 'border': 'medium none' }}>
                                <div style={{ 'position': 'relative', 'width': 'auto' }} className="d-flex justify-content-between bd-highlight mb-3">
                                    <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.textalign_1 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '', 'pointerEvents': 'none' }}>{templateData.secondary_label1 ? templateData.secondary_label1.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                'height': '34px',
                                                'marginTop': '-8px',
                                                'textAlign': templateData.textalign_1 ? templateData.textalign_1 : '',
                                                'pointerEvents': 'none'
                                            }}
                                        >{templateData.secondary_value1}</div></div>
                                    {
                                        secondaryCount > 1 &&
                                        <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.textalign_2 }}>
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_2 ? templateData.textalign_2 : '' }}>{templateData.secondary_label2 ? templateData.secondary_label2.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_2 ? templateData.textalign_2 : ''
                                                }}
                                            >{templateData.secondary_value2}</div>
                                        </div>
                                    }
                                    {
                                        secondaryCount > 2 &&
                                        <div className="hower-bodys" style={{ 'padding': '0px', textAlign: templateData.textalign_3 }}>
                                            <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'pointerEvents': 'none', 'textAlign': templateData.textalign_3 ? templateData.textalign_3 : '' }}>{templateData.secondary_label3 ? templateData.secondary_label3.toUpperCase() : ''}</div>
                                            <div className="drag-input value"
                                                style={{
                                                    'color': templateData.value_color,
                                                    'fontSize': templateData.secondary_font_size ? templateData.secondary_font_size : '17px',
                                                    'height': '34px',
                                                    'marginTop': '-8px',
                                                    'pointerEvents': 'none',
                                                    'textAlign': templateData.textalign_3 ? templateData.textalign_3 : ''
                                                }}
                                            >{templateData.secondary_value3}</div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="droppable template-auxiliary" style={{ 'height': '40px' }}>
                            <div style={{ 'position': 'relative' }} className="d-flex justify-content-between bd-highlight mb-3">
                                <div className="hower-bodys" style={{ textAlign: templateData.auxilairy_alignment1 }}>
                                    <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.auxilairy_alignment1 ? templateData.auxilairy_alignment1 : '', 'pointerEvents': 'none' }}>{templateData.auxilairy_label1 ? templateData.auxilairy_label1.toUpperCase() : ''}</div>
                                    <div className="drag-input value"
                                        style={{
                                            'color': templateData.value_color,
                                            'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                            'height': '27px',
                                            'marginTop': '-6px',
                                            'textAlign': templateData.auxilairy_alignment1 ? templateData.auxilairy_alignment1 : '',
                                            'lineHeight': '16px',
                                            'pointerEvents': 'none'
                                        }}
                                    >{templateData.auxilairy_value1}</div>
                                </div>
                                {
                                    auxCount > 1 &&
                                    <div className="hower-bodys" style={{ textAlign: templateData.auxilairy_alignment2 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.auxilairy_alignment2 ? templateData.auxilairy_alignment2 : '', 'pointerEvents': 'none' }}>{templateData.auxilairy_label2 ? templateData.auxilairy_label2.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                                'height': '27px',
                                                'marginTop': '-6px',
                                                'textAlign': templateData.auxilairy_alignment2 ? templateData.auxilairy_alignment2 : '',
                                                'lineHeight': '16px',
                                                'pointerEvents': 'none'
                                            }}
                                        >{templateData.auxilairy_value2}</div>
                                    </div>
                                }
                                {
                                    auxCount > 2 &&
                                    <div className="hower-bodys" style={{ textAlign: templateData.auxilairy_alignment3 }}>
                                        <div className="drag-input label" style={{ 'color': templateData.label_color, 'fontSize': '11px', 'textAlign': templateData.auxilairy_alignment3 ? templateData.auxilairy_alignment3 : '', 'pointerEvents': 'none' }}>{templateData.auxilairy_label3 ? templateData.auxilairy_label3.toUpperCase() : ''}</div>
                                        <div className="drag-input value"
                                            style={{
                                                'color': templateData.value_color,
                                                'fontSize': templateData.auxilairy_font_size ? templateData.auxilairy_font_size : '17px',
                                                'height': '27px',
                                                'marginTop': '-6px',
                                                'textAlign': templateData.auxilairy_alignment3 ? templateData.auxilairy_alignment3 : '',
                                                'lineHeight': '16px',
                                                'pointerEvents': 'none'
                                            }}
                                        >{templateData.auxilairy_value3}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="template-barcodeSection" style={{ display: templateData.showPDF417 && templateData.showPDF417 == 1 ? '' : 'none' }}>
                            <div className="template-barcode">
                                <div className="template-image">
                                    <img src={templateData.barcode ? templateData.barcode : barcode} alt="barcode" width="100%" height="60px" />
                                </div>
                                <div className="template-altText">{T("Pass status will be shown here")}.</div>
                            </div>
                        </div>
                    </div>
                    {this.props.templateSelected &&
                        <div class="template_issue_date">{T("Date issued")}: {moment(templateData.created_at.substring(0, templateData.created_at.indexOf(' '))).format('DD.MM.YYYY')}</div>}
                </div>
        }

        return (
            <div>{passdesign}</div>
        )
    }
}