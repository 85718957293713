import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import Redirect from 'react-router/Redirect';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from "mui-datatables";
import TemplateLayout from './TemplateLayout';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class SendUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            message: '',
            messageChange: false,
            subjectChange: false,
            errors: [],
            loader: false,
            formIsValid: true
        };
    }
    componentDidMount() {

    }
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            let userlist = [];
            let id = this.props.tempId
            this.setState({ loader: true });
            this.props.userList.forEach(element => {
                userlist.push(element.id)
            });
            if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
                return <Redirect to='/signin' />
            }
            let PostData = {
                mail_subject: this.state.subject,
                mail_body: this.state.message,
                // access_token: functions.getAccessToken(),

            }

            if (this.props.distributionType == "sendPasses") {
                PostData.userIds = userlist
                api.sendSelectedPasses(PostData, id)
                    .then(res => {
                        if (res.data.status == 1) {
                            // toast.success(res.data.message, { hideProgressBar: true });
                            document.getElementById("sendForm").reset();
                            this.props.parentCompnentMount();
                            this.props.modalClose("sendUser");
                        } else {
                            $('body').addClass('modal-open-again');
                            toast.error(res.data.message, { hideProgressBar: true });
                        }
                        this.setState({
                            loader: false,
                            subject: '',
                            message: '',
                            messageChange: false,
                            subjectChange: false,
                            errors: [],
                            formIsValid: true
                        });
                    }).catch(err => {
                        $('body').addClass('modal-open-again');
                        this.setState({
                            loader: false
                        })
                    })
            }
            else if (this.props.distributionType == "sendLicense") {
                PostData.licenseHolderIds = userlist
                api.sendSelectedLicense(PostData, id)
                    .then(res => {
                        if (res.data.status == 1) {
                            // toast.success(res.data.message, { hideProgressBar: true });
                            document.getElementById("sendForm").reset();
                            this.props.modalClose("sendlicense");
                        } else {
                            $('body').addClass('modal-open-again');
                            toast.error(res.data.message, { hideProgressBar: true });
                        }
                        this.setState({
                            loader: false,
                            subject: '',
                            message: '',
                            messageChange: false,
                            subjectChange: false,
                            errors: [],
                            formIsValid: true
                        });
                    }).catch(err => {
                        $('body').addClass('modal-open-again');
                        this.setState({
                            loader: false
                        })
                    })
            }
        }
    }

    validateForm(fieldType) {
        let errors = {};
        let formvalid = true;
        if (fieldType == 'subject' || this.state.subjectChange) {
            if (this.state.subject == '') {
                formvalid = false;
                errors['subject'] = "The field is required.";
            } else {
                if (this.state.subject.length > 500) {
                    errors['subject'] = "Subject length must be below 500 word.";
                }else if(this.state.subject.length < 2){
                    errors['subject'] = "Subject must be at least 2 character";
                }
            }
        }
        if (this.props.distributionType != "sendLicense" && (fieldType == 'message' || this.state.messageChange)) {
            if (this.state.message == '') {
                formvalid = false;
                errors['message'] = "The field is required.";
            } else {
                if (this.state.message.length > 2000) {
                    errors['message'] = "Message length must be below 2000 word.";
                }
            }
        }
        this.setState({
            errors: errors,
            formIsValid: formvalid
        })
        // if(formvalid==true){
        //     document.getElementById("sendDone").focus();
        // }
        return formvalid;
    }
    sendFieldsClose = () => {
        document.getElementById("sendForm").reset();
        this.setState(
            {
                errors: [],
                messageChange: false,
                subjectChange: false,
                formIsValid: true, subject: '',
                message: '',
            }
        );
    }
    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let loader = functions.getLoader(this.state.loader);
        let data = [];
        if (this.props.userList) {
            data = this.props.userList.map(val => {
                return {
                    email: val.email
                }
            })
        } else {
            data = [];
        }
        let dataColumns = [{
            name: 'email',
            label: 'email',
            options: {
                filter: false,
                display: false,
                viewColumns: false,
                sort: false,
            }
        }, {
            name: '',
            label: '',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData) {
                        return <label>{tableMeta.rowData[0]}</label>
                    }
                }
            }
        }]
        const options = {
            filterType: 'dropdown',
            responsive: "scroll",
            print: false,
            filter: false,
            search: false,
            selectableRows: false,
            download: false,
            viewColumns: false,
            rowsPerPage: 5,
            rowsPerPageOptions: []
        }
        let totalUsers = this.props.userData && this.props.userData.length ? this.props.userData.length : "";
        let totalSelectedUsers = this.props.userList && this.props.userList.length ? this.props.userList.length : "";
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <div><label>{this.props.distributionType == "sendPasses" ? T("Sending passes") : T("Send licenses")}</label></div>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.sendFieldsClose}>&times;</button>
                        {this.props.distributionType == "sendPasses" && <h3><b>{`${totalSelectedUsers}`}</b> {T("out of")} <b>{`${totalUsers}`}</b> {T("customers selected")}</h3>}
                        {this.props.distributionType == "sendLicense" && <h3><b>{`${totalSelectedUsers}`}</b> {T("out of")} <b>{`${totalUsers}`}</b> {T("license holder selected")}</h3>}
                    </div>
                    <div className="modal-body">
                        <form id="sendForm" onSubmit={(e) => { this.handleSubmit(e) }}>
                            <div className="send-left">
                                <div className="form-group-cont">
                                    {!this.state.loader &&
                                        <div className="form-group">
                                            {/* <label>Subject </label> */}
                                            <input placeholder={T("Subject")} autoComplete="off" id="mailsubjectinput" type="text"
                                                onBlur={(e) => {
                                                    this.setState({
                                                        subjectChange: true
                                                    })
                                                    setTimeout(() => {
                                                        this.validateForm('subject')
                                                    }, 5);
                                                }}
                                                onChange={(e) => {
                                                    let error = this.state.errors;
                                                    error['subject'] = "";
                                                    this.setState({
                                                        subjectChange: true,
                                                        subject: e.target.value,
                                                        errors: error,
                                                        formIsValid: true
                                                    })
                                                    // this.validateForm('subject');
                                                    setTimeout(() => {
                                                        this.validateForm('subject')
                                                    }, 5);
                                                }} />
                                            <div className="custom-errorMsg">{this.state.errors['subject']}</div>
                                        </div>
                                    }
                                    {!this.state.loader &&
                                        <div className="form-group">
                                            {/* <label>Message </label> */}
                                            <textarea placeholder={T("Message")} id="mailtextarea"
                                                onBlur={(e) => {
                                                    this.setState({
                                                        messageChange: true
                                                    })
                                                    setTimeout(() => {
                                                        this.validateForm('message')
                                                    }, 5);
                                                }}
                                                onChange={(e) => {
                                                    let error = this.state.errors;
                                                    error['message'] = "";
                                                    this.setState({
                                                        messageChange: true,
                                                        message: e.target.value,
                                                        errors: error,
                                                        formIsValid: true
                                                    })
                                                    // this.validateForm('message');
                                                    setTimeout(() => {
                                                        this.validateForm('message')
                                                    }, 5);
                                                }}>
                                            </textarea>
                                            <div className="custom-errorMsg">{this.state.errors['message']}</div>
                                        </div>
                                    }
                                </div>
                                {(this.props.distributionType == "sendPasses" && !this.state.loader) &&
                                    <div className={this.props.singleTemplate.full_pass_path ? this.props.singleTemplate.pass_type === 'pass.snjall.coupon' ? "send_pass_with_image template_image_coupon" : 'send_pass_with_image' : this.props.singleTemplate.pass_type === 'pass.snjall.coupon' ? "send_pass_without_image" : "send_pass_without_image"}>
                                        {this.props.singleTemplate.full_pass_path ? <img src={Constants.Public_Path + this.props.singleTemplate.full_pass_path} /> : <TemplateLayout singleTemplateLayout={this.props.singleTemplate} />}
                                    </div>
                                }
                            </div>

                            <div className="send-right">

                                <div className="allSelectedMailList ">
                                    {/* <label> Selected Users:</label> */}
                                    {/* <ul>
                                    {this.props.userList.map(element => (<li>{element.email}</li>))}
                                </ul> */}
                                    <MUIDataTable
                                        title={this.props.distributionType == "sendLicense" ? T("License code will be recieved by email") : T("Pass will be recieved by email")}
                                        data={data}
                                        columns={dataColumns}
                                        options={options}
                                    />
                                </div>

                                {!this.state.loader &&
                                    <div class="form-btn-btm">
                                        <input type="button" id="sendCancel" className="btn btn-default submit_btn no_to_passes" value={T("Cancel")} data-dismiss="modal" onClick={this.sendFieldsClose} />
                                        <input type="submit" id="sendDone" disabled={!this.state.formIsValid || this.state.subject == "" || (this.props.distributionType == "sendPasses" && this.state.message == "")} className={!this.state.loader && this.state.formIsValid && this.state.subject != "" && ((this.props.distributionType == "sendPasses" && this.state.message != "" || this.props.distributionType=="sendLicense") ) ? "btn btn-default submit_btn sendpasses_activate" : "btn btn-default submit_btn sendpasses_deactivate"} value={this.props.distributionType == "sendPasses" ? T("Send Pass") : T("Send license")} />
                                    </div>
                                }
                            </div>


                        </form>
                    </div>
                    <div className="modal-footer">
                    </div>
                    {loader}
                </div>

            </div>
        )
    }
}