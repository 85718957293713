import React from 'react';
import LogoQr from '../assets/images/logo_Qr.png';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { functions } from '../functions'
import { api } from "../api";
import { Constants } from '../config';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class ConfirmEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmation: '',
            loader: true
        };
    }
    componentDidMount() {
        const CurrentTab = functions.getUrlSlice('key=', '1')
        let key = functions.getParamValuesByName('key');
        var PostData = {
            key: CurrentTab,
            isIssuerConfirmation: true
        };
        if (key) {
            api.confirmIssuerEmail(PostData)
                .then(res => {
                    if (res.data.status == 1) {
                        functions.authenticateUser(res.data);
                        this.setState({
                            confirmation: 'success',
                            loader: false
                        })

                    } else {
                        this.setState({
                            confirmation: 'failed',
                            loader: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({ loader: false, confirmation: 'failed' })
                });
        } else {
            this.setState({ loader: false })
        }
    }
    render() {
        let loader = functions.getLoader(this.state.loader);
        if (this.state.confirmation == 'success') {
            return <Redirect to='/dashboard' />
            
        } else if (this.state.confirmation == 'failed') {
            return (
                <div className="wrapper block_element">
                    <div className="bg_color_section block_element thanku_page padding_container">
                        {/* <Link to="/"><img src={LogoQr} /></Link> */}
                        <a href={Constants.introductionPage}><img src={LogoQr} /></a>
                        <h2>{T("The email has already been confirmed")}.</h2>
                       
                    </div>
                </div>
            )
        } else {
            return (
                //  <Redirect to='/signup' />
                <div className="wrapper block_element">
                    <div className="bg_color_section block_element thanku_page padding_container">
                        <Link to="/"><img src={LogoQr} /></Link>
                        {loader}
                        {!this.state.loader &&
                            <div className="confirmation text">
                                <span>{T("Your email confirmation is expired")}</span>
                            </div>
                        }{
                            this.state.loader && 
                            <div className="confirmation text">
                                <span>{T("Checking your email confirmation token")}</span>
                            </div>
                        }
                    </div>
                </div>
            )
        }
    }
}