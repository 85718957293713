import React from 'react';
import NumberFormat from 'react-number-format';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import VisaCardPng from '../../assets/images/visa.png';
import MasterCardPng from '../../assets/images/mastercard.png';
import { Redirect } from 'react-router';
import OtherCard from '../../assets/images/otherCard.png';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

const creditCardType = require('credit-card-type');


export default class OrderPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            redirect: false,
            editCard: false,
            isSubmittingBlocked: false,
            formSubmit: true,
            card_number: '',
            name_on_card: '',
            exp_date: '',
            cvc: ''
        };
    }
    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        this.setState({
            card_number: this.props.CardCred && this.props.CardCred.card_number ? `**** **** ****  ${this.props.CardCred.card_number} ` : '',
            name_on_card: this.props.CardCred && this.props.CardCred.card_holder_name ? this.props.CardCred.card_holder_name : '',
            exp_date: this.props.CardCred && this.props.CardCred.expire_year && this.props.CardCred.expire_month ? `${this.props.CardCred.expire_month} ${this.props.CardCred.expire_year} ` : '',
        })
    }

    payWithInvoice = (PostData) => {
        api.IsAuthenticatedUser().then(res => {
            if (res.data && res.data.message == "Authenticated.") {
                this.setState({ loader: true })
                api.SaveCardDetailWithPayments(PostData)
                    .then(result => {
                        if (result.data.status == 1) {
                            let content = {
                                token: functions.getToken(),
                                access_token: functions.getAccessToken(),
                                contact_name: functions.getUserName(),
                                company_name: functions.getCompanyName(),
                                subscription_data: result.data.extra_info.issuer,
                                user_permissions: functions.getUserPermissions(),
                                issuer_role: functions.getIssuerRole(),
                            }
                            localStorage.setItem('loginISP_user_sesion', functions.Client(content));
                            $(".myOrderPopup [data-dismiss=modal]").trigger({ type: "click" });
                            $("#OrderConfirmationPopup" + PostData.subscription_id).trigger({ type: "click" });
                            this.setState({ loader: false })
                        } else {
                            this.setState({ loader: false })
                        }
                    })
                    .catch(err => {
                        this.setState({ loader: false })
                    })
            }
            else {
                this.setState({ loader: false })
                // $('.modal').modal('hide')
                $('body').removeClass('modal-open-again modal-open-again-order')
                $('.modal-backdrop').remove()
                return (<Redirect to='/signin' />)
            }
        })

    }
    
    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let loader = functions.getLoader(this.state.loader);
        const orderData = this.props.OrderData;
        let CardCred = this.props.CardCred;
        const tempOrder = this.props.orderTemp;
        // const getTrialDuration = this.props.getTrialDuration ? this.props.getTrialDuration : [0, 0];
        const getTrialDuration = this.props.getTrialDuration ? this.props.getTrialDuration : 0;
        const DiscountPeriodExpired = this.props.DiscountPeriodExpired;
        let editField = (CardCred && Object.keys(CardCred).length == 0) || this.props.changeData ? true : false;
        let priceDeducted = 0;
        let totalPrice=0;
        let VAT = 0;
        if (this.props.SubscriptionState && this.props.SubscriptionState.subscription_due_date) {
            priceDeducted = parseInt(DiscountPeriodExpired ? functions.calculatePrice(this.props.SubscriptionState.trailPeriodStartOn,this.props.SubscriptionState.subscription_due_date, this.props.SubscriptionState.price) : functions.calculatePrice(this.props.SubscriptionState.trailPeriodStartOn,this.props.SubscriptionState.subscription_due_date,this.props.SubscriptionState.price));
        }
        // let totalPrice = orderData && !DiscountPeriodExpired ? ((orderData.offer_price - priceDeducted) * 1.24) : (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 1.24) : 0);
        // let VAT = orderData && !DiscountPeriodExpired ? ((orderData.offer_price - priceDeducted) * 0.24) : (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 0.24) : 0);
        if(parseInt(this.props.subscription_discount)>0){
            totalPrice=(orderData && ((orderData.price-parseInt(orderData.price*this.props.subscription_discount/100)) > 0) ? (((orderData.price - parseInt(orderData.price*this.props.subscription_discount/100)) - priceDeducted) * 1.24) : 0)
            VAT = (orderData && ((orderData.price-parseInt(orderData.price*this.props.subscription_discount/100)) > 0) ? (((orderData.price - parseInt(orderData.price*this.props.subscription_discount/100)) - priceDeducted) * 0.24) : 0)
        }
        else if(orderData && !DiscountPeriodExpired ){
            totalPrice=((orderData.offer_price - priceDeducted) * 1.24)
            VAT = ((orderData.offer_price - priceDeducted) * 0.24)
        }else{
            totalPrice=(orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 1.24) : 0)
            VAT = (orderData && (orderData.price > 0) ? ((orderData.price - priceDeducted) * 0.24) : 0)
        }
        let orderId = orderData && orderData.id ? orderData.id : '';
        if (tempOrder) {
            totalPrice = totalPrice + (this.props.template_price * 1.24);
            VAT = VAT + (this.props.template_price * 0.24);
        }

        let showCardForm = this.state.editCard || editField || !CardCred ? true : false;
        let showCardNum = !showCardForm && CardCred && Object.keys(CardCred).length > 0 ? true : false;

        if (this.state.redirect) {
            // return (<Redirect to={"/subscription?key=" + performance.now()} />)
            window.location.reload();
        }
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <button type="button" id="closebtn" className="close" onClick={this.state.editCard ? () => { $('#testback').click() } : () => { $('input#testback1').click() }} data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        {/* {loader} */}
                        <p className="modal_body_haeding">{orderData || tempOrder ? T('Your order') : T('Change payment method')}</p>
                        {orderData &&
                            <div className="template_build single_build">
                                <div className="template_build_inner">
                                    <span>{orderData.name} {T("monthly subscription")}</span>
                                    {/* <span>- ISK {orderData.price}</span> */}
                                    <span> ISK {functions.toCurrency(orderData.price)}</span>
                                    {/* <span>- ISK <NumberFormat value={orderData.price} displayType={'text'} thousandSeparator={true} /></span> */}
                                </div>
                                {tempOrder &&
                                    <div className="template_build_inner">
                                        <span>1 {T("Pass Template build")}</span>
                                        {/* <span>{this.props.template_price}</span> */}
                                        <span> ISK {functions.toCurrency(this.props.template_price)}</span>
                                        {/* <span><NumberFormat value={this.props.template_price} displayType={'text'} thousandSeparator={true} /></span> */}
                                    </div>
                                }
                                {priceDeducted > 0 &&
                                    <div className="template_build_inner template_bulid_green">
                                        <span>{T("Amount deducted due to previous subscription payment in current billing cycle")}.</span>
                                        {/* <span>- ISK {parseInt(priceDeducted)}</span> */}
                                        <span>- ISK {functions.toCurrency(priceDeducted)}</span>
                                        {/* <span>- ISK <NumberFormat value={parseInt(priceDeducted)} displayType={'text'} thousandSeparator={true} /></span>  */}
                                    </div>
                                }
                                {!DiscountPeriodExpired && parseInt(this.props.subscription_discount) == 0 && orderData.name=="Starter" &&
                                    <div className="template_build_inner template_bulid_green">
                                        {/* <span>{orderData.name} monthly subscription discount ({getTrialDuration[0] > 1 ? (getTrialDuration[0] + " months ") : (getTrialDuration[0] > 0 ? getTrialDuration[0] + ' month ' : '')}{getTrialDuration[1] > 1 ? (getTrialDuration[1] + " days ") : (getTrialDuration[1] > 0 ? getTrialDuration[1] + ' day ' : '')} left)</span> */}
                                        <span>{orderData.name} {T("monthly subscription discount")}({getTrialDuration > 1 ? (getTrialDuration + T("days")) : (getTrialDuration + T("day"))} {T("left")})</span>
                                        {/* <span>- ISK {orderData.price - orderData.offer_price}</span> */}
                                        <span>- ISK {functions.toCurrency(orderData.price - orderData.offer_price)}</span>
                                        {/* <span>- ISK <NumberFormat value={orderData.price-orderData.offer_price} displayType={'text'} thousandSeparator={true} /></span> */}
                                    </div>
                                }
                                {parseInt(this.props.subscription_discount) > 0 &&
                                    <div className="template_build_inner template_bulid_green">
                                        {/* <span>{orderData.name} monthly subscription discount ({getTrialDuration[0] > 1 ? (getTrialDuration[0] + " months ") : (getTrialDuration[0] > 0 ? getTrialDuration[0] + ' month ' : '')}{getTrialDuration[1] > 1 ? (getTrialDuration[1] + " days ") : (getTrialDuration[1] > 0 ? getTrialDuration[1] + ' day ' : '')} left)</span> */}
                                        <span>{orderData.name} {T("monthly subscription discount")}
                                        {/* ({getTrialDuration > 1 ? (getTrialDuration + T("days")) : (getTrialDuration + T("day"))} {T("left")}) */}
                                        </span>
                                        {/* <span>- ISK {orderData.price - orderData.offer_price}</span> */}
                                        <span>- ISK {functions.toCurrency(parseInt(orderData.price*this.props.subscription_discount/100))}</span>
                                        {/* <span>- ISK <NumberFormat value={orderData.price-orderData.offer_price} displayType={'text'} thousandSeparator={true} /></span> */}
                                    </div>
                                }
                            </div>
                        }
                        {!orderData && tempOrder &&
                            <div className="template_build single_build">
                                <div className="template_build_inner">
                                    <span>1 {T("Pass Template build")}</span>
                                    {/* <span>{this.props.template_price}</span> */}
                                    <span> ISK {functions.toCurrency(this.props.template_price)}</span>
                                    {/* <span><NumberFormat value={this.props.template_price} displayType={'text'} thousandSeparator={true} /></span> */}
                                </div>
                            </div>
                        }
                        {(orderData || tempOrder) &&
                            <div className="toatl_vat_price">
                                <div className="toatl_vat_price_left">
                                    {/* <span className="total_cost_vat">Total price with VAT<sup>(VAT ISK {parseInt(VAT)})</sup></span> */}
                                    <span className="total_cost_vat">{T("Total price with")} {T('VAT')}<sup>({T('VAT')} ISK {functions.toCurrency(VAT)})</sup></span>
                                    {/* <span className="total_cost_vat">Total price with VAT<sup>(VAT ISK <NumberFormat value={parseInt(VAT)} displayType={'text'} thousandSeparator={true} />)</sup></span> */}
                                </div>
                                <div className="toatl_vat_price_rgt">
                                    {/* <span>ISK {parseInt(totalPrice)} </span> */}
                                    <span>ISK {functions.toCurrency(totalPrice)} </span>
                                    {/* <span>ISK <NumberFormat value={parseInt(totalPrice)} displayType={'text'} thousandSeparator={true} /> </span> */}
                                </div>
                            </div>}
                        <button style={{ display: 'none' }} id="redirect_page" onClick={() => this.setState({ redirect: true })}></button>
                        {(showCardForm && this.props.subscriptionPaymentType != '1') &&
                            <Formik
                                initialValues={{
                                    card_number: '',// : ('**** **** **** ' + CardCred.card_number),
                                    name_on_card: '',
                                    // company_name: functions.getCompanyName(),
                                    exp_date: '',
                                    cvc: ''
                                }}
                                // initialValues={this.state}
                                onSubmit={(values, { setSubmitting, resetForm, setErrors, setStatus }) => {
                                    this.setState({ errors: '', loader: true, formSubmit: false });
                                    const [expMM, expYY] = values.exp_date.split('/');
                                    var PostData = {
                                        "PAN": (values.card_number).replace(/\s+/g, ''),
                                        "ExpYear": expYY,
                                        "ExpMonth": expMM,
                                        "VerifyCard": { "Currency": "352", "CVC": values.cvc },
                                        "Metadata": { "Payload": "string" }
                                    };
                                    let currentComponent = this;
                                    api.IsAuthenticatedUser().then(res => {
                                        if (res.data && res.data.message == "Authenticated.") {
                                            functions.generateBorgunToken(PostData)
                                                .then(function (response) {
                                                    toast.dismiss();
                                                    if (!response.VerifyCardResult) {
                                                        if (response.Message == "PAN: InvalidFormat") {
                                                            // toast.error("Invalid card number", { hideProgressBar: true });
                                                            setErrors({ card_number: "Invalid card number." });
                                                        } else {
                                                            // toast.error(response.Message, { hideProgressBar: true });
                                                            setErrors({ errmsg: response.Messsage });
                                                            setTimeout(() => {
                                                                setErrors({})
                                                            }, 5000);
                                                        }
                                                        currentComponent.setState({ loader: false, formSubmit: true })
                                                        // $("[data-dismiss=modal]").trigger({ type: "click" });
                                                    } else if (response && response.VerifyCardResult && response.VerifyCardResult.ActionCode != '000') {
                                                        if (Validation_Constants.BorgunErrMsgs[response.VerifyCardResult.ActionCode]) {
                                                            // currentComponent.setState({ loader: false, formSubmit: true })
                                                            // toast.error((Validation_Constants.BorgunErrMsgs[response.VerifyCardResult.ActionCode]).trim(), { hideProgressBar: true })
                                                            // toast.error("Please check your card credentials", { hideProgressBar: true });
                                                            setErrors({ errmsg: Validation_Constants.BorgunErrMsgs[response.VerifyCardResult.ActionCode] });
                                                            setTimeout(() => {
                                                                setErrors({})
                                                            }, 5000);
                                                        } else {
                                                            // currentComponent.setState({ loader: false, formSubmit: true })
                                                            // toast.error(response.TransactionStatus + '. Please try again later.', { hideProgressBar: true });
                                                            setErrors({ errmsg: response.TransactionStatus + '. Please try again later.' });
                                                            setTimeout(() => {
                                                                setErrors({})
                                                            }, 5000);
                                                        }
                                                        currentComponent.setState({ loader: false, formSubmit: true })
                                                    } else if (response && response.VerifyCardResult && response.VerifyCardResult.ActionCode == '000') {
                                                        ;
                                                        var Payload = {}
                                                        if (totalPrice > 0) {
                                                            functions.makePayment(totalPrice, response.Token)
                                                                .then(data => {
                                                                    let cardType = creditCardType(values.card_number);
                                                                    if (data.ActionCode == '000') {
                                                                        Payload = {
                                                                            addUpdateCardDetail: true,
                                                                            // access_token: functions.getAccessToken(),
                                                                            name_on_card: values.name_on_card,
                                                                            card_num: values.card_number,
                                                                            card_type: cardType[0].niceType,
                                                                            expire_year: expYY,
                                                                            expire_month: expMM,
                                                                            token: response.Token,
                                                                            subscription_id: orderId,
                                                                            payment_res: data,
                                                                            priceDeducted: priceDeducted,
                                                                            orderPassTemplate: tempOrder ? true : false,
                                                                            appliedVatAmount: VAT
                                                                        }
                                                                        api.SaveCardDetailWithPayments(Payload)
                                                                            .then(result => {
                                                                                if (result.data.status == 1) {
                                                                                    let content={
                                                                                        token: functions.getToken(),
                                                                                            access_token: functions.getAccessToken(),
                                                                                            contact_name: functions.getUserName(),
                                                                                            company_name: functions.getCompanyName(),
                                                                                            subscription_data: result.data.extra_info.issuer,
                                                                                            user_permissions: functions.getUserPermissions(),
                                                                                            issuer_role: functions.getIssuerRole(),
                                                                                    }
                                                                                    localStorage.setItem('loginISP_user_sesion',
                                                                                       functions.Client(content));

                                                                                    $(".myOrderPopup [data-dismiss=modal]").trigger({ type: "click" });
                                                                                    $("#OrderConfirmationPopup" + orderId).trigger({ type: "click" });
                                                                                }
                                                                                currentComponent.setState({ loader: false, formSubmit: true })
                                                                            })
                                                                    } else {
                                                                        currentComponent.setState({ loader: false, formSubmit: true });
                                                                        // toast.error(Validation_Constants.BorgunErrMsgs[data.ActionCode], { hideProgressBar: true });
                                                                        if (data.ActionCode) {
                                                                            setErrors({ errmsg: Validation_Constants.BorgunErrMsgs[data.ActionCode] });
                                                                        } else if (data.Message) {
                                                                            setErrors({ errmsg: data.Message.substring(data.Message.lastIndexOf(':') + 1) })
                                                                        }
                                                                        setTimeout(() => {
                                                                            setErrors({})
                                                                        }, 5000);
                                                                    }
                                                                }).catch(err => {
                                                                    currentComponent.setState({ loader: false, formSubmit: true })
                                                                })
                                                        } else if (orderData) {
                                                            let cardType = creditCardType(values.card_number);
                                                            var dateobj = new Date();
                                                            Payload = {
                                                                addUpdateCardDetail: true,
                                                                // access_token: functions.getAccessToken(),
                                                                name_on_card: values.name_on_card,
                                                                card_num: values.card_number,
                                                                card_type: cardType[0].niceType,
                                                                expire_year: expYY,
                                                                expire_month: expMM,
                                                                token: response.Token,
                                                                subscription_id: orderId,
                                                                payment_res: {
                                                                    ActionCode: '000',
                                                                    Amount: 0,
                                                                    OrderId: "starterpack",
                                                                    TransactionDate: dateobj.toISOString(),
                                                                    TransactionId: response.TransactionId,
                                                                    TransactionStatus: "Accepted",
                                                                    TransactionType: "Sale"
                                                                },
                                                                orderPassTemplate: tempOrder ? true : false,
                                                                appliedVatAmount: VAT
                                                            }
                                                            api.SaveCardDetailWithPayments(Payload)
                                                                .then(result => {
                                                                    if (result.data && result.data.status == 1) {
                                                                        let content={
                                                                            token: functions.getToken(),
                                                                            access_token: functions.getAccessToken(),
                                                                            contact_name: functions.getUserName(),
                                                                            company_name: functions.getCompanyName(),
                                                                            subscription_data: result.data.extra_info.issuer,
                                                                            user_permissions: functions.getUserPermissions(),
                                                                            issuer_role: functions.getIssuerRole(),
                                                                        }
                                                                        localStorage.setItem('loginISP_user_sesion',
                                                                            functions.Client(content));

                                                                        $(".myOrderPopup [data-dismiss=modal]").trigger({ type: "click" });
                                                                        $("#OrderConfirmationPopup" + orderId).trigger({ type: "click" });
                                                                        currentComponent.setState({ loader: false, formSubmit: true })
                                                                    } else {
                                                                        currentComponent.setState({ loader: false, formSubmit: true })
                                                                    }
                                                                })
                                                        } else {
                                                            let cardType = creditCardType(values.card_number);
                                                            var dateobj = new Date();
                                                            Payload = {
                                                                addUpdateCardDetail: true,
                                                                // access_token: functions.getAccessToken(),
                                                                name_on_card: values.name_on_card,
                                                                card_num: values.card_number,
                                                                card_type: cardType[0].niceType,
                                                                expire_year: expYY,
                                                                expire_month: expMM,
                                                                token: response.Token,
                                                                priceDeducted: priceDeducted,
                                                                orderPassTemplate: tempOrder ? true : false,
                                                                appliedVatAmount: VAT
                                                            }
                                                            api.SaveCardDetailWithPayments(Payload)
                                                                .then(result => {
                                                                    if (result.data && result.data.status == 1) {
                                                                        $("[data-dismiss=modal] , #redirect_page").trigger({ type: "click" });
                                                                        // $("#OrderConfirmationPopup" + orderData.id).trigger({type: "click" });
                                                                        // toast.success(Validation_Constants.Update_Payment, { hideProgressBar: true });
                                                                        setStatus({ msg: Validation_Constants.Update_Payment, loader: false });
                                                                        setTimeout(() => {
                                                                            setStatus({ msg: '' })
                                                                        }, 5000);
                                                                    } else {
                                                                        currentComponent.setState({ loader: false, formSubmit: true })
                                                                    }
                                                                })
                                                        }
                                                    }
                                                });
                                        } else {
                                            currentComponent.setState({ loader: false })
                                            // $('.modal').modal('hide')
                                            $('body').removeClass('modal-open-again modal-open-again-order')
                                            $('.modal-backdrop').remove()
                                            return (<Redirect to='/signin' />)
                                        }
                                    })
                                    setSubmitting(false)
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        name_on_card: Yup.string().trim()
                                            .min('2', Validation_Constants.Min_Name_On_Card)
                                            .max('50', Validation_Constants.Max_Name_On_Card)
                                            .required(Validation_Constants.Required2),
                                        card_number: Yup.string().trim()
                                            .min('16', Validation_Constants.Min_Card_Num)
                                            .required(Validation_Constants.Required2),
                                        exp_date: Yup.string().trim()
                                            .required(Validation_Constants.Required2)
                                            .test(
                                                'test-credit-card-expiration-date',
                                                Validation_Constants.Invalid_Exp_Mon,
                                                exp_date => {
                                                    if (!exp_date) {
                                                        return false
                                                    }
                                                    const today = new Date()
                                                        .getFullYear()
                                                        .toString()
                                                        .substr(-2)
                                                    const [expMonth] = exp_date.split('/')
                                                    if (Number(expMonth) > 12) {
                                                        return false
                                                    }
                                                    return true
                                                }
                                            )
                                            .test(
                                                'test-credit-card-expiration-date',
                                                Validation_Constants.Invalid_Exp_Date,
                                                exp_date => {
                                                    if (!exp_date) {
                                                        return false
                                                    }
                                                    const today = new Date()
                                                    const monthToday = today.getMonth() + 1
                                                    const yearToday = today
                                                        .getFullYear()
                                                        .toString()
                                                        .substr(-2)
                                                    const [expMonth, expYear] = exp_date.split('/')
                                                    if (Number(expYear) < Number(yearToday)) {
                                                        return false
                                                    } else if (
                                                        Number(expMonth) < monthToday &&
                                                        Number(expYear) <= Number(yearToday)
                                                    ) {
                                                        return false
                                                    }
                                                    return true
                                                }
                                            ),
                                        //    .required(Validation_Constants.Empty_Email),
                                        cvc: Yup.string().trim()
                                            .min('3', Validation_Constants.Min_Cvc)
                                            .max('4', Validation_Constants.Max_Cvc)
                                            .required(Validation_Constants.Required2),
                                    })
                                }
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        status,
                                        dirty,
                                        isSubmitting,
                                        resetForm,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                    } = props;

                                    return (
                                        //  {/* <!-- Header-END --> */}
                                        <div >
                                            <form onSubmit={handleSubmit} id="orderform">
                                                <div className="modal_form_field form-group">
                                                    <label for="company_name">{T("Company name")}</label>
                                                    <input
                                                        type="text"
                                                        id="company_name"
                                                        name="company_name"
                                                        value={functions.getCompanyName()}
                                                        placeholder=" "
                                                        className="pre-filled-form"
                                                        tabIndex="-1"
                                                        // onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        maxLength="60"
                                                    />
                                                    {errors.company_name &&
                                                        touched.company_name && <p className="error">{errors.company_name}</p>
                                                    }
                                                </div>
                                                <div className="modal_form_field form-group">
                                                    <div className="floating-label_group">
                                                        <input
                                                            type="text"
                                                            id="name_on_card"
                                                            name="name_on_card"
                                                            value={values.name_on_card || ""}
                                                            placeholder=" "
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            maxLength="50"
                                                            className={
                                                                errors.name_on_card && touched.name_on_card ? 'floating-input inputText error ' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label for="name_on_card">{T("Card holder name")}</label>
                                                        {errors.name_on_card &&
                                                            touched.name_on_card && <p className="error">{errors.name_on_card}</p>
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className="modal_form_field form_field_spacing form-group"> */}

                                                <div className="modal_form_field form-group">
                                                    <div className="floating-label_group">
                                                        <NumberFormat
                                                            type="text"
                                                            id="card_number"
                                                            name="card_number"
                                                            value={values.card_number || ""}
                                                            placeholder=" "
                                                            onChange={handleChange}
                                                            format="#### #### #### ####"
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.card_number && touched.card_number ? 'floating-input inputText error ' : 'floating-input inputText'
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label for="card_number">{T("Card number")}</label>
                                                        {errors.card_number &&
                                                            touched.card_number && <p className="error">{errors.card_number}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="modal_form_field floating_validthru form-group">
                                                    <div className="floating-label_group">
                                                        <NumberFormat
                                                            type="text"
                                                            id="exp_date"
                                                            name="exp_date"
                                                            value={values.exp_date || ""}
                                                            format="##/##"
                                                            placeholder=" "
                                                            //mask={['M', 'M', 'Y', 'Y']}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.exp_date && touched.exp_date ? 'inputText error ' : 'inputText '
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label for="exp_date">{T("Valid thru")} (MM/YY)</label>
                                                        {errors.exp_date &&
                                                            touched.exp_date && <p className="error">{errors.exp_date}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="modal_form_field floating_cvc form-group">
                                                    <div className="floating-label_group">
                                                        <input
                                                            type="password"
                                                            id="cvc"
                                                            name="cvc"
                                                            value={values.cvc || ""}
                                                            placeholder=" "
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.cvc && touched.cvc ? 'floating-input inputText error ' : 'floating-input inputText '
                                                            }
                                                        />
                                                        <span className="highlight"></span>
                                                        <label for="cvc">{T("CVC")}</label>
                                                        {errors.cvc &&
                                                            touched.cvc && <p className="error">{errors.cvc}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <input id="orderConfirm" className="btn btn-default submit_btn" type="submit" disabled={(JSON.stringify(errors) != "{}") || (!this.state.formSubmit) || (values.company_name == "" || values.name_on_card == "" || values.card_number == "" || values.exp_date == "" || values.cvc == "")} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.company_name != "" && values.name_on_card != "" && values.card_number != "" && values.exp_date != "" && values.cvc != "" ? 'isActive' : 'isNotActive'} value={T("Confirm")} />
                                                {this.state.editCard &&
                                                    <input className="btn btn-danger" id="testback" type="button" value={T("Back")} onClick={resetForm, () => { this.setState({ editCard: false }) }} />
                                                }
                                                <input className="btn btn-danger" id="testback1" type="button" style={{ display: 'none' }} value={T("Back")} onClick={() => { resetForm(); this.setState({ editCard: false }) }} />
                                                <div className="form-group-messages">
                                                    {errors && errors.errmsg &&
                                                        <p className="error">{errors.errmsg}</p>}
                                                    {status && status.msg &&
                                                        <p className="success">{status.msg}</p>}
                                                </div>
                                            </form>
                                        </div>
                                        //  {/* <!-- wrapper-end --> */}
                                    );
                                }}
                            </Formik>
                        }
                         {(showCardNum && this.props.subscriptionPaymentType != '1') &&
                            <Formik
                                initialValues={{
                                    card_number: '',// : ('**** **** **** ' + CardCred.card_number),
                                    name_on_card: '',
                                    company_name: functions.getCompanyName(),
                                    exp_date: '',
                                    cvc: ''
                                }}
                                onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                                    this.setState({ loader: true, errors: '', formSubmit: false });
                                    let currentComponent = this;
                                    api.IsAuthenticatedUser().then(res => {
                                        if (res.data && res.data.message == "Authenticated.") {
                                            functions.makePayment(totalPrice, CardCred.token)
                                                .then(data => {
                                                    if (data.ActionCode == '000') {
                                                        let Payload = {
                                                            addUpdateCardDetail: true,
                                                            // access_token: functions.getAccessToken(),
                                                            name_on_card: CardCred.card_holder_name,
                                                            card_num: CardCred.card_number,
                                                            card_type: CardCred.card_type,
                                                            expire_year: CardCred.expire_year,
                                                            expire_month: CardCred.expire_month,
                                                            token: CardCred.token,
                                                            subscription_id: orderId,
                                                            payment_res: data,
                                                            priceDeducted: priceDeducted,
                                                            orderPassTemplate: tempOrder ? true : false,
                                                            appliedVatAmount: VAT
                                                        }
                                                        api.SaveCardDetailWithPayments(Payload)
                                                            .then(result => {
                                                                if (result.data && result.data.status == 1) {
                                                                    let content={
                                                                        token: functions.getToken(),
                                                                            access_token: functions.getAccessToken(),
                                                                            contact_name: functions.getUserName(),
                                                                            company_name: functions.getCompanyName(),
                                                                            subscription_data: result.data.extra_info && result.data.extra_info.issuer ? result.data.extra_info.issuer : functions.getSubscriptionData(),
                                                                            user_permissions: functions.getUserPermissions(),
                                                                            issuer_role: functions.getIssuerRole(),
                                                                    }
                                                                    localStorage.setItem('loginISP_user_sesion',
                                                                        functions.Client(content));
                                                                    currentComponent.setState({ isSubmittingBlocked: false })
                                                                    $(".myOrderPopup [data-dismiss=modal]").trigger({ type: "click" });
                                                                    $("#OrderConfirmationPopup" + orderId).trigger({ type: "click" });
                                                                    resetForm({})
                                                                }
                                                                else {
                                                                    // currentComponent.setState({ loader: false });
                                                                    setErrors({ errmsg: result.Message })
                                                                    setTimeout(() => {
                                                                        setErrors({ msg: '' })
                                                                    }, 5000);
                                                                }
                                                                currentComponent.setState({ loader: false });
                                                            })
                                                    } else {
                                                        if (data.ActionCode) {
                                                            setErrors({ card_number: Validation_Constants.BorgunErrMsgs[data.ActionCode] });
                                                        } else if (data.Message) {
                                                            setErrors({ errmsg: data.Message })
                                                            setTimeout(() => {
                                                                setErrors({ msg: '' })
                                                            }, 5000);
                                                        }
                                                        currentComponent.setState({ loader: false, formSubmit: true });
                                                        // toast.error(Validation_Constants.BorgunErrMsgs[data.ActionCode], {hideProgressBar: true });
                                                    }
                                                }).catch(err => {
                                                    currentComponent.setState({ loader: false });
                                                })
                                        } else {
                                            currentComponent.setState({ loader: false })
                                            // $('.modal').modal('hide')
                                            $('body').removeClass('modal-open-again modal-open-again-order')
                                            $('.modal-backdrop').remove()
                                            return (<Redirect to='/signin' />)
                                        }
                                    })
                                    // this.setState({loader: false});
                                    setSubmitting(false);
                                }}
                            >
                                {props => {
                                    const {
                                        values,
                                        touched,
                                        errors,
                                        dirty,
                                        isSubmitting,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        handleReset,
                                    } = props;
                                    return (
                                        < form onSubmit={handleSubmit} >
                                            <div className="modal_form_field form_field_spacing hide-input-field">
                                                <label>{T("Pay using this credit card")}</label>
                                                {/* <i className="fab fa-cc-mastercard"></i> */}
                                                <input
                                                    type="text"
                                                    id="card_number"
                                                    name="card_number"
                                                    value={"**** **** **** " + CardCred.card_number}
                                                    placeholder=" "
                                                    onChange={handleChange}
                                                />
                                                <p className="card_number_text_value">
                                                    {/* <i class="fab fa-cc-mastercard"></i> */}
                                                    {/* <img src={MasterCardPng} alt="masterCard" className="cardTypePng" /> */}
                                                    <img src={CardCred.card_type == 'Mastercard' ? MasterCardPng : (CardCred.card_type == 'Visa' ? VisaCardPng : OtherCard)} alt="visaCard" className="cardTypePng" />
                                                    <span className="card_number_value">.... .... ....&nbsp;</span>{CardCred.card_number}
                                                    <button type="button" onClick={() => { $(document.body).addClass('modal-open-again'); this.setState({ editCard: true }) }}>{T("Edit")}</button>
                                                </p>
                                            </div>
                                            <div className="form-group-messages">
                                                {errors.card_number &&
                                                    touched.card_number && <p className="error">{errors.card_number}</p>
                                                }
                                                {errors && errors.errmsg &&
                                                    <p className="error">{errors.errmsg}</p>}
                                            </div>
                                            <input className={!this.state.loader ? "btn btn-default submit_btn " : "btn btn-default submit_btn submit_btn_deactivate"} type="submit" value={T("Confirm")} disabled={this.state.loader} />
                                            {/* <input className="btn btn-default submit_btn" type="submit" value="Confirm" /> */}
                                        </form>
                                    );
                                }
                                }
                            </Formik>
                        }
                        {this.props.subscriptionPaymentType == '1' &&
                            <div>
                                <button className={!this.state.loader ? "btn btn-default submit_btn " : "btn btn-default submit_btn submit_btn_deactivate"} type="button" disabled={this.state.loader} onClick={() => {
                                    this.payWithInvoice({
                                        subscription_id: orderId,
                                        priceDeducted: priceDeducted,
                                        orderPassTemplate: tempOrder ? true : false,
                                        appliedVatAmount: VAT,
                                        ammount: totalPrice,
                                    })
                                }}>Confirm</button>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                    </div>
                    {loader}
                </div>
            </div >
        )
    }
}
