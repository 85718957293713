import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import AddLicenseHolder from "./AddLicenseHolder";
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery'
import SendUser from './SendUser';
import MUIDataTable from "mui-datatables";
import RequestConfirmPopup from './RequestConfirmPopup';
import mailDelivered from '../../assets/images/maildelivered.png';
import mailNotDelivered from '../../assets/images/mailnotdelivered.png';
import mailBounce from '../../assets/images/mailbounce.png';
import mailOnTheWay from '../../assets/images/mailontheway.png';
import moment from 'moment';
import DeleteUser from './DeleteUser';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class ScannerDistributionTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Scanner',
            loader: false,
            membersList: [],
            selectedData: [],
            userCount: 0,
            rowsSelected: [],
            userChecked: false,
            confirmSelectedData: [],
            totalAddedHolder: '',
        };
    }
    componentDidMount() {
        // this.props.parentComponentDidMountFunction();
    }

    checkedUser = (selected) => {
        let checkedData = []
        let allMember = this.props.distributedLicenseHolders;
        selected.forEach(id => {
            for (let i = 0; i <= allMember.length; i++) {
                if (id.dataIndex == i) {
                    checkedData.push({ id: allMember[i].id, email: allMember[i].email });
                }
            }
        });
        this.setState({
            confirmSelectedData: checkedData,
            selectedData: checkedData
        })
        if (selected.length > 0) {
            this.setState({
                userChecked: true
            })
        }
        else {
            this.setState({
                userChecked: false
            })
        }
    }

    handleTableAction() {
        this.setState({ optionChanged: false })
    }

    closeModal = (type, userCount) => {
        this.setState({ totalAddedHolder: userCount })
        if (type == "sendlicense") {
            this.setState({ rowsSelected: [], selectedData: [], userChecked: false })
            this.props.parentComponentDidMountFunction();
            $(".sendLicenseHolderModal [data-dismiss=modal]").trigger("click");
            $("#sendLicenseHolderConfirmBtn").trigger({ type: "click" });
        }
        else if (type == "addLicenseHolder") {
            $(".addLicenseHolderModal [data-dismiss=modal]").trigger("click");
            this.props.parentComponentDidMountFunction();
            $("#addLicenseHolderConfirmBtn").trigger({ type: "click" });
        }
        else if (type == "DeleteLicenseHolderList") {
            this.props.parentComponentDidMountFunction();
            $(".deleteHolderList [data-dismiss=modal]").trigger("click");
        }
    }

    render() {

        let tableData = this.props.distributedLicenseHolders.map((val, key) => {
            return {
                id: val.id ? val.id : '-',
                status: val.license_holder_mails_logs && val.license_holder_mails_logs[0] && val.license_holder_mails_logs[0].sendgrid_mail_event ? val.license_holder_mails_logs[0].sendgrid_mail_event : 'null',
                email: val.email ? val.email : "-",
                date_added: val.license_holder_scanners && val.license_holder_scanners[0] && val.license_holder_scanners[0].created_at ? moment(val.license_holder_scanners[0].created_at .substring(0, val.license_holder_scanners[0].created_at .indexOf(' '))).format('DD.MM.YYYY') : '-'
            };
        });
        let rowsSelected = this.state.rowsSelected;
        let colName1 = [
            {
                name: 'email',
                label: 'Email address',
            }
        ];

        let colName = [
            {
                name: 'id',
                label: 'ID',
                options: {
                    display: false,
                    filter: true,
                    sort: true,
                }
            },
            {
                name: 'status',
                label: T("Status"),
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let image = '';
                        if (tableMeta.rowData && tableMeta.rowData[1]) {
                            if (tableMeta.rowData[1] == 'delivered' || tableMeta.rowData[1] == 'open' || tableMeta.rowData[1] == 'click') {
                                image = <img src={mailDelivered} title={tableMeta.rowData[1]} />
                            } else if (tableMeta.rowData[1] == 'deferred' || tableMeta.rowData[1] == 'bounce') {

                                image = <img src={mailBounce} title={tableMeta.rowData[1]} />
                            } else if (tableMeta.rowData[1] == 'processed') {
                                image = <img src={mailOnTheWay} title={tableMeta.rowData[1]} />
                            } else if (tableMeta.rowData[1] == "null") {
                                image = <label>-</label>
                            } else if (tableMeta.rowData[1] == "") {
                                image = <label>-</label>
                            } else {
                                image = <img src={mailNotDelivered} title={tableMeta.rowData[1]} />
                            }
                            return image;
                        } else {
                            return (<label>-</label>)
                        }
                    }
                }
            },
            {
                name: 'email',
                label: T("Email"),
                options: {
                    
                    filter: true,
                    sort: true,
                }
            },
            {
                name: 'date_added',
                label: T("Date added"),
                options: {
                    filter: true,
                    sort: true,

                }
            }
        ];
        const options = {
            filter: false,
            viewColumns: false,
            disableToolbarSelect: true,
            filterType: 'dropdown',
            responsive: "standard",
            downloadOptions: {
                filename: 'eventTable.csv',
            },
            textLabels: {
                body: {
                    noMatch: "No results found, Please try different search parameters",
                }
            },
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                const selected = allRows.map(item => item.dataIndex);
                this.setState({
                    rowsSelected: selected,
                }, () => this.handleTableAction());
                this.checkedUser(allRows);
            },
        };
        const options1 = {
            print: false,
            filter: false,
            search: false,
            selectableRows: false,
            download: true,
            viewColumns: false,
            rowsPerPage: 5,
            downloadOptions: {
                filename: 'scannerTableTemplate.csv',
            },
            rowsPerPageOptions: []
        }
        functions.changePageTitle(this.state.pagetitle);
        const CurrentTab = functions.getUrlSlice('/', '1');
        let loader = functions.getLoader(this.state.loader);
        return (
            <div id="scanner_distribution" className={CurrentTab == 'scanner_distribution' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                {loader}
                {/* <!-- No scanner listed --> */}
                <div className="no_scanner_listed no_and_search_samestyle">
                    <div className="main_distribution_btn">
                        {this.props.distributedLicenseHolders.length > 0 &&
                            <button className={!this.state.userChecked ? "pass_limit_reached distribution_btns" : "distribution_btns active"} disabled={!this.state.userChecked} data-toggle="modal" data-target="#sendUser" title={this.state.rowsSelected && this.state.rowsSelected.length > 0 ? "" : "select checkbox to send license"} style={{ cursor: this.state.userChecked ? "pointer" : "not-allowed" }} onClick={() => { this.childSendlicense.sendFieldsClose() }} data-toggle="modal" data-target="#sendLicense" >{T("Send license to selected")}</button>
                        }

                        <button data-toggle="modal" data-target="#addLicenseHolder" onClick={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }); $('#addLicenseHolderCancel').trigger('click') }} className="distribution_btns" >{T("Add License Holder")}</button>

                        {this.props.distributedLicenseHolders.length > 0 &&
                            <button onClick={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }) }} className="distribution_btns deleteAllUser" data-toggle="modal" data-target="#deleteHolderList">{T("Delete license holder list")}</button>
                        }
                    </div>

                    {this.props.distributedLicenseHolders == 0 &&
                        <div className="no_listing_text">
                            <h3>{T("No license holders listed")} </h3>
                            <span>{T("You need to upload a list or manually add license holders to see them listed here")}.</span>
                        </div>
                    }
                </div>

                {/* <!-- scanner listing --> */}
                {
                    this.props.distributedLicenseHolders.length > 0 &&
                    <div id="scanner_distribution_data">
                        <div className="status_main">
                            <MUIDataTable
                                //title={"Distribution List"}
                                data={tableData}
                                columns={colName}
                                options={options}
                            />
                        </div>
                        <div className="status_main1" style={{ display: 'none', pointerEvents: 'none' }}>
                            <MUIDataTable
                                //title={"Distribution List"}
                                data={[]}
                                columns={colName1}
                                options={options1}
                            />
                        </div>
                    </div>
                }
                <div className="modal addLicenseHolderModal fade " id="addLicenseHolder" role="dialog" ref="addLicenseHolder">
                    <AddLicenseHolder currentScannerId={this.props.currentScannerId} modalClose={(type,count) => { this.closeModal(type,count) }} />
                </div>
                < div className="modal sendLicenseHolderModal fade " id="sendLicense" role="dialog" ref="sendLicense">
                    <SendUser distributionType={"sendLicense"}
                        userList={this.state.selectedData}
                        tempId={this.props.currentScannerId}
                        userData={this.props.distributedLicenseHolders}
                        currentScannerId={this.props.currentScannerId}
                        modalClose={(type,count) => { this.closeModal(type,count) }}
                        ref={instance => {
                            this.childSendlicense = instance;
                        }} />
                </div>

                <button type="button" data-toggle="modal" data-target="#sendLicenseConfirm" className="sendLicenseHolderConfirmBtn" id="sendLicenseHolderConfirmBtn" style={{ display: 'none' }} ></button>
                <div className="modal sendLicenseConfirm fade " id="sendLicenseConfirm" role="dialog" ref="sendLicenseConfirm">
                    <RequestConfirmPopup
                        confirmType="sendLicense"
                        userList={this.state.confirmSelectedData}
                        userData={this.state.membersList} />
                </div>

                <button data-toggle="modal" data-target="#addLicenseHolderConfirm" id="addLicenseHolderConfirmBtn" style={{ display: 'none' }}> </button>
                <div className="modal addUserConfirm fade " id="addLicenseHolderConfirm" role="dialog" ref="addLicenseHolderConfirm">
                    <RequestConfirmPopup
                        confirmType="addLicenseHolder"
                        userList={[]}
                        userData={[]}
                        totalAddedHolder={this.state.totalAddedHolder} />
                </div>
                <div className="modal deleteHolderList adduser fade" id="deleteHolderList" role="dialog" ref="deleteHolderList">
                    <DeleteUser modalType={"DeleteLicenseHolderList"} currentScannerId={this.props.currentScannerId} modalClose={(type) => { this.closeModal(type) }} />
                </div>
            </div>
        );
    }
};

export default ScannerDistributionTab;
