import React from 'react';
import LogoQr from '../assets/images/logo_Qr.png';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Constants } from '../config';
import backArrow from '../assets/images/back_arrow.png';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStickyClass: false
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
       
    }
    render() {
        if (localStorage.getItem('new_email_registered')) {
            var localStorageData = JSON.parse(localStorage.getItem('new_email_registered'));
            return (
                <div className="wrapper block_element">
                    <div className="bg_color_section block_element thanku_page padding_container">
                        <a href={Constants.introductionPage}><img src={LogoQr} /></a>
                        <h2>{T("Thank you for signing up")}!</h2>
                        <div className="confirmation text thank_text">
                            <span>{T("Sign up confirmation email sent")} </span><span className="circle"><i className="fa fa-check"></i></span>
                            <p>{T("An email has been sent to your address")}, {localStorageData.email} <br /> {T("Please click the link in the email to confirm your sign up")}.</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<Redirect to='/signin' />)
        }
    }
}