import React from 'react';
import { Row, Col } from 'reactstrap';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


class AssetsDataProcessor extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (<div className={this.props.class}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>{this.props.title}</h1>
                        {
                            (this.props.view_all_link != "")
                            ?
                            <a className="view-all-link" href={this.props.view_all_link}>{this.props.view_all_title}</a>
                            :
                            ''
                        }
                    </div>
                    <div className="prod-outer">

                        {/* Loop */}
                        {(this.props.data.length != 0) ?
                            (this.props.data).map(item => (
                                <div className="col-sm-3 col-xs-12 padding-none-div">
                                    <div className="prod-block  " title={item.assertHeadline}>
                                        <h3 className="title-text-thumbnail">
                                            {item.assertHeadline}
                                        </h3>
                                        <div className="prod-inner ">
                                            <div className={"image-block " + item.assetType}>
                                                <a href="javascript:;" data-onclick={item.assetsLink}>
                                                    <img src={item.assetsImgSrc} alt="Product" className="img-responsive" />
                                                </a>
                                                <div className="hover-actions">
                                                    <form method="get" action="https://www.tcdam.com/search">
                                                        <a title={item.assetsTagIdentifier} href="javascript:;" className="tag image-id">View Collection</a>
                                                        <input type="hidden" name="tag_identifier" value={item.assetsTagIdentifier} />
                                                        <input type="hidden" name="viewBy" value="picture" />
                                                    </form>
                                                    <ul className="call-to-action">

                                                        <li><a href="javascript:;" className="ru-basket" title="ADD TO CART"><i className="fa fa-shopping-basket fa-lg"></i></a>
                                                            <input type="hidden" name="add-to-cart" value={item.assetsId} />
                                                        </li>

                                                        <li>
                                                            <a href="javascript:;" className="ru-fav" title="ADD TO FAVS"><i className="fa fa-heart fa-lg"></i></a>
                                                            <input type="hidden" name="add-to-cart" value={item.assetsId} />
                                                        </li>
                                                        <li>
                                                            <form action="https://www.tcdam.com/search" method="get">
                                                                <a href="javascript:;" className="ru-view" title="SIMILAR ASSETS"><i className="fa fa-clone fa-lg"></i></a>
                                                                <input type="hidden" name="more_like_this" value={item.assetsKeywords} />
                                                            </form>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            ''
                        }
                        {/* End Loop */}
                    </div>

                        {
                            (this.props.hr_line != "") ?
                                <hr className="assetsHr"/>
                                :
                            ""
                        }
                    


                </div>
            </div>
        </div>
        );
    }
};

export default AssetsDataProcessor;
