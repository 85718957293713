import React from 'react';
import Redirect from 'react-router/Redirect';
import { functions } from "../../functions";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class DowngradePlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    redirectToSubscription() {
        this.setState({
            redirect: true
        })
    }

    render() {
        if (this.state.redirect) {
            //        return (<Redirect to="/subscription" />)
            window.location.reload();
        }
        let userPermissions = functions.getUserPermissions();
        let upgrade_subscription= functions.getUserPermissions().issueraccountuserpermission && functions.getUserPermissions().issueraccountuserpermission.upgrade_subscription ? functions.getUserPermissions().issueraccountuserpermission.upgrade_subscription : '0';
        let issuerRole = functions.getIssuerRole();
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        {/* <button type="button" className="close" data-dismiss="modal" onClick={() => this.redirectToSubscription()}>&times;</button> */}
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        <p className="modal_body_haeding">{T("Cannot change subscription")}</p>
                        {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && upgrade_subscription == '1')) &&
                            <div className="receipt_section">
                                <p>{T("You have too many active passes, Pass Template or Pass Template types")}.</p>
                                <p>{T("Please contact sales@smartsolutions.is to change subscription")}.</p>
                            </div>
                        }
                        {(issuerRole == 'Issuer-Account-User' && upgrade_subscription == '0') &&
                            <div className="receipt_section">
                                <p>{T("You do not have permission to change subscription")}</p>
                            </div>
                        }
                        <input type="button" className="btn btn-default submit_btn" value={T("Got it")} data-dismiss="modal" />
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>

            </div>

        )
    }
}