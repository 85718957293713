import React from 'react';
import MUIDataTable from "mui-datatables";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { api } from "../../api";
import { functions } from "../../functions";
import 'react-confirm-alert/src/react-confirm-alert.css';
import SamplePass from '../../assets/images/sampleMember.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { Constants, Validation_Constants } from '../../config';
import { findDOMNode } from 'react-dom';
import CustomToolbarSelect from "./CustomToolbarSelect";

let data = [];
let key = 0;
export default class MemberTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            allMembersList: [],
            deactivatedMembersList: [],
            selectedMembers: [],
            rowsSelected: [],
            emailSubject: '',
            emailBody: '',
            errors: [],
            key: key
        };
        this.validateEmailForm = this.validateEmailForm.bind(this);
    }
    changeStatus = (id, status, template_id) => {
        //event.preventDefault();
        var msgtext = status == 'Activated' ? 'deactivate' : 'activate';
        var newStatus = status == 'Activated' ? 0 : 1;
        confirmAlert({
            title: 'Confirm action',
            message: 'Are you sure to ' + msgtext + ' this member.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        var PostData = { access_token: functions.getAccessToken(), 'member_id': id, 'template_id': template_id, 'member_status': newStatus };
                        this.setState({ loader: true })
                        api.changeMemberStatus(PostData)
                            .then(res => {
                                if (res.data.status == 1) {
                                    this.props.newMemberData(res.data.data.membersData)
                                }
                                this.setState({ loader: false })
                            }).catch(err => {
                                this.setState({ loader: false })
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => ''
                }
            ]
        })
        this.setState({ loader: true });
    }

    sendEmail(selectedData, type) {
        let newArray = [];
        if (type == "singleEmail") {
            data.map((val, key) => {
                if (val.id == selectedData[0]) {
                    newArray = [val];
                }
            })
            this.setState({ ...this.state, selectedMembers: newArray })
        } else {
            let membersId = this.state.rowsSelected;
            membersId.map((val) => {
                newArray.push(data[val]);
            })
            this.setState({ ...this.state, selectedMembers: newArray })
        }
    }

    validateEmailForm() {
        let result = true;
        let errors = [];
        if (this.state.emailSubject == '') {
            errors["emailSubject"] = "Please enter the email subject.";
            result = false;
        }
        if (this.state.emailBody == '') {
            errors["emailBody"] = "Please enter the email body.";
            result = false;
        }
        this.setState({
            errors: errors
        })
        return result;
    }

    emailData() {
        if (this.validateEmailForm()) {
            var PostData = { access_token: functions.getAccessToken(), 'member_id': this.state.selectedMembers, 'template_id': data[0].temp_id, 'mail_subject': this.state.emailSubject, 'mail_body': this.state.emailBody };
            this.setState({ loader: true });
            api.emailData(PostData)
                .then(res => {
                    if (res.data.status == 1) {
                        ++key;
                        data = res.data.data.membersData.map((val, key) => {
                            return {
                                id: val.id ? val.id : '',
                                name: val.name ? val.name : "",
                                email: val.email ? val.email : "",
                                mobile: val.phone_number ? val.phone_number : "",
                                ssn: val.ssn ? val.ssn : "",
                                status: val.status == "1" ? "Activated" : "Deactivated",
                                delivered: val.last_delivered ? val.last_delivered : "-",
                                temp_id: val.template_id ? val.template_id : "",
                            };
                        });
                        toast.success(res.data.message, { hideProgressBar: true });
                        //    this.setState({ ...this.state, key: key });
                    } else {
                        toast.error(res.data.message, { hideProgressBar: true })
                    }
                    const el = findDOMNode(this.refs.myModal);
                    $(el).modal("toggle");
                    this.setState({ loader: false, emailSubject: '', emailBody: '' });
                }).catch(err => {
                    this.setState({ loader: false });
                });
        }
    }

    render() {
        let loader = functions.getLoader(this.state.loader);
        const columns = [
            {
                name: "id",
                label: "ID",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false,
                }
            },
            {
                name: "name",
                label: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "email",
                label: "Email",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "mobile",
                label: "Mobile",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "ssn",
                label: "Ssn",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "status",
                label: "Status",
                options: {
                    filter: true,
                    sort: false,
                }
            },
            {
                name: "delivered",
                label: "Delivered",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (tableMeta.rowData[9] == 'delivered') {
                            return (
                                <span className={"mail-" + tableMeta.rowData[9]} title={tableMeta.rowData[9]}>{tableMeta.rowData[8]}</span>
                            );
                        } else if (tableMeta.rowData[9] == 'deferred' || tableMeta.rowData[9] == 'bounce') {
                            return (
                                <span className={"mail-" + tableMeta.rowData[9]} title={tableMeta.rowData[9]}>{tableMeta.rowData[8]} <i class="fas fa-exclamation-circle"></i></span>
                            );
                        } else {
                            return (
                                <span className={"mail-other"} title={tableMeta.rowData[9]}>{tableMeta.rowData[8]}</span>
                            );
                        }
                    }
                }
            },
            {
                name: "temp_id",
                label: "Temp_ID",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false,
                }
            },
            {
                name: "delivered_data",
                label: "Temp_ID",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false,
                }
            },
            {
                name: "delivered_status",
                label: "Temp_ID",
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false,
                }
            },
            {
                name: "",
                label: "",
                options: {
                    filter: false,
                    sort: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (tableMeta.rowData && tableMeta.rowData[5] == "Activated") {
                            return (
                                <button className="cmn-btn" onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0], tableMeta.rowData[5], tableMeta.rowData[7]);
                                }}>deactivate</button>
                            );
                        } else {
                            return (
                                <button className="cmn-btn activate" onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0], tableMeta.rowData[5], tableMeta.rowData[7]);
                                }}>activate</button>
                            );
                        }
                    }
                }
            },
            {
                name: "",
                label: "",
                options: {
                    filter: false,
                    print: false,
                    sort: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <a href="javascript:void(0)" onClick={() => {
                                this.sendEmail(tableMeta.rowData, "singleEmail");
                            }} data-toggle="modal" data-target="#myModal" >
                                <i className="fas fa-envelope"></i>
                            </a>
                        );


                    }
                }
            },
        ];
        data = this.props.tableData.map((val, key) => {
            return {
                id: val.id ? val.id : '',
                name: val.name ? val.name : "",
                email: val.email ? val.email : "",
                mobile: val.phone_number ? val.phone_number : "",
                ssn: val.ssn ? val.ssn : "",
                status: val.status == "1" ? "Activated" : "Deactivated",
                delivered: val.last_delivered ? val.last_delivered : "-",
                temp_id: val.template_id ? val.template_id : "",
                delivered_data: val.last_delivered ? val.last_delivered : "-",
                delivered_status: val.last_mail_data && val.last_mail_data.sendgrid_mail_event && val.last_mail_data.sendgrid_mail_event == 'delivered' ? 'delivered' : (val.last_mail_data && val.last_mail_data.sendgrid_mail_event && val.last_mail_data.sendgrid_mail_event == 'bounce' ? 'bounce' : (val.last_mail_data && val.last_mail_data.sendgrid_mail_event && val.last_mail_data.sendgrid_mail_event == null ? 'mail-others' : val.last_mail_data.sendgrid_mail_event)),
            };
        });

        let rowsSelected = this.props.optionChanged ? [] : this.state.rowsSelected;
        const options = {
            filterType: 'dropdown',
            responsive: "scroll",
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                const selected = allRows.map(item => item.dataIndex);
                this.setState({
                    rowsSelected: selected,
                }, () => this.props.handleTableAction());
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect />
            ),
        };
        return (
            <div>
                <div className="serach-member-section d-flex blockElement">
                    <div className="input-serach-form">
                    </div>
                    <div className="btn-send-out-passes">
                        <button className={this.state.rowsSelected.length > 0 ? "cmn-btn" : "cmn-btn disabled"} onClick={() => this.sendEmail()} data-toggle="modal" data-target="#myModal" disabled={this.state.rowsSelected.length > 0 ? false : true} >send out passes</button>
                    </div>
                </div>

                <div className="outer-members-table">
                    <div className="members-table">
                        <MUIDataTable
                            //title={"Employee List"}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                        <div className="modal fade" id="myModal" role="dialog" ref="myModal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" id="closeModal">&times;</button>
                                        <h4 className="modal-title">Are you sure you want to send pass to {this.state.selectedMembers.length} {this.state.selectedMembers.length > 1 ? 'members' : 'member'}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="heading-over-top-modal-en blockElement">
                                            <h4>Are you sure you want to send pass to {this.state.selectedMembers.length} {this.state.selectedMembers.length > 1 ? 'members' : 'member'}:</h4>
                                            <p>This is what your member pass will look like:</p>
                                        </div>
                                        <div className="image-section-envelope-image blockElement">
                                            <img src={SamplePass} alt="" />
                                        </div>

                                        <div className="form-section-en-modal blockElement">
                                            <form>
                                                <div className="en-single-fgroup blockElement">
                                                    <label>Email Subject</label>
                                                    <input type="text" placeholder="Subject" onChange={(e) => this.setState({ emailSubject: e.target.value })} />
                                                    {this.state.errors['emailSubject'] &&
                                                        <span className="form-input-error">{this.state.errors['emailSubject']}</span>
                                                    }
                                                </div>
                                                <div className="en-single-fgroup blockElement">
                                                    <label>Email Body</label>
                                                    <textarea placeholder="Message" onChange={(e) => this.setState({ emailBody: e.target.value })}></textarea>
                                                    {this.state.errors['emailBody'] &&
                                                        <span className="form-input-error">{this.state.errors['emailBody']}</span>
                                                    }
                                                </div>
                                                <div className="scroll-table">
                                                    <table style={{ "textAlign": "center" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Mobile</th>
                                                                <th>Ssn</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.selectedMembers.map((val, key) => (
                                                                    <tr key={"table" + key}>
                                                                        <td>{val.name}</td>
                                                                        <td>{val.email}</td>
                                                                        <td>{val.mobile}</td>
                                                                        <td>{val.ssn}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="btn-en-modal">
                                                    <button className="cmn-btn" onClick={() => this.emailData()}>Send Pass</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}