import React from 'react';
import { functions } from "../../functions";
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { touch } from 'redux-form';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class DeleteUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,

        };
    }

    handleClick = () => {
        let modalType = this.props.modalType
        switch (modalType) {
            case 'DistributionListDelete':
                this.deleteDistributeUser();
                break;
            case 'AssocatedUserDelete':
                this.deleteActiveUsers();
                break;
            case 'DeleteScanner':
                this.deleteScanner();
                break;
            case 'DeleteLicenseHolderList':
                this.deleteLicenseHolderList();
                break;
            case 'deleteHolder':
                this.deleteLicenseHolder();
                break;
            default:
                break;
        }
        // if (modalType == "DistributionListDelete") {
        //     this.deleteDistributeUser();
        // } else if (modalType == "AssocatedUserDelete") {
        //     this.deleteActiveUsers();
        // } else if (modalType == "DeleteScanner") {
        //     this.deleteScanner();
        // } else if (modalType == "DeleteLicenseHolderList") {
        //     this.deleteLicenseHolderList();
        // }
        // else if(modalType == "deleteHolder"){
        //     this.deleteLicenseHolder();
        // }
        // else {

        // }
    }
    deleteDistributeUser = () => {
        this.setState({ loader: true });
        let templateid = this.props.currentTempId;
        // let PostData = {
        //     access_token: functions.getAccessToken()
        // };
        toast.dismiss();
        api.deleteDistributeList(templateid)
            .then(res => {
                if (res.data.status == 1) {
                    this.props.parentCompnentMount();
                    // document.getElementById('deleteUser').style.display = "none";
                    toast.success(res.data.message, { hideProgressBar: true });
                    this.props.modalClose("deleteUser");
                    this.setState({ loader: false })
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true });
                    }, 400)

                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })
    }

    deleteActiveUsers = () => {
        this.setState({ loader: true })
        let subIssuerId = this.props.subIssuerid ? this.props.subIssuerid : -1;
        toast.dismiss();
        api.deleteActiveUsers(subIssuerId)
            .then(res => {
                if (res.data.status == 1) {
                    this.props.parentCompnentMount();
                    // document.getElementById('deleteUser').style.display = "none";
                    toast.success(res.data.message, { hideProgressBar: true });
                    this.props.modalClose("AssociatedDelete");
                    this.setState({ loader: false })
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true });
                    }, 400)
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })
    }

    deleteScanner = () => {
        // this.props.modalClose("deleteScanner")
        this.setState({ loader: false });
        let scannerId = this.props.currentScannerId;
        toast.dismiss();
        api.deleteScanner(scannerId)
            .then(res => {
                if (res.data.status == 1) {
                    toast.success(res.data.message, { hideProgressBar: true });
                    // this.props.updateState();
                    this.props.scannerList("deletescanner")
                    this.props.modalClose("deleteUser");
                    this.setState({ loader: false })
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true });
                    }, 400)
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })
    }
    deleteLicenseHolderList = () => {
        this.setState({ loader: false });
        let scannerId = this.props.currentScannerId;
        toast.dismiss();
        api.deleteLicenseHolderList(scannerId)
            .then(res => {
                if (res.data.status == 1) {
                    toast.success(res.data.message, { hideProgressBar: true });
                    // this.props.updateState();
                    // this.props.scannerList()
                    this.props.modalClose("DeleteLicenseHolderList");
                    this.setState({ loader: false })
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true });
                    }, 400)
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })

    }

    deleteLicenseHolder = () => {
        this.setState({ loader: false });
        let scannerUserId = this.props.scannerUserId
        let scannerId = this.props.currentScannerId;
        toast.dismiss();
        api.deleteLicenseHolder(scannerId, scannerUserId)
            .then(res => {
                if (res.data.status == 1) {
                    toast.success(res.data.message, { hideProgressBar: true });
                    // this.props.updateState();
                    // this.props.scannerList()
                    this.props.modalClose("deleteHolder");
                    this.setState({ loader: false })
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true });
                    }, 400)
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            })

    }

    render() {
        let modalType = this.props.modalType;
        let loader = functions.getLoader(this.state.loader);
        return (
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={() => { this.props.modalClose("deleteUser") }} >&times;</button>
                        {modalType == "DistributionListDelete" && <h4 class="modal-title">{T("Delete your user list forever")}?</h4>
                        }
                        {modalType == "AssocatedUserDelete" && <h4 class="modal-title">{T("Delete your active account user forever")}?</h4>
                        }
                        {modalType == "DeleteScanner" && <h4 class="modal-title">{T("Delete your scanner forever")}?</h4>
                        }
                        {modalType == "DeleteLicenseHolderList" && <h4 class="modal-title">{T("Delete license holder list forever")}?</h4>
                        }
                        {modalType == "deleteHolder" && <h4 class="modal-title">{T("Delete license holder forever")}?</h4>
                        }

                    </div>
                    <div class="modal-body">
                        <p>{T("This action cannot be undone")}. <br />{modalType == "DistributionListDelete" ? T("After you delete this list you will never be able to retrieve this list again")+'.' : modalType == "DeleteScanner" ? T("After you delete this scanner you will never be able to retrieve it again")+'.' : modalType == "DeleteLicenseHolderList" ? T("After you delete this license holder list you will never be able to retrieve it again")+'.' : modalType == "deleteHolder" ? T("After you delete this license holder you will never be able to retrive it again")+'.' : ''}</p>
                        {modalType == "DeleteScanner" &&
                            <p> {T("All licenses connected to this scanner will also be deleted")}.</p>
                        }
                        {modalType == "DistributionListDelete" &&
                            <p>{T("We recommend downloading the list before you delete it")}.</p>
                        }
                        {modalType == "deleteHolder" &&
                            <p>{T("The license holder will not have a license to use SmartScanner anymore")}.</p>
                        }
                    </div>
                    <div class="modal-footer">
                        {modalType == "DistributionListDelete" &&
                            <button type="button" id="deletePopupDelete" class="btn btn-default" onClick={() => { $("#members .status_main button[title='Download CSV']").trigger("click"); }}>{T("Download list")}</button>
                        }
                        <div className="deleteBtns">
                            <button type="button" disabled={this.state.loader} id="deleteUserBtn" onClick={() => { this.handleClick() }}>{T("Delete")}</button>
                            <button type="button" id="cancelDeleteUser" class="btn btn-default" onClick={() => { this.props.modalClose("deleteUser") }} data-dismiss="modal">{T("Cancel")}</button>
                        </div>
                    </div>
                    {loader}
                </div>

            </div>

        )
    }
}