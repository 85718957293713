import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import Redirect from 'react-router/Redirect';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import ExifOrientationImg from 'react-exif-orientation-img';
import ReactFileReader from 'react-file-reader';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class AddLicenseHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isbrowsedFile: true,
            browsedFile: {},
            loader: false,
            isUpdateLicenseInfo: false,
            fileData: []
        };
        this.handleFiles = this.handleFiles.bind(this);
    }


    componentDidMount() {
    }



    csvJSON = (csv) => {
        const lines = csv.split('\n')
        const result = []
        let headers = [];
        const csvheaders = lines[0].split(',')
        csvheaders.forEach(element => {
            headers.push(element.replace(/['"]+/g, '').trim());
        });

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue
            const obj = {}
            const trimedline = [];
            const currentline = lines[i].split(',')
            currentline.forEach(element => {
                trimedline.push(element.replace(/['"]+/g, ''));
            });
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = trimedline[j]
            }
            result.push(obj)
        }
        return result
    }

    selectedCsvFile = (files, resetForm, setErrors) => {
        let fileExt = files.length > 0 ? files[0].name.substring(files[0].name.lastIndexOf('.') + 1) : '';
        if (files.length > 0 && files[0].type == "text/csv" || (files.length > 0 && fileExt == 'csv')) {
            resetForm()
            if (document.getElementById("reactFileReaderButton")) {
                document.getElementById("reactFileReaderButton").blur();
            }
            if (document.getElementById('addbrowsedCsvName')) {
                document.getElementById('addbrowsedCsvName').value = files[0].name;
            }
            this.setState({
                isUpdateLicenseInfo: false,
                isbrowsedFile: true,
                browsedFile: files
            })
            if (document.getElementById("addLicenseHolderBtn")) {
                document.getElementById("addLicenseHolderBtn").focus();
            }
        }
        else {
            toast.dismiss();
            if (document.getElementById('addbrowsedCsvName')) {
                document.getElementById('addbrowsedCsvName').value = "";
            }
            this.setState({
                isUpdateLicenseInfo: false,
                isbrowsedFile: false,
                browsedFile: {}
            })
            toast.error("Please select a .csv file to add user", { hideProgressBar: true })
        }
    }

    Contains = (target) => {
        let missingFields = [];
        ['Email'].forEach(function (word) {
            //   value = value + target.toLowerCase().includes(word.toLowerCase());
            if (!target.toLowerCase().includes(word.toLowerCase())) {
                missingFields.push(word);
            }
        });
        return missingFields;
    }

    handleFiles = () => {
        this.setState({ loader: true });
        toast.dismiss();
        var reader = new FileReader();
        let file = this.state.browsedFile;
        reader.onload = function (e) {
            let errors = false;
            let newArray = ((reader.result).split("\n").filter(Boolean));
            let contains = this.Contains(newArray[0]);
            if (contains.length > 0) {
                setTimeout(() => {
                    toast.error(`${contains.toString()} field are missing in file`, { hideProgressBar: true });
                    this.setState({ isbrowsedFile: false, loader: false, browsedFile: {} })
                }, 500);
                if (document.getElementById('addbrowsedCsvName')) {
                    document.getElementById('addbrowsedCsvName').value = '';
                }
            }
            else {
                let allData = this.csvJSON(reader.result);
                this.setState({ loader: false })
                let fileList = [];
                let currentemailList = [];
                if (allData.length < 1) {
                    errors = true;
                    toast.error("CSV file is empty", { hideProgressBar: true })
                } else {
                    let field = {};
                    let numberformat = /^[0-9]*[1-9][0-9]*$/;
                    let mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                    allData.map((value, key) => {
                        ['Email'].map((val) => {
                            if (value[val] && value[val].trim() == '') {
                                toast.error(`${val} is missing in row ${key + 2}`, { hideProgressBar: true })
                                errors = true;
                            } else {
                                if (val == 'Email' && value[val] != '' && value[val] != null) {
                                    if (value[val] && !mailformat.test(value[val].trim())) {
                                        toast.error(`Invalid email in row ${key + 2}`, { hideProgressBar: true })
                                        errors = true;
                                    } else {
                                        field['email'] = value[val] ? value[val].trim() : ''
                                    }
                                } else {
                                    errors = true;
                                }
                                // if (errors != true) {
                                //     field.push(data);
                                // }
                            }
                        })
                        if (errors != true) {
                            fileList.push(field);
                            field = {};
                        }
                    });
                }
                if (errors != true) {
                    this.setState({
                        fileData: fileList,
                        loader: false
                    }, () => { this.addLicenseHolderCsv() })
                }
                else {
                    toast.error("Invalid CSV file format. Please check you file.", { hideProgressBar: true });
                    this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, emailMatched: false })
                    if (document.getElementById('addbrowsedCsvName')) {
                        document.getElementById('addbrowsedCsvName').value = '';
                    }
                }
            }
        }.bind(this)
        reader.readAsText(file[0]);
    }
    addLicenseHolderCsv(props = this.props) {
        if (this.state.fileData.length > 0) {
            toast.dismiss();
            this.setState({ loader: true });
            let PostData = {
                // access_token: functions.getAccessToken(),
                licenseHoldersList: this.state.fileData
            };
            api.addLicenseHolderCsv(PostData, this.props.currentScannerId)
                .then(res => {
                    if (res.data.status == 1) {
                        this.setState({
                            loader: false,
                            browsedFile: [],
                            isbrowsedFile: false,
                            fileData: [],
                        });
                        this.props.modalClose('addLicenseHolder', res.data.data.addedCount);
                    } else {
                        this.setState({
                            loader: false,
                            // membersList: []
                        });
                        toast.error(res.data.message, { hideProgressBar: true })
                    }
                }).catch(err => {
                    toast.error(err.data.message, { hideProgressBar: true })
                    this.setState({ loader: false })
                });
        }
        else {
            toast.error("Invalid CSV file format. Please check you file.", { hideProgressBar: true });
            this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, emailMatched: false })
            if (document.getElementById('addbrowsedCsvName')) {
                document.getElementById('addbrowsedCsvName').value = '';
            }
        }
    }
    updateState = () => {
        if (document.getElementById('addbrowsedCsvName')) {
            document.getElementById('addbrowsedCsvName').value = "";
        }
        this.setState({
            isbrowsedFile: false,
            browsedFile: {}
        })
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let loader = functions.getLoader(this.state.loader);
        return (
            <div className="modal-dialog modal-dialog-centered">
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={(values, { setSubmitting, resetForm, setErrors, setValues }) => {
                        this.setState({ errors: '', loader: false, formSubmit: false });
                        toast.dismiss();
                        var PostData = {
                            // access_token: functions.getAccessToken(),
                            email: values.email.trim(),
                            isUpdateLicenseInfo: this.state.isUpdateLicenseInfo,
                        };
                        api.addLicenseHolder(PostData, this.props.currentScannerId)
                            .then(res => {
                                if (res.data.status == 1) {
                                    this.props.modalClose('addLicenseHolder', 1);
                                }
                                // else if (res.data.status == -1) {
                                //     let user_name_error = '';
                                //     let email_error = '';
                                //     this.setState({ isUpdateLicenseInfo: true })
                                //     for (const [key, value] of Object.entries(res.data.data)) {
                                //         if (key == "user_name") {
                                //             user_name_error = `${value.message} ( ${value.existingValue} )`;
                                //         } else if (key == 'email') {
                                //             email_error = `${value.message}  ( ${value.existingValue} )`
                                //         }
                                //     }
                                //     setErrors({ user_name: user_name_error, email: email_error, number: res.data.message })
                                // } 
                                else {
                                    setErrors({ email: res.data.message })
                                }
                                this.setState({ loader: false })
                            }).catch(err => {
                                this.setState({ loader: false })
                                setTimeout(() => {
                                    toast.error(err.data.message, { hideProgressBar: true })
                                }, 300)
                            })

                        setSubmitting(false);
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string().trim()
                                .email(Validation_Constants.Invalid_Email)
                                .required(Validation_Constants.Required2),
                        })
                    }
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            setErrors,
                            setStatus,
                            resetForm,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;

                        return (
                            <div className="modal-content bg_color_section">
                                <div className="modal-header">
                                    <button type="button" id="close" className="close" data-dismiss="modal">&times;</button>
                                    <h1>{T("Add license holder")}</h1>
                                </div>
                                {!this.state.loader &&
                                    <div className="modal-body">
                                        <h3>{T("You can add multiple license holder by uploading a .csv file")}</h3>
                                        <div className="browsedCsvContainer" id="browsedCsvContainer" ><input placeholder="Browse for your list" readOnly id="addbrowsedCsvName" />
                                            <ReactFileReader handleFiles={(e) => { this.selectedCsvFile(e, resetForm, setErrors) }} fileTypes={'.csv'}>
                                                <button type="button" id="reactFileReaderButton">{T("Browse")}</button>
                                            </ReactFileReader>
                                        </div>
                                        <h4>{T("What is a .csv file? You can download our template")}<button type="button" id="browsedHere" onClick={() => { $("#scanner_distribution_data .status_main1 button[title='Download CSV']").trigger("click"); }}>{T("here")}.</button></h4>
                                        <h2>... {T("or you can add a license holder manually")}</h2>
                                        <div className="wrapper block_element">
                                            <div className="inner-pages block_element">
                                                <div className="container">
                                                    <form autoComplete="off" className="addLicenseHolderForm sign_up_form block_element" onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <div className="floating-label_group">
                                                                <input
                                                                    id="email"
                                                                    type="text"
                                                                    value={values.email}
                                                                    autoComplete="off"
                                                                    placeholder=" "
                                                                    onChange={(event) => {
                                                                        handleChange(event)
                                                                        this.updateState(event)
                                                                    }}
                                                                    onBlur={(event) => {
                                                                        handleBlur(event)
                                                                        this.updateState(event)
                                                                    }}
                                                                    className={
                                                                        errors.email && touched.email ? 'floating-input inputText error' : 'floating-input inputText'
                                                                    }
                                                                />
                                                                <span className="highlight"></span>
                                                                <label>{T("Email address")}</label>
                                                                <div>
                                                                    <div className="error-main">
                                                                        {errors.email &&
                                                                            touched.email && <p className="error">{errors.email}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group-btn">
                                                            <button type="button" id="addLicenseHolderCancel" data-dismiss="modal" onClick={() => { this.updateState(); resetForm(); }}>{T("Cancel")}</button>
                                                            {!this.state.isbrowsedFile &&
                                                                <input type="submit" id="addLicenseHolderBtn" name="" disabled={JSON.stringify(errors) != "{}" || values.email == ""} className={this.state.isbrowsedFile || (!this.state.loader && (JSON.stringify(errors) == "{}") && values.email != "") ? 'isActive' : 'isNotActive'} value={T("Add")} />
                                                            }
                                                            {/* {
                                                                this.state.isUpdateLicenseInfo &&
                                                                <input type="submit" id="addLicenseHolderBtn" name="" className="isActive" value="Add and Update" />
                                                            } */}
                                                            {this.state.isbrowsedFile &&
                                                                <button type="button" onClick={() => { this.handleFiles() }} id="addLicenseHolderBtn" name="" disabled={!this.state.isbrowsedFile} className={this.state.isbrowsedFile || (!this.state.loader && (JSON.stringify(errors) == "{}") && values.email != "") ? 'isActive' : 'isNotActive'} > {T("Add")} </button>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="modal-footer">
                                </div>
                                {loader}
                            </div>
                        );
                    }
                    }
                </Formik >
            </div >
        )
    }
}