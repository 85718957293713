import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import LogoQr from '../../assets/images/logo_Qr.png';
import Redirect from 'react-router/Redirect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';



class AddScanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Add Scanner',
            loader: false,
            formIsValid: false,
            scannerName: '',
            errors: [],
            redirect: false,
            templateList: [],
            fullAccess: false,
        };
    }
    componentDidMount() {
        this.setState({ loader: true });
        api.getScannerTemplateList()
            .then(res => {
                if (res.data && res.data.status == 1) {
                    if (res.data.data && res.data.data.templates) {
                        let templateList = []
                        res.data.data.templates.map((val, key) => {
                            templateList.push({ id: val.id, name: val.name, isChecked: false })
                        })
                        this.setState({
                            templateList: templateList
                        })
                    }
                }
                this.setState({ loader: false })
            })
            .catch(err => {
                this.setState({ loader: false })
            });
    }
    handleSubmit = (event) => {
        toast.dismiss();
        event.preventDefault();
        this.setState({ loader: true })
        // const token = localStorage.getItem('token');
        let templateList = [];
        this.state.templateList.map((val, key) => {
            if (val.isChecked == true) {
                templateList.push(val.id)
            }
        })
        var PostData = {
            // access_token: functions.getAccessToken(),
            allowedTemplate: templateList,
            name: this.state.scannerName.trim(),
            fullAccess: this.state.fullAccess
        };
        api.addScanner(PostData)
            .then(res => {
                // this.setState({ loader: false })
                if (res.data.status == 1) {
                    this.props.parentComponentDidMountFunction('scannerAdded');
                    this.setState({ redirect: true })
                    toast.success(res.data.message, { hideProgressBar: true });
                    $('#addscanner_cancel_btn').trigger('click');
                } else {
                    this.setState({ loader: false })
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true })
                    }, 300)
                }
            }).catch(err => {
                setTimeout(() => {
                    toast.error(err.data.message, { hideProgressBar: true })
                }, 300)
                this.setState({ loader: false })
            });
    }
    handleAllChecked = (event) => {
        let templateList = this.state.templateList
        if (event.target.checked == true) {
            this.setState({ formIsValid: true })
            templateList.forEach(temp => {
                temp.isChecked = event.target.checked
            });
        }
        this.setState({ templateList: templateList, fullAccess: event.target.checked })
    }
    handleCheckChieldElement = (event) => {
        let countChecked = 0
        let countUnchecked = 0
        let templateList = this.state.templateList
        let fullAccess = false;
        templateList.forEach(temp => {
            if (temp.id == event.target.value) {
                temp.isChecked = event.target.checked
            }
            if (temp.isChecked == true) countChecked += 1;
            else countUnchecked += 1;
        })
        countUnchecked < 1 ? $('#checkedAllTemp').prop('checked', true) : $('#checkedAllTemp').prop('checked', false);
        this.setState({ templateList: templateList, formIsValid: countChecked > 0 ? true : false, fullAccess: fullAccess })
    }
    validateForm = (fieldType) => {
        let countChecked = 0
        let formvalid = true;
        let templateList = this.state.templateList
        templateList.forEach(temp => {
            if (temp.isChecked == true) countChecked += 1;
        })
        formvalid = countChecked > 0 ? true : false;
        let errors = {};
        if (fieldType == 'scannername') {
            if (this.state.scannerName == '') {
                formvalid = false;
                errors['scannername'] = "The field is required.";
            }
        }
        this.setState({
            errors: errors,
            formIsValid: formvalid
        })
        return formvalid;
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to='/scanner' />
        }
        functions.changePageTitle(this.state.pagetitle);
        const CurrentTab = functions.getUrlSlice('/', '1');
        let loader = functions.getLoader(this.state.loader);
        return (
            <div id="scanner" className={CurrentTab == 'add_scanner' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                {loader}
                {(this.state.templateList.length <= 0 && !this.state.loader) &&
                    <div className='tab-content tab_background tab_background_scanner_distribution insideScanner block_element' >
                        <div className="empty_add_scanner_tab tab-pane fade in active show">
                            <div className="no_scanner_listed no_and_search_samestyle">
                                <div className="no_listing_text">
                                    <h3>{T("Welcome")}!</h3>
                                    <span>{T("You do not have any template to create scanner")}.</span>
                                </div>
                                <div className="form_btn_group">
                                    <Link className="addscanner_cancel_btn" id="addscanner_cancel_btn" to="/scanner">{T("Back")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(this.state.templateList.length <= 0 && this.state.loader) &&
                    <div className="wrapper block_element">
                        <div className="bg_color_section block_element thanku_page padding_container">
                            <Link to="javascript:void()"><img src={LogoQr} /></Link>
                        </div>
                    </div>
                }
                {this.state.templateList.length > 0 &&
                    <form id="addScannerForm" onSubmit={(e) => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            {/* <label>Subject </label> */}
                            <input className="name_of_scanner_input" placeholder={T("Name of scanner")} autoComplete="off" id="nameofScannerInput" type="text"
                                onBlur={(e) => {
                                    this.setState({
                                        scannerName: e.target.value
                                    })
                                    this.validateForm('scannername')
                                    setTimeout(() => {
                                        this.validateForm('scannername')
                                    }, 5);
                                }}
                                onChange={(e) => {
                                    let error = this.state.errors;
                                    error['scannername'] = "";
                                    this.setState({
                                        scannerNameChange: true,
                                        scannerName: e.target.value,
                                        errors: error,
                                        formIsValid: true
                                    })
                                    this.validateForm('scannername')
                                    setTimeout(() => {
                                        this.validateForm('scannername')
                                    }, 5);
                                }} />
                            <div className="custom-errorMsg">{this.state.errors['scannername']}</div>
                        </div>
                        <div className="form-group">
                            <label class="form-group-container">{T("Allow all templates")}
                                <input id="checkedAllTemp" type="checkbox" checked={this.state.fullAccess} onClick={(e) => { this.handleAllChecked(e) }} value="checkedall" />
                                <span class="checkmark"></span>
                            </label>
                            {
                                this.state.templateList.map((val, key) => {
                                    return (
                                        <label class="form-group-container">{val.name}
                                            <input key={val.id} onClick={(e) => { this.handleCheckChieldElement(e) }} type="checkbox" checked={val.isChecked} value={val.id} />
                                            <span class="checkmark"></span>
                                        </label>
                                    )
                                    // return (<CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...val} />)
                                })
                            }
                        </div>
                        <div className="form_btn_group">
                            <Link className="addscanner_cancel_btn" id="addscanner_cancel_btn" to="/scanner">{T("Cancel")}</Link>
                            <button type="submit" disabled={!this.state.formIsValid || this.state.scannerName == ""} className={!this.state.loader && this.state.formIsValid && this.state.scannerName != "" ? "addscanner_add_btn add_btn_activate" : "addscanner_add_btn add_btn_deactivate"} >{T("Add scanner")} </button>
                        </div>
                    </form>
                }
            </div>
        );
    }
};

export default AddScanner;
