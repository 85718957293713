import React from 'react';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from "react-to-print";
import Select from 'react-select';

let templateOptions = [];
let defaultTemplate;
class passTemplateCodes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: { value: '', label: '' },
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    render() {
        const templateData = this.props.data;
        templateOptions = this.props.data.map((val, key) => {
            return {
                value: val.id,
                label: val.name,
                url: val.qrcode_path,
                distributionLink: val.distributionLink
            }
        })
        defaultTemplate = templateOptions.length > 0 ? { value: templateOptions[0].value, label: templateOptions[0].label, url: templateOptions[0].url, distributionLink: templateOptions[0].distributionLink } : { value: '', label: '', url: '', distributionLink: '' };
        return (
            <div className="col-md-4">
                {templateData.length == 0 &&
                    <div className="single-code-section-tabs blockElement">
                        <div className="single-code-section-heading blockElement">
                            <h5>Pass Template Code</h5>
                        </div>
                        <div className="main-part-codeE-single-section blockElement">
                            <div>
                                <span className="empty-top-section-single-code">You don’t have any pass templates yet</span>

                                <p>Please Contact us<br /> <a href="mailto:support@snjallveskid.is">support@snjallveskid.is</a><br /> to learn more about self services code</p>
                            </div>
                        </div>
                    </div>
                }
                {templateData.length > 0 &&
                    <div className="single-code-section-tabs blockElement">
                        <div className="single-code-section-heading blockElement">
                            <h5>Pass Template Code</h5>
                        </div>
                        <div className="main-part-code-single-section blockElement" ref="templatePrint">
                            <div className="select-code-section blockElement">
                                <Select
                                    value={this.state.selectedOption.value != '' ? this.state.selectedOption : defaultTemplate}
                                    onChange={this.handleChange}
                                    options={templateOptions}
                                    defaultValue={defaultTemplate}
                                />
                            </div>
                            <div className="code-section-codeimage blockElement">
                                <img className="templete-qr-code" src={this.state.selectedOption.value != '' ? this.state.selectedOption.url : defaultTemplate.url} alt="" />
                            </div>
                            <a className="code-url" href={this.state.selectedOption.value != '' ? this.state.selectedOption.distributionLink : defaultTemplate.distributionLink}>{this.state.selectedOption.value != '' ? this.state.selectedOption.distributionLink : defaultTemplate.distributionLink}</a>
                            <div className="buttons-group-code blockElement">
                                <ReactToPrint
                                    trigger={() => <button className="cmn-btn">Print Code</button>}
                                    content={() => this.refs.templatePrint}
                                />
                                <a href={this.state.selectedOption.value != '' ? this.state.selectedOption.url : defaultTemplate.url} download={this.state.selectedOption.value != '' ? this.state.selectedOption.label + '.png' : defaultTemplate.label + '.png'}><button className="cmn-btn">Download Code</button></a>
                            </div>
                            <Link to="/view-all-templates" className="view-more-btn">
                                view all<i className="fas fa-angle-double-right"></i>
                            </Link>
                        </div>
                    </div>
                }
            </div>

        );
    }
};

export default passTemplateCodes;
