import React from 'react';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from "../api";
import { Constants, Validation_Constants, Data_Constants } from '../config';
import { functions } from "../functions";
class AccountSettings extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         pagetitle: 'Account Settings',
         loader: false,
      };
   }


   render() {
      //Page_title
      functions.changePageTitle(this.state.pagetitle);
      //Loader
      let loader = functions.getLoader(this.state.loader);
      return (
         <div className="account-settings-page">
            {loader}
            {/* <!-- account detail page start--> */}

            <section className="sign-in-form profile-detail">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                            {/* <!-- account detail page content start--> */}
                            <div className="create-account about-div">
                                <h1>Account Details</h1>
                                <div className="about-main"> 
                                    {/* <!--left sidebar --> */}
                                    <div className="profile-detail-left about-left col-md-2 col-sm-6">
                                        <ul>
                                            <li><a  data-id="1" href="javascript:;" >User Profile</a></li>
                                            <li><a  href="javascript:;" data-id="2" >Change Password</a></li>
                                            <li><a  data-id="3" href="javascript:;" >Download History</a></li>
                                        </ul>
                                    </div>
                                    {/* <!--end left sidebar -->  */}

                                    {/* <!--right sidebar --> */}
                                    {/* <!-- right inner  -->  */}
                                    {/* <!--user register section --> */}
                                    <div className="col-md-5 col-sm-6">
                                        <h5>Profile</h5>
                                        <label>Member since: <span>02-26-2016</span></label>
                                        {/* <!--profile form --> */}
                                        <div className="profile-from">
                                            <form action="https://www.tcdam.com/account-settings" novalidate="novalidate" id="AccountSettings" method="post" name="AccountSettings" className="AccountSettings">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="firstname" value="Gorguin" name="firstname" className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="lastname" value="Shaikoli" name="lastname" className="form-control" />
                                                </div>
                                                <div id="email-field" className="form-group">
                                                    <label>Email</label>
                                                    <input disabled="" className="form-control" type="email" id="email" value="gshaikoli@tcco.com" name="email" />
                                                </div>
                                                <div id="username-field" className="form-group">
                                                    <label>Username</label>
                                                    <input disabled="" type="text" className="form-control" id="username-first" name="username_first" value="gshaikoli@tcco.com" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Business Unit, Location or Company</label>
                                                    <select name="businessunit" id="businessunit" className="form-control">
                                                        <option value="18" data-id="5">Alabama</option>
                                                        <option value="34" data-id="6">Alaska</option>
                                                        <option value="30" data-id="7">Arizona</option>
                                                        <option value="19" data-id="5">Arkansas</option>
                                                        <option value="35" data-id="6">California</option>
                                                        <option value="45" data-id="9">Wyoming</option>                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Region</label>
                                                    <select id="region_id" name="userRegionId" className="form-control">
                                                        <option selected="" value="4">Northeast</option>                          
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Department</label>
                                                    <select name="department" id="department" className="form-control"> 
                                                        <option value="1">Administrative</option>
                                                        <option value="3">Community / Citizenship</option>
                                                        <option value="4">Engineering</option>
                                                        <option value="5">Estimating</option>
                                                        <option value="6">Finance / Accounting</option>
                                                        <option value="7">Human Resources</option>
                                                        <option value="8">Insurance</option>
                                                        <option value="30">Visitor</option>                    </select>
                                                </div>
                                                <div className="form-group create-text">
                                                    <input type="submit" value="SAVE CHANGES" id="saveChanges" />
                                                    <input type="hidden" name="submitType" value="update_data" />
                                                </div>
                                            </form>
                                        </div>
                                        {/* <!-- end profile form -->  */}
                                    </div>
                                    <div className="col-md-5 col-sm-6">
                                        <h5>Access &amp; Administration</h5>
                                        <div className="pro-access-sec"> 
                                            {/* <!--privileges --> */}
                                            <div className="profile-access-info">
                                                <label>Privilages: </label>
                                                <div className="access-data">
                                                    <p>Access Level:<span className="pri-right">             Super Administrator                                  </span></p>
                                                    <p>View &amp; Download Assets:<span className="pri-right">   Yes                    </span></p>
                                                    <p>Contribute Assets:<span className="pri-right">            Yes                            </span></p>
                                                    <p>Review &amp; Publish Assets:<span className="pri-right">       Yes                      </span></p>
                                                    <p>Update Asset Metadata:<span className="pri-right">    Yes                         </span></p>
                                                    <p>Approve New Members:<span className="pri-right">      Yes                             </span></p>
                                                </div>
                                            </div>

                                            {/* <!-- administration --> */}

                                            <div className="profile-access-info profile-doc2">
                                                <label>Administration: </label>
                                                <div className="access-data ">
                                                    <p>Account Manager: <span className="pri-right">NY Marketing</span></p>
                                                    <p> <span className="pri-right">nymarketing@tcco.com</span></p>
                                                    <div className="profile-admin">Contact your account manager with questions relating to asset uploads,
                                                        asset metadata and other general site inquiries.</div>
                                                </div>
                                            </div>

                                            {/* <!-- administrtion doc2--> */}
                                            <div className="profile-access-info profile-doc2"> 
                                                <div className="access-data ">
                                                    <p>Account Administrator: <span className="pri-right">NY Marketing</span></p>
                                                    <p> <span className="pri-right">nymarketing@tcco.com</span></p>
                                                    <div className="profile-admin profile-admin-top-space">Contact your account administrator with technical difficulty questions, profile
                                                        management and other high level site inquiries.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!--end user register section -->  */}



                                {/* <!--user thank you section -->  */}
                            </div>
                            {/* <!--end right sidebar -->  */}
                        </div>
                    </div>
                    {/* <!-- account detail page content ends-->  */}
                </div>
            </section>

            {/* <!-- account detals ends here --> */}
         </div>
      )
   }
}


export default AccountSettings;
