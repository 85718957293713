import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import MUIDataTable from "mui-datatables";
import RequestConfirmPopup from './RequestConfirmPopup';
import mailDelivered from '../../assets/images/maildelivered.png';
import mailNotDelivered from '../../assets/images/mailnotdelivered.png';
import mailBounce from '../../assets/images/mailbounce.png';
import mailOnTheWay from '../../assets/images/mailontheway.png';
import moment from 'moment';
import DeleteUser from './DeleteUser';
import ActivatePopup from './ActivatePopup';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { T } from 'react-translator-component';


class LicenseHolderTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Scanner',
            loader: false,
            fields: [],
            rowsSelected: [],
            selectedHolderId: -1,
            isLicenseHolderActive:'',
        };
    }
    componentDidMount() {
        // this.props.parentComponentDidMountFunction();
    }

    deleteLicenseHolder = () => {

    }

    changeStatus = (scannerUserId,is_restricted) => {
        let changeStatus = is_restricted == '0' ? false : true;
        this.setState({
            selectedHolderId: scannerUserId,
            isLicenseHolderActive: changeStatus
        })
        $('#changeHolderStatusBtn').trigger('click');

    }

    closeModal = (type, userCount) => {
        if (type == "deleteHolder") {
            this.props.parentComponentDidMountFunction();
            $("#deleteHolder [data-dismiss=modal]").trigger("click");
            this.setState({ selectedHolderId: -1 })
        }
        else if (type == "changeHolderStatus") {
            this.props.parentComponentDidMountFunction();
            $('#changeHolderStatusBtn').trigger('click');
            this.setState({ selectedHolderId: -1 })
        }
    }
    render() {
        let tableData = this.props.licenseHoldersList.map((val, key) => {
            return {
                id: val.id ? val.id : '-',
                is_restricted: val.is_restricted ? val.is_restricted : '1',
                email: val.basicInfo.email ? val.basicInfo.email : "-",
                date_added: val.created_at ? moment(val.created_at.substring(0, val.created_at.indexOf(' '))).format('DD.MM.YYYY') : "-",
            };
        });

        let colName = [
            {
                name: 'id',
                label: 'ID',
                options: {
                    display: false,
                    filter: true,
                    sort: true,
                }
            },
            {
                name: 'is_restricted',
                lable: '',
                options: {
                    display: false,
                }

            },
            {
                name: 'email',
                label: T("Email address"),
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: 'date_added',
                label: T("Date added"),
                options: {
                    filter: true,
                    sort: true,

                }
            },
            {
                name: '',
                label: '',
                options: {
                    filter: false,
                    sort: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (tableMeta.rowData && tableMeta.rowData[1] == "0") {
                            return (
                                <button className="cmn-btn deactivate" onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0],tableMeta.rowData[1]);
                                }}>{T("Deactivate")}</button>
                            );
                        } else if (tableMeta.rowData && tableMeta.rowData[1] == "1") {
                            return (
                                <button className="cmn-btn active" onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0],tableMeta.rowData[1]);
                                }}>{T("Activate")}</button>
                            );
                        }
                    }
                }
            },
            {
                name: '',
                label: '',
                options: {
                    filter: false,
                    sort: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <button className="cmn-btn deactivate" onClick={() => { this.setState({ selectedHolderId: tableMeta.rowData[0] }) }} data-toggle="modal" data-target="#deleteHolder">{T("Delete")}</button>
                        );
                    }
                }
            }
        ];
        const options = {
            filter: false,
            viewColumns: false,
            selectableRows: false,
            disableToolbarSelect: true,
            filterType: 'dropdown',
            responsive: "standard",
            downloadOptions: {
                filename: 'eventTable.csv',
            },
            textLabels: {
                body: {
                    noMatch: "No results found, Please try different search parameters",
                }
            },
        };

        functions.changePageTitle(this.state.pagetitle);
        const CurrentTab = functions.getUrlSlice('/', '1');
        let loader = functions.getLoader(this.state.loader);
        return (
            <div id="license_holder" className={CurrentTab == 'license_holder' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                {loader}
                {/* <!-- No scanner listed --> */}
                <div className="no_scanner_listed no_and_search_samestyle">
                    {this.props.licenseHoldersList.length == 0 &&
                        <div className="no_listing_text">
                            <h3>{T("No license holders listed")}</h3>
                            <span>{T("As soon as a license holder authenticates his scanner you will see them listed here")}.</span>
                        </div>
                    }
                </div>

                {/* <!-- scanner listing --> */}
                {
                    this.props.licenseHoldersList.length > 0 &&
                    <div id="license_holder_data">
                        <div className="status_main">
                            <MUIDataTable
                                //title={"Distribution List"}
                                data={tableData}
                                columns={colName}
                                options={options}
                            />
                        </div>
                    </div>
                }
                <button type="button" data-toggle="modal" data-target="#changeHolderStatus" className="changeHolderStatusBtn" id="changeHolderStatusBtn" style={{ display: 'none' }} ></button>
                <div className="modal changeHolderStatus adduser fade" id="changeHolderStatus" role="dialog" ref="changeHolderStatus">
                    <ActivatePopup currentScannerId={this.props.currentScannerId} scannerUserId={this.state.selectedHolderId} isLicenseHolderActive={this.state.isLicenseHolderActive} modalClose={(type) => { this.closeModal(type) }} />
                </div>
                <div className="modal deleteHolder adduser fade" id="deleteHolder" role="dialog" ref="deleteHolder">
                    <DeleteUser modalType={"deleteHolder"} currentScannerId={this.props.currentScannerId} scannerUserId={this.state.selectedHolderId} modalClose={(type) => { this.closeModal(type) }} />
                </div>
            </div>
        );
    }
};

export default LicenseHolderTab;
