import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import DeleteUser from './DeleteUser';
import 'react-toastify/dist/ReactToastify.css';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


class ScannerTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Scanner',
            loader: false,
            formIsValid: false,
            scannerNameChange: false,
            scannerName: '',
            fullAccess: false,
            errors: [],
            scannerList: []
        };
    }
    componentDidMount() {
        this.setState({
            fullAccess: this.props.fullAccess,
            scannerName: this.props.scannerName,
            scannerList: this.props.scannerList
        })
        // this.props.parentComponentDidMountFunction();
    }

    closeModal = (type) => {
        $(".deleteScanner [data-dismiss=modal]").trigger("click");
    }
    handleSubmit = (event) => {
        toast.dismiss();
        event.preventDefault();
        this.setState({ loader: true })
        // const token = localStorage.getItem('token');
        let currentScannerId = this.props.currentScannerId;
        let scannerList = [];
        this.state.scannerList.map((val, key) => {
            if (val.isChecked == true) {
                scannerList.push(val.id)
            }
        })
        var PostData = {
            // access_token: functions.getAccessToken(),
            allowedTemplate: scannerList,
            name: this.state.scannerName.trim(),
            fullAccess: this.state.fullAccess
        };
        api.updateScanner(PostData, currentScannerId)
            .then(res => {
                this.setState({ loader: false })
                if (res.data.status == 1) {
                    this.setState({ formIsValid: false })
                    this.props.parentComponentDidMountFunction();
                    toast.success(res.data.message, { hideProgressBar: true });
                } else {
                    setTimeout(() => {
                        toast.error(res.data.message, { hideProgressBar: true })
                    }, 300)
                }

            }).catch(err => {
                setTimeout(() => {
                    toast.error(err.data.message, { hideProgressBar: true })
                }, 300)
                this.setState({ loader: false })

            });
    }

    handleAllChecked = (event) => {
        let scannerList = this.state.scannerList
        if(event.target.checked==true){
            this.setState({formIsValid:true})
            scannerList.forEach(temp => {
                temp.isChecked = event.target.checked
            });
        }
        this.setState({ scannerList: scannerList,  fullAccess: event.target.checked })


    }

    handleCheckChieldElement = (event) => {
        let countChecked = 0
        let countUnchecked = 0
        let fullAccess=false;
        let scannerList = this.state.scannerList
        scannerList.forEach(temp => {
            if (temp.id == event.target.value) {
                temp.isChecked = event.target.checked
            }
            if (temp.isChecked == true) countChecked += 1;
            else countUnchecked += 1;
        })
        countUnchecked < 1 ? $('#checkedAllTemp').prop('checked', true) : $('#checkedAllTemp').prop('checked', false);
        this.setState({ scannerList: scannerList, formIsValid: countChecked > 0 ? true : false, fullAccess: fullAccess })
    }
    validateForm = (fieldType) => {
        let countChecked = 0
        let formvalid = true;
        let scannerList = this.state.scannerList
        scannerList.forEach(temp => {
            if (temp.isChecked == true) countChecked += 1;
        })
        formvalid = countChecked > 0 ? true : false;
        let errors = {};
        if (fieldType == 'scannername') {
            if (this.state.scannerName == '') {
                formvalid = false;
                errors['scannername'] = "The field is required.";
            }
        }
        this.setState({
            errors: errors,
            formIsValid: formvalid
        })
        return formvalid;
    }
    render() {
        functions.changePageTitle(this.state.pagetitle);
        // let {scannerName,fullAccess,scannerList}=this.props;
        const CurrentTab = functions.getUrlSlice('/', '1');
        let loader = functions.getLoader(this.state.loader);
        return (
            <div id="scanner" className={CurrentTab == 'scanner' || CurrentTab == 'my_scanner' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                {loader}
                {/* <!-- No scanner listed --> */}
                <div className="no_scanner_listed no_and_search_samestyle">
                    {this.state.scannerList.length == 0 &&
                        <div className="no_listing_text">
                            <h3>{T("Welcome")}!</h3>
                            <span>{T("After you have added scanners you will see them here")}!</span>
                        </div>
                    }
                </div>

                {/* <!-- scanner listing --> */}
                {
                    this.state.scannerList.length > 0 &&
                    <div className="scanner_update_form">
                        <form id="updateScannerForm" onSubmit={(e) => { this.handleSubmit(e) }}>
                            <div className="form-group">
                                {/* <label>Subject </label> */}
                                <input className="name_of_scanner_input" placeholder={T("Name of scanner")} value={this.state.scannerName} autoComplete="off" id="nameofScannerInput" type="text"
                                    onBlur={(e) => {
                                        this.setState({
                                            scannerNameChange: true
                                        })
                                        this.validateForm('scannername')
                                        setTimeout(() => {
                                            this.validateForm('scannername')
                                        }, 5);
                                    }}
                                    onChange={(e) => {
                                        let error = this.state.errors;
                                        error['scannername'] = "";
                                        this.setState({
                                            scannerNameChange: true,
                                            scannerName: e.target.value,
                                            errors: error,
                                            formIsValid: true
                                        })
                                        this.validateForm('scannername')
                                        setTimeout(() => {
                                            this.validateForm('scannername')
                                        }, 5);
                                    }} />
                                <div className="custom-errorMsg">{this.state.errors['scannername']}</div>
                            </div>
                            <div className="form-group">
                                <label class="form-group-container">{T("Allow all templates")}
                                <input id="checkedAllTemp" type="checkbox" checked={this.state.fullAccess} onClick={(e) => { this.handleAllChecked(e) }} value="checkedall" />
                                    <span class="checkmark"></span>
                                </label>
                                {
                                    this.state.scannerList.map((val, key) => {
                                        return (
                                            <label class="form-group-container">{val.name}
                                                <input key={val.id} onClick={(e) => { this.handleCheckChieldElement(e) }} type="checkbox" checked={val.isChecked} value={val.id} />
                                                <span class="checkmark"></span>
                                            </label>
                                        )
                                        // return (<CheckBox handleCheckChieldElement={this.handleCheckChieldElement}  {...val} />)
                                    })
                                }
                            </div>
                            <p>...{T("all issuer templates are displayed here in a list")}</p>
                            <div className="form_btn_group">
                                <button type="submit" disabled={!this.state.formIsValid || this.state.scannerName == ""} className={!this.state.loader && this.state.formIsValid && this.state.scannerName != "" ? "addscanner_add_btn add_btn_activate" : "addscanner_add_btn add_btn_deactivate"} >{T("Update scanner")} </button>
                            </div>
                        </form>
                        <div className="scanner_tab_delete_btn">
                            <button className="scanner_btn delete_scanner" data-toggle="modal" data-target="#deleteScanner">{T("Delete scanner")}</button>
                        </div>
                    </div>
                }
                <div className="modal deleteScanner  adduser fade" id="deleteScanner" role="dialog" ref="deleteScanner">
                    <DeleteUser modalType="DeleteScanner"  scannerList ={(e)=>{this.props.getScannersList(e)}} currentScannerId={this.props.currentScannerId} modalClose={(type)=>{this.closeModal(type)}} />
                </div>
            </div>
        );
    }
};

export default ScannerTab;
