import React from 'react';
import { Link } from 'react-router-dom';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
export default class TermsCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <div className="privacy_policy_page">
                <div className="container">
                    <span>{T("Privacy and cookie policy")}</span>
                    <h3><i>{T("Last update")}: January 16th 2020</i></h3>
                    <h2>1. {T("Introduction")}</h2>
                    <p>{T("This is the Privacy and Cookie Policy of Smart Solutions. These Policies are between you as a User (“you”, or “your”)")};</p>
                    <p>{T("And the legal entity that acts under the applicable laws and regulations as the Data Processor, and in that capacity is responsible for the processing of (personal) data")}:</p>
                    <p><b>{T("Smart Solutions ehf (Company id 680995-2499) Vsk id 81978, Iceland (“we”, “us”, “our”)")}</b></p>
                    <p>{T("We promise to implement the highest standards of privacy and data protection when we design our products and services, offering them to you. For us, the following three key principles play a central role in our privacy and data protection policies")}:</p>
                    <ol type="1">
                        <li>{T("You own your personal data")};</li>
                        <li>{T("We keep your data secure, and work only with parties that maintain the same high level of data security")};</li>
                        <li>{T("We are always transparent about the way we treat your personal data")}.</li>
                    </ol>
                    <p>{T("If you visit our website and use our services, you acknowledge and agree that we store, collect and use your (personal) data in an automated way. We take all appropriate and necessary security measures in order to prevent unauthorized access to, disclosure or modification of all the data we collect and process, and we follow certain organizational procedures to adhere to all privacy and data protection laws that may apply")}.</p>
                    <p>{T("If you have any doubts or questions about our privacy and data protection policies, or if you want to exercise your legal rights, please email us at")}: legal@smartsolutions.is.</p>
                    <h2>2. {T("Your Privacy")}</h2>
                    <p><b>{T("This Privacy and Cookie Policy has been prepared based on multiple legislations for which we take full responsibility, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation), the California Consumer Privacy Act of 2018 and the US Children's Online Privacy Protection Act 1998 (COPPA)")}.</b></p>
                    <p>{T("All information requested by us is obligatory in order to use our services, and your refusal or failure to provide your (personal) data could hinder your access to our services. We may indicate that some specific (personal) data may not be necessary to provide in order to use our services. If you have questions about which personal data we collect, and is mandatory to provide, please request more information at")}: legal@smartsolutions.is.</p>
                    <p>{T("We represent and warrant that we use industry standards to protect your (personal) data. However, you are aware that there is no method of electronic storage that is absolutely safe, and 100% secure on the internet")}.</p>
                    <p>{T("As part of our business practice, we may authorize and appoint third-parties, including but not limited to (technical) service and hosting providers, mail carriers, agents and contractors that handle your (personal) data. Upon request, we will provide the most recent list of all the above-mentioned third-parties")}.</p>
                    <h2>3. {T("Collection and Use")}</h2>
                    <p>{T("We collect and process different types of information and (personal) data necessary in order to provide and improve our services to you")}:</p>
                    <table className="privacy_policy_table">
                        <tbody>
                            <tr>
                                <td>{T("Device ID")}</td>
                                <td>{T("Device Brand and Make")}</td>
                            </tr>
                            <tr>
                                <td>{T("Device Operating System (OS)")}</td>
                                <td>{T("Contact full name")} </td>
                            </tr>
                            <tr>
                                <td>{T("Contact email")}</td>
                                <td>{T("Company name")}</td>
                            </tr>
                            <tr>
                                <td>{T("Contact phone number")}</td>
                                <td>{T("Company ID number")}</td>
                            </tr>
                            <tr>
                                <td>{T("Company invoice email")}</td>
                                <td>{T("Credit card token")}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4><b>{T("Personal Data")}</b></h4>
                    <p>{T("We solely collect and process specific information and (personal) data, when")}:</p>
                    <ol type="a" class="privacy-list">
                        <li>{T("You have given us your consent")}; </li>
                        <li>{T("It is necessary for the performance of a contractual or pre-contractual duty with you")};</li>
                        <li>{T("We have to adhere to a legal obligation to which we are subject")};</li>
                        <li>{T("The handling of (personal) data is carried out in the public interest")};</li>
                        <li>{T("When the handling of (personal) data is for our legitimate interests or those of third-parties")}.</li>
                    </ol>
                    <h4><b>{T("Retention Time")}</b></h4>
                    <p>{T("Your (personal) data shall be stored for as long as the legitimate purpose requires it, typically for six (6) months. However, there are circumstances under which we retain (personal) data for a longer period of time, for example when you have not withdrawn your consent or in case of a legal obligation or judicial order. Once the retention period expires, we may delete your (personal) data permanently, and as a result your right to access, erase and amend your (personal) data cannot be exercised")}. </p>
                    <h4><b>{T("Usage Data and Device Data")}</b></h4>
                    <p>{T("Usage data we collect may include the date and time you logged in, app features you used, searches, click path and pages you visited. In addition we may collect Device data, which may include IP and MAC address, type of device and ID, specific apps settings, app crash reports, browser type and version, language settings, and your geolocation based on Bluetooth, Wi-Fi information and GPS")}.</p>

                    <h2>4. {T("Our Responsibility")}</h2>
                    <h4><b>{T("General")}</b></h4>
                    <p>{T("We, our employees, agents, and affiliates are fully responsible for your personal information. We may also use the services of third-parties and may share your (personal) data with them.Some of these third-parties may be located outside Iceland, namely in the United States or the European Economic Area. In that case, the transfer of (personal) data to these third-parties will only be done after we have evaluated and fully assessed their privacy policies and they are in accordance with the highest standards of personal data protection and all necessary legal safeguards")}.</p>
                    <h4><b>{T("Children’s Protection")}</b></h4>
                    <p>{T("Under no circumstances, persons under the age of thirteen (13) may use our Services or visit our Website according to the US Children's Online Privacy Protection Act 1998 (COPPA). Our services are restricted to Users who are eighteen (18) years of age or older, or an adult  according to the applicable law. We do not allow Users under the age of eighteen (18) on our website and platform and we do not collect and process personal information from anyone under the age of eighteen (18). If you suspect a User is under the age of thirteen (13) or eighteen (18), please report this immediately to us")}.</p>
                    <h4><b>{T("Your Rights")}</b></h4>
                    <p>{T("You may exercise certain rights. You have the right to")}:</p>
                    <ul>
                        <li>{T("Withdraw consent where you have previously given it to the storing and processing of your personal data")};</li>
                        <li>{T("Object to the storing and processing of your (personal) data")};</li>
                        <li>{T("Verify the correctness and accuracy of your (personal) data and ask for updates and corrections")};</li>
                        <li>{T("Demand the full erasure of all of your data from us")};</li>
                        <li>{T("Receive your data in a commonly used and machine-readable format and, have it transmitted to another controller without any hindrance")};</li>
                        <li>{T("Bring a claim before the competent, and national Data Protection Authority (DPA)")}.</li>
                    </ul>
                    <h2>5. {T("Why we process your data")}</h2>
                    <p>{T("Here we explain the exact reasons and purposes why we process your data")}:</p>
                    <ul>
                        <li>{T("To provide services to you")};</li>
                        <li>{T("To communicate with you by telephone and email")};</li>
                        <li>{T("To provide you with information relating to other services")};</li>
                        <li>{T("To grant you access to our services, and specific features of our services")};</li>
                        <li>{T("To give customer support for technical matters")}; </li>
                        <li>{T("To monitor the way you use our service")};</li>
                        <li>{T("To detect and prevent fraud or any other illegal or unauthorized activity")};</li>
                        <li>{T("To evaluate and improve our website, app and services")}; </li>
                    </ul>
                    <h2>6. {T("Third-party controllers and processors")} </h2>
                    <p>{T("We employ third-party service providers and companies with whom we share personal data. Those service providers will only have access to data to perform tasks on our behalf. The third parties that are mentioned in this Privacy and Cookie policy operate their respective services independently from us. You agree and acknowledge you have read and fully understand their respective privacy and data protection policies. We have no control whatsoever over it, and assume no liability for the privacy policies or related practices of any third-party website")}.</p>
                    <h4><b>{T("Third-Party Service Providers")}</b></h4>
                    <div class="privacy_policy_table">
                        <table>
                            <tbody>
                                <tr>
                                    <td><i>{T("Name")} + {T("Company")}</i></td>
                                    <td><i>{T("Sub-Processing Activities")}</i></td>
                                    <td><i>{T("Country of Operations")}</i></td>
                                    <td><i>{T("Link to Privacy Policy")}</i></td>
                                </tr>
                                <tr>
                                    <td><i>{T("Google Analytics (Google LLC)")}</i></td>
                                    <td><i>{T("Visitor’s/User’s data and analytics")}</i></td>
                                    <td><i>USA</i></td>
                                    <td><i><a href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US</a></i></td>
                                </tr>
                                <tr>
                                    <td><i>{T("Apple Pay (In-App Purchase) (Apple Payments Inc")}</i></td>
                                    <td><i>{T("Subscribed User’s data and analytics")}</i></td>
                                    <td><i>USA</i></td>
                                    <td><i><a href="https://support.apple.com/en-us/HT203027">https://support.apple.com/en-us/HT203027</a></i></td>
                                </tr>
                                <tr>
                                    <td><i>{T("Google Pay (Google LLC)")}</i></td>
                                    <td><i>{T("Payment data processing")}</i></td>
                                    <td><i>USA</i></td>
                                    <td><i><a href="https://policies.google.com/privacy?hl=en=US">https://policies.google.com/privacy?hl=en=US</a></i></td>
                                </tr>
                                <tr>
                                    <td><i>{T("Valitor hf.")}</i></td>
                                    <td><i>{T("Payment data processing")}</i></td>
                                    <td><i>Iceland</i></td>
                                    <td><i><a href="https://www.valitor.com/about-us/privacy-notice/">https://www.valitor.com/about-us/privacy-notice/</a></i></td>
                                </tr>
                                <tr>
                                    <td><i>{T("Borgun hf")}</i></td>
                                    <td><i>{T("Payment data processing")}</i></td>
                                    <td><i>Iceland</i></td>
                                    <td><i><a href="https://www.borgun.com/library/Files/L ogfraedi/Borgun%C2%B4s%20Privacy%20Policy.pdf">https://www.borgun.com/library/Files/L ogfraedi/Borgun%C2%B4s%20Privacy%20Policy.pdf</a></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h2 id="cookies">7. {T("Cookies")}</h2>
                    <p>{T("We use cookies and other tracking technologies to monitor all activities on our website and in our app. Cookies are small files with data, containing a so-called anonymous identifier. Cookies can be sent to your browser from a website you visit, and subsequently stored on your computer or phone. You can change your browser settings in order to reject all cookies or to request a notification when a cookie is being sent. However, if you do not accept cookies, you may not be able to use our services")}.</p>
                    <h2>8. {T("Amendments to this Privacy Policy")}</h2>
                    <p>{T("We may amend our Privacy and Cookie Policy from time to time, which changes are effective when they are posted on this page. We advise you to review our Website and this Privacy Policy from time to time")}. </p>
                    <h2>9. {T("Legal Information")}</h2>
                    <p>{T("This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation). And the California Consumer Privacy Act of 2018. This Privacy and Cookie Policy relates solely to Smart Solutions Ehf., if not stated otherwise within this Privacy Policy")}.</p>
                </div>
            </div>
        )
    }
}