import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultUserPng from '../../assets/images/user.png';
import SmartSolutionInnerLogo from '../../assets/images/smart-solution-inner-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { functions } from "../../functions";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import { Constants } from '../../config';
import { Redirect } from 'react-router';
import { api } from '../../api';
import $ from 'jquery';
import EnglishCurrencyFlagsPng from '../../../locale/flags/english.png';
import IcelandCurrencyFlagsPng from '../../../locale/flags/iceland.png';

class UserOuterHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languageMenuShow: false,
            language: localStorage.getItem('rtc-lang') ? localStorage.getItem('rtc-lang') : 'en',
            activeStickyClass: false,
        };
        this.Togglelanugage = this.Togglelanugage.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            let activeStickyClass = true;
            if (window.scrollY === 0) {
                activeStickyClass = false;
            }
            this.setState({ activeStickyClass });
        });
    }
    changeLanguage(value) {
        this.setState({ language: value });
        this.Togglelanugage();
    }
    Togglelanugage(e) {
        this.setState(prevState => ({
            languageMenuShow: !prevState.languageMenuShow
        }));
    }
    render() {
        $(document.body).removeClass('modal-open');
        return (
            // <!-- Header -->
            <div>
                <header className={this.state.activeStickyClass ? "header_login padding_container block_element bg_color_section sticky" : "header_login padding_container block_element bg_color_section"}>
                    <div className="container">
                        <div className="headerInside">
                            <a href="#" id="scroll" style={{}}><span></span></a>
                            <div className="header_logo">
                                {(localStorage.getItem('loginISP_user_sesion') != null)
                                    &&
                                    <Link to="/dashboard">
                                        <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                    </Link>
                                }
                                {(localStorage.getItem('loginISP_user_sesion') == null)
                                    &&
                                    <a href={Constants.introductionPage}>
                                        <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                    </a>
                                }
                            </div>
                            {/* <div>
                                <div className="language_selector">
                                    <div className="current_language" onClick={this.Togglelanugage}>
                                        <img src={this.state.language == 'is' ? IcelandCurrencyFlagsPng : EnglishCurrencyFlagsPng} /> <i className={this.state.languageMenuShow ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                                    </div>
                                    <ul className={this.state.languageMenuShow ? "users show" : "users"}>
                                        <li onClick={() => { this.changeLanguage('en') }}><img src={EnglishCurrencyFlagsPng} /></li>
                                        <li onClick={() => { this.changeLanguage('is') }}><img src={IcelandCurrencyFlagsPng} /></li>

                                    </ul>
                                    <LanguageList Language={this.state.language} Theme="Dropdown" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </header>
                <header className={this.state.activeStickyClass ? "header_login mobile_header padding_container block_element bg_color_section sticky" : "mobile_header header_login padding_container block_element bg_color_section"}>
                    <div className="headerInside">
                        <a href="#" id="scroll" style={{}}><span></span></a>
                        <div className="header_logo">
                            {(localStorage.getItem('loginISP_user_sesion') != null)
                                &&
                                <Link to="/dashboard">
                                    <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                </Link>
                            }
                            {(localStorage.getItem('loginISP_user_sesion') == null)
                                &&
                                <a href={Constants.introductionPage}>
                                    <img src={this.state.language == 'is' ? Constants.SmartSolutionLogoWitTextIs  :Constants.SmartSolutionLogoWitText} />
                                </a>
                            }
                            {/* <div className="language_selector mobile_language_selector">
                                <div className="current_language" onClick={this.Togglelanugage}>
                                    <img src={this.state.language == 'is' ? IcelandCurrencyFlagsPng : EnglishCurrencyFlagsPng} /> <i className={this.state.languageMenuShow ? "fa fa-chevron-up" : "fa fa-chevron-down"}></i>
                                </div>
                                <ul className={this.state.languageMenuShow ? "users show" : "users"}>
                                    <li onClick={() => { this.changeLanguage('en') }}><img src={EnglishCurrencyFlagsPng} /></li>
                                    <li onClick={() => { this.changeLanguage('is') }}><img src={IcelandCurrencyFlagsPng} /></li>

                                </ul>
                                <LanguageList Language={this.state.language} Theme="Dropdown" />
                            </div> */}
                        </div>

                    </div>
                </header>
            </div>
        );
    }
}

export default UserOuterHeader;
