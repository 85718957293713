import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import Redirect from 'react-router/Redirect';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class CancelSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            redirect: false
        };
    }

    cancelSubscription() {
        this.setState({loader:true})
        
        api.cancelSubscription()
            .then(res => {
                if (res.data.status == 1) {
                    let content={
                        token: functions.getToken(),
                            access_token: functions.getAccessToken(),
                            contact_name: functions.getUserName(),
                            company_name: functions.getCompanyName(),
                            subscription_data: res.data.extra_info.issuer,
                            user_permissions: functions.getUserPermissions(),
                    }
                    localStorage.setItem('loginISP_user_sesion',
                        functions.Client(content));
                    this.setState({ redirect: true ,loader:false });
                    toast.success(Validation_Constants.Subscription_Cancellation, { hideProgressBar: true });
                } else {
                    this.setState({loader:false});
                    toast.error(res.data.message, { hideProgressBar: true });
                }
            }).catch(err=>{
                toast.error(Validation_Constants.Something_Went_Wrong, {hideProgressBar:true});
                this.setState({loader:false});
            })
    }

    render() {
        let loader = functions.getLoader(this.state.loader);
        if (this.state.redirect) {
            window.location.reload();
        }
        return (
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg_color_section">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <p class="modal_body_haeding">{T("Are you sure you want to cancel")} <br /> {T("subscription")}?</p>
                        <div class="receipt_section">
                            <p>{T("This will deactivate all of your Pass Templates at")} <br /> {T("end of this month. This means your customers")} <br /> {T("will not be able to use their passes")}!</p>
                        </div>
                        <form>
                            <input type="button" class="btn btn-default submit_btn no_to_passes" value={T("No")} data-dismiss="modal" />
                            <input type="button" class="btn btn-default submit_btn cancel_yes" value={T("Yes")+'!'} disabled={this.state.loader} onClick={() => this.cancelSubscription()} />
                        </form>
                    </div>
                    <div class="modal-footer">

                    </div>
                    {loader}
                </div>

            </div>
        )
    }
}