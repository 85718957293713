import React from 'react';
import { functions } from "../../functions";
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { touch } from 'redux-form';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class TemplateTypeLimit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
        };
    }
    render() {
        let loader = functions.getLoader(this.state.loader);
        return (
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" className="close" data-dismiss="modal" onClick={() => { $(".templateTypeLimitPopup [data-dismiss=modal]").trigger({ type: "click" }); }} >&times;</button>
                        <h4 class="modal-title">{T("Order a Pass Template")}</h4>

                    </div>
                    <div class="modal-body">
                        <p>{T("You can only order a Pass Template build of existing type")}.
                       {T("If you want to order a Pass Template of another type, you have to upgrade your subscription")}. </p>
                    </div>
                    <div class="modal-footer">
                        <div className="Btns">
                            <button className="button_upgrade" onClick={() => {
                                this.props.chooseModal('upgrade_popup')
                            }}>{T("Upgrade")}</button>
                            <button className="button_order" onClick={() => {
                                this.props.chooseModal('order_popup')
                            }}>{T("Order")}</button>
                        </div>
                    </div>
                    {loader}
                </div>
            </div>

        )
    }
}