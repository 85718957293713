import React from 'react';
import '../../assets/css/style.css';
import '../../assets/css/responsive.css'
import UserOuterHeader from './UserOuterHeader';
import UserFooter from './UserFooter';

const UserOuterLayout = ({ children }) => (
    <div className="front_layout home" >
        <div className="main-section">
            <UserOuterHeader />
            {children}
            <UserFooter />
        </div>
    </div>
);

export default UserOuterLayout;
