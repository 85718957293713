import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Reset Password',
            loader: false,
            success: false,
        };
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') != null) { // If unauthrized user
            return <Redirect to='/dashboard' />
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);

        if (this.state.success) {
            return (<Redirect to='/signin' />);
        }

        const key = functions.getParamValuesByName('key');
        if(key==''){
            return (<Redirect to='/signin' />);
        }

        return (
            <Formik
                initialValues={{ password: '', passwordd: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    this.setState({ errors: '', loader: true });
                    toast.dismiss();
                    var PostData = {
                        userRole: "Issuer",
                        password: values.password,
                        password_confirmation: values.passwordd,
                        token: key
                    };
                    api.setNewPassword(PostData)
                        .then(res => {
                            if (res.data.status == 1) {
                                this.setState({ loader: false, success: true });
                                toast.success(res.data.data && res.data.data.message? res.data.data.message:'', { hideProgressBar: true });
                            } else {
                                this.setState({ loader: false });
                                toast.error(res.data.message, { hideProgressBar: true });
                            }
                            this.setState({ loader: false });
                        }).catch(err => {
                            toast.error(err.message, { hideProgressBar: true });
                            this.setState({ loader: false })
                        });
                    setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .min(8, Validation_Constants.Min_Password)
                        .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                        .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                        .max(20, Validation_Constants.Max_Password)
                        .required(Validation_Constants.Empty_NewPassword),
                    passwordd: Yup.string()
                        .required(Validation_Constants.Passwords_Not_Match_Empty)
                        .test('passwords-match', Validation_Constants.Invalid_RePassword, function (value) {
                            return this.parent.password === value;
                        }),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                    } = props;

                    return (
                        //  {/* <!-- Header-END --> */}
                        <div className="wrapper block_element">
                            <div className="inner-pages block_element">
                                <div className="container">
                                    {loader}
                                    <div className="bg_color_section block_element sign_up_page sign_in padding_container">
                                        <h2>{T("Reset password")}</h2>
                                        <form className="sign_up_form block_element" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="password"
                                                        type="password"
                                                        value={values.password}
                                                        placeholder=" "
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.password && touched.password ? 'floating-input inputText error' : 'floating-input inputText'
                                                        }
                                                    />
                                                    {/* <span className="floating-label">New Password</span> */}
                                                    <span className="highlight"></span>
                                                    <label for="password">{T("New password")}</label>
                                                    <div className="error-main">
                                                        {errors.password &&
                                                            touched.password && <p className="error">{errors.password}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="passwordd"
                                                        type="password"
                                                        value={values.passwordd}
                                                        placeholder=" "
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.passwordd && touched.passwordd ? 'floating-input inputText error' : 'floating-input inputText'
                                                        }
                                                    />
                                                    {/* <span className="floating-label">Confirm New Password</span> */}
                                                    <span className="highlight"></span>
                                                    <label for="password">{T("Confirm new password")}</label>
                                                    <div className="error-main">
                                                        {errors.passwordd &&
                                                            touched.passwordd && <p className="error">{errors.passwordd}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group-btn">
                                                <input type="submit" name="" value={T("Reset")} disabled={(JSON.stringify(errors) != "{}") || values.password == "" || values.passwordd == ""} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.password != "" && values.passwordd != "" ? 'isActive' : 'isNotActive'} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        //  {/* <!-- wrapper-end --> */}
                    );
                }}
            </Formik>
        );
    }
};

export default NewPassword;
