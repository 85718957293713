import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Col } from 'reactstrap';
import { Constants, Validation_Constants } from '../../config';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from "../../api";
import { functions } from "../../functions";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            islogin: false,
            isloginerror: false,
            loader: false,
            forgetpassword: false,
            isrecover: false,
            isrecovererror: false,
            passwordresettoken: ''
        };
        this.ForgetPassword = this.ForgetPassword.bind(this);
        this.ResetAll = this.ResetAll.bind(this);
    }

    // Forget Password Form
    getForgotPasswordForm() {
        return (<div className="sign-in pull-right forget_pass_form"><span className="back-btn" title="Back" onClick={this.ResetAll} replace>&#x2190;</span><h4>Forgot your Password?</h4>
            <Formik
                initialValues={{ email: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    //  values.email;
                    this.setState({
                        islogin: false,
                        isloginerror: false,
                        loader: true,
                        isrecover: false,
                        isrecovererror: false
                    });
                    var PostData = { email: values.email };
                    api.forgetPassword(PostData)
                        .then(res => {
                            if (res.data.response.status == '1') {
                                this.setState({ isrecover: true, loader: false });
                            }
                            else {
                                this.setState({ isrecovererror: Validation_Constants.Account_Recover_Error, loader: false });
                            }
                        })
                        .catch(err => {

                            this.setState({ loader: false })
                        });
                    setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().trim()
                        .email(Validation_Constants.Invalid_Email)
                        .required(Validation_Constants.Empty_Email),
                })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                    } = props;


                    return (
                        <form id="form_contact" className="login_form formDetail" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="form-label">Please enter your e-mail address to receive a reset link.</label>
                            </div>
                            <div className="form-group">

                                <input
                                    id="email"
                                    placeholder="Email"
                                    type="text"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.email && touched.email ? 'form-control error' : 'form-control'
                                    }
                                />
                                {errors.email &&
                                    touched.email && <span className="form-input-error">{errors.email}</span>}

                            </div>

                            <button type="submit" className="forget_pass_btn" name="forget_btn" disabled={isSubmitting}>
                                Submit
                     </button>
                            <span className="form-input-error recover_error">{this.state.isrecovererror}</span>
                        </form>
                    );
                }}
            </Formik>
        </div>);
    }

    // Login Form
    getLoginForm() {
        return (<span className="Sign_in_form">
            <div className="sign-in pull-right"><h4> Sign In:</h4>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={(values, { setSubmitting }) => {
                        this.setState({ isloginerror: "", loader: true });

                        var PostData = {
                            email: values.username,
                            password: values.password
                        };
                        api.login(PostData)
                            .then(res => {
                                if (res.data.response.status == '1') {
                                    localStorage.setItem('loginISP_user_sesion', JSON.stringify({ email: values.username, token: res.data.response.data.token }));
                                    this.setState({ islogin: true, loader: false });
                                }
                                else {
                                    this.setState({ isloginerror: true, loader: false });
                                }
                            })
                            .catch(err => {
    
                                this.setState({ loader: false })
                            });

                        setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().trim()
                            .required(Validation_Constants.Empty_Username)
                            .min(2, Validation_Constants.Min_Username)
                            .max(100, Validation_Constants.Max_Username)
                            .email(Validation_Constants.Invalid_Username),
                        password: Yup.string()
                            .required(Validation_Constants.Empty_Password)
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;

                        let content;
                        if (this.state.isloginerror) {
                            content =
                                <span className="try_again">Oh No! Let's <Link to="/" onClick={handleReset} disabled={!dirty || isSubmitting} >try that again....</Link></span>

                        } else {
                            content = "";
                        }
                        return (
                            <form id="form_contact" className="login_form formDetail" onSubmit={handleSubmit}>
                                {content}
                                <div className="form-group">

                                    <input
                                        id="username"
                                        placeholder="Username"
                                        type="text"
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.username && touched.username ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    {errors.username &&
                                        touched.username && <span className="form-input-error">{errors.username}</span>}

                                </div>

                                <div className="form-group">

                                    <input
                                        id="password"
                                        placeholder="Password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.password && touched.password ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    {errors.password &&
                                        touched.password && <span className="form-input-error">{errors.password}</span>}

                                </div>

                                <button type="submit" className="sign-in-btn" disabled={isSubmitting}>
                                    Sign In
                     </button>
                            </form>
                        );
                    }}
                </Formik>
            </div>
            <Link to="#" onClick={this.ForgetPassword}><h5 className="forg">Forgot your DAM password?</h5></Link>
        </span>);
    }

    // Account Recovered Message
    getAccountRecoverMessage() {
        return (<div className="sign-in pull-right forget_pass_form forget_sucess"><h4>Forgot your Password?</h4>
            <div className="form-group">
                <label className="form-label">{Validation_Constants.Account_Recover_Success}</label>
            </div>
            <Link to="/" onClick={this.ResetAll}>
                <input type="submit" className="forget_pass_btn" name="forget_btn" value="Login" />
            </Link>
        </div>);
    }

    // Password reset Form
    getPasswordResetForm() {
        return (
            <span className="Sign_in_form">
                <div className="sign-in pull-right"><h4> Reset your Password?</h4>
                    <Formik
                        initialValues={{ passworddd: '', repasswordd: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            //  values.passworddd;
                            this.setState({ isloginerror: "", loader: true });
                            var PostData = {
                                token: functions.getUrlSlice('/', 2)[1], //get reset token
                                password: values.passworddd,
                                email: 'test@yopmail.com',
                                password_confirmation: values.passworddd,
                            };
                            api.passwordReset(PostData)
                                .then(res => {
                                    if (res.data.response.status == '1') {
                                        this.setState({ loader: false });
                                        alert("password reset sucess\nPlease login now.");
                                    }
                                    else {
                                        this.setState({ loader: false });
                                        alert(Validation_Constants.Expiry_Password_link);
                                    }
                                })
                                .catch(err => {
        
                                    this.setState({ loader: false })
                                });


                            setSubmitting(false);
                        }}
                        validationSchema={Yup.object().shape({
                            passworddd: Yup.string()
                                .required(Validation_Constants.Empty_Password)
                                .min(8, Validation_Constants.Min_Password)
                                .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                                .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                                .max(100, Validation_Constants.Max_Password)
                            ,
                            repasswordd: Yup.string()
                                .required(Validation_Constants.Empty_RePassword)
                                .test('passwords-match', Validation_Constants.Invalid_RePassword, function (value) {
                                    return this.parent.passworddd === value;
                                }),
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                            } = props;

                            let content;
                            if (this.state.isloginerror) {
                                content =
                                    <span className="try_again">Oh No! Let's <Link to="/" onClick={handleReset} disabled={!dirty || isSubmitting} >try that again....</Link></span>

                            } else {
                                content = "";
                            }
                            return (
                                <form id="form_contact" className="password_reset_form formDetail" onSubmit={handleSubmit}>
                                    {content}
                                    <div className="form-group">

                                        <input
                                            id="passworddd"
                                            placeholder="Enter new Password"
                                            type="password"
                                            value={values.passworddd}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.passworddd && touched.passworddd ? 'form-control error' : 'form-control'
                                            }
                                        />
                                        {errors.passworddd &&
                                            touched.passworddd && <span
                                                className="form-input-error">{errors.passworddd}</span>}

                                    </div>

                                    <div className="form-group">

                                        <input
                                            id="repasswordd"
                                            placeholder="Confirm password"
                                            type="password"
                                            value={values.repasswordd}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.repasswordd && touched.repasswordd ? 'form-control error' : 'form-control'
                                            }
                                        />
                                        {errors.repasswordd &&
                                            touched.repasswordd && <span className="form-input-error">{errors.repasswordd}</span>}


                                    </div>

                                    <button type="submit" className="forget_pass_btn" disabled={isSubmitting}>
                                        Submit
                     </button>
                                </form>
                            );
                        }}
                    </Formik>
                </div>
            </span>)
    }

    // For reset all values and forms
    ResetAll(e) {
        // e.preventDefault();
        this.setState({
            islogin: false,
            isloginerror: false,
            loader: false,
            forgetpassword: false,
            isrecover: false,
            isrecovererror: false
        });
        return <Redirect to='/#' replace />
    }
    // if Clicked on forget password
    ForgetPassword(e) {
        e.preventDefault();
        this.setState({
            islogin: false,
            isloginerror: false,
            forgetpassword: true
        });
    }

    render() {

        //Loader
        let loader = functions.getLoader(this.state.loader);

        if (localStorage.getItem('loginISP_user_sesion') != null) {
            return <Redirect to='/dashboard' />
        }
        if (this.state.islogin === true) {
            return <Redirect to='/dashboard' />
        }

        let form;
        //Forgot password form
        if (this.state.forgetpassword) {
            form = this.getForgotPasswordForm();
        }
        else {
            //Login form
            form = this.getLoginForm();
        }

        // Account Recover message
        if (this.state.isrecover) {
            form = this.getAccountRecoverMessage();
        }

        //If password reset request
        var arr = functions.getUrlSlice('/', 2)
        if (arr[0] == "passwordReset" && arr[1].trim() != '') {
            form = this.getPasswordResetForm();
        }

        return (
            <header className="main-header">
                {loader}
                <div className="header-main">
                    <Col md={4} sm={4}>
                        <div className="logo">
                            <Link to="/" onClick={this.ResetAll} replace>
                                <img src={Constants.SiteLogo} alt="logo" />
                            </Link>
                        </div>
                    </Col>
                    <Col md={8} sm={8} className="rgt">
                        {form}
                    </Col>
                </div>
            </header>
        );
    }
}
export default Header;
