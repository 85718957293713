import React from 'react';
import { Constants } from '../config';
import { Redirect } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { date } from 'yup';
import { ECDH } from 'crypto';
import { data } from 'jquery';
const jwt = require('jwt-simple');
const debug_mode = false;
const secret = 'SG.6RQ0Y9JISm-Wa5aZpkF6rCQ.feVPneG2i8-QU7yaIadt05-sQYNND7A9gkObwa-Zf6jFM' // secret key for incryption 

// random secret for incryption 
const randomSalt = () => {
    var length = Math.floor(Math.random() * (60 - 40 + 1)) + 40
    var randomChars = 'ABCDEFGkmnoHIJKLMNOPabcd56789!efghijpqrstuQRSTUVWXYZvwxyz01234$';
    var result = '';
    for (var i = 0; i < length; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length.toString()));
    }
    return result;
}
export const functions = {
    getUserToken,
    getParamValuesByName,
    getUrlSlice,
    changePageTitle,
    getLoader,
    openPopupWindow,
    getToken,
    getAccessToken,
    getUserName,
    getIssuerRole,
    getSubscriptionData,
    getCancellationStatus,
    getCancellationDate,
    getCancellsubscription,
    getCompanyName,
    getInvoiceEmail,
    getActiveSubscription,
    getLocalStorageData,
    checkUnAuthenticated,
    authenticateUser,
    getUserPermissions,
    makeMID,
    makePayment,
    generateBorgunToken,
    getTrialDuration,
    calculatePrice,
    toCurrency,
    imageExists,
    D_Client,
    Client
}


// function to incrpyt json data
function Client(Data) {
    if (debug_mode != true) {
        try {
            let encrypt = jwt.encode(JSON.stringify(Data), secret);
            let encrypt2 = 'eyJ0.' + randomSalt() + 'l' + encrypt.substr(encrypt.indexOf('.') + 1);
            let encrypt3 = jwt.encode(JSON.stringify(encrypt2.toString()), secret);
            return 'eyJ0.' + randomSalt() + 'l' + encrypt3.substr(encrypt3.indexOf('.') + 1);
            // return jwt.encode(JSON.stringify(Data), secret);
        }
        catch (error) {
            return {};
        }
    } else {
        return Data;
    }

}


// function to decrypt encrypted  data
function D_Client(Data) {
    if (debug_mode != true) {
        try {
            let decrypt = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9." + Data.substring(Data.indexOf("l") + 1);
            let decrypt2 = jwt.decode(decrypt, secret, false, 'HS256');
            let decrypt3 = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9." + decrypt2.substring(decrypt2.indexOf("l") + 1);
            let lastChar = decrypt3.charAt(decrypt3.length - 1)
            if (lastChar == '"') {
                decrypt3 = decrypt3.substring(0, decrypt3.length - 1)
            }
            let decrypt4 = jwt.decode(decrypt3, secret, false, 'HS256');
            return decrypt4;
        }
        catch (error) {
            return {};
        }
    } else {
        return JSON.stringify(Data);
    }
}

function imageExists(image_url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status != 404;
}

// get user token from localstorage
function getUserToken(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion') ? localStorage.getItem('loginISP_user_sesion') : '';
    localStorageData=localStorage.getItem('loginISP_user_sesion') != null ? JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.token;
}
//For read query string by name
function getParamValuesByName(querystring) {
    var qstring = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < qstring.length; i++) {
        var urlparam = qstring[i].split('=');
        if (urlparam[0] == querystring) {
            return urlparam[1];
        } else {
            return ''
        }
    }
}

// function to convert currency in iceland format
function toCurrency(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1;
}
//Save user data in localstorage
function authenticateUser(data) {
    let content = {
        token: data.token,
        access_token: data.access_token,
        contact_name: data.contact_name,
        company_name: data.extra_info.issuer.company_name,
        subscription_data: data.extra_info.issuer,
        user_permissions: data.extra_info.issuerAccountUser && data.extra_info.issuerAccountUser[0] ? data.extra_info.issuerAccountUser[0] : {},
        issuer_role: data.role,
    };
    localStorage.setItem('loginISP_user_sesion', Client(content));
}

//For read URL with slice
function getUrlSlice(sep = '/', slice = '') {
    var arr = window.location.href.split(sep)
    return (arr.slice(Math.max(arr.length - slice, 1)));
}

//For change page title per component
function changePageTitle(page_title = 'Issuer Smart Pages | Issuer service page') {
    document.title = 'Issuer Smart Pages | ' + page_title;
}

//For Site Loader
function getLoader(status = false) {
    var loader = '';
    document.body.classList.remove('modal-open');
    if (status) {
        document.body.classList.add('modal-open');
        loader = <div id="loader">
            <img src={Constants.Loader} alt="loading.." />
        </div>
    }
    return (loader);
}

//For open new popup window
function openPopupWindow(e) {
    // alert("==>"+e);
    var n = window.innerHeight, o = window.innerWidth;
    return n -= 14.5 * n / 100, o <= 1366 ? (o -= 24 * o / 100, n -= 23 * n / 100) : o < 1920 ? (n -= 4 * n / 100, o -= 35 * o / 100) : o >= 1920 && (n -= 4 * n / 100, o -= 45 * o / 100), window.open(e, "", "fullscreen=no,toolbar=yes, scrollbars=yes, width = " + o + " ,height= " + n + ",top=57,left=60")
}


//For get Token
function getToken(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.token;
}

//For get Access Token
function getAccessToken(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    if (localStorageData && localStorageData.access_token) {
        return localStorageData.access_token;
    } else {
        return (<Redirect to='/signin' />)
    }
}

//For get User Name
function getUserName(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.contact_name;
}
// get issuer role from localstorage
function getIssuerRole(e) {
    if (localStorage.getItem('loginISP_user_sesion') != null) {
        var localStorageData = localStorage.getItem('loginISP_user_sesion');
        localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
        return localStorageData.issuer_role;
    } else {
        return '';
    }
}
//function to get user permission from localstorage
function getUserPermissions(e) {
    if (localStorage.getItem('loginISP_user_sesion') != null) {
        var localStorageData = localStorage.getItem('loginISP_user_sesion');
        localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
        return localStorageData.user_permissions;
    } else {
        return [];
    }
}

//For get Token
function getSubscriptionData(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData =localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    if (localStorageData != null) {
        return localStorageData.subscription_data;
    }
    else {
        return {};
    }
}

//Get Cancellation status
function getCancellationStatus(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.subscription_data.canceled_subscription;
}

//Get Cancellation status
function getCancellationDate(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.subscription_data.subscription_ends_on;
}
function getCancellsubscription(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.subscription_data.canceled_subscription;
}

//For get Token
function getInvoiceEmail(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.subscription_data.company_email ? localStorageData.subscription_data.company_email : localStorageData.subscription_data.contact_email;
}

//For get Company Name
function getCompanyName(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.company_name;
}

//For get Local Storage Data
function getLocalStorageData(localStorageId) {
    var localStorageData = localStorage.getItem(localStorageId);
    return localStorageData;
}

// function to check user authenticated in api response
function checkUnAuthenticated(data) {
    if (data && data.data) {
        if (data.data.message == "Unauthenticated" || data.data.message == "Unauthenticated.") {
            localStorage.removeItem('loginISP_user_sesion');
            return (<Redirect to='/signin' />)
        }
    }
    else {
        toast.dismiss();
        localStorage.removeItem('loginISP_user_sesion');
        return (<Redirect to='/signin' />)
    }
}

// function to get active subcription of user from localstorage
function getActiveSubscription(e) {
    var localStorageData = localStorage.getItem('loginISP_user_sesion');
    localStorageData = localStorage.getItem('loginISP_user_sesion') != null ?  JSON.parse(D_Client(localStorageData)):'';
    return localStorageData.subscription_data.subscription;
}

function makeMID(length = 12) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
// function for borgun payment
function makePayment(totalPrice, Borgun_Token) {
    var string = {
        "TransactionType": "Sale",
        "Amount": (parseInt(totalPrice)) * 100,
        "Currency": "352",
        "TransactionDate": new Date().toISOString(),
        "PaymentMethod": { "PaymentType": 'TokenMulti', "Token": Borgun_Token },
        "OrderId": this.makeMID(12),
        "Metadata": { "Payload": "string" }
    };
    return fetch(Constants.Borgun_API_PAYMENT,
        {
            method: 'POST',
            body: JSON.stringify(string),
            headers: { "Content-Type": "text/json", 'Authorization': "Basic " + btoa(Constants.Borgun_API_KEY + ':' + Constants.Borgun_API_KEY) }
        })
        .then(function (response) {
            return response.json();
        }).then(data => {
            return data;
        }).catch(function (error) {
            return error;
        });
}

// generate Borgun token for borgun payment api
function generateBorgunToken(PostData = {}) {
    return fetch(Constants.Borgun_API_TOKEN,
        {
            method: 'POST',
            body: JSON.stringify(PostData),
            headers: { "Content-Type": "text/json", 'Authorization': "Basic " + btoa(Constants.Borgun_API_KEY + ':' + Constants.Borgun_API_KEY) }
        })
        .then(function (response) {
            return response.json();
        }).then(function (response) {
            return response;
        }).catch(function (error) {

            return error;
        });
}

// get subscirption days left in Month and Day format
// function getTrialDuration(numberOfDaysLeft) {
//     var d = new Date();
//     let currentMonth = d.getMonth();
//     let currentYear = d.getFullYear();
//     let daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
//     let monthsLeft = 0;
//     while (numberOfDaysLeft > daysInMonth) {
//         numberOfDaysLeft = numberOfDaysLeft - daysInMonth
//         monthsLeft++;
//         currentMonth++;
//         if (currentMonth > 11) {
//             currentMonth = currentMonth - 12;
//             currentYear = currentYear + 1;
//         }
//         daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
//     }
//     return ([monthsLeft, numberOfDaysLeft]);
// }

// get number of day left in days 
function getTrialDuration(numberOfDaysLeft) {
    return numberOfDaysLeft;
}

//Calculate the price based on remaining dates of current plan
function calculatePrice(startDate,dueDate, price) {
    let startingDate = startDate.split(' ');
    startingDate = startingDate[0];
    startingDate = startingDate.split('-')
    startingDate = new Date(startingDate[1]+'/'+startingDate[2]+'/'+startingDate[0]); 
    let lastDate = dueDate.split(' ');
    lastDate = lastDate[0];
    lastDate = lastDate.split('-')
    lastDate = new Date(lastDate[1]+'/'+lastDate[2]+'/'+lastDate[0]);
    let Difference_In_Time = lastDate.getTime() - startingDate.getTime(); 
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
    let currentDate=new Date();
    let Difference_In_Time1 = lastDate.getTime() - currentDate.getTime(); 
    let Difference_In_Days1 = Math.ceil(Difference_In_Time1 / (1000 * 3600 * 24)); 
    let pricePerDay=price/Difference_In_Days;
    let pricek=pricePerDay*Difference_In_Days1;
    //--------------------------------------------------    
    var d = new Date();
    let currentMonth = d.getMonth();
    let currentYear = d.getFullYear();
    let currentDay = d.getDate();
    let daysInMonth = new Date(currentYear, (currentMonth + 1), 0).getDate();
    let days = 0;
    let priceDeducted = 0;
    let costPerDay = 0;

    if (price > 0) {
        dueDate = dueDate.split(' ');
        dueDate = dueDate[0];
        dueDate = dueDate.split('-')
        if (currentYear == dueDate[0]) {
            if ((currentMonth + 1) == (dueDate[1] - 1)) {
                days = currentDay - dueDate[2];
                days = daysInMonth - days;
                costPerDay = price / (new Date(currentYear, currentMonth + 1, 0).getDate());
                // Date(Date.getDate, Date.getMonth + 1, 0)
            } else if ((currentMonth + 1) > (dueDate[1] - 1)) {
                days = dueDate[2] - currentDay;
                costPerDay = price / (new Date(currentYear, currentMonth, 0).getDate());
            }
        } else {
            if (currentMonth == 11) {
                days = currentDay - dueDate[2];
                days = daysInMonth - days;
                costPerDay = price / (new Date(currentYear, currentMonth + 1, 0).getDate());
            }
        }
        priceDeducted = (days * costPerDay);
    }
    // return pricek
    return priceDeducted;
}

export default functions;