import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import StartFreePng from '../../assets/images/start-free.png';
import PopularPng from '../../assets/images/popular.png';
import checkedMark from '../../assets/images/checkedMark.svg';
import OrderPopup from './OrderPopup';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import OrderConfirmationPopup from './OrderConfirmationPopup';
import UpgradeSubscription from './UpgradeSubscription';
import CancelSubscription from './CancelSubscription';
import WarningImg from '../../assets/images/warning.png';
import DowngradePlan from './DowngradePlan';
import VisaCardPng from '../../assets/images/visa.png';
import MasterCardPng from '../../assets/images/mastercard.png';
import OtherCard from '../../assets/images/otherCard.png';
import NumberFormat from 'react-number-format';
import { Redirect } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import CustomToolTip from './CustomToolTip'
import { isMobileOnly } from 'react-device-detect';
import $ from 'jquery';
import ContactUs from './ContactUs';
import { func } from 'prop-types';
import * as Scroll from 'react-scroll';
let Link = Scroll.Link;
let Element = Scroll.Element;
let Events = Scroll.Events;
let scroll = Scroll.animateScroll;
let scrollSpy = Scroll.scrollSpy;
let scroller = Scroll.scroller;

class SubscriptionTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SubscriptionData: [],
            pagetitle: 'Available Subscription',
            loader: false,
            setupPayment: {},
            planId: '',
            totalPasses: 0,
            subscriptionState: {},
            cardInfo: {},
            discountPeriodExpired: false,
            subscriptionName: '',
            subscriptionPaymentType: '0',
            template_price: 19800,
            subscription_discount: 0,

        }
    }

    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        var PostData = {
            access_token: functions.getAccessToken(),
        };
        api.getAccountData()
            .then(res => {
                if (res.data && res.data.data && res.data.data.issuerAccountUser && res.data.data.issuerAccountUser.length > 0 && res.data.data.issuerAccountUser[0]) {
                    let content = {
                        token: functions.getToken(),
                        access_token: functions.getAccessToken(),
                        contact_name: functions.getUserName(),
                        company_name: functions.getCompanyName(),
                        subscription_data: functions.getSubscriptionData(),
                        user_permissions: res.data.data.issuerAccountUser[0],
                        issuer_role: functions.getIssuerRole(),
                    }
                    localStorage.setItem('loginISP_user_sesion',
                        functions.Client(content));
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            });
        this.getSubscriptionData(); // Get Subscription Data
    }

    getSubscriptionData(props = this.props) {
        this.setState({ loader: true });

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getSubscriptionData()
            .then(res => {
                // if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
                //     return <Redirect to='/signin' />
                // }
                let result = [];
                let planId, totalPasses = 0;
                let subscriptionState = {};
                let cardInfo = {};
                let subscriptionName = '';
                let discountPeriodExpired = false;
                let subscriptionPaymentType = '';
                let template_price = 19800;
                let subscription_discount = 0;

                if (res.data && res.data.status == 1) {
                    result = res.data.data ? res.data.data.subscriptions : [];
                    planId = res.data.data ? res.data.data.subscription_id : 0;
                    totalPasses = res.data.passesInSmartwallet;
                    discountPeriodExpired = res.data.data.discountPeriodExpired;
                }
                if (res.data && res.data.data && res.data.data.subscription_status && res.data.data.subscription_status.status == 1) {
                    subscriptionState = res.data.data.subscription_status.data;
                    subscriptionName = res.data.data.subscription_status.data.subscriptionName;
                }
                if (res.data && res.data.data && res.data.data.userPaymentInfo && res.data.data.userPaymentInfo.length > 0) {
                    cardInfo = res.data.data.userPaymentInfo[0];
                }
                this.setState({
                    loader: false,
                    SubscriptionData: result,
                    planId: planId,
                    totalPasses: totalPasses,
                    subscriptionState: subscriptionState,
                    cardInfo: cardInfo,
                    discountPeriodExpired: discountPeriodExpired,
                    template_price: res.data && res.data.data &&  res.data.data.template_price ? res.data.data.template_price:19800,
                    subscription_discount: res.data && res.data.data &&  res.data.data.subscription_discount ? res.data.data.subscription_discount:0,
                    subscriptionName: subscriptionName,
                    subscriptionPaymentType: res.data && res.data.data &&  res.data.data.pay_with_invoice ? res.data.data.pay_with_invoice:'0',
                }, () => {
                    if (this.props.SubscriptionPayment) {
                        scroller.scrollTo('myScrollToElement', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            // containerId: 'ContainerElementID',
                        })
                    }
                })
            })
            .catch(err => {
                toast.error(err.message, { hideProgressBar: true })
                this.setState({ loader: false })
            });
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let activePrice = 0;
        const AccountData = this.props.AccountData;
        const CancellationStatus = functions.getCancellationStatus();
        const CancellationDate = functions.getCancellationDate();
        const CancellationSubscription = functions.getCancellsubscription();
        let percentPasses = 0;
        let colorArea = 100;
        let DiscountPeriod = this.state.subscriptionState && this.state.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.state.subscriptionState.reamainingDaysOfTrailPeriod) : 90;
        // let getTrialDuration = [0, 0];
        let getTrialDuration = 0;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }
        if (this.state.subscriptionState) {
            percentPasses = (Math.round((this.state.subscriptionState.distributedPasses * 100) / this.state.subscriptionState.maxPasses));
            colorArea = 100 - (Math.round((this.state.subscriptionState.distributedPasses * 100) / this.state.subscriptionState.maxPasses));
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);
        let subscriptionData = functions.getSubscriptionData();
        let subscriptionName = this.state.subscriptionName;
        let subscriptionPaymentType = this.state.subscriptionPaymentType;
        //Loader
        let loader = functions.getLoader(this.state.loader);
        let activeSubscriptionSection = (subscriptionData.subscription != 0 && this.state.subscriptionState) ? true : false;
        let idNumber_toolTip = 0;
        let userPermissions = functions.getUserPermissions();
        let issuerRole = functions.getIssuerRole();
        return (
            <div id="subscription" className={this.props.CurrentTab == 'subscription' || this.props.CurrentTab == 'subscriptions' ? 'tab-pane subscription_sec  fade in active show' : 'tab-pane fade in subscription_sec'}>
                {loader}
                {(subscriptionData.subscription == 0) &&
                    <div className="desktop_subscription_table">
                        <div className="subsription_heading padding_container">
                            <h3>{T("Select subscription")}</h3>
                        </div>
                        <div className="padding_container subscription_table">
                            <table className="subscription_single_table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <th key={"name" + key}>{val.name}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className={parseInt(this.state.subscription_discount) > 0?'with_discount':''}>
                                    <tr>
                                        <td>{T("Distribution Limit")}
                                            <CustomToolTip
                                                toolTipText={T("This is the maximum number of valid passes in circulation by your customers") + '.'}
                                                toolTipId={'13'} /> </td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"limit" + key} className={val.offer_price == 0 || val.name == 'Professional' ? "starter" : ''}>
                                                {val.max_passes == -1 ? T("Negotiable") : functions.toCurrency(val.max_passes)}
                                                {/* <NumberFormat value={val.max_passes} displayType={'text'} thousandSeparator={true} /> */}
                                                {/* {val.offer_price == 0 &&
                                                <span><img src={StartFreePng} alt="" /></span>
                                            }
                                            {val.name == 'Professional' &&
                                                <span><img src={PopularPng} alt="" /></span>
                                            } */}
                                            </td>
                                        ))}
                                        {/* <td className="starter">2.000<span><img src={StartFreePng} alt="" /></span></td> */}
                                        {/* <td className="starter">90.000 <span><img src={PopularPng} alt="" /></span></td> */}
                                    </tr>
                                    <tr>
                                        <td>{T("Pass Template Types")}
                                            <CustomToolTip
                                                toolTipText={T("There are 4 different Pass Template types: coupon, ticket, punch and member") + '.'}
                                                toolTipId={'14'} /> </td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"type" + key}>{val.pass_type == -1 ? T("Negotiable") : val.pass_type}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Pass Templates")}
                                            <CustomToolTip
                                                toolTipText={T("We build a Pass Template for you. Your customer personal pass is then made from that Pass Template") + '.'}
                                                toolTipId={'15'} /> </td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"temp" + key}>{val.pass_template == -1 ? T("Negotiable") : val.pass_template}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Issuer Smartpages")}</td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"ISP" + key}>{val.issuer_smartpages == -1 ? T("Negotiable") : val.issuer_smartpages > 1 ? val.issuer_smartpages + " " + T("users") : val.issuer_smartpages + " " + T("user")}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Usage Statistics")}</td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"ISP" + key}>{T(Constants.UsageStatistics[val.usage_statistics])}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Smartscanner app")}</td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"SSA" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                            // <td key={"ISP" + key}>{val.smartscanner_app == -1 ? T("Negotiable") : val.smartscanner_app > 1 ? val.smartscanner_app + T("users") : val.smartscanner_app + T("user")}</td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Smartwallet app")}</td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"USS" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td>{T("Customer Self Service")}
                                            <CustomToolTip
                                                toolTipText={T("You don't need any hardware if you use the Customer self service functionality") + "."}
                                                toolTipId={'16'}
                                                toolTipSubText={T("This functionality is only available in the Smartwallet app") + "."} />
                                        </td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"US" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                        ))}
                                    </tr>
                                    <tr className={parseInt(this.state.subscription_discount) > 0 ? "table-price table-price-withdiscount" : "table-price"}>
                                        <td></td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"pricing" + key}>
                                                {val.name == 'Starter' && parseInt(this.state.subscription_discount) == 0 &&
                                                    < span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                                }
                                                {val.name != "Expert" && parseInt(this.state.subscription_discount) > 0 &&
                                                    < span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                                }
                                                {/* <NumberFormat value={val.price} displayType={'text'} thousandSeparator={true} /> */}
                                                {val.name == 'Starter' && parseInt(this.state.subscription_discount) == 0 &&
                                                    <span className="price_kr">ISK {functions.toCurrency(val.offer_price)}</span>
                                                }
                                                {val.name == 'Starter' && parseInt(this.state.subscription_discount) > 0 &&
                                                    <span className="price_kr">ISK {functions.toCurrency(val.price - parseInt(val.price * this.state.subscription_discount / 100))}</span>
                                                }
                                                {val.name != "Expert" && val.name != "Starter" &&
                                                    <span className={parseInt(this.state.subscription_discount) > 0 ? "price_kr ":"price_kr price_without_discount"}>ISK {parseInt(this.state.subscription_discount) > 0 ? functions.toCurrency(val.price - parseInt(val.price * this.state.subscription_discount / 100)) : functions.toCurrency(val.price)}</span>
                                                }
                                                {/* <NumberFormat value={val.offer_price} displayType={'text'} thousandSeparator={true} /> */}
                                                {/* <span className="month_validity">For the first {getTrialDuration[0] > 1 ? (getTrialDuration[0] + " months ") : (getTrialDuration[0] > 0 ? getTrialDuration[0] + 'month' : '')}{getTrialDuration[1] > 1 ? (getTrialDuration[1] + " days ") : (getTrialDuration[1] > 0 ? getTrialDuration[1] + 'day' : '')}!</span> */}
                                                {val.name != "Expert" && val.name == 'Starter' &&
                                                    <span className="month_validity">{T("Monthly for the first three months")}!</span>
                                                    // <span className="month_validity">Monthly for the first {getTrialDuration > 1 ? (getTrialDuration + " days") : (getTrialDuration + " day")}</span>
                                                }
                                                {val.name != "Expert" && val.name != 'Starter' &&
                                                    <span className="month_validity">{T("Monthly")}!</span>
                                                    // <span className="month_validity">Monthly for the first {getTrialDuration > 1 ? (getTrialDuration + " days") : (getTrialDuration + " day")}</span>
                                                }
                                            </td>
                                        ))}

                                    </tr>
                                    <tr className="table-price">
                                        <td>
                                            <span className="free_subscription_text" >
                                                {T("Start FREE plan")}
                                            </span>
                                        </td>
                                        {this.state.SubscriptionData.map((val, key) => (
                                            <td key={"select" + key}>
                                                <button onClick={val.name == "Expert" ? () => { $('#contactus_btn_reset').trigger('click') } : () => { $('input#testback1').trigger('click'); }} className="free_subsription_btns" data-toggle="modal" data-target={val.name == "Expert" ? '#modalcontactus' : "#myOrderPopup" + key}>
                                                    {val.offer_price == 0 ? T("FREE") : val.name == "Expert" ? T("Contact us") : T("Start plan")}
                                                </button>
                                                <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup" + val.id} data-target={"#OrderConfirmation" + val.id} style={{ display: 'none' }}>Open Modal</button>
                                            </td>
                                        ))}
                                    </tr>
                                    {/* <!-- table-price-End --> */}
                                </tbody>
                            </table>
                            {/* <button data-toggle="modal" data-target="#modalcontactus">Contact Us</button> */}
                        </div>
                        <div className="standard-fee-note">
                            {T('subscript_template')}

                        </div>
                        {/* <div className="modal modalcontactus adduser fade" id="modalcontactus" role="dialog" ref="modalcontactus">
                        <ContactUs />
                    </div> */}
                    </div>
                }
                {(subscriptionData.subscription == 0) &&
                    <div className="mobile_subscription_table">
                        <div className="mobile_subscription_table_inside">
                            <h2>{T("Subscription plans for you")}</h2>
                            <h3>{T("Our services are sold by monthly subscription")}</h3>
                        </div>
                        <div className="listingInfo">
                            <p>{T("All of our subscription plans include")}:</p>
                            <ul>
                                <li><div className="listingInfo-list"><img src={checkedMark} />{T("Customer Self Service")}</div>
                                    <CustomToolTip
                                        toolTipText={T("You don't need any hardware if you use the Customer self service functionality") + "."}
                                        toolTipId={'0'}
                                        toolTipSubText={T("This functionality is only available in the Smartwallet app") + "."} />
                                </li>
                                <li>
                                    <img src={checkedMark} />
                                    {T("Smartwallet app")}</li>
                                <li><img src={checkedMark} />{T("Smartscanner app")}</li>
                            </ul>
                        </div>
                        {this.state.SubscriptionData.map((val, key) => (
                            <div className="pachageArea">
                                <h2><div>{val.name}
                                </div></h2>
                                <div className="AreaFull">
                                    <div className="HeadingMain">
                                        {T("Distribution Limit")}
                                        <CustomToolTip
                                            toolTipText={T("This is the maximum number of valid passes in circulation by your customers") + '.'}
                                            toolTipId={idNumber_toolTip = ++idNumber_toolTip} />
                                    </div>
                                    <div className={val.max_passes == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {val.max_passes == -1 ? T("Negotiable") : functions.toCurrency(val.max_passes)}
                                    </div>
                                </div>

                                <div className="AreaFull whiteBG">
                                    <div className="HeadingMain">
                                        {T("Pass Template Types")}
                                        <CustomToolTip
                                            toolTipText={T("There are 4 different Pass Template types: coupon, ticket, punch and member") + '.'}
                                            toolTipId={idNumber_toolTip = ++idNumber_toolTip} />
                                    </div>
                                    <div className={val.pass_type == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {val.pass_type == -1 ? T("Negotiable") : val.pass_type}
                                    </div>
                                </div>

                                <div className="AreaFull ">
                                    <div className="HeadingMain">
                                        {T("Pass Templates")}
                                        <CustomToolTip
                                            toolTipText={T("We build a Pass Template for you. Your customer personal pass is then made from that Pass Template") + '.'}
                                            toolTipId={idNumber_toolTip = ++idNumber_toolTip} />
                                    </div>
                                    <div className={val.pass_template == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {val.pass_template == -1 ? T("Negotiable") : val.pass_template}
                                    </div>
                                </div>

                                <div className="AreaFull whiteBG">
                                    <div className="HeadingMain">
                                        {T("Issuer Smartpages")}
                                    </div>
                                    <div className={val.issuer_smartpages == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {val.issuer_smartpages == -1 ? T("Negotiable") : val.issuer_smartpages > 1 ? val.issuer_smartpages + " " + T("users") : val.issuer_smartpages + " " + T("user")}
                                    </div>
                                </div>
                                <div className="AreaFull">
                                    <div className="HeadingMain">
                                        {T("Usage Statistics")}
                                    </div>
                                    <div className={val.usage_statistics == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {T(Constants.UsageStatistics[val.usage_statistics])}
                                    </div>
                                </div>
                                {/* <div className="AreaFull whiteBG">
                                    <div className="HeadingMain">
                                        {T("Smartscanner app")}
							        </div>
                                    <div className={val.smartscanner_app == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                        {val.smartscanner_app == -1 ? ("Negotiable") : val.smartscanner_app > 1 ? val.smartscanner_app + " "+ T("users") : val.smartscanner_app + " "+T("user")}
                                    </div>
                                </div> */}
                                <span className={val.name != "Expert" ? "_MobileServicePriceSection " : "_ExpertMobileServicePriceSection  _MobileServicePriceSection"}>
                                    {val.name != "Expert" &&
                                        <div className="SubscriptionPlansBoxMobile__TextWrapper-sc-5ufmgf-3">
                                            {val.name == "Starter" && parseInt(this.state.subscription_discount) == 0 &&
                                                <div className="SubscriptionPlansBoxMobile__StrikeThroughPrice">ISK {functions.toCurrency(val.price)}</div>
                                            }
                                            {parseInt(this.state.subscription_discount) > 0 &&
                                                <div className="SubscriptionPlansBoxMobile__StrikeThroughPrice">ISK {functions.toCurrency(val.price)}</div>
                                            }
                                            {val.name == "Starter" && parseInt(this.state.subscription_discount) == 0 &&
                                                <div className="SubscriptionPlansBoxMobile__Price">ISK {functions.toCurrency(val.offer_price)}</div>
                                            }
                                            {val.name == "Starter" && parseInt(this.state.subscription_discount) > 0 &&
                                                <div className="SubscriptionPlansBoxMobile__Price">ISK {functions.toCurrency(val.price - val.price * this.state.subscription_discount / 100)}</div>
                                            }
                                            {val.name != "Starter" &&
                                                <div className="SubscriptionPlansBoxMobile__Price">ISK {parseInt(this.state.subscription_discount) > 0 ? functions.toCurrency(val.price - val.price * this.state.subscription_discount / 100) : functions.toCurrency(val.price)}</div>
                                            }
                                            {val.name == "Starter" &&
                                                <div className="SubscriptionPlansBoxMobile__Text">{T("For the first three months")}!</div>
                                            }
                                            {val.name != "Starter" &&
                                                <div className="SubscriptionPlansBoxMobile__Text">{T("Monthly")}!</div>
                                            }
                                        </div>
                                    }
                                </span>
                                <button onClick={val.name == "Expert" ? () => { $('#contactus_btn_reset').trigger('click') } : () => { $('input#testback1').trigger('click'); }} className="free_subsription_btns" data-toggle="modal" data-target={val.name == "Expert" ? '#modalcontactus' : "#myOrderPopup" + key}>
                                    {val.offer_price == 0 ? T("FREE") : val.name == "Expert" ? T("Contact us") : T("Start plan")}
                                </button>
                                {/* <div className={'table_price_mobile'}>
                                    < span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                    <span className="price_kr">ISK {functions.toCurrency(val.offer_price)}</span>
                                    <span className="month_validity">For the first {getTrialDuration > 1 ? (getTrialDuration + " days") : (getTrialDuration + " day")}</span>
                                </div>
                                <div className={"subscription_table_mobile_btn"}>
                                    <button className="free_subsription_btns" data-toggle="modal" data-target={"#myOrderPopup" + key}>
                                        {val.offer_price == 0 ? 'Free' : 'Select'}
                                    </button>
                                </div> */}
                            </div>
                        ))}

                        <div className="SubscriptionPlansBoxMobile__CancelText">
                            {T("You can cancel or change your subscription anytime")}.<br />
                            {T("All prices do not include VAT")}.
                        </div>
                        <div className="standard-fee-note">
                            {T('subscript_template')}
                        </div>
                        <div className="modal contactUsModal fade " id="contactUsModal" role="dialog" ref="contactUsModal">
                            <ContactUs />
                        </div>

                    </div>
                }
                {
                    activeSubscriptionSection &&
                    <div>
                        <div className="subsription_heading  padding_container">
                            <h3>{T("Your subscription")}</h3>
                        </div>
                        {/* <Link activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} >sdfdsffsdfdsf
                        </Link> */}
                        <div className="growing_section">
                            <p className="growing_heading">{subscriptionName}</p>
                            {CancellationStatus != 0 &&
                                <p className="alert alert-danger expiry-note"> <img src={WarningImg} />{T("You have requested cancellation of your subscription! It will end on")} &nbsp;<strong>{CancellationDate.split(" ")[0].replace(/\:/g, '.')}</strong> </p>
                            }
                            {subscriptionName != "Expert" && this.state.subscriptionState.reamainingDaysOfTrailPeriod > 0 &&
                                <span className="remaining_days">{this.state.subscriptionState.reamainingDaysOfTrailPeriod} {T("days remaining of your trial period")}</span>
                            }
                            <div className="template_types">
                                <span>{this.state.subscriptionState.passTemplatesTypes} {T("Pass Templates types_subscription")}</span>
                                <span>{this.state.subscriptionState.passTemplates} {T("Pass Templates")}</span>
                            </div>
                            <div className="distribution_limit block_element">
                                {/* <p>Use of distribution limit({this.state.subscriptionState.distributedPassesUser > 1 ? this.state.subscriptionState.distributedPassesUser + " users" : this.state.subscriptionState.distributedPassesUser + " user"})</p> */}
                                <p>{T("Use of distribution limit")}({functions.toCurrency(this.state.subscriptionState.maxPasses)} {T("users")})</p>
                                {percentPasses < 100 &&
                                    <div className="range_slider block_element">
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: colorArea + '%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                <span>{percentPasses}%</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {percentPasses >= 100 &&
                                    <div className="subscription_exceeded block_element">
                                        <button className="subscription_exceeded_btn" data-toggle="modal" data-target={subscriptionName == "Expert" ? '#modalcontactus' : '#UpgradeSubscription'}><span>{T("Subscription exceeded")}. {subscriptionName == "Expert" ? T("Contact us") : T("Upgrade now")}.</span></button>
                                    </div>
                                }
                            </div>

                            <div className="upgrade_subsirption block_element">
                                <button type="button" className={CancellationSubscription > 0 ? "cancel_subscription cancel_subscription_deactivate" : " cancel_subscription"} data-toggle="modal" disabled={CancellationSubscription > 0} data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? '#DowngradePlan' : '#cancel_subscription'} title={CancellationSubscription > 0 ? "You have already deactivate your subscription." : ""}>{T("Cancel subscription")}</button>
                                {subscriptionName != "Expert" &&
                                    <button className="upgrade_subscription_btn" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? '#DowngradePlan' : '#UpgradeSubscription'} >{T("Upgrade subscription")}</button>
                                }
                            </div>
                        </div>
                        <div class="modal fade cancel_subscription" id="cancel_subscription" role="dialog">
                            <CancelSubscription />
                        </div>
                        <div className="modal fade select_subscription" id="UpgradeSubscription" role="dialog">
                            <UpgradeSubscription subscription_discount={this.state.subscription_discount} SubscriptionState={this.state.subscriptionState} SubscriptionData={this.state.SubscriptionData} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                        </div>
                    </div>
                }
                {subscriptionPaymentType == '1' &&
                    <div>
                        <Element name="myScrollToElement">
                            <div className="subsription_heading  padding_container">
                                <h3>{T("Payment method")}</h3>
                                <div className="pay_with_invoice block_element">
                                    <h4>{T("Paid with invoice")}</h4>
                                    <span>{T("You will be billed monthly by invoice")}</span>
                                </div>
                            </div>
                        </Element>
                    </div>
                }
                {
                    (Object.keys(this.state.cardInfo).length > 0 && subscriptionPaymentType == '0') &&
                    <div>
                        <div className="subsription_heading padding_container">
                            <h3>{T("Payment method")}</h3>
                        </div>
                        <Element name="myScrollToElement">
                            <form className="payment_method_form sign_up_form block_element" action="javascript:void(0)">
                                <div className="form-group">
                                    <input type="text" className="inputText pre-filled-form" value={functions.getCompanyName()} required="" placeholder="" />
                                    <span className="floating-label">{T("Company name")}</span>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="inputText pre-filled-form" value={this.state.cardInfo.card_holder_name} required="" placeholder="" />
                                    <span className="floating-label">{T("Card holder name")}</span>
                                    <p className="error"></p>
                                </div>
                                <div className="form-group card-detail">
                                    <img src={this.state.cardInfo.card_type == 'Mastercard' ? MasterCardPng : (this.state.cardInfo.card_type == 'Visa' ? VisaCardPng : MasterCardPng)} alt={this.state.cardInfo.card_type} className="cardTypePng" />
                                    <span class="card_number_value">.... .... ....&nbsp;</span>
                                    <input type="text" className="inputText pre-filled-form" value={this.state.cardInfo.card_number} required="" placeholder="" />
                                    <span className="floating-label">{T("Card number")}</span>
                                    <p className="error"></p>
                                </div>
                                <div className="form-group form-group-half">
                                    <input type="text" className="inputText pre-filled-form" value={this.state.cardInfo.expire_month + '/' + this.state.cardInfo.expire_year} required="" placeholder="" />
                                    <span className="floating-label">{T("Valid thru")} (MM/YY)</span>
                                    <p className="error"></p>
                                </div>
                                <div className="form-group form-group-half padding_right_zero">
                                    <input type="text" className="inputText pre-filled-form" value="" placeholder="CVC" required="" />
                                    {/* <span className="floating-label">CVC</span> */}
                                    <p className="error"></p>
                                </div>
                                <div className="form-group-btn">
                                    <button className="upgrade_subscription_btn" onClick={() => { $('input#testback1').trigger('click'); }} data-toggle="modal" data-target="#myOrderPopup">
                                        {T("Change method")}
                                    </button>
                                    <div className="modal fade myOrderPopup" id="myOrderPopup" role="dialog" >
                                        <OrderPopup subscriptionPaymentType={this.state.subscriptionPaymentType} changeData={this.state.cardInfo} />
                                    </div>
                                </div>
                            </form>
                        </Element>
                    </div>
                }
                {
                    this.state.SubscriptionData.map((val, key) => (
                        <div>
                            <div className="modal fade myOrderPopup" id={"myOrderPopup" + key} role="dialog" >
                                <OrderPopup subscriptionPaymentType={this.state.subscriptionPaymentType} subscription_discount={this.state.subscription_discount} template_price={this.state.template_price} OrderData={val} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} SubscriptionState={this.state.subscriptionState} activePrice={activePrice = val.id == this.state.subscriptionState.id ? val.price : activePrice} />
                            </div>
                            <div className="modal orderConfirmModal fade" id={"OrderConfirmation" + val.id} role="dialog">
                                <OrderConfirmationPopup subscription_discount={this.state.subscription_discount} template_price={this.state.template_price} OrderData={val} SubscriptionState={this.state.subscriptionState} DiscountPeriodExpired={this.state.discountPeriodExpired} />
                            </div>
                        </div>
                    ))
                }
                <div className="modal downgradePlan fade" id="DowngradePlan" role="dialog">
                    <DowngradePlan />
                </div>
            </div >
        );
    }
};

export default SubscriptionTab;
