import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import ReactFileReader from 'react-file-reader';
import MemberTable from './MemberTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select';
import moment from 'moment';
import SampleCSV from '../../assets/images/members.csv'
import LogoQr from '../../assets/images/logo_Qr.png';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { object } from 'prop-types';
import $ from "jquery";
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router';
import Magnifyingglass from '../../assets/images/Magnifyingglass.png'
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Toolbar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class UsersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Members',
            loader: false,
            optionChanged: false,
            memberTemps: [],
            fileContent: [],
            membersList: [],
            rowsSelected: [],
            templatePassUsers: {},
            selectedOption: {},
            issuerFields: [],
            rowsPerPage: 5,
            jumpPageNumber: 1,
            pageNumber: 0,
            searchedText: '',
            pageIndex: 0,
            totalUsers: 0,
            searchedTextField: false,
            distributedUser: {},
            sortVal: '',
            sortName: '',
            sortFieldId: '',
            isSortFieldId: false,
            templatePassUsersissuerFields: {}
        };
        // this.handleFiles = this.handleFiles.bind(this);
        // this.newMemberData = this.newMemberData.bind(this);
    }
    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        this.setState({
            searchedText: '', pageIndex: 0, jumpPageNumber: 1, rowsPerPage: 5, sortVal: '',
            sortName: '', isSortFieldId: false
        }, () => {
            // this.getTemplateStats();
            this.getTemplateUser();
        })

    }
    getTemplateUser() {
        this.setState({ loader: true })
        let tempId = this.props.currentTempId;
        var PostData = {
            page: this.state.pageIndex,
            pageSize: this.state.rowsPerPage,
            searchTerm: this.state.searchedText,
            sortVal: this.state.sortVal != '' ? this.state.sortVal == 'asc' ? "true" : "false" : '',
            sortName: this.state.isSortFieldId ? this.state.sortFieldId : this.state.sortName,
            isDynamicColumn: this.state.isSortFieldId == true ? 'true' : 'false',
            // tempId: tempId
        };

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getTemplateUser(PostData, tempId)
            .then(res => {
                if (res.data && res.data.status == 1) {
                    this.setState({
                        totalUsers: res.data.data.totalRecords ? res.data.data.totalRecords : 0,
                        templatePassUsers: res.data.data.templatePassUsers && res.data.data.templatePassUsers.data ? res.data.data.templatePassUsers.data : {},
                        templatePassUsersissuerFields: res.data.data.templatePassUsers,
                    })
                }
                this.setState({
                    loader: false,
                });
            })
            .catch(err => {
                this.setState({ loader: false })
            });
    }


    handleTableAction() {
        this.setState({ optionChanged: false })
    }



    changeStatus = (passId, status) => {
        toast.dismiss();
        let msgtext = status == '1' ? 'deactivate' : 'activate';
        let changeStatus = status == '1' ? '0' : '1';
        let PostData = {
            is_pass_activate: changeStatus,
            // access_token: functions.getAccessToken(),
        }
        confirmAlert({
            title: 'Confirm action',
            message: 'Are you sure to ' + msgtext + ' this User.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        api.changeActivationStatus(passId, PostData)
                            .then(res => {
                                if (res.data.status == 1) {
                                    toast.success(res.data.message, { hideProgressBar: true });
                                    // this.props.newMemberData(res.data.data.membersData)
                                }
                                else {
                                    toast.error(res.data.message, { hideProgressBar: true })
                                }
                                this.getTemplateUser();
                                this.setState({ loader: false })
                                // setTimeout(() => {
                                //     window.location.reload();
                                // }, 1000)
                                // window.location.reload();
                            }).catch(err => {
                                this.setState({ loader: false })
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => ''
                }
            ]
        })
    }
    DownloadCsv = () => {
        $("#users button[title='Download CSV']").trigger("click");
    }
    changeToNextPage = () => {
        let count = this.state.pageIndex;
        count += 1;
        this.setState({ pageIndex: count, jumpPageNumber: count + 1 }, () => { this.getTemplateUser() })
    }
    changeToPrevPage = () => {
        let jumbpage = this.state.jumpPageNumber;
        let count = this.state.pageIndex;
        count -= 1;
        this.setState({ pageIndex: count, jumpPageNumber: jumbpage - 1 }, () => { this.getTemplateUser() })
    }
    jumpPageNumberChange = (event) => {
        let value = parseInt(event.target.value);
        if (value < 1) {
            value = 1
        }
        else if (value > Math.ceil(this.state.totalUsers / this.state.rowsPerPage)) {
            value = Math.ceil(this.state.totalUsers / this.state.rowsPerPage)
        }
        this.setState({
            pageIndex: value - 1,
            jumpPageNumber: value
        })
    }
    jumpPageNumber = (event) => {
        let value = parseInt(event.target.value);
        if (Number.isNaN(value)) {
            value = 1
        }
        this.setState({
            pageIndex: value - 1,
            jumpPageNumber: value
        }, () => { this.getTemplateUser() })
    }
    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        //Page_title
        const issuerFields = this.state.templatePassUsersissuerFields;
        const issuerFieldsList = this.state.templatePassUsersissuerFields && this.state.templatePassUsersissuerFields.data && this.state.templatePassUsersissuerFields.data.issuerInputFields ? this.state.templatePassUsersissuerFields.data.issuerInputFields : {}
        let dataColumns = [
            {
                name: 'passId',
                label: 'passId',
                options: {
                    download: false,
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false
                }
            }, {
                name: 'status',
                label: T("Status"),
                options: {
                    filter: true,
                    sort: true,
                    sortDirection: this.state.sortName == 'status' ? this.state.sortVal : "none",
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let label = '';
                        if (tableMeta.rowData && tableMeta.rowData[1]) {
                            if (tableMeta.rowData[1] == 'V') {
                                label = <div>{T("Valid")}</div>
                            } else if (tableMeta.rowData[1] == 'I') {
                                label = <div>{T("Invalid")}</div>
                            } else if (tableMeta.rowData[1] == 'R') {
                                label = <div>{T("Redeemed")}</div>
                            } else if (tableMeta.rowData[1] == "U") {
                                label = <div>{T("Used")}</div>
                            } else if (tableMeta.rowData[1] == "D") {
                                label = <div>{T("Deleted")}</div>
                            } else if (tableMeta.rowData[1] == "E") {
                                label = <div>{T("Expired")}</div>
                            } else if (tableMeta.rowData[1] == "PR") {
                                label = <div>{T("Restricted")}</div>
                            } else {
                                label = <div>{T("Invalid")}</div>
                            }
                            return label;
                        }
                    }
                }
            },
            {
                name: 'activeStatus',
                label: 'activeStatus',
                options: {
                    filter: false,
                    display: false,
                    viewColumns: false,
                    sort: false,
                }
            }];
        let data = [];
        let rowsSelected = this.props.optionChanged ? [] : this.state.rowsSelected;
        const tableData = this.state.templatePassUsers && this.state.templatePassUsers.templatePassUsers ? this.state.templatePassUsers.templatePassUsers : [];
        const optionTemplates = this.state.memberTemps.map((val, key) => {
            return {
                value: val.id,
                label: val.name + "- " + val.membersCount + (val.membersCount > 1 ? " MEMBERS" : " MEMBER")
            }
        })
        let valueInQuestion = '';
        let count = 0;
        if (this.state.templatePassUsers && this.state.templatePassUsers.templatePassUsers) {
            if (issuerFields && issuerFields.data && issuerFields.data.issuerInputFields && Object.keys(issuerFields.data.issuerInputFields).length > 0 && this.state.templatePassUsers.templatePassUsers.length > 0) {
                // for (const [key, value] of Object.entries(issuerFields.data.issuerInputFields)) {
                issuerFields.data.issuerInputFields.map(value => {
                    this.state.templatePassUsers.templatePassUsers.map((val, memkey) => {
                        val.issuerInputFields.map(datavalue => {
                            if (value.id == datavalue.field_id && datavalue.field_lable !== 'UN-númer') {
                                valueInQuestion = datavalue.field_value ? datavalue.field_value : "-";
                            }
                        })
                        if (count == 0) {
                            let number9 = '-';
                            let number10 = '-';
                            if (val.UnNumer && Object.keys(val.UnNumer).length > 0) {
                                if (val.UnNumer.TANKAR && Object.keys(val.UnNumer.TANKAR).length>0) {
                                    number9=''
                                    let count = Object.keys(val.UnNumer.TANKAR).length;
                                    for (const [key, value] of Object.entries(val.UnNumer.TANKAR)) {
                                        if(value!=''){
                                            if (parseInt(key) !== parseInt(count)) {
                                            number9 += value + '/n';
                                        } else {
                                            number9 += value;
                                        }
                                    }
                                    }
                                }
                                if (val.UnNumer.ANNAD && Object.keys(val.UnNumer.ANNAD).length>0) {
                                    number10='';
                                    let count = Object.keys(val.UnNumer.ANNAD).length
                                    for (const [key, value] of Object.entries(val.UnNumer.ANNAD)) {
                                       if(value!=''){ 
                                        if (parseInt(key) !== parseInt(count)) {
                                            number10 += value + '/n';
                                        } else {
                                            number10 += value;
                                        }
                                    }
                                    }
                                }
                            }

                            data.push({
                                number9: number9,
                                number10: number10,
                                passId: val.passId ? val.passId : "0",
                                status: val.passStatus ? val.passStatus : "",
                                activeStatus: val.isActivePass ? val.isActivePass : "",
                                created_at: val.isCreatedAt && val.isCreatedAt.date ? moment(val.isCreatedAt.date.substring(0, val.isCreatedAt.date.indexOf(' '))).format('DD.MM.YYYY') : "-",
                                last_update_request_received: val.updateRequest ? moment(val.updateRequest.substring(0, val.updateRequest.indexOf(' '))).format('DD.MM.YYYY') != '01.01.1970' ? moment(val.updateRequest.substring(0, val.updateRequest.indexOf(' '))).format('DD.MM.YYYY') : '-' : "-",
                                pass_updated: val.passUpdated ? moment(val.passUpdated.substring(0, val.passUpdated.indexOf(' '))).format('DD.MM.YYYY') != '01.01.1970' ? moment(val.passUpdated.substring(0, val.passUpdated.indexOf(' '))).format('DD.MM.YYYY') : '-' : "-",
                                [value.value]: valueInQuestion
                            })
                        } else {
                            data[memkey][value.value] = valueInQuestion
                        }
                        valueInQuestion = '-';
                    })
                    count++;
                })
            } else {
                this.state.templatePassUsers.templatePassUsers.map(val => {
                    data.push({
                        passId: val.passId ? val.passId : "0",
                        status: val.passStatus ? val.passStatus : "",
                        activeStatus: val.isActivePass ? val.isActivePass : "",
                        created_at: val.isCreatedAt && val.isCreatedAt.date ? moment(val.isCreatedAt.date.substring(0, val.isCreatedAt.date.indexOf(' '))).format('DD.MM.YYYY') : "-",
                        last_update_request_received: val.updateRequest ? moment(val.updateRequest.substring(0, val.updateRequest.indexOf(' '))).format('DD.MM.YYYY') != '01.01.1970' ? moment(val.updateRequest.substring(0, val.updateRequest.indexOf(' '))).format('DD.MM.YYYY') : '-' : "-",
                        pass_updated: val.passUpdated ? moment(val.passUpdated.substring(0, val.passUpdated.indexOf(' '))).format('DD.MM.YYYY') != '01.01.1970' ? moment(val.passUpdated.substring(0, val.passUpdated.indexOf(' '))).format('DD.MM.YYYY') : '-' : "-",
                    })
                })
            }
        } else {
            data = [];
        }
        const defaultValue = optionTemplates.length > 0 ? { label: optionTemplates[0].label, value: optionTemplates[0].value } : {};
        // functions.changePageTitle(this.state.pagetitle);
        if (issuerFields && issuerFields.data && issuerFields.data.issuerInputFields && Object.keys(issuerFields.data.issuerInputFields).length > 0) {
            // for (const [key, val] of Object.entries(issuerFields.data.issuerInputFields)) {
            issuerFields.data.issuerInputFields.map(val => {
                val = val.value.trim();
                if (val == 'Image') {
                    dataColumns.push({
                        name: val,
                        label: val,
                        options: {
                            filter: true,
                            sort: false,
                            // sortDirection: this.state.sortName==val? this.state.sortVal : "none",
                            customBodyRender: (value, tableMeta, updateValue) => {
                                if (tableMeta.rowData) {
                                    if (value != "-") {
                                        return <img src={Constants.Public_Path + value} width="100px" height="100px" />
                                    } else {
                                        return <label title="Not Available">-</label>
                                    }
                                }
                            }
                        }
                    })
                }

                else if (val == 'nafn') {
                    dataColumns.push({
                        name: val,
                        label: '2.EIGINNAFN 1.KENNINAFN',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'faedingardagur') {
                    dataColumns.push({
                        name: val,
                        label: '3. FÆÐINGADAGUR',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'utgafudagur') {
                    dataColumns.push({
                        name: val,
                        label: '4A. ÚTGÁFUDAGUR',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'gildistimaLikur') {
                    dataColumns.push({
                        name: val,
                        label: ' 4B. GILDIR TIL',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'kennitala') {
                    dataColumns.push({
                        name: val,
                        label: '4D. KENNITALA',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'numerSkirteinis') {
                    dataColumns.push({
                        name: val,
                        label: '5. NÚMER SKÍRTEINIS',
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'mynd') {
                    dataColumns.push({
                        name: val,
                        label: 'MYND',
                        options: {
                            filter: true,
                            sort: false,
                            // sortDirection: this.state.sortName==val? this.state.sortVal : "none",
                            customBodyRender: (value, tableMeta, updateValue) => {
                                if (tableMeta.rowData) {
                                    if (value != "-") {
                                        return <img src={Constants.Public_Path + value} width="100px" height="100px" />
                                    } else {
                                        return <label title="Not Available">-</label>
                                    }
                                }
                            }
                        }
                    })
                }
                else if (val == 'okurettindaflokkar') {
                    dataColumns.push({
                        name: val,
                        label: '6. RÉTTINDAFLOKKAR',
                        options: {
                            filter: true,
                            sort: false,
                            // sortDirection: this.state.sortName==val? this.state.sortVal : "none",
                        }
                    })
                }
                else if (val == 'Vinnuvéla réttindi') {
                    dataColumns.push({
                        name: 'Vinnuvéla réttindi',
                        label: 'Vinnuvéla réttindi',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                var ar = value.split('\n\n')
                                return (
                                    <ul className="fullColumnWidthHeavyMachine">
                                    {ar.map(val=>{
                                            return (
                                                   <li>{val}</li> 
                                            )
                                        })}
                                    </ul>
                                )                                                          
                            }
                        }
                    })
                }
                else if (val == 'UN-númer') {
                    dataColumns.push({
                        name: 'number9',
                        label: '9. Tankar',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                var ar = value.split('/n') 
                                return(
                                    <div className='fullColumnWidthAdr'>{ar.map(val=>{
                                        return (
                                            <div>{val}</div>
                                        )
                                    })}</div>
                                )                              
                            }
                        }
                    },
                        {
                            name: 'number10',
                            label: '10. Annað en í tanki',
                            options: {
                                filter: false,
                                sort: false,
                                customBodyRender: (value, tableMeta, updateValue) => {
                                    var ar = value.split('/n') 
                                    return(
                                        <div className='fullColumnWidthAdr'>{ar.map(val=>{
                                            return (
                                                <div >{val}</div>
                                            )
                                        })}</div>
                                    )                              
                                }
                                // sortDirection: this.state.sortName==val? this.state.sortVal : "none",
                            }
                        }
                    )
                }
                else {
                    dataColumns.push({
                        name: val,
                        label: val,
                        options: {
                            filter: true,
                            sort: true,
                            sortDirection: this.state.sortName == val ? this.state.sortVal : "none",
                        }
                    })
                }
            })
        }

        dataColumns.push(
            {
                name: 'created_at',
                label: this.props.isLicensePassTemplate == true ? " DAGSETNING SÓTT" : T("Date added"),
                options: {
                    filter: true,
                    sort: true,
                    sortDirection: this.state.sortName == 'created_at' ? this.state.sortVal : "none",

                }
            },
            {
                name: '',
                label: '',
                options: {
                    display: this.props.isLicensePassTemplate ? false : true,
                    download: false,
                    filter: false,
                    sort: false,
                    print: false,
                    viewColumns: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (tableMeta.rowData && tableMeta.rowData[2] == "1") {
                            return (
                                <button title={tableMeta.rowData[1] == 'V' || this.props.isLicensePassTemplate == true ? '' : 'This pass is not valid to perform this action'} disabled={tableMeta.rowData[1] != 'V' && this.props.isLicensePassTemplate != true} className={tableMeta.rowData[1] == 'V' || this.props.isLicensePassTemplate == true ? "cmn-btn deactivate" : "cmn-btn deactivate disable"} onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0], tableMeta.rowData[2]);
                                }}>{T("Deactivate")}</button>
                            );
                        } else if (tableMeta.rowData && tableMeta.rowData[2] == "0") {
                            return (
                                <button title={tableMeta.rowData[1] == 'V' || this.props.isLicensePassTemplate == true ? '' : 'This pass is not valid to perform this action'} disabled={tableMeta.rowData[1] != 'V' && this.props.isLicensePassTemplate != true} className={tableMeta.rowData[1] == 'V' || this.props.isLicensePassTemplate == true ? "cmn-btn active" : "cmn-btn active disable"} onClick={() => {
                                    this.changeStatus(tableMeta.rowData[0], tableMeta.rowData[2]);
                                }}>{T("Activate")}</button>
                            );
                        }
                    }
                }
            })

        if (this.props.isLicensePassTemplate == true && !this.props.isVinnueftirlitioLicensePassTemplate) {
            dataColumns.push(
                {
                    name: 'last_update_request_received',
                    label: 'RLS UPPFÆRT',
                    options: {
                        filter: true,
                        sort: true,
                        sortDirection: this.state.sortName == 'last_update_request_received' ? this.state.sortVal : "none",
                    }
                },
                {
                    name: 'pass_updated',
                    label: 'PASSI UPPFÆRÐUR Í VESKI',
                    options: {
                        filter: true,
                        sort: true,
                        sortDirection: this.state.sortName == 'pass_updated' ? this.state.sortVal : 'none',
                    }
                }
            )
        }
        const options_without_license = {
            filterType: 'dropdown',
            responsive: "standard",
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 15, 20],
            filter: false,
            selectableRows: false,
            downloadOptions: {
                filename: 'User_List.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                }
            },
            textLabels: {
                body: {
                    noMatch: T("No results found, Please try different search parameters")
                }
            },
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                const selected = allRows.map(item => item.dataIndex);
                this.setState({
                    rowsSelected: selected,
                }, () => this.handleTableAction());
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect />
            ),
        };
        const options = {
            filter: false,
            count: this.state.totalUsers,
            filterType: 'dropdown',
            responsive: "standard",
            rowsPerPage: this.state.rowsPerPage,
            downloadOptions: {
                filename: 'License_User_List.csv',
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                }
            },
            textLabels: {
                body: {
                    noMatch: T("No results found, Please try different search parameters")
                }
            },
            onColumnSortChange: (changedColumn, direction) => {
                let directionView = ''
                let sortFieldId = ''
                let isSortFieldId = false;
                for (const [key, value] of Object.entries(issuerFieldsList)) {
                    if (value.value == changedColumn) {
                        sortFieldId = value.id;
                        isSortFieldId = true;
                    }
                }
                if (direction == 'descending' || direction == 'desc') {
                    directionView = 'desc';
                } else {
                    directionView = 'asc';
                }
                this.setState({
                    sortVal: directionView,
                    sortName: changedColumn,
                    sortFieldId: sortFieldId,
                    isSortFieldId: isSortFieldId
                }, () => { this.getTemplateUser() })
            },
            customFooter: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                textLabels
            ) => {
                return (<TableFooter>
                    <TableRow>
                        <TableCell>
                            <Toolbar>
                                <Select
                                    value={this.state.rowsPerPage}
                                    onChange={(event) => { this.setState({ rowsPerPage: event.target.value, pageIndex: 0, jumpPageNumber: 1 }, () => { changeRowsPerPage(event.target.value); this.getTemplateUser() }) }}
                                >
                                    <MenuItem selected={this.state.rowsPerPage == 5} value={5}>Rows 5</MenuItem>
                                    <MenuItem selected={this.state.rowsPerPage == 10} value={10}>Rows 10</MenuItem>
                                    <MenuItem selected={this.state.rowsPerPage == 15} value={15}>Rows 15</MenuItem>
                                    <MenuItem selected={this.state.rowsPerPage == 20} value={20}>Rows 20</MenuItem>
                                </Select>
                                <IconButton disabled={this.state.pageIndex < 1} onClick={() => { this.changeToPrevPage() }} >
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <IconButton disabled={this.state.pageIndex + 1 >= Math.ceil(this.state.totalUsers / this.state.rowsPerPage) || Math.ceil(this.state.totalUsers / this.state.rowsPerPage) <= 1} onClick={() => { this.changeToNextPage() }}  >
                                    <ArrowForwardIosIcon />
                                </IconButton>
                                Page
                                <NumberFormat
                                    style={{ width: '80px' }}
                                    fixedDecimalScale={true} decimalScale={0} allowNegative={false}
                                    id="ContactMobile"
                                    type="text"
                                    onChange={(event) => { this.jumpPageNumberChange(event) }}
                                    onBlur={(event) => this.jumpPageNumber(event)}
                                    value={this.state.jumpPageNumber}
                                    min={1}
                                    max={Math.ceil(this.state.totalUsers / this.state.rowsPerPage)}
                                />
                                of {Math.ceil(this.state.totalUsers / this.state.rowsPerPage)}
                            </Toolbar>
                        </TableCell>
                    </TableRow>
                </TableFooter>);
            },
            page: this.state.pageNumber,
            selectableRows: false,
            search: false,
            textLabels: {
                body: {
                    noMatch: T("No results found, Please try different search parameters")
                }
            },
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                const selected = allRows.map(item => item.dataIndex);
                this.setState({
                    rowsSelected: selected,
                }, () => this.handleTableAction());
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect />
            ),
        };
        //Loader
        let loader = functions.getLoader(this.state.loader);
        if (this.props.contentTemplate > 0) {
            return (
                <div id="users" className={this.props.CurrentTab == 'users' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                    {loader}
                    {(tableData.length > 0 || this.state.searchedTextField == true)  &&
                        <div className="search_section">
                            <input type="text" name="" value={this.state.searchedText} onChange={(event) => { this.setState({ searchedText: event.target.value, searchedTextField: true }) }} onBlur={(event) => { this.setState({ pageIndex: 0, jumpPageNumber: 1 }, () => { this.getTemplateUser() }) }} placeholder="Search" className="serach" />
                            <i className="fa fa-search"></i>
                        </div>
                    }

                    {/* <!-- No user listed --> */}
                    <div className="no_user_listed no_and_search_samestyle">
                        <div className="main_distribution_btn">
                            {tableData.length > 0 &&
                                <button className="distribution_btns" onClick={this.DownloadCsv}>{T("Download list")}</button>
                            }
                        </div>
                        {tableData.length == 0 &&
                            <div className="no_listing_text">
                                <h3>{T("No users listed")}</h3>
                                <span>{T("As soon as an user downloads your pass you will see them listed here")}.</span>
                            </div>
                        }
                    </div>
                    {/* <!-- User listing --> */}
                    {
                        // (tableData.length > 0 && this.props.isLicensePassTemplate) &&
                        tableData.length > 0 &&
                        <div className="user-listing">
                            <div className="status_main">
                                <MUIDataTable
                                    //title={"Distribution List"}
                                    data={data}
                                    columns={dataColumns}
                                    options={options}
                                />
                            </div>
                        </div>
                    }
                    {/* {
                        (tableData.length > 0 && !this.props.isLicensePassTemplate) &&
                        <div className="user-listing">
                            <div className="status_main">
                                <MUIDataTable
                                    //title={"Distribution List"}
                                    data={data}
                                    columns={dataColumns}
                                    options={options_without_license}
                                />
                            </div>
                        </div>
                    } */}
                </div>
            );
        } else {
            return (
                <div id="users" className={this.props.CurrentTab == 'users' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                    <div className="search_section">
                        {/* <i className="fa fa-search"></i> */}
                        <img src={Magnifyingglass} />
                        <input type="text" name="" placeholder="Search" className="serach" />
                    </div>
                    {/* <!-- No user listed --> */}
                    <div className="no_user_listed no_and_search_samestyle">
                        <div className="main_distribution_btn">
                            <button className="distribution_btns">{T("Download list")}</button>
                        </div>
                    </div>

                    {/* <!-- User listing --> */}
                    <div className="user-listing">
                        <div className="status_main">
                            <table className="status_table">
                                <thead>
                                    <tr className="">
                                        <th> <span className="name">{T("Name")} </span></th>
                                        <th>{T("Column")} 2</th>
                                        <th>{T("Column")} 3</th>
                                        <th>{T("Date added")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="deactiavted_row">

                                        <td>
                                            {T("Row Data")} 1.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 1.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 1.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="actiavted_row">

                                        <td>
                                            {T("Row Data")} 2.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 2.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 2.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="activate_btn">{T("Activate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="deactiavted_row">

                                        <td>
                                            {T("Row Data")} 3.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 3.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 3.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="deactiavted_row">

                                        <td>
                                            {T("Row Data")} 4.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 4.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 4.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="deactiavted_row">

                                        <td>
                                            {T("Row Data")} 5.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 5.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 5.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="actiavted_row">

                                        <td>
                                            {T("Row Data")} 6.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 6.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 6.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="activate_btn">{T("Activate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="actiavted_row">

                                        <td>
                                            {T("Row Data")} 7.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 7.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 7.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                    <tr className="actiavted_row">

                                        <td>
                                            {T("Row Data")} 8.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 8.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 8.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td>
                                            <button className="deactivate_btn">{T("Deactivate")}</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* OVER LAY FOR DUMMY CONTENT*/}
                    <div className="dummy_data-content">
                        <div className="dummy_data-content-logo">
                            <img src={LogoQr} alt="" />
                            <h3>{T("DEMO CONTENT")}</h3>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default UsersTab;
