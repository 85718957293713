import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import successChecked from '../../assets/images/successChecked.svg'
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

export default class RequestConfirmPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            loader: false,
        };
    }

    redirectToSubscription() {
        this.setState({
            redirect: true
        })
    }

    render() {
        let loader = functions.getLoader(this.state.loader);

       
        // let totalPrice = orderData && !DiscountPeriodExpired ? (orderData.offer_price * 1.24) : (orderData && (orderData.price > 0) ? (orderData.price * 1.24) : 0);
        let totalUsers = this.props.userData && this.props.userData.length ? this.props.userData.length : "";
        let totalAddedHolder= this.props.totalAddedHolder ? this.props.totalAddedHolder:"";
        let totalSelectedUsers = this.props.userList && this.props.userList.length ? this.props.userList.length : "";
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        {/* <button type="button" className="close" data-dismiss="modal" onClick={() => this.redirectToSubscription()}>&times;</button> */}
                    </div>
                    <div className="modal-body">
                        <img src={successChecked} />
                        {this.props.confirmType == "addUser" &&
                            <span>{T("USERS ADDED")}</span>
                        }
                        {this.props.confirmType == "sendPass" &&
                            <span>{totalSelectedUsers} {totalSelectedUsers > 1 ? T("PASSES SENT") : T("PASS SENT")}</span>
                        }
                        {this.props.confirmType == "sendLicense" &&
                            <span>{totalSelectedUsers} {totalSelectedUsers > 1 ? T("LICENSES SENT") : T("LICENSE SENT")}</span>
                        }
                        {(this.props.confirmType == "contactusRequest" || this.props.confirmType == "inviteUserRequest") &&
                            <span>{T("REQUEST SENT")}</span>
                        }{
                            this.props.confirmType=="addLicenseHolder" &&
                            <span>{totalAddedHolder} {totalAddedHolder > 1 ? T("LICENSE HOLDERS ADDED") : T("LICENSE HOLDER ADDED")}</span>
                        }
                    </div>
                    <div className="modal-footer">

                    </div>
                </div>

            </div>

        )
    }
}