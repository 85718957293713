import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderConfirmationPopup from './OrderConfirmationPopup';
import OrderPopup from './OrderPopup';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import UpgradeSubscription from './UpgradeSubscription';
import DeleteUser from './DeleteUser';
import RequestConfirmPopup from './RequestConfirmPopup';
import ActiveUserPermissions from './ActiveUserPermissions';
import DowngradePlan from './DowngradePlan';
import $ from 'jquery';

class ActiveAccountUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            issuer_associated_user: 0,
            activeAccountUsers: [],
            subIssuerid: -1,
            activeAccountKey: 0
        };
    }
    componentDidMount = () => {
        this.getActiveAccountUsers();
    }
    getActiveAccountUsers(props = this.props) {
        this.setState({ loader: true });

        api.getActiveAccountUsers()
            .then(res => {
                let activeAccountUsers = []
                if (res.data.status == 1) {
                    if (res.data.data.activeAccountUsers) {
                        activeAccountUsers = res.data.data.activeAccountUsers
                    }
                }
                this.setState({
                    activeAccountUsers: activeAccountUsers,
                    loader: false,
                })
            })
            .catch(err => {
                this.setState({ loader: false })
            });
    }
    closeModal = (type) => {
        if (type == "AssociatedDelete") {
            this.setState({
                subIssuerid: -1
            })
            $(".AssociatedDelete [data-dismiss=modal]").trigger("click");
        } else if (type == "permission") {
            this.getActiveAccountUsers();
            $(".userPermission [data-dismiss=modal]").trigger("click");
        }
    }
    updateState = (id) => {
        this.setState({
            subIssuerid: id
        })
        $(".userPermission [data-dismiss=modal]").trigger("click");
    }
    render() {
        let issuer_associated_user = this.props.issuer_associated_user;
        let activeAccountUsers = this.state.activeAccountUsers;
        let issuerRole = functions.getIssuerRole();
        let userPermissions = functions.getUserPermissions();
        let activeAccountUsersLength = Object.keys(activeAccountUsers).length;
        // if (this.props.planId >= 2 && issuerRole == 'Issuer-Account-User') {
        //     activeAccountUsersLength = activeAccountUsersLength + 1;
        // }
        if (issuerRole == 'Issuer-Account-User') {
            activeAccountUsersLength = activeAccountUsersLength + 1;
        }
        let associated_user_limit_axceeded = activeAccountUsersLength < issuer_associated_user ? false : true;

        let activePrice = 0;
        let DiscountPeriod = this.props.subscriptionState && this.props.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.props.subscriptionState.reamainingDaysOfTrailPeriod) : 90;
        let getTrialDuration = 0;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }
        let subscriptionData = functions.getSubscriptionData();
        let activeSubscriptionSection = (subscriptionData && subscriptionData.subscription != 0 && this.props.subscriptionState) ? true : false;
        let loader = functions.getLoader(this.state.loader);
        return (
            <div className="active_user_account">
                {loader}
                {activeSubscriptionSection &&
                    <div className="multi_user_section">
                        <h2>{T("Active account users")}</h2>
                        <div>
                            <h4>{(Object.keys(activeAccountUsers).length) > 0 ? (Object.keys(activeAccountUsers).length) + " "+T('additional account users') : T("No additional account users")}  </h4>
                            {(issuerRole == 'Issuer' || (this.props.planId > 1 &&  issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) &&
                                <span>{T("Your subscription enables you to have")} {issuer_associated_user > 1 ? issuer_associated_user +  T("activeuser_Accounts") : issuer_associated_user +  T("activeuser_Account") } {T("associated with your SmartPages")}.</span>
                            }
                            {(issuerRole == 'Issuer' || (this.props.planId > 1 && issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) &&
                                <span>{associated_user_limit_axceeded ? T("To invite a new account user delete one below or upgrade your subscription") : T("You can invite users in the input below!")}</span>
                            }
                            {issuer_associated_user > 0 &&
                                <div className="associated_user_list">
                                    <table className="associated_user_list_table">
                                        {activeAccountUsers.map((val, key) => (
                                            <tr><td>{val.user != null ? val.user.first_name + ',' : ''} {val.email}</td>
                                                {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) &&
                                                    <td><div><button title={T("Manage permissions")} onClick={() => { this.setState({ activeAccountKey: key }, () => { this.childpermission.componentDidMount() }) }} className="assocated_permission_btn" data-toggle="modal" data-target="#userPermission" ><i className="fa fa-cog fa-spin" aria-hidden="true"></i>
                                                    </button>{val.user != null ? <button onClick={() => { this.setState({ subIssuerid: val.id }) }} className="assocated_delete_btn" data-toggle="modal" data-target="#AssociatedDelete" >{T("Delete")}</button> : <span className="assocated_user_pending">{T("Invitation pending")}</span>}</div></td>
                                                }
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            }
                            {/* {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) && (this.props.planId < 2 || associated_user_limit_axceeded) &&
                                <button className="upgrade_subscription_btn" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? "#DowngradePlan" : '#UpgradeSubscription'} >Upgrade subscription</button>
                            }
                            {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) && !(this.props.planId < 2 || associated_user_limit_axceeded) && */}
                             {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) &&  associated_user_limit_axceeded &&
                                <button className="upgrade_subscription_btn" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? "#DowngradePlan" : '#UpgradeSubscription'} >{T("Upgrade subscription")}</button>
                            }
                            {(issuerRole == 'Issuer' || (issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.invite_users == '1')) && ! associated_user_limit_axceeded &&
                                <Formik
                                    initialValues={{ email: '' }}
                                    onSubmit={(values, { setSubmitting, setStatus, resetForm, setErrors }) => {
                                        this.setState({ errors: '', loader: true, formSubmit: false });
                                        toast.dismiss();
                                        var PostData = {
                                            // access_token: functions.getAccessToken(),
                                            email: values.email.trim(),
                                        };
                                        api.inviteActiveUser(PostData)
                                            .then(res => {
                                                if (res.data.status == 1) {
                                                    $('#email').blur();
                                                    resetForm()
                                                    this.getActiveAccountUsers();
                                                    $("#inviteUserConfirmBtn").trigger({ type: "click" });
                                                }
                                                else {
                                                    setErrors({ email: res.data.message })
                                                }
                                                this.setState({ loader: false });
                                            })
                                            .catch(err => {
                                                setErrors({ email: err.message })
                                                this.setState({ loader: false })

                                            });

                                        // resetForm({});

                                        setSubmitting(false);
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().trim()
                                            .email(Validation_Constants.Invalid_Email)
                                            .required(Validation_Constants.Required2),
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            dirty,
                                            status,
                                            resetForm,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            handleReset,
                                        } = props;
                                        return (
                                            <div className="user_invitation_container">
                                                <form className="user_invitation_form" onSubmit={handleSubmit}>
                                                    <div className="form-group">
                                                        <input id="email"
                                                            type="text"
                                                            value={values.email}
                                                            placeholder={T("Enter email address to give access to your Smartpage")}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.email && touched.email ? 'inputText error' : 'inputText'
                                                            } />
                                                        <div className="error-main">
                                                            {errors.email &&
                                                                touched.email && <p className="error">{errors.email}</p>
                                                            }
                                                            {status && status.email &&
                                                                <p className="success">{status.email}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="form-group-btn">
                                                        <input className="" id="invitation_user_back" type="button" style={{ display: 'none', visibility: 'hidden' }} value="" onClick={() => { resetForm() }} />
                                                        <input type="submit" name="" className={(JSON.stringify(errors) == "{}") && !this.state.loader && values.email != "" ? 'isActive' : 'isNotActive'} value={T("Send invitation")} disabled={(JSON.stringify(errors) != "{}") || values.email == ""} />
                                                    </div>
                                                </form>
                                            </div>
                                        );
                                    }}
                                </Formik>
                            }
                        </div>
                    </div>
                }
                {!activeSubscriptionSection &&
                    <div className="multi_user_section">
                        <h2>{T("Active account users")}</h2>
                        <div>
                            <h4>{T("You need to select a subscription plan to add more account users")}</h4>
                            <button className="upgrade_subscription_btn" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? "#DowngradePlan" : '#UpgradeSubscription'} >{T("Upgrade subscription")}</button>
                        </div>
                    </div>
                }
                <div className="modal AssociatedDelete adduser fade" id="AssociatedDelete" role="dialog" ref="AssociatedDelete">
                    <DeleteUser
                        parentCompnentMount={this.componentDidMount}
                        modalType={"AssocatedUserDelete"} subIssuerid={this.state.subIssuerid}
                        currentTempId={'-1'}
                        modalClose={(e) => { this.closeModal(e) }} />
                </div>
                <div className="modal userPermission adduser fade" id="userPermission" role="dialog" ref="userPermission">
                    <ActiveUserPermissions
                        updateState={(e) => { this.updateState(e) }}
                        activeAccountUsers={this.state.activeAccountUsers}
                        activeAccountKey={this.state.activeAccountKey}
                        modalClose={(e) => { this.closeModal(e) }}
                        ref={instance => {
                            this.childpermission = instance;
                        }} />
                </div>

                <div className="modal inviteUserConfirm fade " id="inviteUserConfirm" role="dialog" ref="inviteUserConfirm">
                    <RequestConfirmPopup
                        confirmType="inviteUserRequest"
                        userList={[]}
                        userData={[]} />
                </div>
                <button type="button" data-toggle="modal" data-target="#inviteUserConfirm" className="inviteUserConfirmBtn" id="inviteUserConfirmBtn" style={{ display: 'none' }} >Open Modal</button>
                {/* {(this.props.planId < 2 || associated_user_limit_axceeded) && */}
                {associated_user_limit_axceeded &&
                    <div>
                        <div className="modal fade select_subscription" id="UpgradeSubscription" role="dialog">
                            <UpgradeSubscription subscription_discount={this.props.subscription_discount} SubscriptionState={this.props.subscriptionState} SubscriptionData={this.props.SubscriptionData} DiscountPeriodExpired={this.props.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                        </div>
                        {
                            this.props.SubscriptionData.map((val, key) => (
                                <div>
                                    <div className="modal fade myOrderPopup" id={"myOrderPopup" + key} role="dialog" >
                                        <OrderPopup 
                                        template_price={this.props.template_price} subscription_discount={this.props.subscription_discount} subscriptionPaymentType={this.props.subscriptionPaymentType}
                                        OrderData={val} CardCred={this.props.cardInfo} DiscountPeriodExpired={this.props.discountPeriodExpired} getTrialDuration={getTrialDuration} SubscriptionState={this.props.subscriptionState} activePrice={activePrice = val.id == this.props.subscriptionState.id ? val.price : activePrice} />
                                    </div>
                                    <div className="modal orderConfirmModal fade" id={"OrderConfirmation" + val.id} role="dialog">
                                        <OrderConfirmationPopup
                                         template_price={this.props.template_price} subscription_discount={this.props.subscription_discount}  OrderData={val} SubscriptionState={this.props.subscriptionState} DiscountPeriodExpired={this.props.discountPeriodExpired} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
                <div className="modal downgradePlan fade" id="DowngradePlan" role="dialog">
                    <DowngradePlan />
                </div>
            </div>
        );
    }
};

export default ActiveAccountUsers;
