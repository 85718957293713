import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import BlockIcon from "@material-ui/icons/Block";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
    iconButton: {
    },
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

class CustomToolbarSelect extends React.Component {
    handleClickInverseSelection = () => {
        const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
            if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
                nextSelectedRows.push(index);
            }

            return nextSelectedRows;
        }, []);

        this.props.setSelectedRows(nextSelectedRows);
    };

    handleClickDeselectAll = () => {
        this.props.setSelectedRows([]);
    };

    handleClickBlockSelected = () => {
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.iconContainer}>
                <Tooltip title={"Deselect ALL"}>
                    <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
                        <IndeterminateCheckBoxIcon className={classes.icon} />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title={"Inverse selection"}>
                    <IconButton className={classes.iconButton} onClick={this.handleClickInverseSelection}>
                        <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={"Block selected"}>
                    <IconButton className={classes.iconButton} onClick={this.handleClickBlockSelected}>
                        <BlockIcon className={classes.icon} />
                    </IconButton>
                </Tooltip> */}
            </div>
        );
    }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);