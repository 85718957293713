const Validation_Constants = {

    Something_Went_Wrong: "Something went wrong, Please try again",
    Login_Session_Revoked: "Your login session has revoked",
    //Success Messages
    Borgun_000: "Payment successful",
    //Error Messages
    Borgun_909: "System error or network connection lost. Please try again later",
    //Error File Format CSV
    File_Format_Error: "The file doesn't follow the desired structure, Please try again",
    No_Recipients: "Please select members you want to send the pass to",

    API_Server_Not_Responding: "Currently Passbuilder not responding for this action.\nPlease try again later",

    Required: "This field is required", //Global Required MSG
    Required2: "The field is required",

    Empty_Contact_Name: "Please enter the contact full name",
    Min_Contact_Name: "Your contact name must consist of at least 2 characters",
    Max_contact_Name: "Your contact name must not be more than 50 characters",

    Empty_Company_Id: "Please enter the company ID number",
    Invalid_Username: "Username is not valid",
    // Min_Company_Id: "Your company id must consist of at least 10 characters",
    Min_Company_Id: "Your company id must be consist 10 characters",
    // Empty_Company_Name: "Please enter a company name",
    Min_Company_Name: "Your company name must consist of at least 2 characters",
    Max_Company_Name: "Your company name must not be more than 50 characters",
    Empty_Company_Name:'Please enter the company name',
    Valid_Contact: "Enter a valid contact number",
    Empty_Terms: "Please agree to our terms and conditions",

    Empty_Password: "Please enter a password",
    Min_Password: "Your password must be at least 8 characters long",
    Max_Password: "Your password must be less than 100 characters",
    Regex_Number_Password: "One number is required",
    Regex_Special_Char_Password: "One special character is required",

    Empty_OldPassword: "Please enter the old password",
    Invalid_NewRePassword: "Passwords don't match",
    Empty_NewRePassword: "Please enter a confirm new password",
    Empty_NewPassword: "Please enter the new password",
    Passwords_Not_Match_Empty: "Please confirm new password",
    Empty_RePassword: "Please re-enter the new password",
    Invalid_RePassword: "Passwords do not match",

    Empty_Email: "Please enter an email addres",
    Invalid_Email: "Please enter a valid email address",
    Invalid_CompanyEmail: "Please enter a valid company invoice email address",

    Empty_ContactMobile: 'Please enter the contact phone number',
    Min_ContactMobile: 'Your company contact number must be at least 7 characters long',
    Max_ContactMobile: 'Your company contact number can not be more than 13 characters long',

    Min_CompanyPhone: "Your company phone number can not be less than 7 digits",
    Max_CompanyPhone: "Your company phone number can not be more than 7 digits",
    Empty_CompanyPhone: "Please enter a company phone number",

    Empty_CompanyAddress: 'Please enter the company invoice email',

    Empty_CompanyCity: "Please enter a company city",

    Empty_CompanyZipCode: 'Please enter the company zip code',
    Min_CompanyZipCode: 'Your company zipcode can not be less than 3 digits',
    Max_CompanyZipCode: 'Your company zipcode can not be more than 3 digits',

    Account_Recover_Error: "This e-mail is not associated with an account. Please try again, or contact your TCDam Manager",
    Account_Recover_Success: "A link to rest your password has been sent to the e-mail account on file. This link will expire in 15-minutes",
    Account_Already_Exists: "Account already exists",
    Account_Created_Success: "Thank you for registering an account!. Please allow our team 24hrs to review your information",

    Min_Name_On_Card: "The name must not be less than 2 characters",
    Max_Name_On_Card: 'The name must not be more than 50 characters',
    Empty_Name_On_Card: "Please enter the name as on card",

    Min_Card_Num: 'Your card number must not be less than 16 digits',
    Max_Card_Num: 'Your card number must not be more than 20 digits',
    Empty_Card_Num: 'Please enter a card number',

    Min_Cvc: 'Your cvc must not be less than 3 digits',
    Max_Cvc: 'Your cvc must not be more than 4 digits',
    Empty_CVC: 'Please enter the CVC',

    Empty_Exp_Date: 'Please enter the expiration date',
    Invalid_Exp_Mon: 'Invalid expiration month',
    Invalid_Exp_Date: 'Invalid expiration date',

    Empty_Last_Name: "Please enter your last name",

    Empty_Business_Unit: "Please enter your business Unit",

    Empty_Department: "Please enter your department",

    Expiry_Password_link: "This Is Expiry Link Please Request For A New Code",

    Empty_Name: "Please enter a name",
    Min_Name: "Your name must consist of at least 2 characters",
    Max_Name: "Your name must consist of at below 100 characters",

    Empty_Subject_Matter: "Please enter your subject matter",

    Email_NotRegistered: "Email not registered",
    Email_NotRecognize: "We do not recognize this email address!",

    Changed_Password: "Password changed successfully",

    Subscription_Successful: 'You successfully subscribed to the plan',
    Update_Payment: 'Payment method updated successfully',

    Subscription_Cancellation: 'You have successfully unsubscribed your subscription',
    Subscription_Cancellation_Failed: 'Subscription cancellation failed! Please try again later',

    Empty_Message: "Please enter your message",
    Max_Message: "Your message must consist of at below 2000 characters",
    BorgunErrMsgs: {
        '000': 'Accepted',
        '100': 'Do not honor (Not accepted)',
        '101': 'Expired card',
        '102': 'Suspected card forgery (fraud)	',
        '103': 'Merchant call acquirer',
        '104': 'Restricted card',
        '106': 'Allowed PIN retries exceeded',
        '109': 'Merchant not identified',
        '110': 'Invalid amount',
        '111': 'Invalid card number',
        '112': 'PIN required	',
        '116': 'Not sufficient funds',
        '117': 'Invalid PIN',
        '118': 'Unknown card	',
        '119': 'Transaction not allowed to cardholder',
        '120': 'Transaction not allowed to terminal',
        '121': 'Exceeds limits to withdrawal',
        '125': 'Card not valid	',
        '126': 'False PIN block	',
        '129': 'Suspected fraud	',
        '130': 'Invalid Track2	',
        '131': 'Invalid Expiration Date	',
        '161': 'DCC transaction not allowed to cardholder',
        '162': 'DCC cardholder currency not supported',
        '163': 'DCC exceeds time limit for withdrawal',
        '164': 'DCC transaction not allowed to terminal',
        '165': 'DCC not allowed to merchant	',
        '166': 'DCC unknown error	',
        '200': 'No not honor',
        '201': 'Card not valid',
        '202': 'Suspected card forgery (fraud)	',
        '203': 'Merchant contact acquirer',
        '204': 'Limited card',
        '205': 'Merchant contact police	',
        '206': 'Allowed PIN-retries exceeded',
        '207': 'Special occasion	',
        '208': 'Lost card',
        '209': 'Stolen card',
        '210': 'Suspected fraud	',
        '902': 'False transaction',
        '904': 'Form error',
        '907': 'Issuer not responding	',
        '908': 'Message can not be routed',
        '909': 'System error',
        '910': 'Issuer did not respond',
        '911': 'Issuer timed out',
        '912': 'Issuer not reachable',
        '913': 'Double transaction transmission',
        '914': 'Original transaction can not be traced',
        '916': 'Merchant not found	',
        '950': 'No financial record found for detail data',
        '951': 'Batch already closed	',
        '952': 'Invalid batch number	',
        '953': 'Host timeout	',
        '954': 'Batch not closed	',
        '955': 'Merchant not active	',
        '956': 'Transaction number not unique	',
        '997': 'Host timeout, A network communication problem',
        '998': 'A form error',
        '999': 'Host timeout, A system error',
    }
};

export default Validation_Constants;