import SiteLogo from '../assets/images/LOGO.png';
import Loader from '../assets/images/loader.gif';
import SmartSolutionLogoWitText from '../assets/images/Smartsolutions_logo.svg';
import SmartSolutionInvertedLogoWitText from '../assets/images/Smartsolutions_inverted.svg';
import SmartSolutionLogoWitTextIs from '../assets/images/smartsolutions_logo_is.svg';
import SmartSolutionLogoFooterWitTextIs from '../assets/images/SmartSolutionsIsFooterLogo.png'


const API_URL = process.env.REACT_APP_API_URL ?? 'https://passbuilder.smartsolutions.is';

const Constants = {
    UsageStatistics:{"B":"Basic","F":"Full",'-1':"Negotiable","Basic":"Basic","Full":"Full","basic":"Basic","full":"Full"},
    introductionPage: 'https://www.smartsolutions.is',
    imgUrl: API_URL + '/public_data',
    SiteLogo: SiteLogo,
    SmartSolutionLogoWitText: SmartSolutionLogoWitText,
    SmartSolutionInvertedLogoWitText: SmartSolutionInvertedLogoWitText,
    SmartSolutionLogoWitTextIs:SmartSolutionLogoWitTextIs,
    SmartSolutionLogoFooterWitTextIs:SmartSolutionLogoFooterWitTextIs,
    Loader: Loader,
    Public_Path: API_URL + '/public_data',

    // BORGUN
    Borgun_API_KEY: process.env.REACT_APP_BORGUN_API_KEY,
    Borgun_API_TOKEN: process.env.REACT_APP_BORGUN_API_TOKEN,
    Borgun_API_PAYMENT: process.env.REACT_APP_BORGUN_API_PAYMENT,
    
    Template_Build: 19800,
    Login_API: API_URL + '/backend/api/login',
    ResetPassword_API: API_URL + '/backend/api/createtoken',
    SetNewPassword_API: API_URL + '/backend/api/reset',
    ChangePassword_API: API_URL + '/backend/api/issuerBackend/changePassword',
    Account_Data_API: API_URL + '/backend/api/issuerBackend',
    SaveCardDetailWithPayments_Data_API: API_URL + '/backend/api/issuerBackend/saveCardDetailWithPayments',
    Issuer_Templates_API: API_URL + '/backend/api/issuerBackend/myTemplatesData',
    Scanner_Template_List_Api: API_URL + '/backend/api/issuerBackend/getScannersTemplatesData',
    Add_Scanner_API: API_URL + '/backend/api/issuerBackend/addScanner',
    Update_Scanner_API: API_URL + '/backend/api/issuerBackend/updateScannerData/',
    Delete_Scanner_API: API_URL + '/backend/api/issuerBackend/deleteScannerData/',
    Scanner_List_API: API_URL + '/backend/api/issuerBackend/getScannersList',
    Get_Scanner_Data: API_URL + '/backend/api/issuerBackend/getScannerData/',
    Add_License_Holder_API: API_URL + '/backend/api/issuerBackend/addLicenseHolder/',
    Add_License_Holder_Csv_API: API_URL + '/backend/api/issuerBackend/addLicenseHoldersByCSV/',
    Send_License_To_Customers: API_URL + '/backend/api/issuerBackend/sendLicenses/',
    Delete_License_Holder_List_API: API_URL + '/backend/api/issuerBackend/deleteLicenseHolderList/',
    Delete_License_Holder_API: API_URL + '/backend/api/issuerBackend/deleteLicenseHolder/',
    Change_License_Holder_Status: API_URL + '/backend/api/issuerBackend/licenseHolderActivation/',
    Template_Stats_API: API_URL + '/backend/api/issuerBackend/getPassStatisticsData/',
    Template_Users_API: API_URL + '/backend/api/issuerBackend/getTemplatePassUsers/',
    Account_Update_API: API_URL + '/backend/api/issuerBackend/accountUpdate',
    Signup_API: API_URL + '/backend/api/register',
    ConfirmEmail_API: API_URL + '/backend/api/issuerBackend/issuerConfirmation',
    Subscription_Data_API: API_URL + '/backend/api/issuerBackend/getSubscriptions',
    ActiveUserData_API: API_URL + '/backend/api/issuerBackend/getActiveAccountUsers',
    InviteActiveUser_API: API_URL + '/backend/api/issuerBackend/inviteUser',
    DeleteActiveUser_API: API_URL + '/backend/api/issuerBackend/deleteAccountUser/',
    InviteUserInfo_API: API_URL + '/backend/api/issuerBackend/getInvitedUserInfo/',
    Set_Active_User_Permission_API: API_URL + '/backend/api/issuerBackend/setAccountUserPermissions/',
    MemberTemplate_Data_API: API_URL + '/backend/api/issuerBackend/getMembersData',
    AddMembers_DB_API: API_URL + '/backend/api/issuerBackend/saveIssuerInputFieldsByCSV/',
    Change_Member_Status_API: API_URL + '/backend/api/issuerBackend/changeMemberStatus',
    Send_Member_Pass_API: API_URL + '/backend/api/issuerBackend/sendMemberPass',
    Pass_Statistics_Data_API: API_URL + '/backend/api/issuerBackend/getPassStatisticsData',
    Codes_Data_API: API_URL + '/backend/api/issuerBackend/getCodesData',
    Save_Subscription_API: API_URL + '/backend/api/issuerBackend/processSubscriptionPayment',
    Cancel_Subscription_API: API_URL + '/backend/api/issuerBackend/cancelSubscriptionPayment',
    Add_User_Dist: API_URL + '/backend/api/issuerBackend/saveIssuerInputFields',
    Send_Passes_To_Customers: API_URL + '/backend/api/issuerBackend/sendPassesToCustomers/',
    Change_Pass_Activation_Status: API_URL + '/backend/api/issuerBackend/changePassActivation/',
    Delete_Distribute_List: API_URL + '/backend/api/issuerBackend/deleteDistributedUsers/',
    IsAuthenticatedUser: API_URL + '/backend/api/issuerBackend/isAuthenticatedUser',
    ContactUs_API: API_URL + '/backend/api/issuerBackend/contactUs',
    Logout_API: API_URL + '/backend/api/logout',
};


export default Constants;
