import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";
import { Redirect } from 'react-router';
import ActiveAccountUsers from './ActiveAccountUsers';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import { template } from '@babel/core';


class ProfileTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Profile',
            loader: false,
            PersonHandlesPassBuild: false,
            isUpdatePaymentInfo: false,
            apiTokenClass: '',
            SubscriptionData: [],
            planId: '',
            totalPasses: 0,
            subscriptionState: {},
            cardInfo: {},
            discountPeriodExpired: false,
            subscriptionName: '',
            subscriptionPaymentType: '0',
            issuer_associated_user: 0,
            template_price: 19800,
            subscription_discount:0,
        };
    }
    componentDidMount = () => {
        var PostData = {
            access_token: functions.getAccessToken(),
        };
        api.getAccountData()
            .then(res => {
                if (res.data && res.data.data && res.data.data.issuerAccountUser && res.data.data.issuerAccountUser.length > 0 && res.data.data.issuerAccountUser[0]) {
                    let content = {
                        token: functions.getToken(),
                        access_token: functions.getAccessToken(),
                        contact_name: functions.getUserName(),
                        company_name: functions.getCompanyName(),
                        subscription_data: functions.getSubscriptionData(),
                        user_permissions: res.data.data.issuerAccountUser[0],
                        issuer_role: functions.getIssuerRole(),
                    }
                    localStorage.setItem('loginISP_user_sesion',
                        functions.Client(content));
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            });
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        this.getSubscriptionData(); // Get Subscription Data
        this.props.parentComponentDidMountFunction();
    }

    getSubscriptionData(props = this.props) {
        this.setState({ loader: true });

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getSubscriptionData()
            .then(res => {
                if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
                    return <Redirect to='/signin' />
                }
                let result = [];
                let planId, totalPasses = 0;
                let subscriptionState = {};
                let cardInfo = {};
                let subscriptionName = '';
                let discountPeriodExpired = false;
                let template_price = 19800;
                let subscriptionPaymentType = '';
                let issuer_associated_user = 0;
                let subscription_discount=0;
                if (res.data.status == 1) {
                    result = res.data.data.subscriptions;
                    planId = res.data.data.subscription_id;
                    totalPasses = res.data.passesInSmartwallet;
                    discountPeriodExpired = res.data.data.discountPeriodExpired;
                }
                if (res.data.data && res.data.data.subscription_status && res.data.data.subscription_status.status == 1) {
                    subscriptionState = res.data.data.subscription_status.data;
                    subscriptionName = res.data.data.subscription_status.data.subscriptionName;
                    issuer_associated_user = res.data.data.subscription_status.data.issuer_smartpages ? res.data.data.subscription_status.data.issuer_smartpages : 0;

                }
                if (res.data.data && res.data.data.userPaymentInfo && res.data.data.userPaymentInfo.length > 0) {
                    cardInfo = res.data.data.userPaymentInfo[0];
                }
                this.setState({
                    loader: false,
                    SubscriptionData: result,
                    planId: planId,
                    totalPasses: totalPasses,
                    subscriptionState: subscriptionState,
                    cardInfo: cardInfo,
                    issuer_associated_user: issuer_associated_user,
                    discountPeriodExpired: discountPeriodExpired,
                    subscriptionName: subscriptionName,
                    subscriptionPaymentType: res.data && res.data.data &&  res.data.data.pay_with_invoice ? res.data.data.pay_with_invoice:'0',
                    template_price: res.data && res.data.data &&  res.data.data.template_price ? res.data.data.template_price:19800,
                    subscription_discount:res.data && res.data.data &&  res.data.data.subscription_discount ? res.data.data.subscription_discount:0,
                })
            })
            .catch(err => {
                toast.error(err.message, { hideProgressBar: true })
                this.setState({ loader: false })
            });
    }
    closeModal = (type) => {
        if (type == "AssociatedDelete") {
            $(".AssociatedDelete [data-dismiss=modal]").trigger("click");
        }
    }

    render() {
        let issuerRole = functions.getIssuerRole();
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let DiscountPeriod = this.state.subscriptionState && this.state.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.state.subscriptionState.reamainingDaysOfTrailPeriod) : 90;
        let getTrialDuration = 0;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }
        let currentYear = parseInt(new Date().getFullYear().toString().substr(-2));

        let years = [];
        for (var i = currentYear; i < currentYear + 25; i++) {
            years.push(i);
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);
        const AccountData = this.props.AccountData;
        let paymentProvider='';

        if(AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].issuer_payment_api_information != null && AccountData.userMetadata[0].issuer_payment_api_information != ''){
            paymentProvider='borgun';
        }else if(AccountData.userMetadata && AccountData.userMetadata.length > 0 && ((AccountData.userMetadata[0].korta_merchant != null && AccountData.userMetadata[0].korta_merchant != '') ||  (AccountData.userMetadata[0].korta_terminal != null && AccountData.userMetadata[0].korta_terminal != '') || (AccountData.userMetadata[0].korta_secretcode != null && AccountData.userMetadata[0].korta_secretcode != ''))){
            paymentProvider='korta';
        }

        return (
            <div className="profiletab_section">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        //APIKey: AccountData.userMetadata && AccountData.userMetadata.length > 0 ? AccountData.userMetadata[0].issuer_payment_api_information : '',
                        //SecretKey: AccountData.userMetadata && AccountData.userMetadata.length > 0 ? AccountData.userMetadata[0].payment_secret_key : '',
                        ContactName: AccountData.user && AccountData.user.length > 0 && AccountData.user[0].first_name != null ? AccountData.user[0].first_name : '',
                        ContactEmail: AccountData.user && AccountData.user.length > 0 && AccountData.user[0].email != null ? AccountData.user[0].email : '',
                        ContactMobile: issuerRole == 'Issuer-Account-User' && AccountData.issuerAccountUser && AccountData.issuerAccountUser.length > 0 && AccountData.issuerAccountUser[0].contact_mobile != null ? AccountData.issuerAccountUser[0].contact_mobile : issuerRole == 'Issuer' && AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].contact_mobile != null ? AccountData.userMetadata[0].contact_mobile : '',
                        ContactDirectPhone: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].contact_direct_phone != null ? AccountData.userMetadata[0].contact_direct_phone : '',
                        CompanyIdNo: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].company_id != null ? AccountData.userMetadata[0].company_id : '',
                        CompanyName: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].company_name != null ? AccountData.userMetadata[0].company_name : '',
                        CompanyEmail: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].company_email != null ? AccountData.userMetadata[0].company_email : '',
                        CompanyPhone: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].company_phone != null ? AccountData.userMetadata[0].company_phone : '',

                        payment_provider: paymentProvider,
                        borgunPaymentApi: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].issuer_payment_api_information != null ? AccountData.userMetadata[0].issuer_payment_api_information : '',
                        kortaMerchant: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].korta_merchant != null ? AccountData.userMetadata[0].korta_merchant : '',
                        kortaTerminal: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].korta_terminal != null ? AccountData.userMetadata[0].korta_terminal : '',
                        kortaSecretCode: AccountData.userMetadata && AccountData.userMetadata.length > 0 && AccountData.userMetadata[0].korta_secretcode != null ? AccountData.userMetadata[0].korta_secretcode : '',


                        // CardHolderName: AccountData.userPaymentInfo && AccountData.userPaymentInfo.length > 0 ? AccountData.userPaymentInfo[0].card_holder_name : '',
                        // CardNumber: AccountData.userPaymentInfo && AccountData.userPaymentInfo.length > 0 ? "XXXX-XXXX-XXXX-" + AccountData.userPaymentInfo[0].card_number : '',
                        // ExpiresMM: AccountData.userPaymentInfo && AccountData.userPaymentInfo.length > 0 ? AccountData.userPaymentInfo[0].expire_month : '',
                        // ExpiresYY: AccountData.userPaymentInfo && AccountData.userPaymentInfo.length > 0 ? AccountData.userPaymentInfo[0].expire_year.toString().substr(-2) : '',
                        // CVC: AccountData.userPaymentInfo && AccountData.userPaymentInfo.length > 0 ? '***' : '',
                    }}
                    onSubmit={(values, { setSubmitting, resetForm, setTouched, setStatus, setErrors }) => {
                        this.setState({ errors: '', loader: false });

                        var PostData = values.payment_provider == 'korta' ? {
                            // APIKey: values.APIKey,
                            // SecretKey: values.SecretKey,

                            payment_provider: values.payment_provider,
                            korta_merchant: values.kortaMerchant.trim(),
                            korta_terminal: values.kortaTerminal.trim(),
                            korta_secretcode: values.kortaSecretCode.trim(),
                            ContactName: values.ContactName,
                            ContactEmail: values.ContactEmail.trim(),
                            ContactMobile: values.ContactMobile,
                            // Password: values.Password,
                            //PersonHandlesPassBuild: this.state.PersonHandlesPassBuild,
                            CompanyIdNo: values.CompanyIdNo,
                            CompanyName: values.CompanyName,
                            CompanyEmail: values.CompanyEmail.trim(),
                        } : values.payment_provider == 'borgun' ? {
                            // APIKey: values.APIKey,
                            // SecretKey: values.SecretKey,
                            payment_provider: values.payment_provider,
                            issuer_payment_api_information: values.borgunPaymentApi.trim(),
                            ContactName: values.ContactName,
                            ContactEmail: values.ContactEmail.trim(),
                            ContactMobile: values.ContactMobile,
                            // Password: values.Password,
                            //PersonHandlesPassBuild: this.state.PersonHandlesPassBuild,
                            CompanyIdNo: values.CompanyIdNo,
                            CompanyName: values.CompanyName,
                            CompanyEmail: values.CompanyEmail.trim(),
                        } : {
                                    // APIKey: values.APIKey,
                                    // SecretKey: values.SecretKey,
                                    ContactName: values.ContactName,
                                    ContactEmail: values.ContactEmail.trim(),
                                    ContactMobile: values.ContactMobile,
                                    // Password: values.Password,
                                    //PersonHandlesPassBuild: this.state.PersonHandlesPassBuild,
                                    CompanyIdNo: values.CompanyIdNo,
                                    CompanyName: values.CompanyName,
                                    CompanyEmail: values.CompanyEmail.trim(),
                                };
                        setStatus({ msg: '' })
                        api.accountUpdate(PostData)
                            .then(res => {
                                if (res.data && res.data.status == 1) {
                                    let content = {
                                        token: functions.getToken(),
                                        access_token: functions.getAccessToken(),
                                        contact_name: PostData.ContactName,
                                        company_name: PostData.CompanyName,
                                        subscription_data: functions.getSubscriptionData(),
                                        user_permissions: functions.getUserPermissions(),
                                        issuer_role: functions.getIssuerRole(),
                                    }
                                    // toast.success("Account Updated Successfully", { hideProgressBar: true });
                                    localStorage.setItem('loginISP_user_sesion',
                                        functions.Client(content)
                                    );
                                    setStatus({ msg: res.data.message });
                                    setTimeout(() => {
                                        setStatus({ msg: '' })
                                    }, 5000);
                                    setTouched({})
                                    this.props.parentComponentDidMountFunction();
                                    this.setState({ loader: false });
                                } else {
                                    if (res.data.message.error) {
                                        Object.keys(res.data.message.error).forEach((key, index) => {
                                            setErrors({ [key]: res.data.message.error[key] });
                                        })
                                    }
                                    else if (res.data.message) {
                                        setErrors({ errmsg: res.data.message });
                                    }
                                    this.setState({ loader: false });
                                }
                            }).catch(function (err) {
                                setErrors({ errmsg: err.data.message })
                            }, this.setState({ loader: false }));
                        setSubmitting(false);
                        this.setState({ apiTokenClass: '' });
                    }}
                    validationSchema={Yup.object().shape({
                        CompanyIdNo: Yup.string().trim()
                            .min('10', Validation_Constants.Min_Company_Id)
                            .max('10', Validation_Constants.Min_Company_Id)
                            .required(Validation_Constants.Empty_Company_Id),
                        CompanyName: Yup.string().trim()
                            .min('2', Validation_Constants.Min_Company_Name)
                            .max('50', Validation_Constants.Max_Company_Name)
                            .required(Validation_Constants.Empty_Company_Name),
                        ContactName: Yup.string().trim()
                            .min('2', Validation_Constants.Min_Contact_Name)
                            .max('50', Validation_Constants.Max_contact_Name)
                            .required(Validation_Constants.Empty_Contact_Name),
                        ContactEmail: Yup.string().trim()
                            .email(Validation_Constants.Invalid_Email)
                            .required(Validation_Constants.Empty_Email),
                        ContactMobile: Yup.string().trim()
                            // .typeError(Validation_Constants.Valid_Contact)
                            // .positive(Validation_Constants.Valid_Contact)
                            // .integer(Validation_Constants.Valid_Contact)
                            .matches(/^[0-9]+$/, Validation_Constants.Valid_Contact)
                            .min(7, Validation_Constants.Min_ContactMobile)
                            .max(13, Validation_Constants.Max_ContactMobile)
                            .required(Validation_Constants.Empty_ContactMobile),
                        CompanyEmail: issuerRole != 'Issuer-Account-User' ? Yup.string().trim()
                            .email(Validation_Constants.Invalid_CompanyEmail)
                            .required(Validation_Constants.Empty_CompanyAddress) : '',
                        borgunPaymentApi: this.state.payment_provider == 'borgun' || paymentProvider == 'borgun' ? Yup.string().trim()
                            .required('Please enter the borgun payment api') : '',
                        kortaMerchant: this.state.payment_provider == 'korta' || paymentProvider == 'korta' ? Yup.string().trim()
                            .required('Please enter the korta merchant id') : '',
                        kortaTerminal: this.state.payment_provider == 'korta' || paymentProvider == 'korta' ? Yup.string().trim()
                            .required('Please enter the korta terminal id') : '',
                        kortaSecretCode: this.state.payment_provider == 'korta' || paymentProvider == 'korta' ? Yup.string().trim()
                            .required('Please enter the korta secret code') : '',
                        // CompanyPhone: Yup.string().trim()
                        //     .min(7, Validation_Constants.Min_CompanyPhone)
                        //     .max(7, Validation_Constants.Max_CompanyPhone)
                        //     .required(Validation_Constants.Empty_CompanyPhone),
                    })}
                >
                    {
                        props => {
                            const {
                                values,
                                touched,
                                errors,
                                status,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                            } = props;

                            return (
                                <div id="profile" className={this.props.CurrentTab == 'profile' ? 'tab-pane fade in active show profile_sec same_profile_tab_style ' : ' tab-pane fade in profile_sec same_profile_tab_style'}>
                                    {loader}
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    id="ContactName"
                                                    type="text"
                                                    value={values.ContactName == null ? '' : values.ContactName}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        handleBlur(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=" "
                                                    className={
                                                        errors.ContactName && touched.contactName ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                />
                                                <span className="highlight"></span>
                                                <label for="ContactName">{T("Contact full name_profile")}{issuerRole == "Issuer" ? ' (ADMIN)' : ''}</label>
                                                {errors.ContactName &&
                                                    touched.ContactName && <p className="error">{errors.ContactName}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <input data-tip='You cannot edit this information' aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    title='You cannot edit this information'
                                                    id="ContactEmail"
                                                    type="email"
                                                    value={values.ContactEmail == null ? '' : values.ContactEmail}
                                                    //placeholder="miso@noodlehouse.com"
                                                    //onChange={handleChange}
                                                    //onBlur={handleBlur}
                                                    className={
                                                        errors.ContactEmail && touched.ContactEmail ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                    disabled
                                                />
                                                <span className="highlight"></span>
                                                <label for="ContactEmail">{T("Contact email")}</label>
                                                {errors.ContactEmail &&
                                                    touched.ContactEmail && <p className="error">{errors.ContactEmail}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <NumberFormat fixedDecimalScale={true} decimalScale={0} allowNegative={false} aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    id="ContactMobile"
                                                    type="tel"
                                                    value={values.ContactMobile == null ? '' : values.ContactMobile}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        handleBlur(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=""
                                                    className={
                                                        errors.ContactMobile && touched.ContactMobile ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                />
                                                <span className="highlight"></span>
                                                <label for="ContactMobile">{T("Contact phone number")}</label>
                                                {errors.ContactMobile &&
                                                    touched.ContactMobile && <p className="error">{errors.ContactMobile}</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    disabled={issuerRole == 'Issuer-Account-User'}
                                                    data-tip={issuerRole == 'Issuer-Account-User' ? 'You cannot edit this information' : ''}
                                                    id="CompanyName"
                                                    type="text"
                                                    value={values.CompanyName == null ? '' : values.CompanyName}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        handleBlur(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=""
                                                    className={
                                                        errors.CompanyName && touched.CompanyName ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                />
                                                <span className="highlight"></span>
                                                <label for="CompanyName">{T("Company name")}</label>
                                                {errors.CompanyName &&
                                                    touched.CompanyName && <p className="error">{errors.CompanyName}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    disabled={issuerRole == 'Issuer-Account-User'}
                                                    data-tip={issuerRole == 'Issuer-Account-User' ? 'You cannot edit this information' : ''}
                                                    id="CompanyIdNo"
                                                    type="text"
                                                    value={values.CompanyIdNo == null ? '' : values.CompanyIdNo}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        handleBlur(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=" "
                                                    className={
                                                        errors.CompanyIdNo && touched.CompanyIdNo ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                />
                                                <span className="highlight"></span>
                                                <label for="CompanyIdNo">{T("Company ID number")}</label>
                                                {errors.CompanyIdNo &&
                                                    touched.CompanyIdNo && <p className="error">{errors.CompanyIdNo}</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="floating-label_group">
                                                <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                    disabled={issuerRole == 'Issuer-Account-User'}
                                                    data-tip={issuerRole == 'Issuer-Account-User' ? 'You cannot edit this information' : ''}
                                                    id="CompanyEmail"
                                                    type="email"
                                                    value={values.CompanyEmail == null ? '' : values.CompanyEmail}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        handleBlur(event)
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=" "
                                                    className={
                                                        errors.CompanyEmail && touched.CompanyEmail ? 'floating-input form-control error' : 'floating-input form-control'
                                                    }
                                                />
                                                <span className="highlight"></span>
                                                <label for="CompanyEmail">{T("Company invoice email")}</label>
                                                <div className="error-main">
                                                    {errors.CompanyEmail &&
                                                        touched.CompanyEmail && <p className="error">{errors.CompanyEmail}</p>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group payment_api_section">
                                            <h2>{T("Add your payment provider to sell passes")}</h2>
                                            <span>{T("Please add your payment provider information and the payments goes straight to your account")}.</span>
                                        </div>
                                        <div className="form-group profile_form_group_select">
                                            <div className="floating-label_group">
                                                <select
                                                    id="payment_provider"
                                                    type="text"
                                                    value={values.payment_provider == null ? '' : values.payment_provider}
                                                    onChange={(event) => {
                                                        handleChange(event)
                                                        this.setState({ payment_provider: event.target.value })
                                                    }}
                                                    onBlur={handleBlur}
                                                    placeholder=" "
                                                    className={
                                                        errors.payment_provider && touched.payment_provider ?values.payment_provider==''? 'empty_select floating-input form-control error': 'floating-input form-control error' :values.payment_provider==''? 'floating-input form-control empty_select ': 'floating-input form-control'
                                                    }
                                                >
                                                    <option value="">{T("Select your payment provider")}</option>
                                                    <option selected={values.payment_provider == 'korta'} value="korta">KORTA</option>
                                                    <option selected={values.payment_provider == 'borgun'} value="borgun">Borgun</option>
                                                </select>
                                                <span className="highlight"></span>
                                                <label>{T("Payment provider")}</label>
                                                {errors.payment_provider &&
                                                    touched.payment_provider && <p className="error">{errors.payment_provider}</p>
                                                }
                                            </div>
                                        </div>
                                        {values.payment_provider == 'borgun' &&
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="borgunPaymentApi"
                                                        type="text"
                                                        value={values.borgunPaymentApi == null ? '' : values.borgunPaymentApi}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        placeholder=" "
                                                        className={
                                                            errors.borgunPaymentApi && touched.borgunPaymentApi ? 'floating-input form-control error' : 'floating-input form-control'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label >{T("Issuer payment api information")}</label>
                                                    {errors.borgunPaymentApi &&
                                                        touched.borgunPaymentApi && <p className="error">{errors.borgunPaymentApi}</p>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {values.payment_provider == 'korta' &&
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="kortaMerchant"
                                                        type="text"
                                                        value={values.kortaMerchant == null ? '' : values.kortaMerchant}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder=" "
                                                        className={
                                                            errors.kortaMerchant && touched.kortaMerchant ? 'floating-input form-control error' : 'floating-input form-control'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label >{T("merchant")}</label>
                                                    {errors.kortaMerchant &&
                                                        touched.kortaMerchant && <p className="error">{errors.kortaMerchant}</p>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {values.payment_provider == 'korta' &&
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="kortaTerminal"
                                                        type="text"
                                                        value={values.kortaTerminal == null ? '' : values.kortaTerminal}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder=" "
                                                        className={
                                                            errors.kortaTerminal && touched.kortaTerminal ? 'floating-input form-control error' : 'floating-input form-control'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label >{T("terminal")}</label>
                                                    {errors.kortaTerminal &&
                                                        touched.kortaTerminal && <p className="error">{errors.kortaTerminal}</p>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {values.payment_provider == 'korta' &&
                                            <div className="form-group">
                                                <div className="floating-label_group">
                                                    <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                        id="kortaSecretCode"
                                                        type="text"
                                                        value={values.kortaSecretCode == null ? '' : values.kortaSecretCode}
                                                        onChange={(event) => {
                                                            handleChange(event)
                                                        }}
                                                        onBlur={handleBlur}
                                                        placeholder=" "
                                                        className={
                                                            errors.kortaSecretCode && touched.kortaSecretCode ? 'floating-input form-control error' : 'floating-input form-control'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label >{T("secret code")}</label>
                                                    {errors.kortaSecretCode &&
                                                        touched.kortaSecretCode && <p className="error">{errors.kortaSecretCode}</p>
                                                    }

                                                </div>
                                            </div>
                                        }
                                        {errors.errmsg &&
                                            <p className="error">{errors.errmsg}</p>
                                        }
                                        {status && status.msg &&
                                            <p className="success">{status.msg}</p>}
                                        <div className="profile-form_btn">
                                            <input type="submit" name="" disabled={(JSON.stringify(touched) == "{}") || (JSON.stringify(errors) != "{}") || values.ContactName == "" || values.ContactEmail == "" || values.ContactMobile == "" || values.CompanyName == "" || values.CompanyIdNo == "" || (values.CompanyEmail == "" && issuerRole != 'Issuer-Account-User') || (this.state.payment_provider == 'korta'&& (values.kortaMerchant !== '' || values.kortaTerminal !== '' || values.kortaSecretCode !== '')) && (this.state.payment_provider == 'borgun' && values.borgunPaymentApi !== '')} className={(JSON.stringify(touched) != "{}") && !this.state.loader && (JSON.stringify(errors) == "{}") && values.ContactName != "" && values.ContactEmail != "" && values.ContactMobile != "" && values.CompanyName != "" && values.CompanyIdNo != "" && (values.CompanyEmail != "" || issuerRole == 'Issuer-Account-User') || ((this.state.payment_provider == 'korta' && values.kortaMerchant !== '' && values.kortaTerminal !== '' && values.kortaSecretCode !== '') || (this.state.payment_provider == 'borgun' && values.borgunPaymentApi !== '')||this.state.payment_provider=='') ? 'isActive' : 'isNotActive'} value={T("Update profile")} />
                                        </div>
                                        <ReactTooltip />
                                    </form>
                                </div>
                            );
                        }
                    }
                </Formik >
                <ActiveAccountUsers planId={this.state.planId}
                subscription_discount={this.state.subscription_discount}
                    subscriptionPaymentType={this.state.subscriptionPaymentType}
                    template_price={this.state.template_price}
                    subscription_discount={this.state.subscription_discount}
                    subscriptionState={this.state.subscriptionState}
                    SubscriptionData={this.state.SubscriptionData}
                    discountPeriodExpired={this.state.discountPeriodExpired}
                    cardInfo={this.state.cardInfo}
                    issuer_associated_user={this.state.issuer_associated_user} />
            </div>

        );
    }
};

export default ProfileTab;
