import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import { AccountNavigationTab, CodesTab, ViewAllTemplates, ViewAllScanners, PassStatisticsTab, MembersTab, ProfileTab, SubscriptionTab, ChangePassword, MyPassTemplates } from './../components/Dashboard';
import { Redirect } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import MyScanners from '../components/Dashboard/MyScanners';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Dashboard',
            loader: false,
            AccountData: {},
            paymentModes: [],
            orderedTemplates: [],
        };
    }
    componentDidMount() {
        const CurrentTab = functions.getUrlSlice('/', '1');
        if(CurrentTab == 'my_scanner' || CurrentTab == 'scanner' || CurrentTab == 'license_holder' || CurrentTab == 'scanner_distribution' || CurrentTab == 'add_scanner' ){
            this.childMyscanner.getScannersList();
        }
     
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If unauthrized user
            setTimeout(() => {
                return <Redirect to='/signin' />
            }, 10000);
        } else {
            localStorage.removeItem('new_email_registered');
            this.getAccountData(); // Get AccountData
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    // Get Related contact us form data
    getAccountData(props = this.props) {
      
        api.getAccountData()
            .then(res => {
                this.setState({
                    loader: false,
                    AccountData: res.data.data,
                });
            })
            .catch(err => {
                this.setState({ loader: false })
            });
    }

    render() {
   
        const CurrentTab = functions.getUrlSlice('/', '1'); //Get Current 
        if (localStorage.getItem('loginISP_user_sesion') == null ) { // If unauthrized user
            setTimeout(() => {
                return <Redirect to='/signin' />
            }, 10000);
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);
        window.onorientationchange = () => {
            if (CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard') {
                window.location.reload();
            }
        }
        //Loader
        let loader = functions.getLoader(this.state.loader);
    

        return (
            <div className="wrapper block_element">
                <div className="inner-pages block_element">
                        {loader} 


                        {/* AccountNavigationTab */}
                        {
                            (CurrentTab == 'profile' || CurrentTab == 'subscription' || CurrentTab == 'subscriptions' || CurrentTab == 'change_password') ? <AccountNavigationTab CurrentTab={CurrentTab} /> : ''
                        }

                        {/* My pass templates */}
                        {
                            (CurrentTab == 'my_pass_templates' || CurrentTab == 'dashboard' || CurrentTab == 'codes' || CurrentTab == 'distribution' || CurrentTab == 'users-list') ? <MyPassTemplates CurrentTab={CurrentTab} /> : ''
                        }
                        {
                            (CurrentTab == 'my_scanner' || CurrentTab == 'scanner' || CurrentTab == 'license_holder' || CurrentTab == 'scanner_distribution' || CurrentTab == 'add_scanner') ? <MyScanners  
                            CurrentTab={CurrentTab} 
                            ref={instance => {
                                this.childMyscanner = instance;
                            }}
                            /> : ''
                        }
                        {/* END My pass templates */}

                        <div className={CurrentTab == 'subscription' || CurrentTab == 'subscriptions' ? "tab-content-subscription tab-content tab_background block_element" : "tab-content tab_background block_element"}>

                            <div className="container">
                                {/* Codes */}
                                {
                                    CurrentTab == 'view-all-templates' ? <ViewAllTemplates CurrentTab={CurrentTab} /> : ''
                                }
                                {
                                    CurrentTab == 'view-all-scanners' ? <ViewAllScanners CurrentTab={CurrentTab} /> : ''
                                }
                                {/* END Codes */}

                                {/* Profile */}
                                {
                                    CurrentTab == 'profile' ? <ProfileTab CurrentTab={CurrentTab} AccountData={this.state.AccountData} parentComponentDidMountFunction={(e) => this.componentDidMount()} /> : ''
                                }
                                {/* END Profile */}

                                {/* Available Subscriptions */}
                                {
                                    CurrentTab == 'subscription' ? <SubscriptionTab CurrentTab={CurrentTab} AccountData={this.state.AccountData} /> : ''
                                }
                                {
                                    CurrentTab == 'subscriptions' ? <SubscriptionTab SubscriptionPayment={true} CurrentTab={CurrentTab} AccountData={this.state.AccountData} /> : ''
                                }
                                {/* END Available Subscriptions */}

                                {/* Change Password */}
                                {
                                    CurrentTab == 'change_password' ? <ChangePassword CurrentTab={CurrentTab} AccountData={this.state.AccountData} /> : ''
                                }
                                {/* END Change Password */}
                            </div>

                        </div>
                    </div>
                </div>
        );
    }
};

export default Dashboard;
