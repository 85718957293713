import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import { Constants } from '../../config';
import Redirect from 'react-router/Redirect';
import $ from 'jquery';
import CustomToolTip from './CustomToolTip'
import { api } from "../../api";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


export default class UpgradeSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            redirect: false,
            Subscription_exceeded: false,
            templatesData: [],
        };
    }
    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getIssuerTemplates()
            .then(res => {
                if (res.data && res.data.status == 1) {
                    this.setState({
                        templatesData: res.data.data.templates
                    })
                }
            })
            .catch(err => {
            });

        let DistributedPasses = this.props.SubscriptionState && this.props.SubscriptionState.distributedPasses ? this.props.SubscriptionState.distributedPasses : 0;
        let MaxPasses = this.props.SubscriptionState && this.props.SubscriptionState.maxPasses ? this.props.SubscriptionState.maxPasses : 0;
        let exceeded = this.props.SubscriptionState && this.props.SubscriptionState.distributedPasses && this.props.SubscriptionState.maxPasses && this.props.SubscriptionState.distributedPasses >= this.props.SubscriptionState.maxPasses ? true : false
        this.setState({
            Subscription_exceeded: exceeded
        })
    }
    redirectToSubscription() {
        this.setState({
            redirect: true
        })
    }

    render() {

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        const upgradeType = this.props.upgradeType ? this.props.upgradeType : '';
        let loader = functions.getLoader(this.state.loader);
        const SubscriptionState = this.props.SubscriptionState;
        const getTrialDuration = this.props.getTrialDuration;
        const SubscriptionData = this.props.SubscriptionData;
        const DiscountPeriodExpired = this.props.DiscountPeriodExpired;
        const ActiveSubscriptionName = SubscriptionState && SubscriptionState.subscriptionName ? SubscriptionState.subscriptionName : "";
        const ActiveSubscriptionId = SubscriptionState && SubscriptionState.subscription_id ? SubscriptionState.subscription_id : 0;
        const MaxPasses = SubscriptionState && SubscriptionState.maxPasses ? SubscriptionState.maxPasses : 0;
        const Subscription_exceeded = this.props.SubscriptionState && this.props.SubscriptionState.distributedPasses && this.props.SubscriptionState.maxPasses && this.props.SubscriptionState.distributedPasses >= this.props.SubscriptionState.maxPasses ? true : false
        const TemplateLimit = this.props.SubscriptionState && this.props.SubscriptionState.passTemplates ? this.props.SubscriptionState.passTemplates : 0;
        const TemplateTypeLimit = this.props.SubscriptionState && this.props.SubscriptionState.passTemplatesTypes ? this.props.SubscriptionState.passTemplatesTypes : 0;
        let CurrentTemplateType = {};
        this.state.templatesData.map((val, key) => {
            if (val.pass_type == "pass.snjall.memberpass") {
                CurrentTemplateType[val.pass_type] = true;
            }
            if (val.pass_type == "pass.snjall.coupon") {
                CurrentTemplateType[val.pass_type] = true;

            }
            if (val.pass_type == "pass.snjall.punchcard") {
                CurrentTemplateType[val.pass_type] = true;

            }
            if (val.pass_type == "pass.snjall.eventticket") {
                CurrentTemplateType[val.pass_type] = true;

            }
        })
        if (this.state.redirect) {
            return (<Redirect to="/subscription" />)
        }
        let userPermissions = functions.getUserPermissions();
        let issuerRole = functions.getIssuerRole();
        let upgrade_subscription = functions.getUserPermissions().issueraccountuserpermission && functions.getUserPermissions().issueraccountuserpermission.upgrade_subscription ? functions.getUserPermissions().issueraccountuserpermission.upgrade_subscription : '0';
        return (
            <div className={ActiveSubscriptionId ? "modal-dialog modal-dialog-centered have-subscription " : ' unsbscribed_account modal-dialog modal-dialog-centered'}>
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <div className="sending_passes_heading">

                            <h3>{(ActiveSubscriptionId > 0 && (Subscription_exceeded || this.state.templatesData.length >= TemplateLimit || upgradeType == 'scanner')) ? T('You have exceeded your subscription limit') : (ActiveSubscriptionId > 0 ? T("Upgrade subscription") : T("Start by selecting subscription"))}</h3>


                            <span>{(ActiveSubscriptionId > 0 && (Subscription_exceeded || this.state.templatesData.length >= TemplateLimit || upgradeType == 'scanner')) ? upgradeType != 'scanner' ? T("Please upgrade your subscription if you want to add a Pass Template") : T("Please upgrade your subscription if you want to add a Scanner") : (ActiveSubscriptionId > 0 ? '' : T("Before you can order a Pass Template you need to select subscription"))}</span>
                        </div>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>
                    <div className="modal-body">
                        {loader}
                        <div className="subscription_table">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {SubscriptionData.map((val, key) => (
                                            <th key={"name" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>
                                                {val.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className={parseInt(this.props.subscription_discount) > 0 ?'with_discount':''}>
                                    <tr className="sub-row">
                                        <td>{T("Distribution Limit")}
                                            {ActiveSubscriptionId == 0 &&
                                                <CustomToolTip
                                                    toolTipText={T("This is the maximum number of valid passes in circulation by your customers") + '.'}
                                                    toolTipId={'1'} />
                                            }

                                        </td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"limit" + key} className={val.offer_price == 0 || val.name.trim() == 'Professional' ? "starter" : ''} data-class={val.id == ActiveSubscriptionId ? 'active-plan' : ''} >
                                                {/* {val.max_passes} */}
                                                {SubscriptionState && SubscriptionState.maxPasses && val.id == ActiveSubscriptionId ?SubscriptionState.maxPasses<0 ?'0' : functions.toCurrency(SubscriptionState.maxPasses) : val.max_passes == -1 ? T("Negotiable") : functions.toCurrency(val.max_passes)}
                                                {/* <NumberFormat value={val.max_passes} displayType={'text'} thousandSeparator={true} /> */}
                                                {/* {val.offer_price == 0 && !DiscountPeriodExpired &&
                                                    <span><img src={StartFreePng} alt="" /></span>
                                                } */}
                                                {/* {val.name.trim() == 'Professional' &&
                                                    <span><img src={PopularPng} alt="" /></span>
                                                } */}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="sub-row">
                                        <td>{T("Pass Template Types")}
                                            {ActiveSubscriptionId == 0 &&
                                                <CustomToolTip
                                                    toolTipText={T("There are 4 different Pass Template types: coupon, ticket, punch and member") + '.'}
                                                    toolTipId={'2'} />
                                            }

                                        </td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"type" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{SubscriptionState && SubscriptionState.passTemplatesTypes && val.id == ActiveSubscriptionId ? SubscriptionState.passTemplatesTypes < 0 ? '0': functions.toCurrency(SubscriptionState.passTemplatesTypes) :val.pass_type == -1 ? T("Negotiable") : val.pass_type}</td>
                                        ))}
                                    </tr>
                                    <tr className="sub-row">
                                        <td>{T("Pass Templates")}
                                            {ActiveSubscriptionId == 0 &&
                                                <CustomToolTip
                                                    toolTipText={T("We build a Pass Template for you. Your customer personal pass is then made from that Pass Template") + '.'}
                                                    toolTipId={'3'} />
                                            }

                                        </td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"temp" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{SubscriptionState && SubscriptionState.passTemplates && val.id == ActiveSubscriptionId ? SubscriptionState.passTemplates <0 ? '0' : functions.toCurrency(SubscriptionState.passTemplates) :val.pass_template == -1 ? T("Negotiable") :  val.pass_template}</td>
                                        ))}
                                    </tr>
                                    <tr className="sub-row">
                                        <td>{T("Issuer Smartpages")}</td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"temp" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{SubscriptionState && SubscriptionState.issuer_smartpages && val.id == ActiveSubscriptionId ? SubscriptionState.issuer_smartpages<0 ? '0 '+T("user") : SubscriptionState.issuer_smartpages > 1 ? SubscriptionState.issuer_smartpages + " " + T("users") : SubscriptionState.issuer_smartpages + " " + T("user") : val.issuer_smartpages == -1 ? T("Negotiable") : val.issuer_smartpages > 1 ? val.issuer_smartpages + " " + T("users") : val.issuer_smartpages + " " + T("user")}</td>
                                        ))}
                                    </tr>
                                    <tr className="sub-row">
                                        <td>{T("Usage Statistics")}</td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"ISP" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{SubscriptionState && SubscriptionState.usage_statistics && val.id == ActiveSubscriptionId ? T(Constants.UsageStatistics[SubscriptionState.usage_statistics]) :T(Constants.UsageStatistics[val.usage_statistics])}</td>
                                        ))}
                                    </tr>
                                    {/* <tr className="sub-row">
                                        <td>SmartScanner licenses</td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"temp" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{val.smartscanner_app == -1 ? T("Negotiable") : val.smartscanner_app > 1 ? val.smartscanner_app + " users" : val.smartscanner_app + " user"}</td>

                                            // <td key={"SSA" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                        ))}
                                    </tr> */}
                                    {ActiveSubscriptionId == 0 &&
                                        <tr >
                                            <td> {T("Smartscanner app")}</td>
                                            {SubscriptionData.map((val, key) => (
                                                // <td key={"temp" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>{val.smartscanner_app == -1 ? T("Negotiable") : val.smartscanner_app > 1 ? val.smartscanner_app + " users" : val.smartscanner_app + " user"}</td>

                                                <td key={"SSA" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                            ))}
                                        </tr>
                                    }

                                    {ActiveSubscriptionId == 0 &&
                                        <tr>
                                            <td>{T("Smartwallet app")}</td>
                                            {SubscriptionData.map((val, key) => (
                                                <td key={"USS" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                            ))}
                                        </tr>
                                    }
                                    {ActiveSubscriptionId == 0 &&
                                        <tr>
                                            <td>{T("Customer Self Service")}
                                                {ActiveSubscriptionId == 0 &&
                                                    <CustomToolTip
                                                        toolTipText={T("You don't need any hardware if you use the Customer self service functionality") + "."}
                                                        toolTipId={'4'}
                                                        toolTipSubText={T("This functionality is only available in the Smartwallet app") + "."} />
                                                }

                                            </td>
                                            {SubscriptionData.map((val, key) => (
                                                <td key={"US" + key}><span className="check_service"><i className="fa fa-check"></i></span></td>
                                            ))}
                                        </tr>
                                    }
                                    <tr className={!DiscountPeriodExpired ? "table-price" : "without_disscount table-price"}>
                                        <td></td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"pricing" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>
                                                <div className={val.name != "Expert" && val.name != 'Starter' ? 'extra_space' : 'starter_price'}>
                                                    
                                                    {val.name != "Expert" && val.name == 'Starter' && !DiscountPeriodExpired && parseInt(this.props.subscription_discount) == 0  &&
                                                        // <span className="price_cutoff">ISK {val.price}</span>
                                                        <span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                                        // <span className="price_cutoff">ISK  <NumberFormat value={val.price} displayType={'text'} thousandSeparator={true} /></span>
                                                    }
                                                    {val.name != "Expert" && parseInt(this.props.subscription_discount) > 0 &&
                                                        // <span className="price_cutoff">ISK {val.price}</span>
                                                        <span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                                        // <span className="price_cutoff">ISK  <NumberFormat value={val.price} displayType={'text'} thousandSeparator={true} /></span>
                                                    }
                                                    {/* <span className="price_kr">ISK {!DiscountPeriodExpired ? val.offer_price : val.price}</span> */}
                                                    {val.name != "Expert" && <span className={val.id == ActiveSubscriptionId && val.name != "Starter" ? 'active-plan_span price_kr' : 'price_kr'} >ISK {this.props.subscription_discount > 0 ? functions.toCurrency(val.price -parseInt(val.price*this.props.subscription_discount/100) ):!DiscountPeriodExpired ? functions.toCurrency(val.offer_price) : functions.toCurrency(val.price)}</span>
                                                    }
                                                    {val.name != "Expert" && val.name == "Starter" && !DiscountPeriodExpired &&
                                                        // <span className="month_validity">For the first {getTrialDuration[0] > 1 ? (getTrialDuration[0] + " months ") : (getTrialDuration[0] > 0 ? getTrialDuration[0] + ' month ' : '')}{getTrialDuration[1] > 1 ? (getTrialDuration[1] + " days") : (getTrialDuration[1] > 0 ? getTrialDuration[1] + ' day' : '')}!</span>
                                                        <span className="month_validity">{T("Monthly for the first three months")}!</span>
                                                    }
                                                    {val.name != "Expert" && val.name != "Starter" && !DiscountPeriodExpired &&
                                                        // <span className="month_validity">For the first {getTrialDuration[0] > 1 ? (getTrialDuration[0] + " months ") : (getTrialDuration[0] > 0 ? getTrialDuration[0] + ' month ' : '')}{getTrialDuration[1] > 1 ? (getTrialDuration[1] + " days") : (getTrialDuration[1] > 0 ? getTrialDuration[1] + ' day' : '')}!</span>
                                                        <span className="month_validity">{T("Monthly")}!</span>
                                                    }
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr className="table-price table-action-tab">
                                        <td>
                                            <div>
                                                {!ActiveSubscriptionId &&
                                                    <span className="free_subscription_text" >
                                                        {T("Start FREE plan")}
                                                    </span>
                                                }
                                            </div>
                                        </td>
                                        {SubscriptionData.map((val, key) => (
                                            <td key={"select" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>
                                                <div>
                                                    {val.id != ActiveSubscriptionId &&
                                                        <button className="free_subsription_btns" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && upgrade_subscription == '0') && (ActiveSubscriptionId && ActiveSubscriptionId < val.id) ? '#DowngradePlan' : val.name == "Expert" ? '#modalcontactus' : ActiveSubscriptionName == "Expert" ? '#DowngradePlan' : ActiveSubscriptionId < val.id ? ("#myOrderPopup" + key) : ('#DowngradePlan')} onClick={val.name == "Expert" ? () => { $('#contactus_btn_reset').trigger('click'); $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); } : () => {
                                                            $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); $(document.body).addClass('modal-open-again-order'); $('input#testback1').trigger('click');
                                                        }}
                                                        >
                                                            {val.name == "Expert" ? T('Contact us') : (ActiveSubscriptionName == "Expert" && ActiveSubscriptionId ? T('Select') : ActiveSubscriptionId < val.id && ActiveSubscriptionId ? T('Upgrade') : (val.offer_price == 0 && !DiscountPeriodExpired && !ActiveSubscriptionId ? T("FREE") : val.name == "Expert" ? T("Contact us") : ActiveSubscriptionId == 0 ? T("Start plan") : T("Select")))}
                                                        </button>
                                                    }
                                                    {val.id == ActiveSubscriptionId &&
                                                        <span>{T("Your subscription")}</span>
                                                    }
                                                    <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup" + val.id} data-target={"#OrderConfirmation" + val.id} style={{ display: 'none' }} onClick={() => { $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); }} >Open Modal</button>
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            {/* <button onClick={() => {
                                $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" });
                            }} data-toggle="modal" data-target="#modalcontactus">Contact Us</button> */}
                        </div>
                        <div className="mobile_subscription_table">
                            {SubscriptionData.map((val, key) => (
                                <div className={val.id == ActiveSubscriptionId ? 'active-plan pachageArea' : 'pachageArea'}>
                                    <h2><div>{val.name}
                                    </div></h2>
                                    <div className="AreaFull">
                                        <div className="HeadingMain">
                                            {T("Distribution Limit")}
                                        </div>
                                        <div className={val.max_passes == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {SubscriptionState && SubscriptionState.maxPasses && val.id == ActiveSubscriptionId ?SubscriptionState.maxPasses<0 ? '0' : functions.toCurrency(SubscriptionState.maxPasses) :val.max_passes == -1 ? T("Negotiable") :functions.toCurrency(val.max_passes)}
                                        </div>
                                    </div>

                                    <div className="AreaFull whiteBG">
                                        <div className="HeadingMain">
                                            {T("Pass Template Types")}
                                        </div>
                                        <div className={val.pass_type == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {SubscriptionState && SubscriptionState.passTemplatesTypes &&  val.id == ActiveSubscriptionId ? SubscriptionState.passTemplatesTypes<0 ? '0': functions.toCurrency(SubscriptionState.passTemplatesTypes) :val.pass_type == -1 ? T("Negotiable") : val.pass_type}
                                        </div>
                                    </div>

                                    <div className="AreaFull ">
                                        <div className="HeadingMain">
                                            {T("Pass Templates")}
                                        </div>
                                        <div className={val.pass_template == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {SubscriptionState && SubscriptionState.passTemplates && val.id == ActiveSubscriptionId ? SubscriptionState.passTemplates ? '0' : functions.toCurrency(SubscriptionState.passTemplates) :val.pass_template == -1 ? T("Negotiable") : val.pass_template}
                                        </div>
                                    </div>

                                    <div className="AreaFull whiteBG">
                                        <div className="HeadingMain">
                                            {T("Issuer Smartpages")}
                                        </div>
                                        <div className={val.issuer_smartpages == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {SubscriptionState && SubscriptionState.issuer_smartpages && val.id == ActiveSubscriptionId ? SubscriptionState.issuer_smartpages<0 ? '0 ' + T("user") : SubscriptionState.issuer_smartpages > 1 ? SubscriptionState.issuer_smartpages + " " + T("users") : SubscriptionState.issuer_smartpages + " " + T("user") : val.issuer_smartpages == -1 ? T("Negotiable") :  val.issuer_smartpages > 1 ? val.issuer_smartpages + " " + T("users") : val.issuer_smartpages + " " + T("user")}
                                        </div>
                                    </div>
                                    <div className="AreaFull">
                                        <div className="HeadingMain">
                                            {T("Usage Statistics")}
                                        </div>
                                        <div className={val.usage_statistics == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {SubscriptionState && SubscriptionState.usage_statistics && val.id == ActiveSubscriptionId ? T(Constants.UsageStatistics[SubscriptionState.usage_statistics]) :T(Constants.UsageStatistics[val.usage_statistics])}
                                        </div>
                                    </div>
                                    {/* <div className="AreaFull whiteBG">
                                        <div className="HeadingMain">
                                        SmartScanner licenses
							        </div>
                                        <div className={val.smartscanner_app == -1 ? "ValueMain NegotiableVlaueMain" : "ValueMain"}>
                                            {val.smartscanner_app == -1 ? T("Negotiable") : val.smartscanner_app > 1 ? val.smartscanner_app + " users" : val.smartscanner_app + " user"}
                                        </div>
                                    </div> */}
                                    <span className={val.name != "Expert" ? "_MobileServicePriceSection " : "_ExpertMobileServicePriceSection  _MobileServicePriceSection"}>
                                        {val.name != "Expert" &&
                                            <div className="SubscriptionPlansBoxMobile__TextWrapper-sc-5ufmgf-3">
                                                {val.name == 'Starter' && !DiscountPeriodExpired  && parseInt(this.props.subscription_discount) == 0 && <div className="SubscriptionPlansBoxMobile__StrikeThroughPrice">ISK {functions.toCurrency(val.price)}</div>
                                                }
                                                {parseInt(this.props.subscription_discount) > 0 && <div className="SubscriptionPlansBoxMobile__StrikeThroughPrice">ISK {functions.toCurrency(val.price)}</div>
                                                }
                                                <div className="SubscriptionPlansBoxMobile__Price">ISK { parseInt(this.props.subscription_discount) > 0 ? functions.toCurrency(val.price-parseInt(val.price * this.props.subscription_discount/100)) : !DiscountPeriodExpired ? functions.toCurrency(val.offer_price) : functions.toCurrency(val.price)}</div>
                                                {val.name == 'Starter' && !DiscountPeriodExpired &&
                                                    <div className="SubscriptionPlansBoxMobile__Text"> {T("Monthly for the first three months")}!</div>
                                                }
                                                {val.name != 'Starter' && !DiscountPeriodExpired && <div className="SubscriptionPlansBoxMobile__Text"> {T("Monthly")}!</div>
                                                }
                                            </div>
                                        }
                                    </span>
                                    <div key={"select" + key} className={val.id == ActiveSubscriptionId ? 'active-plan' : ''}>
                                        <div>
                                            {val.id != ActiveSubscriptionId &&
                                                <button className="free_subsription_btns" data-toggle="modal" data-target={(issuerRole == 'Issuer-Account-User' && upgrade_subscription == '0') && (ActiveSubscriptionId && ActiveSubscriptionId < val.id) ? '#DowngradePlan' : val.name == "Expert" ? '#modalcontactus' : ActiveSubscriptionName == "Expert" ? '#DowngradePlan' : ActiveSubscriptionId < val.id ? ("#myOrderPopup" + key) : ('#DowngradePlan')} onClick={val.name == "Expert" ? () => { $('#contactus_btn_reset').trigger('click'); $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); } : () => {
                                                    $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); $(document.body).addClass('modal-open-again-order'); $('input#testback1').trigger('click');
                                                }}
                                                >
                                                    {val.name == "Expert" ? T("Contact us") : (ActiveSubscriptionName == "Expert" && ActiveSubscriptionId ? T('Select') : ActiveSubscriptionId < val.id && ActiveSubscriptionId ? T('Upgrade') : (val.offer_price == 0 && !DiscountPeriodExpired && !ActiveSubscriptionId ? T("FREE") : val.name == "Expert" ? T("Contact us") : ActiveSubscriptionId == 0 ? T("Start plan") : T("Select")))}
                                                </button>
                                            }
                                            {val.id == ActiveSubscriptionId &&
                                                <span>{T("Your subscription")}</span>
                                            }
                                            <button type="button" data-toggle="modal" className="OrderConfirmationPopup" id={"OrderConfirmationPopup" + val.id} data-target={"#OrderConfirmation" + val.id} style={{ display: 'none' }} onClick={() => { $(".select_subscription [data-dismiss=modal]").trigger({ type: "click" }); }} >Open Modal</button>
                                        </div>
                                    </div>
                                    {/* <div className={'table_price_mobile'}>
                                    < span className="price_cutoff">ISK {functions.toCurrency(val.price)}</span>
                                    <span className="price_kr">ISK {functions.toCurrency(val.offer_price)}</span>
                                    <span className="month_validity">For the first {getTrialDuration > 1 ? (getTrialDuration + " days") : (getTrialDuration + " day")}</span>
                                </div>
                                <div className={"subscription_table_mobile_btn"}>
                                    <button className="free_subsription_btns" data-toggle="modal" data-target={"#myOrderPopup" + key}>
                                        {val.offer_price == 0 ? 'Free' : 'Select'}
                                    </button>
                                </div> */}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        )
    }
}