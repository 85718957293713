import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import Dropzone from 'react-dropzone'
import ReactFileReader from 'react-file-reader';
import { api } from "../../api";
import { functions } from "../../functions";
import MemberTable from './MemberTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import SampleCSV from '../../assets/images/members.csv'
import LogoQr from '../../assets/images/logo_Qr.png';
import mailDelivered from '../../assets/images/maildelivered.png';
import mailNotDelivered from '../../assets/images/mailnotdelivered.png';
import mailBounce from '../../assets/images/mailbounce.png';
import mailOnTheWay from '../../assets/images/mailontheway.png';
import DragNDropBlack from '../../assets/images/dragndropblack.png';
import DragNDropWhite from '../../assets/images/dragndropwhite.png';
import AddUser from './AddUser';
import SendUser from './SendUser';
import MUIDataTable from "mui-datatables";
import CustomToolbarSelect from "./CustomToolbarSelect";
import { findDOMNode } from 'react-dom';
import $ from "jquery";
import { array } from 'prop-types';
import { replace } from 'formik';
import moment from 'moment';
import RequestConfirmPopup from './RequestConfirmPopup';
import { Redirect } from 'react-router';
import Magnifyingglass from '../../assets/images/Magnifyingglass.png'
import DeleteUser from './DeleteUser';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


class MembersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Members',
            loader: false,
            modalLoader: false,
            optionChanged: false,
            memberTemps: [],
            fileContent: [],
            membersList: [],
            searchResult: [],
            rowsSelected: [],
            selectedData: [],
            confirmSelectedData: [],
            userChecked: false,
            singleTemplate: {},
            selectedOption: {},
            browsedFile: {},
            isbrowsedFile: false,
            allEmailList: [],
            emailMatched: false,
            distributedUser: {},
            issuerFields: [],
            totalInputFields: [],
            Subscription_exceeded: false,

        };
        this.handleFiles = this.handleFiles.bind(this);
        this.newMemberData = this.newMemberData.bind(this);
    }
    componentDidMount = () => {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
       
        this.getTemplateStats();
        this.setState({
            rowsSelected: [], selectedData: [], userChecked: false,
            singleTemplate: this.props.singleTemplate ? this.props.singleTemplate : {}
        }, () => {
            if (this.props.contentTemplate > 0) {
                this.childAdduser.componentDidMount();
                this.childSendpass.componentDidMount();
            }
        })
        let exceeded = this.props.SubscriptionState && this.props.SubscriptionState.distributedPasses && this.props.SubscriptionState.maxPasses && this.props.SubscriptionState.distributedPasses >= this.props.SubscriptionState.maxPasses ? true : false
        this.setState({
            Subscription_exceeded: exceeded
        })
    }

    newMemberData(data) {
        this.setState({
            ...this.state,
            membersList: data
        })
    }

    getTemplateStats() {
        // this.setState({ loader: true });
        let tempId = this.props.currentTempId;
        var PostData = {
            // access_token: functions.getAccessToken(),
            tempId: tempId
        };
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getTemplateStats(PostData)
            .then(res => {
                if (res.data && res.data.status == 1) {
                    let emailList = [];
                    if (res.data.data.distributedUser && res.data.data.distributedUser.data && res.data.data.distributedUser.data.distributedUser) {
                        res.data.data.distributedUser.data.distributedUser.map((val, memkey) => {
                            emailList.push(val.customerInfo[0].email)
                        })
                    }
                    if (res.data.data.formForDistributed && res.data.data.formForDistributed.status != 1) {
                        toast.dismiss();
                        let statusVal = '';
                        if (res.data.data.formForDistributed.template_status) {
                            switch (res.data.data.formForDistributed.template_status) {
                                case 'R':
                                    statusVal = 'Template is redeemed';
                                    break;
                                case 'I':
                                    statusVal = 'Template is invalid';
                                    break;
                                case 'D':
                                    statusVal = 'Template is deleted'
                                    break;
                                case 'PR':
                                    statusVal = 'Template is restricted'
                                    break;
                                case 'E':
                                    statusVal = 'Template is expired'
                                    break;
                                case 'U':
                                    statusVal = 'Template is used'
                                    break;
                                default:
                                    statusVal = res.data.data.formForDistributed.message
                            }
                        } else {
                            statusVal = res.data.data.formForDistributed.message
                        }
                        toast.error(statusVal, { hideProgressBar: true })
                    }
                    this.setState({
                        loader: false,
                        allEmailList: emailList,
                        rowsSelected: [],
                        issuerFields: res.data.data.formForDistributed,
                        distributedUser: res.data.data.distributedUser,
                        membersList: res.data.data.distributedUser && res.data.data.distributedUser.data ? res.data.data.distributedUser.data.distributedUser : [],
                        totalInputFields: res.data.data.distributedUser && res.data.data.distributedUser.data ? res.data.data.distributedUser.data.issuerInputFields : [],
                    })
                }
                else {
                    this.setState({
                        loader: false,
                    });
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            });
        // this.setState({ loader: false })
    }


    Contains = (target) => {
        let inputFields = this.state.issuerFields && this.state.issuerFields.data && this.state.issuerFields.data.issuerInputFields ? this.state.issuerFields.data.issuerInputFields : [];
        let totalfields = [];
        totalfields.push('Email');
        if (Object.keys(inputFields).length > 0) {
            for (const [key, val] of Object.entries(inputFields)) {
                if (val.field_value != "Email") {
                    totalfields.push(val.field_value);
                }
            }
        }
        var value = 0;
        let missingFields = [];
        totalfields.forEach(function (word) {
            //   value = value + target.toLowerCase().includes(word.toLowerCase());
            if (!target.toLowerCase().includes(word.toLowerCase())) {
                missingFields.push(word);
            }
        });
        return missingFields;
    }


    csvJSON = (csv) => {
        const lines = csv.split('\n')
        const result = []
        let headers = [];
        const csvheaders = lines[0].split(',')
        csvheaders.forEach(element => {
            headers.push(element.replace(/['"]+/g, '').trim());
        });

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue
            const obj = {}
            const trimedline = [];
            const currentline = lines[i].split(',')
            currentline.forEach(element => {
                trimedline.push(element.replace(/['"]+/g, ''));
            });
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = trimedline[j]
            }
            result.push(obj)
        }
        return result
    }
    validateFile = () => {
        let errors = {};
        let formIsValid = true;

        return formIsValid;
    }
    handleFiles = (files) => {
        toast.dismiss();
        this.setState({ modalLoader: true });
        var reader = new FileReader();
        reader.onload = function (e) {
            // Use reader.result
            let fileList = [];
            let newArray = ((reader.result).split("\n").filter(Boolean));
            let inputFields = this.state.issuerFields && this.state.issuerFields.data && this.state.issuerFields.data.issuerInputFields ? this.state.issuerFields.data.issuerInputFields : [];
            let totalfields = [];
            let errors = false;
            if (Object.keys(inputFields).length > 0) {
                for (const [key, val] of Object.entries(inputFields)) {
                    if (val.field_value != "Email") {
                        totalfields.push({ "field_type": val.field_value, "id": val.id });
                    }
                }
            }
            let contains = this.Contains(newArray[0]);
            if (contains.length > 0) {
                toast.error(`${contains.toString()} field are missing in file`, { hideProgressBar: true });
                this.setState({ modalLoader: false });
            } else {
                let allData = this.csvJSON(reader.result);
                let currentEmailList = [];
                if (allData.length < 1) {
                    errors = true;
                    toast.error("CSV file is empty", { hideProgressBar: true })
                } else {
                    allData.map((value, key) => {
                        let field = [];
                        let mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        if (!(mailformat.test(value.email))) {
                            toast.error(`Invalid email in row ${key + 1}`, { hideProgressBar: true })
                            toast.error("CSV data isn't saved", { hideProgressBar: true })
                            errors = true;
                        }
                        totalfields.map((val) => {
                            let data = {};
                            if (value[val.field_type] == '') {
                                toast.error(`${val.field_type} is missing in row ${key + 1}`, { hideProgressBar: true })
                                toast.error("CSV data isn't saved", { hideProgressBar: true })
                                errors = true;
                            } else {
                                data = {
                                    id: val.id,
                                    value: value[val.field_type.trim()]
                                };
                                if (errors != true) {
                                    field.push(data);
                                }
                            }
                        })
                        if (errors != true) {
                            if (this.state.allEmailList.indexOf(value.email) !== -1) {
                                this.setState({
                                    emailMatched: true
                                })
                            } else {
                                if (currentEmailList.indexOf(value.email) !== -1) {
                                } else {
                                    currentEmailList.push(value.email);
                                    fileList.push({ field, email: value.email });
                                }
                            }
                        }
                    })
                }
                if (errors != true) {
                    this.setState({
                        ...this.state,
                        fileContent: fileList
                    },
                        () => {
                            this.addMembersToDB();
                        })
                }
                this.setState({ modalLoader: false });
            }
        }.bind(this)
        reader.readAsText(files[0]);
    }

    addMembersToDB(props = this.props) {
        this.setState({ modalLoader: true });
        if (this.state.fileContent.length > 0) {
            toast.dismiss();
            let templateid = this.props.currentTempId;
            let PostData = {
                // access_token: functions.getAccessToken(),
                usersList: this.state.fileContent
            };
            api.addMembersToDB(templateid, PostData)
                .then(res => {
                    if (res.data.status == 1) {
                        this.setState({
                            modalLoader: false,
                            browsedFile: [],
                            isbrowsedFile: false,
                            emailMatched: false,
                            fileContent: [],
                            membersList: res.data.data.membersData
                        },
                            
                            // () => { window.reload() }
                        );
                        this.closeModal("uploadedUser");
                    } else {
                        this.setState({
                            modalLoader: false,
                            // membersList: []
                        });
                        toast.error(res.data.message, { hideProgressBar: true })
                    }
                    this.componentDidMount();

                }).catch(err => {
                    this.setState({ modalLoader: false })
                });
        }
        else {
            toast.dismiss();
            if (this.state.emailMatched) {
                toast.error("Email id already exists in the user list.", { hideProgressBar: true });
                this.setState({ emailMatched: false, modalLoader: false })
            } else {
                toast.error("Invalid CSV file format. Please check you file.", { hideProgressBar: true });
            }
        }
    }


    handleTableAction() {
        this.setState({ optionChanged: false })
    }





    checkedUser = (selected) => {
        let checkedData = []
        let allMember = this.state.membersList;
        selected.forEach(id => {
            for (let i = 0; i <= allMember.length; i++) {
                if (id.dataIndex == i) {
                    checkedData.push({ id: allMember[i].customerInfo[0].id, email: allMember[i].customerInfo[0].email });
                }
            }
        });
        this.setState({
            confirmSelectedData: checkedData,
            selectedData: checkedData,
        })
        if (selected.length > 0) {
            this.setState({
                userChecked: true
            })
        }
        else {
            this.setState({
                userChecked: false
            })
        }
    }
    selectedCsvFile = (files) => {
        let fileExt = files.length > 0 ? files[0].name.substring(files[0].name.lastIndexOf('.') + 1) : '';
        if (files.length > 0 && files[0].type == "text/csv" || (files.length > 0 && fileExt == 'csv')) {
            if (document.getElementById("uploadUpload")) {
                document.getElementById("uploadUpload").focus();
            }
            if (document.getElementById('browsedCsvName')) {
                document.getElementById('browsedCsvName').value = files[0].name;
                this.setState({
                    isbrowsedFile: true,
                    browsedFile: files
                })
            }
            if (document.getElementById("reactFileReaderBtn")) {
                document.getElementById("reactFileReaderBtn").blur();
            }
            if (document.getElementById('browsedCsvError')) {
                document.getElementById('browsedCsvError').innerHTML = "";
            }
        } else {
            toast.dismiss();
            this.closeDragnDrop()
            toast.error("Please select a .csv file to add user", { hideProgressBar: true })
        }

    }

    closeModal = (type) => {
        if (type == "addUser") {
            $(".adduser [data-dismiss=modal]").trigger("click");
            $("#adduserConfirmbtn").trigger({ type: "click" });
            this.componentDidMount();
        }
        else if (type == "sendUser") {
            this.setState({ rowsSelected: [], selectedData: [], userChecked: false })
            $(".senduser [data-dismiss=modal]").trigger("click");
            $("#senduserConfirmbtn").trigger({ type: "click" });
            this.componentDidMount();
        }
        else if (type == "uploadedUser") {
            $(".uploadUser [data-dismiss=modal]").trigger("click");
            $("#adduserConfirmbtn").trigger({ type: "click" });
            this.componentDidMount();
        }
        else if (type == "deleteUser") {
            $(".deleteUser [data-dismiss=modal]").trigger("click");
        }

    }

    DownloadCsv = () => {
        this.setState({ rowsSelected: [], selectedData: [], userChecked: false })
        setTimeout(() => {
            $("#members .status_main button[title='Download CSV']").trigger("click");
        }, 500);
    }
    DownloadCsvTemplate = () => {
        this.setState({ rowsSelected: [], selectedData: [], userChecked: false })
        setTimeout(() => {
            $("#members .status_main1 button[title='Download CSV']").trigger("click");
        }, 500);
    }
    uploadCsvFile = (event) => {
        if (Object.keys(this.state.browsedFile).length === 0) {
            if (document.getElementById('browsedCsvError')) {
                document.getElementById('browsedCsvError').innerHTML = "Please select a .csv file to add users.";
            }
        }
        else {
            this.handleFiles(this.state.browsedFile);
        }
    }
    closeDragnDrop = () => {
        if (document.getElementById('uploadUser')) {
            if (document.getElementById('browsedCsvError')) {
                document.getElementById('browsedCsvError').innerHTML = "";
            }

            if (document.getElementById('browsedCsvName')) {
                document.getElementById('browsedCsvName').value = "";
            }
        }
        this.setState({
            isbrowsedFile: false,
            browsedFile: {}
        })
    }
    // closeDeleteUserPopup = () => {
    //     if (document.getElementById('deleteUser')) {
    //         document.getElementById('deleteUser').style.display = "none";
    //     }
    // }
    render() {

        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        if(this.props.isLicensePassTemplate){
            return <Redirect to='/dashboard' />
        }
        //Page_title
        let emailList = [];
        let colName1 = [{
            name: 'email',
            label: 'Email',
        }];

        let colName = [{
            name: "Status",
            label: T("Status"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let image = '';
                    if (tableMeta.rowData && tableMeta.rowData[0]) {
                        if (tableMeta.rowData[0] == 'delivered' || tableMeta.rowData[0] == 'open' || tableMeta.rowData[0] == 'click') {
                            image = <img src={mailDelivered} title={tableMeta.rowData[0]} />
                        } else if (tableMeta.rowData[0] == 'deferred' || tableMeta.rowData[0] == 'bounce') {

                            image = <img src={mailBounce} title={tableMeta.rowData[0]} />
                        } else if (tableMeta.rowData[0] == 'processed') {
                            image = <img src={mailOnTheWay} title={tableMeta.rowData[0]} />
                        } else if (tableMeta.rowData[0] == "null") {
                            image = <label>-</label>
                        } else if (tableMeta.rowData[0] == "") {
                            image = <label>-</label>
                        } else {
                            image = <img src={mailNotDelivered} title={tableMeta.rowData[0]} />
                        }
                        return image;
                    } else {
                        return (<label>-</label>)
                    }
                }
            }
        },
        {
            name: 'email',
            label: T("Email"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    emailList.push(value);
                    return <lable>{value}</lable>
                }
            }
        }];
        let data = [];
        const tableData = this.state.membersList ? this.state.membersList : [];
        let issuerFields = this.state.issuerFields;
        let rowsSelected = this.props.optionChanged ? [] : this.state.rowsSelected;
        let totalInputFields = this.state.totalInputFields;
        let valueInQuestion = '';
        let count = 0;
        if (this.state.membersList) {
            if (issuerFields.data && issuerFields.data.issuerInputFields && issuerFields.data.issuerInputFields.length > 0 && Object.keys(totalInputFields).length > 0) {
                for (const [key, value] of Object.entries(totalInputFields)) {
                    this.state.membersList.map((val, memkey) => {
                        val.issuerInputFields.map(datavalue => {
                            if (key == datavalue.field_id) {
                                valueInQuestion = datavalue.field_value ? datavalue.field_value : "-";
                            }
                        })
                        if (count == 0) {
                            data.push({
                                Status: val.sendgridMailStatus ? val.sendgridMailStatus.sendgrid_mail_event : "null",
                                email: val.customerInfo[0].email ? val.customerInfo[0].email : "-",
                                Date_added: val.passDistributedOn && val.passDistributedOn.date ? moment(val.passDistributedOn.date.substring(0, val.passDistributedOn.date.indexOf(' '))).format('DD.MM.YYYY') : "-",
                                [value]: valueInQuestion
                            })
                        } else {
                            data[memkey][value] = valueInQuestion
                        }
                        valueInQuestion = '-';
                    })
                    count++;
                }
            } else {
                this.state.membersList.map((val, memkey) => {
                    data.push({
                        Status: val.sendgridMailStatus ? val.sendgridMailStatus.sendgrid_mail_event : "null",
                        email: val.customerInfo[0].email ? val.customerInfo[0].email : "-",
                        Date_added: val.passDistributedOn && val.passDistributedOn.date ? moment(val.passDistributedOn.date.substring(0, val.passDistributedOn.date.indexOf(' '))).format('DD.MM.YYYY') : "-",
                    })
                })
            }
        } else {
            data = [];
        }
        // issuerFields.data && issuerFields.data.issuerInputFields.length > 0 ? (issuerFields.data.issuerInputFields).map((val,key) =>
        // {  })
        if (issuerFields.data && issuerFields.data.issuerInputFields && issuerFields.data.issuerInputFields.length > 0 && Object.keys(totalInputFields).length > 0) {

            for (const [key, val] of Object.entries(totalInputFields)) {
                if (val != 'Email' && val != 'Image') {
                    colName.push({
                        name: val,
                        label: val,
                        options: {
                            filter: true,
                            sort: true,
                        }
                    });
                    colName1.push({
                        name: val,
                        label: val,
                    })

                } else if (val != 'Email' && val == 'Image') {
                    colName.push({
                        name: val,
                        label: val,
                        options: {
                            filter: true,
                            sort: true,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                if (tableMeta.rowData) {
                                    if (value == "-") {
                                        return <span>-</span>
                                    }
                                    else {
                                        return <img src={Constants.Public_Path + value} width="100px" height="100px" />
                                    }
                                }
                            }
                        }
                    });
                    colName1.push({
                        name: val,
                        label: val,
                    })
                }
            }
        }

        colName.push({
            name: 'Date_added',
            label: T("Date added"),
            options: {
                filter: true,
                sort: true,
            }
        })

        const options1 = {
            print: false,
            filter: false,
            search: false,
            selectableRows: false,
            download: true,
            viewColumns: false,
            rowsPerPage: 5,
            rowsPerPageOptions: []
        }
        const options = {
            disableToolbarSelect: true,
            rowsPerPageOptions: [5, 10, 15, 20],
            filterType: 'dropdown',
            responsive: "standard",
            downloadOptions: {
                filename: 'eventTable.csv',
            },
            textLabels: {
                body: {
                    noMatch: T("No results found, Please try different search parameters"),
                }
            },
            // onFilterDialogOpen: ()=>{
            //     $('.header_login').addClass('newHeader')
            // },
            // onFilterDialogClose: ()=>{
            //     $('.header_login').removeClass('newHeader')
            // },
            rowsSelected: rowsSelected,
            onRowsSelect: (rowsSelected, allRows) => {
                const selected = allRows.map(item => item.dataIndex);
                this.setState({
                    rowsSelected: selected,
                }, () => this.handleTableAction());
                this.checkedUser(allRows);
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <CustomToolbarSelect setSelectedRows={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }) }} />
            ),
        };
        let loader = functions.getLoader(this.state.loader);
        let modalLoader = functions.getLoader(this.state.modalLoader);
        if (this.props.contentTemplate > 0) {
            return (
                <div id="members" className={this.props.CurrentTab == 'members' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                    {/* <div className="search_section">
                        <i className="fa fa-search"></i>
                        <input type="text" name="" placeholder="Search" className="serach" />
                    </div> */}
                    {/* <!-- No user listed --> */}
                    {loader}
                    <div className="no_user_listed no_and_search_samestyle">
                        <div className="main_distribution_btn">
                            {tableData.length > 0 &&
                                <button onClick={() => { this.childSendpass.sendFieldsClose() }} className={issuerFields.pass_limit || issuerFields.status != 1 || !this.state.userChecked || this.props.isLicensePassTemplate || this.state.Subscription_exceeded ? "pass_limit_reached distribution_btns" : "distribution_btns active"} disabled={!this.state.userChecked || issuerFields.pass_limit || issuerFields.status != 1 || this.props.isLicensePassTemplate || this.state.Subscription_exceeded} data-toggle="modal" data-target="#sendUser" title={this.state.rowsSelected && this.state.rowsSelected.length > 0 ? "" : "select checkbox to send pass"} style={{ cursor: this.state.userChecked ? "pointer" : "not-allowed" }} >{T("Send pass to selected")}</button>
                            }
                            {/* <div className="choosefile">
                                <button className="distribution_btns"></button>
                                <button onClick={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }); this.closeDragnDrop() }} className={issuerFields.pass_limit || issuerFields.status != 1 ? "pass_limit_reached choosefilebtn" : "choosefilebtn"} disabled={issuerFields.pass_limit || issuerFields.status != 1} data-toggle="modal" data-target="#uploadUser">{tableData.length > 0 ? "Upload new list" : "Upload a list"}</button>
                            </div> */}

                            {/* <button className="distribution_btns">Upload a list</button> */}
                            <button onClick={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }); this.childAdduser.closeAdduserPopup() }} className={issuerFields.pass_limit || issuerFields.status != 1 || this.props.isLicensePassTemplate ? "pass_limit_reached distribution_btns" : "distribution_btns"} data-toggle="modal" disabled={issuerFields.pass_limit || issuerFields.status != 1 || this.props.isLicensePassTemplate} data-target="#addUser">{T("Add users")}</button>
                            {tableData.length > 0 &&
                                // <a href={SampleCSV} className="distrobiton_btns">Download Sample File</a>
                                <button className="distribution_btns" onClick={this.DownloadCsv}>{T("Download list")}</button>
                            }
                            {/* <button className="distribution_btns" onClick={this.DownloadCsvTemplate}>Download .csv template</button> */}
                            {tableData.length > 0 &&
                                <button onClick={() => { this.setState({ rowsSelected: [], selectedData: [], userChecked: false }) }} className="distribution_btns deleteAllUser" data-toggle="modal" data-target="#deleteUser">{T("Delete user list")}</button>
                            }
                        </div>
                        {tableData.length == 0 &&
                            <div className="no_listing_text">
                                <h3>{T("No users listed")}</h3>
                                <span>{T("You need to upload a list or manually add new users to see them listed here.")}</span>
                            </div>
                        }
                        {/* <div className="no_listing_text">
                            <h3>No results found</h3>
                            <span>Please try diffrent search parameters.</span>
                        </div> */}
                    </div>
                    {
                        <div className="user-listing" style={{
                            display: tableData.length == 0 ? 'none' : ''
                        }} >
                            <div className="status_main">
                                <MUIDataTable
                                    //title={"Distribution List"}
                                    data={data}
                                    columns={colName}
                                    options={options}
                                />
                            </div>
                            <div className="status_main1" style={{ display: 'none', pointerEvents: 'none' }}>
                                <MUIDataTable
                                    //title={"Distribution List"}
                                    data={[]}
                                    columns={colName1}
                                    options={options1}
                                />
                            </div>
                        </div>
                    }
                    {/* <!-- No result found on search --> */}
                    {/* <!-- User listing --> */}
                    <div className="modal uploadUser adduser fade" id="uploadUser" role="dialog" ref="uploadUser">
                        <div class="modal-dialog">
                            {modalLoader}
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" onClick={this.closeDragnDrop} >&times;</button>
                                    <h4 class="modal-title">{T("Upload list")}</h4>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={this.uploadCsvFile} enctype="multipart/form-data">
                                        <div>
                                            <h3>{T("Upload a .csv file")}</h3>
                                            <div className="browsedCsvContainer"  ><input placeholder="Browse for your list" readOnly id="browsedCsvName" />
                                                <ReactFileReader handleFiles={this.selectedCsvFile} fileTypes={'.csv'}>
                                                    <button type="button" id="reactFileReaderBtn">{T("Browse")}</button>
                                                </ReactFileReader>

                                            </div>
                                            <h4>{T("What is a .csv file? You can download our template")}<button type="button" id="browsedHere" onClick={() => { $("#members .status_main button[title='Download CSV']").trigger("click"); }}>{T("here")}.</button></h4>
                                        </div>
                                        <Dropzone onDrop={this.selectedCsvFile} accept=".csv">
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <section className="uploadUser-sec" style={isDragActive ? { background: "#57c0d9", color: '#fff' } : {}}>
                                                    <div {...getRootProps()}>
                                                        <input id="reactFileReaderBtn" {...getInputProps()} />
                                                        <img src={isDragActive ? DragNDropWhite : DragNDropBlack} />
                                                        <p>...{T("or simply drag and drop it here")}.</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        <span id="browsedCsvError"></span>
                                        <div class="form-btn-btm">
                                            <button type="button" id="uploadCancel" class="btn btn-default" onClick={this.closeDragnDrop} data-dismiss="modal">{T("Cancel")}</button>
                                            <button type="submit" id="uploadUpload" disabled={!this.state.isbrowsedFile} className={!this.state.loader && this.state.isbrowsedFile ? "dragndrop-Upload_Active" : "dragndropInactive"}>{T("Upload")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal adduser fade " id="addUser" role="dialog" ref="addUser">
                        <AddUser formData={this.props.issuerFields}
                            tempId={this.props.currentTempId}
                            parentCompnentMount={this.componentDidMount}
                            modalClose={this.closeModal}
                            emailList={emailList}
                            issuerFields={this.state.issuerFields}
                            ref={instance => {
                                this.childAdduser = instance;
                            }} />
                    </div>
                    < div className="modal senduser fade " id="sendUser" role="dialog" ref="sendUser">
                        <SendUser distributionType={"sendPasses"}
                         userList={this.state.selectedData}
                            userData={this.state.membersList}
                            tempId={this.props.currentTempId}
                            parentCompnentMount={this.componentDidMount}
                            modalClose={this.closeModal}
                            singleTemplate={this.state.singleTemplate}
                            ref={instance => {
                                this.childSendpass = instance;
                            }} />
                    </div>

                    <button type="button" data-toggle="modal" data-target="#sendPassConfirm" className="senduserConfirmbtn" id="senduserConfirmbtn" style={{ display: 'none' }} >Open Modal</button>
                    <div className="modal sendPassConfirm fade " id="sendPassConfirm" role="dialog" ref="sendPassConfirm">
                        <RequestConfirmPopup
                            confirmType="sendPass"
                            userList={this.state.confirmSelectedData}
                            userData={this.state.membersList} />
                    </div>

                    <button type="button" data-toggle="modal" data-target="#addUserConfirm" className="adduserConfirmbtn" id="adduserConfirmbtn" style={{ display: 'none' }} >Open Modal</button>
                    <div className="modal addUserConfirm fade " id="addUserConfirm" role="dialog" ref="addUserConfirm">
                        <RequestConfirmPopup
                            confirmType="addUser"
                            userList={this.state.selectedData}
                            userData={this.state.membersList} />
                    </div>

                    <div className="modal deleteUser adduser fade" id="deleteUser" role="dialog" ref="deleteUser">
                        <DeleteUser parentCompnentMount={this.componentDidMount} modalType={"DistributionListDelete"} currentTempId={this.props.currentTempId} modalClose={(type)=>{this.closeModal(type)}} />
                    </div>
                </div >
            );
        } else {
            return (
                <div id="members" className={this.props.CurrentTab == 'members' ? 'tab-pane fade in active show distribution _section' : 'tab-pane fade in distribution _section'}>
                    <div className="search_section">
                        {/* <i className="fa fa-search"></i> */}
                        <img src={Magnifyingglass} />
                        <input type="text" name="" disabled placeholder="Search" className="serach" />
                    </div>
                    <div className="user-listing">
                        <div className="main_distribution_btn">
                            <button className="distribution_btns active">{T("Send pass to selected")}</button>
                            <button className="distribution_btns">{T("Add users")}</button>
                            <button className="distribution_btns">{T("Download list")}</button>
                            <button className="distribution_btns">{T("Delete user list")}</button>
                        </div>
                        <div className="status_main">
                            <table className="status_table">
                                <thead>
                                    <tr className="">
                                        <th>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </th>
                                        <th>{T("Status")}</th>
                                        <th> <span className="name">{T("Name")} </span></th>
                                        <th>{T("Column")} 2</th>
                                        <th>{T("Column")} 3</th>
                                        <th>{T("Date added")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="deactivated_status"></span></td>
                                        <td>{T("Row Data")} 1.1</td>
                                        <td>{T("Row Data")} 1.2</td>
                                        <td>{T("Row Data")} 1.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="actiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="activated_status"></span></td>
                                        <td>{T("Row Data")} 2.1</td>
                                        <td>{T("Row Data")} 2.2</td>
                                        <td>{T("Row Data")} 2.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="deactivated_status"></span></td>
                                        <td>{T("Row Data")} 3.1</td>
                                        <td>{T("Row Data")} 3.2</td>
                                        <td>{T("Row Data")} 3.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="deactivated_status"></span></td>
                                        <td>{T("Row Data")} 4.1</td>
                                        <td>{T("Row Data")} 4.2</td>
                                        <td>{T("Row Data")} 4.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className=""><img src={mailOnTheWay} /></span></td>
                                        <td>{T("Row Data")} 5.1</td>
                                        <td>{T("Row Data")} 5.2</td>
                                        <td>{T("Row Data")} 5.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="actiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td>
                                            <span className="activated_status"></span>
                                        </td>
                                        <td>
                                            {T("Row Data")} 6.1
                                    </td>
                                        <td>
                                            {T("Row Data")} 6.2
                                    </td>
                                        <td>
                                            {T("Row Data")} 6.3
                                    </td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="deactivated_status"></span></td>
                                        <td>{T("Row Data")} 7.1</td>
                                        <td>{T("Row Data")} 7.2</td>
                                        <td>{T("Row Data")} 7.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                    <tr className="deactiavted_row">
                                        <td>
                                            <label className="table_checkbox">
                                                <input type="checkbox" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                        <td><span className="deactivated_status"></span></td>
                                        <td>{T("Row Data")} 8.1</td>
                                        <td>{T("Row Data")} 8.2</td>
                                        <td>{T("Row Data")} 8.3</td>
                                        <td>DD.MM.YYYY</td>
                                        <td></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* OVER LAY FOR DUMMY CONTENT*/}
                        <div className="dummy_data-content">
                            <div className="dummy_data-content-logo">
                                <img src={LogoQr} alt="" />
                                <h3>{T("DEMO CONTENT")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
};

export default MembersTab;
