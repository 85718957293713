import React from 'react';
import Header from './Header';
import Footer from './Footer';
import UserHeader from './UserHeader';
import UserFooter from './UserFooter';

const FrontLayout = ({ children, ...restProps }) => (
    <div className="front_layout home"  {...restProps}>
        <div className="main-section">
            {(localStorage.getItem('loginISP_user_sesion') != null) ?
                <UserHeader />
                :
                <Header />
            }

            {children}

            {(localStorage.getItem('loginISP_user_sesion') != null) ?
                <UserFooter />
                :
                <Footer />
            }

        </div>
    </div>
);

export default FrontLayout;
