import React from 'react';
import { Link } from 'react-router-dom';
import { Data_Constants } from '../../config';
import ReactHtmlParser from 'react-html-parser';
const Footer = () => {
  return (
    <footer className="user_footer">
        <div className="container-fluid">
            <div className="row"> 
                <div className="col-md-12 col-xs-12">
                    <div className="footer-block1">
                        <div className="get-help">
                            <h3>Need some DAM help?</h3>
                            <p>{Data_Constants.FooterContactUsDatainDays} | {Data_Constants.FooterContactUsDatainTime}<br/>
                                <a className="mob" href={"tel:"+Data_Constants.FooterContactUsDatabyMob}>{Data_Constants.FooterContactUsDatabyMob}</a> | <Link to="contact-us">Contact Us</Link></p><br/>
                            <p className="copyright">{ReactHtmlParser(Data_Constants.FooterNotice)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
