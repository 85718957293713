import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { functions } from "../../functions";
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';


class AccountNavigationTab extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let subscriptionData = functions.getSubscriptionData();
        return (
            <div className={ (subscriptionData.subscription == 0 && this.props.CurrentTab)  == 'subscription' ? "inner_subscripton_page bg_color_section block_element main_logo_heading_padding my_pass_templates my_profile_section padding_container" : "bg_color_section block_element main_logo_heading_padding my_pass_templates my_profile_section padding_container"}>
               <div className="container">
               <h3>{T("My Profile")}</h3>
                <ul className="nav nav-tabs">
                    <li>
                        <Link to="/profile" className={this.props.CurrentTab == 'profile' ? 'active show' : ''}>
                            {T("Profile")}
                        </Link>
                    </li>
                    <li>
                        <Link to="/subscription" className={this.props.CurrentTab == 'subscription' ? 'active show' : ''}>
                            {T("Subscription")}
                        </Link>
                    </li>
                    <li>
                        <Link to="/change_password" className={this.props.CurrentTab == 'change_password' ? 'active show' : ''}>
                            {T("Password")}
                        </Link>
                    </li>
                </ul>
               </div>
            </div>
        );
    }
};

export default AccountNavigationTab;
