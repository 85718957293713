import React from 'react';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import PassRemainingPng from '../../assets/images/pass_remaining.png';
import LogoQr from '../../assets/images/logo_Qr.png';
import PassesGraphPng from '../../assets/images/passes_graph.png';//Chart
import staticGauge from '../../assets/images/staticGauge.png'
import staticGauge_Icelandic from '../../assets/images/staticGauge_icelandic.png'
import staticLocations from '../../assets/images/staticLocations.svg'
import Chart from 'react-apexcharts';
// import ReactSpeedometer from "react-d3-speedometer";
import UpgradeSubscription from './UpgradeSubscription';
import OrderPopup from './OrderPopup';
import OrderConfirmationPopup from './OrderConfirmationPopup';
import DowngradePlan from './DowngradePlan';
import { autofill } from 'redux-form';
import { Redirect } from 'react-router';
import { isMobileOnly } from 'react-device-detect';
import ContactUs from './ContactUs';
import { Line } from 'react-chartjs-2';
import { object } from 'prop-types';
import GaugeChart from 'react-gauge-chart'
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class PassStatisticsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Pass Statistics',
            loader: false,
            generalStatisticsData: {},
            locationStatisticsData: [],
            passTypeStatisticsData: {},
            passTemplateAvailibility: {},
            selectedOption: {},
            passTemplateStatisticData: [],
            currentPage: 0,
            SubscriptionData: [],
            planId: '',
            usage_statistics: 'B',
            totalPasses: 0,
            subscriptionState: {},
            cardInfo: {},
            discountPeriodExpired: false,
            orderingTemplate: false
        };
    }


    componentDidMount() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        var PostData = {
            access_token: functions.getAccessToken(),
        };
        api.getAccountData()
            .then(res => {
                if (res.data && res.data.data && res.data.data.issuerAccountUser && res.data.data.issuerAccountUser.length > 0 && res.data.data.issuerAccountUser[0]) {
                    let content = {
                        token: functions.getToken(),
                        access_token: functions.getAccessToken(),
                        contact_name: functions.getUserName(),
                        company_name: functions.getCompanyName(),
                        subscription_data: functions.getSubscriptionData(),
                        user_permissions: res.data.data.issuerAccountUser[0],
                        issuer_role: functions.getIssuerRole(),
                    }
                    localStorage.setItem('loginISP_user_sesion',
                        functions.Client(content));
                }
            })
            .catch(err => {
                this.setState({ loader: false })
            });
        this.props.parentComponentDidMountFunction();
        // this.getPassStatisticsData(PostData); // Get AccountData
        this.getSubscriptionData();
        api.getIssuerTemplates()
            .then(res => {
                if (res.data && res.data.status == 1) {
                    if (res.data.data.orderedTemplates.length < 1 && res.data.data.templates.length < 1) {
                        // toast.dismiss();
                        toast.success(T("Order a Pass Template to get started with Smart Solutions") + ".", { hideProgressBar: true });
                    }
                }
            })
            .catch(err => {
            });
    }
    updateOrdering() {
        this.setState({ orderingTemplate: this.props.orderingTemplate })
    }
    getSubscriptionData(props = this.props) {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        api.getSubscriptionData()
            .then(res => {
                let result = [];
                let planId, totalPasses = 0;
                let subscriptionState = {};
                let usage_statistics = 'B';
                let cardInfo = {};
                let discountPeriodExpired = false;
                if (res.data.status == 1) {
                    result = res.data.data.subscriptions;
                    planId = res.data.data.subscription_id;
                    totalPasses = res.data.data.passesInSmartwallet;
                    discountPeriodExpired = res.data.data.discountPeriodExpired;
                }
                if (res.data.data && res.data.data.subscription_status && res.data.data.subscription_status.status == 1) {
                    subscriptionState = res.data.data.subscription_status.data;
                    usage_statistics = res.data.data.subscription_status.data.usage_statistics ? res.data.data.subscription_status.data.usage_statistics : 'B';
                }
                if (res.data.data && res.data.data.userPaymentInfo && res.data.data.userPaymentInfo.length > 0) {
                    cardInfo = res.data.data.userPaymentInfo[0];
                }
                this.setState({
                    loader: false,
                    usage_statistics: usage_statistics,
                    SubscriptionData: result,
                    planId: planId,
                    totalPasses: totalPasses,
                    subscriptionState: subscriptionState,
                    cardInfo: cardInfo,
                    discountPeriodExpired: discountPeriodExpired
                });
            })
            .catch(err => {
                // toast.error("Something went wrong.", { hideProgressBar: true })
                this.setState({ loader: false })
            });
    }
    mouseHover = color => {
        if (document.getElementById('status_upgrade')) {
            document.getElementById('status_upgrade').style.color = '#fff'
            document.getElementById('status_upgrade').style.background = `${color}`
        }
    }
    mouseOut = color => {
        if (document.getElementById('status_upgrade')) {
            document.getElementById('status_upgrade').style.color = '#1B3F71'
            document.getElementById('status_upgrade').style.background = `#fff`
        }
    }

    render() {
        // let issuerpassesInSmartwallet= this.state.generalStatisticsData && this.state.generalStatisticsData.passesInSmartwallet ?this.state.generalStatisticsData.passesInSmartwallet:0;
        const timeChartDataset = {
            labels: this.props.hourLabel,
            datasets: [
                {
                    label: '',
                    fill: false,
                    lineTension: 0,
                    pointBackgroundColor: '#339D91',
                    pointBorderColor: 'white',
                    pointHoverBorderColor: 'white',
                    pointBorderWidth: 3.5,
                    pointHoverRadius: 6,
                    pointHoverBorderWidth: 3.5,
                    pointRadius: 3.5,
                    backgroundColor: '#339D91',
                    borderColor: '#339D91',
                    borderWidth: 4,
                    data: this.props.hourCount
                }
            ]
        }
        const timeChartOptions = {
            title: {
                display: false,
                text: "Usage on time per day",
                position: 'top',
                fontSize: 20,
            },
            maintainAspectRatio: false,
            legend: {
                display: false,
                position: 'right'
            },
            scales: {
                xAxes: [{

                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    ticks: {
                        stepSize: 1,
                        maxTicksLimit: 7,
                        beginAtZero: true
                    },
                    gridLines: {
                        drawBorder: false,
                        drawTicks: true,

                    }
                }]
            },
            tooltips: {
                displayColors: false,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return T("Usages") + data['datasets'][0]['data'][tooltipItem['index']];
                    },
                },
                backgroundColor: '#F3F1ED',
                titleFontColor: '#97937F',
                bodyFontColor: '#1B3F71',

            }
        }

        let currentTemplateData = this.props.currentTempData;
        // let orderingTemplate = this.props.orderingTemplate;
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            // return <Redirect to='/signin' />
            setTimeout(() => {
                return <Redirect to='/signin' />
            }, 10000);
        }
        const Locationstatics = this.props.LocationStats;
        const templateData = this.props.Statistics;

        let templateQuantityRestriction = templateData.templateQuantityRestriction && templateData.templateQuantityRestriction.length > 0 ? templateData.templateQuantityRestriction[0] : {};
        let renewedCycleCount = templateData.renewedCycleCount ? templateData.renewedCycleCount : 0;
        let totalUsed = templateData.passesUsed + templateData.passesRedeemed;
        let totalUnused = (templateData.passesInSmartwallet + renewedCycleCount) - totalUsed
        //Page_title
        // functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);
        let templateClass = '';
        let templateName = '';
        switch (this.state.selectedOption.value) {
            case 'pass.snjall.coupon':
                templateClass = "main-pass-pass-section blockElement curly-bar-section";
                templateName = "Coupon Pass";
                break
            case 'pass.snjall.punchcard':
                templateClass = "main-pass-pass-section blockElement round-corners";
                templateName = "Punch Pass";
                break
            case 'pass.snjall.memberpass':
                templateClass = "main-pass-pass-section blockElement round-corners";
                templateName = "Member Pass";
                break
            case 'pass.snjall.eventticket':
                templateClass = "main-pass-pass-section blockElement round-top-main round-corners";
                templateName = "Event Ticket"
                break
            default:
                templateClass = "main-pass-pass-section blockElement curly-bar-section";
                templateName = "Coupon Pass";
                break
        }
        let usagesChartOptions = this.props.usagesChartOptions;
        let usagesChartSeries = this.props.usagesChartSeries;
        let daysChartOptions = this.props.daysChartOptions;
        let daysChartSeries = this.props.daysChartSeries;


        let DiscountPeriod = this.state.subscriptionState && this.state.subscriptionState.reamainingDaysOfTrailPeriod ? parseInt(this.state.subscriptionState.reamainingDaysOfTrailPeriod) : 90;

        let getTrialDuration = 0;
        if (DiscountPeriod > 0) {
            getTrialDuration = functions.getTrialDuration(DiscountPeriod);
        }
        const num = 6;
        const maxPasses = this.props.subscriptionState && this.props.subscriptionState.maxPasses ? this.props.subscriptionState.maxPasses : 0;
        // const maxPasses = 900000;
        const distributedlimitPasses = this.props.subscriptionState && this.props.subscriptionState.distributedPasses;
        // const distributedlimitPasses = 750000;
        let remeaningPassess = maxPasses - distributedlimitPasses;
        let remeanPasses = remeaningPassess < 0 ? 0 : remeaningPassess;
        let passesPercentage = distributedlimitPasses / maxPasses * 100;
        let passesLeft = maxPasses - templateData.passesInSmartwallet;
        let minVal = maxPasses / num;
        let anotherMinval = minVal;
        let color = "";
        for (let i = 0; i < num; i++) {
            switch (i) {
                case 1:
                    color = "#339D91";
                    break;
                case 2:
                    color = "#AFCD7E";
                    break;
                case 3:
                    color = "#F2C94C";
                    break;
                case 4:
                    color = "#F28051";
                    break;
                case 5:
                    color = "#F16772";
                    break;
                default:
                    color = "#266F99";
                    break;
            }
            if (distributedlimitPasses < minVal) {
                break;
            } else {
                minVal += anotherMinval;
            }
        }
        const SubscriptionGaugeStyle = {
            width: '100%',
            fontFamily: 'Museo Sans 500'
        }
        let refreshWithSS = templateData.refreshWithSS ? templateData.refreshWithSS : 0;
        let refreshWithSW = templateData.refreshWithSW ? templateData.refreshWithSW : 0;
        let refreshSummary = parseInt(refreshWithSW) + parseInt(refreshWithSS);
        let rewardsPassesUsed = templateData.rewardsPassesUsed ? templateData.rewardsPassesUsed : 0;
        let rewardsPassesRedeemed = templateData.rewardsPassesRedeemed ? templateData.rewardsPassesRedeemed : 0;
        let rewardsReceived = templateData.rewardsReceived ? templateData.rewardsReceived : 0;
        let punchedBySWCount = templateData.punchedBySWCount ? templateData.punchedBySWCount : 0;
        let punchedBySSCount = templateData.punchedBySSCount ? templateData.punchedBySSCount : 0;
        let passesNotPinged = templateData.passesNotPinged ? templateData.passesNotPinged : 0;
        let punchedSummary = parseInt(punchedBySWCount) + parseInt(punchedBySSCount);
        let userPermissions = functions.getUserPermissions();
        let issuerRole = functions.getIssuerRole();
        return (
            <div>
                {loader}
                {Object.keys(templateData).length > 0 &&
                    < div >
                        {/* <div className="modal modalcontactus fade " id="modalcontactus" role="dialog" ref="modalcontactus">
                            <ContactUs />
                        </div> */}
                        <div className="modal fade select_subscription" id="UpgradeSubscription" role="dialog">
                            <UpgradeSubscription subscription_discount={this.props.subscription_discount} orderTemp={this.state.orderingTemplate ? '1' : ''} SubscriptionState={this.state.subscriptionState} SubscriptionData={this.state.SubscriptionData} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} />
                        </div>
                        <div className="pass_single_detail">
                            <div className="passesDetailLimit passess-details">
                                <p>{T("Number of passes downloaded")}</p>
                                <div className="passes_counting">
                                    <span className="pass_number">{(templateData.passesInSmartwallet + renewedCycleCount).toLocaleString('de-DE')}</span>
                                    {templateQuantityRestriction.quantity && templateQuantityRestriction.quantity != null &&

                                        <span className="pass_percantage">({templateData.passesInSmartwallet != 0 ? templateData.passesInSmartwallet == templateQuantityRestriction.quantity && templateQuantityRestriction.quantity ? ((templateData.passesInSmartwallet / templateQuantityRestriction.quantity) * 100) : (((templateData.passesInSmartwallet / templateQuantityRestriction.quantity) * 100).toFixed(1).replace(".", ",")) : 0}%)</span>
                                    }
                                </div>
                            </div>

                            {currentTemplateData.pass_type == "pass.snjall.memberpass" &&
                                <div className="passess-details">
                                    <p>{T("Number of passes expired")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(templateData.passesExpired).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({templateData.passesExpired != 0 ? templateData.passesExpired == templateData.passesInSmartwallet ? ((templateData.passesExpired / templateData.passesInSmartwallet) * 100) : (((templateData.passesExpired / templateData.passesInSmartwallet) * 100).toFixed(1).replace(".", ",")) : 0}%)</span>
                                    </div>
                                </div>
                            }
                            {(currentTemplateData.pass_type == "pass.snjall.memberpass" && this.props.isLicensePassTemplate == true) &&
                                <div className="passess-details">
                                    <p>{T("Passes not pinged for 42 days or more")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(passesNotPinged).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({passesNotPinged != 0 ? passesNotPinged == templateData.passesInSmartwallet ? ((passesNotPinged / templateData.passesInSmartwallet) * 100) : (((passesNotPinged / templateData.passesInSmartwallet) * 100).toFixed(1).replace(".", ",")) : 0}%)</span>
                                    </div>
                                </div>
                            }
                            {currentTemplateData.pass_type != "pass.snjall.memberpass" &&
                                <div className="passess-details">
                                    <p>{currentTemplateData.pass_type == "pass.snjall.punchcard" ? T("Number of passes not fully used") : T("Number of passes unused")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(totalUnused).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({totalUnused != 0 ? totalUnused == (templateData.passesInSmartwallet + renewedCycleCount) ? ((totalUnused / (templateData.passesInSmartwallet + renewedCycleCount)) * 100) : (((totalUnused / (templateData.passesInSmartwallet + renewedCycleCount)) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                            }

                            {currentTemplateData.pass_type == "pass.snjall.punchcard" &&
                                <div className="passess-details">
                                    <p>{T("Number of passes fully used")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(totalUsed).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({totalUsed != 0 ? totalUsed == (templateData.passesInSmartwallet + renewedCycleCount) ? ((totalUsed / (templateData.passesInSmartwallet + renewedCycleCount)) * 100) : (((totalUsed / (templateData.passesInSmartwallet + renewedCycleCount)) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                            }
                            {(currentTemplateData.pass_type !== "pass.snjall.punchcard" && (this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full') && templateQuantityRestriction.quantity != null) &&
                                <div className="passess-details">
                                    <p>{T("Number of passes left")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(templateQuantityRestriction.quantity_left).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({templateQuantityRestriction.quantity_left != 0 ? templateQuantityRestriction.quantity == templateQuantityRestriction.quantity_left && templateQuantityRestriction.quantity_left ? ((templateQuantityRestriction.quantity_left / templateQuantityRestriction.quantity) * 100) : (((templateQuantityRestriction.quantity_left / templateQuantityRestriction.quantity) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>

                                </div>
                            }
                        </div>
                        <div className="passes_more_details">
                            {currentTemplateData.pass_type != "pass.snjall.memberpass" &&
                                <div className="passesDetailLimit passess-details">
                                    <p>{T("Times")} {currentTemplateData.pass_type == "pass.snjall.punchcard" ? T("punched") : T("used")} {T("with self service")}</p>
                                    {currentTemplateData.pass_type != "pass.snjall.punchcard" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(templateData.passesUsed).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">({templateData.passesUsed != 0 ? templateData.passesUsed == totalUsed ? ((templateData.passesUsed / totalUsed) * 100) : (((templateData.passesUsed / totalUsed) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                        </div>
                                    }
                                    {currentTemplateData.pass_type == "pass.snjall.punchcard" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(punchedBySWCount).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">({punchedBySWCount != 0 ? punchedBySWCount == punchedSummary ? ((punchedBySWCount / punchedSummary) * 100) : (((punchedBySWCount / punchedSummary) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                        </div>
                                    }
                                </div>
                            }
                            {currentTemplateData.pass_type != "pass.snjall.memberpass" &&
                                <div className="passesDetailLimit passess-details">
                                    <p>{T("Times")} {currentTemplateData.pass_type == "pass.snjall.punchcard" ? T("punched") : T("used")} {T("with SmartScanner")}</p>
                                    {currentTemplateData.pass_type != "pass.snjall.punchcard" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(templateData.passesRedeemed).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">({templateData.passesRedeemed != 0 ? templateData.passesRedeemed == totalUsed ? ((templateData.passesRedeemed / totalUsed) * 100) : (((templateData.passesRedeemed / totalUsed) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                        </div>
                                    }
                                    {currentTemplateData.pass_type == "pass.snjall.punchcard" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(punchedBySSCount).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">({punchedBySSCount != 0 ? punchedBySSCount == punchedSummary ? ((punchedBySSCount / punchedSummary) * 100) : (((punchedBySSCount / punchedSummary) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                        </div>
                                    }
                                </div>
                            }

                            {(currentTemplateData.pass_type == "pass.snjall.memberpass" && (this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full')) &&
                                <div className="passess-details">
                                    <p>{T("Times verified with Refresh in SmartWallet")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(refreshWithSW).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({refreshWithSW != 0 ? refreshWithSW == refreshSummary ? ((refreshWithSW / refreshSummary) * 100) : (((refreshWithSW / refreshSummary) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                            }
                            {(currentTemplateData.pass_type == "pass.snjall.memberpass" && (this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full')) &&
                                <div className="passess-details">
                                    <p>{T("Times verified with SmartScanner")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(refreshWithSS).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({refreshWithSS != 0 ? refreshWithSS == refreshSummary ? ((refreshWithSS / refreshSummary) * 100) : (((refreshWithSS / refreshSummary) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                            }
                            {(this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full') &&
                                <div className="passess-details">
                                    <p> {currentTemplateData.pass_type == "pass.snjall.punchcard" ? T("Summary of times punched") : currentTemplateData.pass_type == "pass.snjall.memberpass" ? T("Summary of times verified") : T("Summary of times used")}</p>
                                    {(currentTemplateData.pass_type != "pass.snjall.memberpass" && currentTemplateData.pass_type != "pass.snjall.punchcard") &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{templateData.passesRedeemed + templateData.passesUsed}</span>
                                            <span className="pass_percantage">(100%)</span>
                                        </div>
                                    }
                                    {currentTemplateData.pass_type == "pass.snjall.punchcard" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(punchedSummary).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">(100%)</span>
                                        </div>
                                    }
                                    {currentTemplateData.pass_type == "pass.snjall.memberpass" &&
                                        <div className="passes_counting">
                                            <span className="pass_number">{(refreshSummary).toLocaleString('de-DE')}</span>
                                            <span className="pass_percantage">(100%)</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {(currentTemplateData.pass_type == "pass.snjall.punchcard" && templateData.rewardAvailability && (this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full')) &&
                            <div className="passes_full_more_details">
                                <div className="passess-details">
                                    <p>{T("Number of rewards used")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{((rewardsPassesUsed + rewardsPassesRedeemed)).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({(rewardsPassesUsed + rewardsPassesRedeemed) != 0 ? (rewardsPassesUsed + rewardsPassesRedeemed) == rewardsReceived ? (((rewardsPassesUsed + rewardsPassesRedeemed) / rewardsReceived) * 100) : ((((rewardsPassesUsed + rewardsPassesRedeemed) / rewardsReceived) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                                <div className="passess-details">
                                    <p>{T("Number of rewards unused")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(rewardsReceived - (rewardsPassesUsed + rewardsPassesRedeemed)).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({rewardsReceived - (rewardsPassesUsed + rewardsPassesRedeemed) != 0 ? rewardsReceived - (rewardsPassesUsed + rewardsPassesRedeemed) == rewardsReceived ? (((rewardsReceived - (rewardsPassesUsed + rewardsPassesRedeemed)) / rewardsReceived) * 100) : ((((rewardsReceived - (rewardsPassesUsed + rewardsPassesRedeemed)) / rewardsReceived) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                                <div className="passess-details">
                                    <p>{T("Number of rewards recieved")}</p>
                                    <div className="passes_counting">
                                        <span className="pass_number">{(rewardsReceived).toLocaleString('de-DE')}</span>
                                        <span className="pass_percantage">({rewardsReceived != 0 ? rewardsReceived == (templateData.passesInSmartwallet + renewedCycleCount) ? ((rewardsReceived / (templateData.passesInSmartwallet + renewedCycleCount)) * 100) : (((rewardsReceived / (templateData.passesInSmartwallet + renewedCycleCount)) * 100).toFixed(1)).replace(".", ",") : 0}%)</span>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="status_of_passses block_element">
                            <div className="status_gauge same_status_section">
                                <p>{T("Status of pass limit used")}</p>
                                <GaugeChart id="gauge-chart1"
                                    style={SubscriptionGaugeStyle}
                                    className="subscriptin_gauge_chart"
                                    textColor="#666666"
                                    nrOfLevels={6}
                                    cornerRadius={0}
                                    arcWidth={0.3}
                                    arcPadding={0}
                                    marginInPercent={0}
                                    needleColor='#57C0D9'
                                    needleBaseColor='#57C0D9'
                                    formatTextValue={value => `${T('Passes remaining')}: ${functions.toCurrency(remeanPasses)} `}
                                    percent={passesPercentage / 100}
                                    colors={[
                                        "#266F99",
                                        "#339D91",
                                        "#AFCD7E",
                                        "#F2C94C",
                                        "#F28051",
                                        "#F16772",
                                    ]} />
                                <button id="status_upgrade" className="status_upgrade" onMouseOut={() => { this.mouseOut(color) }} onMouseOver={() => { this.mouseHover(color) }}
                                    onClick={() => { this.setState({ orderingTemplate: false }) }} data-toggle="modal" style={{ border: `2px solid ${color}` }} data-target={(issuerRole == 'Issuer-Account-User' && userPermissions.issueraccountuserpermission.upgrade_subscription == '0') ? '#DowngradePlan' : '#UpgradeSubscription'} >{T("Upgrade subscription")}</button>
                            </div>
                            <div className="status_graph same_status_section">
                                <p>{T("Usage by location")}</p>
                                <Chart options={usagesChartOptions} type="bar" series={usagesChartSeries} />
                            </div>

                            <div className="modal fade myOrderPopup" id="myOrderPopup" role="dialog" >
                                <OrderPopup template_price={this.props.template_price} subscription_discount={this.props.subscription_discount} subscriptionPaymentType={this.props.subscriptionPaymentType} orderTemp={this.state.orderingTemplate ? '1' : ''} changeData={this.state.cardInfo} />
                            </div>
                        </div>
                        {(this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full') &&
                            <div className="day_status_of_passses block_element">
                                <div className="status_graph same_status_section">
                                    <p>{T("Usage per day")}</p>
                                    <Chart options={daysChartOptions} type="bar" series={daysChartSeries} />
                                </div>
                            </div>
                        }
                        {(this.state.usage_statistics == 'F' || this.state.usage_statistics == 'Full' || this.state.usage_statistics == 'full') &&
                            <div className="time_status_of_passses block_element">
                                <p>{T("Usage on time per day")}</p>
                                <Line
                                    data={timeChartDataset}
                                    options={timeChartOptions}
                                />

                            </div>
                        }
                    </div >
                }
                {this.state.SubscriptionData.map((val, key) => (
                    <div>
                        <div className="modal fade myOrderPopup" id={"myOrderPopup" + key} role="dialog" >
                            <OrderPopup template_price={this.props.template_price} subscription_discount={this.props.subscription_discount} subscriptionPaymentType={this.props.subscriptionPaymentType} orderTemp={this.state.orderingTemplate ? '1' : ''} OrderData={val} CardCred={this.state.cardInfo} DiscountPeriodExpired={this.state.discountPeriodExpired} getTrialDuration={getTrialDuration} SubscriptionState={this.state.subscriptionState} />
                        </div>
                        <div className="modal orderConfirmModal fade" id={"OrderConfirmation" + val.id} role="dialog">
                            <OrderConfirmationPopup template_price={this.props.template_price} subscription_discount={this.props.subscription_discount} orderTemp={this.state.orderingTemplate ? '1' : ''} OrderData={val} DiscountPeriodExpired={this.state.discountPeriodExpired} />
                        </div>
                    </div>
                ))
                }
                <div className="modal downgradePlan fade" id="DowngradePlan" role="dialog">
                    <DowngradePlan />
                </div>
                {(Object.keys(templateData).length == 0 && !this.state.loader) &&
                    <div className="Dummy-Stats">
                        {loader}
                        <div className="pass_single_detail">
                            <div className="passess-details">
                                <p>{T("Number of passes downloaded")}</p>
                                <div className="passes_counting">
                                    <span className="pass_number">10,562</span>
                                    {/* <span className="pass_percantage">(88%)</span> */}
                                </div>
                            </div>
                            <div className="passess-details">
                                <p>{T("Number of passes unused")}</p>
                                <div className="passes_counting">
                                    <span className="pass_number">356</span>
                                    <span className="pass_percantage">(3,26%)</span>
                                </div>
                            </div>
                        </div>
                        <div className="passes_more_details">
                            <div className="passess-details">
                                <p>{T("Times used with self service")}</p>
                                <div className="passes_counting">
                                    <span className="pass_number">2,395</span>
                                    <span className="pass_percantage">(22,7%)</span>
                                </div>
                            </div>
                            <div className="passess-details">
                                <p>{T("Times used with SmartScanner")}</p>
                                <div className="passes_counting">
                                    <span className="pass_number">8,167</span>
                                    <span className="pass_percantage">(77,3%)</span>
                                </div>
                            </div>
                        </div>
                        <div className="status_of_passses block_element">
                            <div className="status_gauge same_status_section">
                                <p>{T("Status of pass limit used")} </p>
                                <img src={localStorage.getItem('rtc-lang') && localStorage.getItem('rtc-lang') == 'is' ? staticGauge_Icelandic : staticGauge} />
                                <button className="status_upgrade">{T("Upgrade subscription")}</button>
                            </div>
                            <div className="status_graph same_status_section">
                                {/* <p>Usage by location</p> */}
                                <img src={staticLocations} />
                            </div>
                        </div>


                        {/* OVER LAY FOR DUMMY CONTENT*/}
                        <div className="dummy_data-content">
                            <div className="dummy_data-content-logo">
                                <img src={LogoQr} alt="" />
                                <h3>{T("DEMO CONTENT")}</h3>
                            </div>
                        </div>
                    </div>
                }
                {(Object.keys(templateData).length == 0 && this.state.loader) &&
                    <div className="wrapper block_element">
                        <div className="bg_color_section block_element thanku_page padding_container">
                            <Link to="/"><img src={LogoQr} /></Link>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default PassStatisticsTab;
