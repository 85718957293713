import React from 'react';
import { functions } from "../../functions";
import 'react-toastify/dist/ReactToastify.css';
import Redirect from 'react-router/Redirect';
import { Constants, Validation_Constants } from '../../config';
import { api } from "../../api";
import { ToastContainer, toast } from 'react-toastify';
import ExifOrientationImg from 'react-exif-orientation-img';
import ReactFileReader from 'react-file-reader';
import * as EmailValidator from 'email-validator';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';
import $ from 'jquery';
export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            imagePreviewUrl: '',
            fields: [],
            fields1: [],
            errors: [],
            touches: [],
            email: '',
            loader: false,
            fileContent: [],
            browsedFile: {},
            isbrowsedFile: false,
            formIsValid: false,
            emailMatched: false,
            inputFieldEmail: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.isbrowsedFile) {
            this.handleFiles(this.state.browsedFile);
        } else {
            let formValid = true;
            let error = {}
            if (this.props.emailList.indexOf(this.state.email) !== -1) {
                if (this.state.inputFieldEmail == true) {
                    formValid = false;
                    error['email'] = "Email already exist in your distribution list."
                    this.setState({
                        formIsValid: formValid,
                        errors: error
                    })
                }
                else {
                    formValid = false;
                    error['email'] = "Email already exist in your distribution list."
                    this.setState({
                        formIsValid: formValid,
                        errors: error
                    })
                }
                // toast.error("Email already exist in your distribution list.", { hideProgressBar: true });
            }
            else {
                // event.preventDefault();
                if (this.state.formIsValid) {
                    this.setState({ loader: true });
                    if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
                        return <Redirect to='/signin' />
                    }
                    const token = localStorage.getItem('token');
                    var PostData = {
                        id: this.props.tempId,
                        // access_token: functions.getAccessToken(),
                        field: this.state.fields,
                        email: this.state.email
                    };
                    // var formData = { 'field': this.state.fields }
                    api.addNewUserToDist(PostData)
                        .then(res => {
                            if (res.data.status == 1) {
                                toast.dismiss();
                                this.state.fields.map((val, key) => {
                                    let formdata = this.state.fields;
                                    formdata[key].value = ''
                                    this.setState({
                                        fields: formdata,
                                    })
                                })
                                this.setState({
                                    loader: false, errors: [],
                                    imagePreviewUrl: '',
                                    formIsValid: false,
                                    email: '',
                                    fields: this.props.formData && this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : [],
                                    errors: [],
                                    inputFieldEmail: false,
                                    isbrowsedFile: false
                                });
                                // toast.success(res.data.message, { hideProgressBar: true })
                                // setTimeout(() => { window.location.reload(); this.setState({ loader: false }); }, 500)
                                this.props.modalClose("addUser");
                            } else {
                                toast.error(res.data.message, { hideProgressBar: true })
                                this.setState({ loader: false });
                            }
                            this.componentDidMount();
                            this.props.parentCompnentMount();

                            // window.location.reload();
                        }).catch(err => {
                            this.setState({ loader: false })

                        });
                }
            }
        }

    }
    validateForm(fieldType) {
        let errors = {};
        let formValid = true;
        let countEmail = 0;
        this.state.fields.map((val, key) => {
            countEmail = val.type_value == 'email' ? countEmail + 1 : countEmail;
            if (!val.value || val.value == '') {
                formValid = false;
                if (fieldType == val.field_value || this.state.touches[val.field_value] == true) {
                    errors[key] = T("This field is required");
                }
            } else if (!val.value || val.value == '0' || val.value == 0) {
                formValid = false;
                if (fieldType == val.field_value || this.state.touches[val.field_value] == true) {
                    errors[key] = "Please enter a valid value.";
                }
            }
            if (val.type_value == 'email') {
                if (!val.value || val.value == '') {
                    formValid = false;
                    if (fieldType == val.field_value || this.state.touches[val.field_value] == true) {
                        errors[key] = T("This field is required");
                    }

                    // } else if (val.value != '' && !(val.value.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/))) {
                } else if (val.value != '' && !EmailValidator.validate(val.value)) {
                    formValid = false;
                    if (fieldType == val.field_value || this.state.touches[val.field_value] == true) {
                        errors[key] = T("Please enter a valid email.");
                    }
                } else if (this.props.emailList.indexOf(val.value) !== -1) {
                    formValid = false;
                    if (fieldType == val.field_value || this.state.touches[val.field_value] == true) {
                        errors[key] = "Email already exist in your distribution list."
                    }
                }
            }

        })

        if (countEmail == 0 && (!this.state.email || this.state.email == '')) {
            formValid = false;
            if (fieldType == 'email' || this.state.touches['email'] == true) {
                errors['email'] = T("This field is required");
            }
            // } else if (countEmail == 0 && this.state.email != '' && !((this.state.email).match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/))) {
        } else if (countEmail == 0 && this.state.email != '' && !EmailValidator.validate(this.state.email)) {
            // if(){
            formValid = false;
            if (fieldType == 'email' || this.state.touches['email'] == true) {
                errors['email'] = T("Please enter a valid email.");
            }
            // }
        }
        else if (this.props.emailList.indexOf(this.state.email) !== -1) {
            formValid = false;
            if (fieldType == 'email' || this.state.touches['email'] == true) {
                errors['email'] = "Email already exist in your distribution list."
            }
        }
        this.setState({
            formIsValid: formValid,
            errors: errors,
            isbrowsedFile: false,
            browsedFile: {},
        });
        // if (formValid == true) {
        //     document.getElementById("addBtn").focus();
        // }
        return formValid;
    }

    componentDidMount() {

        this.setState({
            fields: this.props.formData && this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : [],
            fields1: this.props.formData && this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : []
        })
    }
    _handleImageChange(e, key, fieldType) {
        let error = {};
        let touch = this.state.touches;
        e.preventDefault();
        error[key] = '';
        touch[fieldType] = true;
        if (document.getElementById('addbrowsedCsvName')) {
            document.getElementById('addbrowsedCsvName').value = '';
        }
        if (document.getElementById(`${key}`)) {
            document.getElementById(`${key}`).blur();
        }
        if (document.getElementById("addBtn")) {
            document.getElementById("addBtn").focus();
        }
        this.setState({
            errors: error,
            touches: touch,
            isbrowsedFile: false,
            browsedFile: {}
        })
        let reader = new FileReader();
        let file = e.target.files[0];
        let formdata = this.state.fields;
        reader.onloadend = () => {
            formdata[key].value = reader.result;
            this.setState({
                formIsValid: true,
                fields: formdata,
                imagePreviewUrl: reader.result
            });
            this.validateForm(fieldType)
        }
        reader.readAsDataURL(file)
    }

    Contains = (target) => {
        let inputFields = this.props.issuerFields && this.props.issuerFields.data && this.props.issuerFields.data.issuerInputFields ? this.props.issuerFields.data.issuerInputFields : [];
        let totalfields = [];
        totalfields.push('Email');
        if (Object.keys(inputFields).length > 0) {
            for (const [key, val] of Object.entries(inputFields)) {
                if (val.field_value != "Email") {
                    totalfields.push(val.field_value);
                }
            }
        }
        var value = 0;
        let missingFields = [];
        totalfields.forEach(function (word) {
            //   value = value + target.toLowerCase().includes(word.toLowerCase());
            if (!target.toLowerCase().includes(word.toLowerCase()) && word.toLowerCase() != 'image') {
                missingFields.push(word);
            }
        });
        return missingFields;
    }

    csvJSON = (csv) => {
        const lines = csv.split('\n')
        const result = []
        let headers = [];
        const csvheaders = lines[0].split(',')
        csvheaders.forEach(element => {
            headers.push(element.replace(/['"]+/g, '').trim());
        });

        for (let i = 1; i < lines.length; i++) {
            if (!lines[i])
                continue
            const obj = {}
            const trimedline = [];
            const currentline = lines[i].split(',')
            currentline.forEach(element => {
                trimedline.push(element.replace(/['"]+/g, ''));
            });
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = trimedline[j]
            }
            result.push(obj)
        }
        return result
    }

    selectedCsvFile = (files) => {
        let fileExt = files.length > 0 ? files[0].name.substring(files[0].name.lastIndexOf('.') + 1) : '';
        document.getElementById("addUserForm").reset();
        if (files.length > 0 && files[0].type == "text/csv" || (files.length > 0 && fileExt == 'csv')) {

            if (document.getElementById("reactFileReaderButton")) {
                document.getElementById("reactFileReaderButton").blur();
            }
            if (document.getElementById('addbrowsedCsvName')) {
                document.getElementById('addbrowsedCsvName').value = files[0].name;
            }
            this.setState({
                errors: [],
                fields: this.props.formData && this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : [],
                isbrowsedFile: true,
                browsedFile: files
            })
            if (document.getElementById("addBtn")) {
                document.getElementById("addBtn").focus();
            }
        }
        else {
            toast.dismiss();
            if (document.getElementById('addbrowsedCsvName')) {
                document.getElementById('addbrowsedCsvName').value = "";
            }
            this.setState({
                isbrowsedFile: false,
                browsedFile: {}
            })
            toast.error("Please select a .csv file to add user", { hideProgressBar: true })
        }
    }

    handleFiles = (files) => {
        this.setState({ loader: true });
        toast.dismiss();
        var reader = new FileReader();
        reader.onload = function (e) {
            // Use reader.result
            let fileList = [];
            let newArray = ((reader.result).split("\n").filter(Boolean));
            let inputFields = this.props.issuerFields && this.props.issuerFields.data && this.props.issuerFields.data.issuerInputFields ? this.props.issuerFields.data.issuerInputFields : [];
            let totalfields = [];
            let errors = false;
            if (Object.keys(inputFields).length > 0) {
                for (const [key, val] of Object.entries(inputFields)) {
                    if (val.field_value != "Email") {
                        totalfields.push({ "field_type": val.field_value, "id": val.id });
                    }
                }
            }
            let contains = this.Contains(newArray[0]);
            if (contains.length > 0) {
                setTimeout(() => {
                    toast.error(`${contains.toString()} field are missing in file`, { hideProgressBar: true });
                    this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, emailMatched: false })
                }, 500);
                if (document.getElementById('addbrowsedCsvName')) {
                    document.getElementById('addbrowsedCsvName').value = '';
                }
            } else {
                let allData = this.csvJSON(reader.result);
                let currentEmailList = [];
                if (allData.length < 1) {
                    errors = true;
                    toast.error("CSV file is empty", { hideProgressBar: true })
                } else {
                    allData.map((value, key) => {
                        let field = [];
                        let mailformat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        if (value.Email && value.Email!='' && !(mailformat.test(value.Email.trim()))) {
                            toast.error(`Invalid email in row ${key + 1}`, { hideProgressBar: true })
                            toast.error("CSV data isn't saved", { hideProgressBar: true })
                            errors = true;
                        }
                        totalfields.map((val) => {
                            let data = {};
                            if (val.field_type.toLowerCase() != 'image') {
                            if (value[val.field_type] && value[val.field_type].trim() == '') {
                                toast.error(`${val.field_type} is missing in row ${key + 1}`, { hideProgressBar: true })
                                toast.error("CSV data isn't saved", { hideProgressBar: true })
                                errors = true;
                            } else {
                                data = {
                                    id: val.id,
                                    value: value[val.field_type] ? value[val.field_type].trim():''
                                };
                                if (errors != true) {
                                    field.push(data);
                                }
                            }
                        }
                        })
                        if (errors != true) {
                            if (value.Email && value.Email !='') {
                                if (this.props.emailList.indexOf(value.Email.trim()) !== -1) {
                                    this.setState({
                                        emailMatched: true
                                    })
                                } else {
                                    if (currentEmailList.indexOf(value.Email.trim()) !== -1) {
                                    } else {
                                        currentEmailList.push(value.Email.trim());
                                        fileList.push({ field, email: value.Email.trim() });
                                    }
                                }
                            }
                        }
                    })
                }
                if (errors != true) {
                    this.setState({
                        loader: false,
                        ...this.state,
                        fileContent: fileList
                    },
                        () => {
                            this.addMembersToDB();
                        })
                } else {
                    this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, emailMatched: false })
                    if (document.getElementById('addbrowsedCsvName')) {
                        document.getElementById('addbrowsedCsvName').value = '';
                    }
                }
                currentEmailList = [];
                // this.setState({ loader: false });
            }
        }.bind(this)
        reader.readAsText(files[0]);

    }


    addMembersToDB(props = this.props) {
        if (this.state.fileContent.length > 0) {
            toast.dismiss();
            this.setState({ loader: true });
            let templateid = this.props.tempId;
            let PostData = {
                // access_token: functions.getAccessToken(),
                usersList: this.state.fileContent
            };
    
            api.addMembersToDB(templateid, PostData)
                .then(res => {
                    if (res.data.status == 1) {
                        this.setState({
                            loader: false,
                            browsedFile: [],
                            isbrowsedFile: false,
                            emailMatched: false,
                            fileContent: [],
                            membersList: res.data.data.membersData
                        });
                        this.props.modalClose("addUser");
                        this.props.parentCompnentMount();
                    } else {
                        this.setState({
                            loader: false,
                            // membersList: []
                        });
                        toast.error(res.data.message, { hideProgressBar: true })
                    }
                    this.componentDidMount();
                    this.props.mount();
                }).catch(err => {
                    this.setState({ loader: false })
                });
        }
        else {
            toast.dismiss();
            if (this.state.emailMatched) {
                toast.error("Email id already exists in the user list.", { hideProgressBar: true });
                this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, emailMatched: false })
                if (document.getElementById('addbrowsedCsvName')) {
                    document.getElementById('addbrowsedCsvName').value = '';
                }
            } else {
                toast.error("Invalid CSV file format. Please check you file.", { hideProgressBar: true });
                this.setState({ isbrowsedFile: false, loader: false, browsedFile: {}, })
                if (document.getElementById('addbrowsedCsvName')) {
                    document.getElementById('addbrowsedCsvName').value = '';
                }
            }
        }
    }
    closeAdduserPopup = () => {
        this.state.fields.map((val, key) => {
            let formdata = this.state.fields;
            formdata[key].value = ''
            this.setState({
                fields: formdata,
            })
        })
        if (document.getElementById("addUserForm")) {
            document.getElementById("addUserForm").reset();
        }
        this.setState({
            isbrowsedFile: false,
            browsedFile: {},
            touches: [],
            errors: [],
            fields: this.props.formData && this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : [],
            imagePreviewUrl: '',
            formIsValid: false
        })
        if (document.getElementById('addbrowsedCsvName')) {
            document.getElementById('addbrowsedCsvName').value = "";
        }
    }
    inputPaste(fieldType) {
        let touch = this.state.touches;
        touch[fieldType] = true;
        if (document.getElementById('addbrowsedCsvName')) {
            document.getElementById('addbrowsedCsvName').value = '';
        }
        this.validateForm(fieldType);
        this.setState({
            touches: touch,
            isbrowsedFile: false,
            browsedFile: {},
        })
    }



    render() {
        if (localStorage.getItem('loginISP_user_sesion') == null) { // If authrized user
            return <Redirect to='/signin' />
        }
        let loader = functions.getLoader(this.state.loader);
        const formFields = this.props.formData.data && this.props.formData.data.issuerInputFields ? this.props.formData.data.issuerInputFields : [];
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        let gender = [
            { value: 'Female', label: 'Female' },
            { value: 'Male', label: 'Male' }
        ];
        let emailFieldCount = 0;
        formFields.map((val, key) => {
            if (val.type_value == 'email') {
                emailFieldCount = 1;
            }
        })
        // if (this.state.redirect) {
        //     window.location.reload();
        // }
        // $("#browsedCsvContainer").keypress(function(event) { 
        //     if (event.keyCode === 13) { 
        //         $("#add").click(); 
        //     } 
        // });
        return (
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg_color_section">
                    <div className="modal-header">
                        <button type="button" id="close" className="close" onClick={this.closeAdduserPopup} data-dismiss="modal">&times;</button>
                        <h1>{T("Add new user")}</h1>
                    </div>
                    <div className="modal-body">
                        <h3>{T("You can add multiple users by uploading a .csv file")}</h3>
                        <div className="browsedCsvContainer" id="browsedCsvContainer" ><input placeholder={T("Browse for your list")} readOnly id="addbrowsedCsvName" />
                            <ReactFileReader handleFiles={this.selectedCsvFile} fileTypes={'.csv'}>
                                <button type="button" id="reactFileReaderButton">{T("Browse")}</button>
                            </ReactFileReader>

                        </div>
                        <h4>{T("What is a .csv file? You can download our template")}<button type="button" id="browsedHere" onClick={() => { $("#members .status_main1 button[title='Download CSV']").trigger("click"); }}>{T("here")}.</button></h4>
                        {this.state.loader &&
                            <div className="empty_loading_div"></div>
                        }
                        {!this.state.loader &&
                            <h2>... {T("or you can add a user manually")}</h2>
                        }
                        {!this.state.loader &&
                            <form id="addUserForm" onSubmit={(e) => { this.handleSubmit(e) }} className="block_element" enctype="multipart/form-data">
                                {
                                    formFields.map((val, key) => (
                                        <div className="modal_form_field form-group" id="single-form-group" key={"addUser" + key} >
                                            {
                                                val.type_value == 'image' &&
                                                <div className="floating-label_group">
                                                    <div className="image-input">
                                                        <label>{val.placeholder}</label>
                                                        <input className="fileInput"
                                                            type="file"
                                                            placeholder={val.field_value}
                                                            id={key}
                                                            accept="image/*"
                                                            onBlur={() => {
                                                                this.inputPaste(val.field_value);
                                                            }}
                                                            onChange={(e) => this._handleImageChange(e, key, val.field_value)} />
                                                        {
                                                            imagePreviewUrl &&
                                                            <div className="img-preview">
                                                                <ExifOrientationImg src={imagePreviewUrl} style={{ width: 120 }} />
                                                                {/* <img src={imagePreviewUrl} style={{ width: 150 }} /> */}
                                                                <span className="img-pre-span" onClick={() => {
                                                                    document.getElementById(key).value = null;
                                                                    let formdata = this.state.fields;
                                                                    formdata[key].value = '';
                                                                    this.setState({
                                                                        imagePreviewUrl: '',
                                                                        fields: formdata
                                                                    })
                                                                }} >+</span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="custom-errorMsg">{this.state.errors[key]}</div>
                                                </div>
                                            }
                                            {
                                                val.type_value != 'image' && val.field_key != "$(Gender)" &&
                                                <div className="floating-label_group">
                                                    < input aria-label="Small" aria-describedby="inputGroup-sizing-sm" type={val.type_value}
                                                        placeholder=" "
                                                        onPaste={() => {
                                                            this.inputPaste(val.field_value);
                                                        }}
                                                        onBlur={() => {
                                                            this.inputPaste(val.field_value);
                                                        }}
                                                        onChange={(e) => {
                                                            let touch = this.state.touches;
                                                            let error = {};
                                                            error[key] = '';
                                                            if (document.getElementById('addbrowsedCsvName')) {
                                                                document.getElementById('addbrowsedCsvName').value = '';
                                                            }
                                                            let formdata = this.state.fields;
                                                            formdata[key].value = e.target.value;
                                                            touch[val.field_value] = true;
                                                            this.setState({
                                                                touches: touch,
                                                                errors: error,
                                                                isbrowsedFile: false,
                                                                browsedFile: {},
                                                                fields: formdata,
                                                                inputFieldEmail: val.type_value == 'email' ? true : false,
                                                                email: val.type_value == 'email' ? e.target.value : this.state.email
                                                            })
                                                            this.validateForm(val.field_value)
                                                            setTimeout(() => {
                                                                this.validateForm(val.field_value)
                                                            }, 10);
                                                        }}
                                                        className={
                                                            this.state.errors[key] ? 'floating-input inputText error ' : 'floating-input inputText'
                                                        }
                                                    />
                                                    <span className="highlight"></span>
                                                    <label>{T(val.field_value)}</label>

                                                    {(this.state.errors[key]) &&
                                                        < p className="error" > {this.state.errors[key]}
                                                        </p>
                                                    }

                                                </div>
                                            }

                                            {
                                                val.type_value != 'image' && val.field_key == "$(Gender)" &&
                                                <div className="floating-label_group">
                                                    <label>{val.placeholder}</label>
                                                    <select
                                                        onBlur={() => {
                                                            this.inputPaste(val.field_value);
                                                        }}
                                                        onChange={(e) => {
                                                            let touch = this.state.touches;
                                                            let error = {};
                                                            touch[val.field_value] = true;
                                                            error[key] = '';
                                                            if (document.getElementById('addbrowsedCsvName')) {
                                                                document.getElementById('addbrowsedCsvName').value = '';
                                                            }
                                                            let formdata = this.state.fields;
                                                            formdata[key].value = e.target.value;
                                                            this.setState({
                                                                touches: touch,
                                                                errors: error,
                                                                fields: formdata,
                                                                isbrowsedFile: false,
                                                                browsedFile: {},
                                                            })
                                                            this.validateForm(val.field_value)
                                                            setTimeout(() => {
                                                                this.validateForm(val.field_value)
                                                            }, 10);
                                                        }}
                                                    >
                                                        <option value="">{val.field_value}</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Male">Male</option>
                                                    </select>
                                                    <div className="custom-errorMsg">{this.state.errors[key]}</div>
                                                </div>
                                            }

                                        </div>

                                    ))
                                }
                                {emailFieldCount == 0 &&
                                    <div className="modal_form_field form-group">
                                        <div className="floating-label_group">
                                            < input aria-label="Small" aria-describedby="inputGroup-sizing-sm" onpaste="return false;" type='text'
                                                placeholder=" "
                                                onBlur={() => {
                                                    this.inputPaste('email');
                                                }}
                                                onPaste={() => {
                                                    this.inputPaste('email');
                                                }}
                                                onChange={(e) => {
                                                    let touch = this.state.touches;
                                                    let error = {};
                                                    if (document.getElementById('addbrowsedCsvName')) {
                                                        document.getElementById('addbrowsedCsvName').value = '';
                                                    }
                                                    error['email'] = '';
                                                    touch['email'] = true;
                                                    this.setState({
                                                        touches: touch,
                                                        errors: error,
                                                        email: e.target.value.trim(),
                                                        isbrowsedFile: false,
                                                        browsedFile: {},
                                                    })
                                                    this.validateForm('email')
                                                    setTimeout(() => {
                                                        this.validateForm('email')
                                                    }, 10);
                                                }}
                                                className={
                                                    this.state.errors['email'] ? 'floating-input inputText error ' : 'floating-input inputText'
                                                }
                                            />
                                            <span className="highlight"></span>
                                            <label>{T("Email")}</label>
                                            {this.state.errors['email'] &&
                                                < p className="error" > {this.state.errors['email']}
                                                </p>
                                            }
                                            {/* {this.state.errors['email'] &&
                                            < p className="error" > {this.state.errors['email']}
                                            </p>
                                        } */}
                                        </div>



                                    </div>
                                }
                                {/* <div className="add_error_msg">
                                {this.state.errors['emailExist_allready'] &&
                                    <p className="error"> {this.state.errors['emailExist_allready']}</p>
                                }
                            </div> */}
                                <div class="form-btn-btm">
                                    {!this.state.isbrowsedFile &&
                                        <input type="submit" id="addBtn" disabled={!this.state.formIsValid} className={!this.state.loader && (this.state.isbrowsedFile || this.state.formIsValid) ? "btn btn-default submit_btn adduserbtnActive" : "btn btn-default submit_btn adduserbtnDeActive"} value={T("Add")} />
                                    }
                                    {this.state.isbrowsedFile &&
                                        <input type="button" id="addBtn" onClick={this.handleSubmit} disabled={!this.state.isbrowsedFile} className={!this.state.loader && (this.state.isbrowsedFile || this.state.formIsValid) ? "btn btn-default submit_btn adduserbtnActive" : "btn btn-default submit_btn adduserbtnDeActive"} value={T("Add")} />
                                    }
                                    <input type="button" id="uploadCancel" className="btn btn-default submit_btn no_to_passes adduserbtnActive" onClick={this.closeAdduserPopup} value={T("Cancel")} data-dismiss="modal" />
                                </div>
                            </form>
                        }
                    </div>
                    <div className="modal-footer">
                    </div>
                    {loader}
                </div>

            </div >
        )
    }
}