import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Formik } from 'formik';
import LogoQr from '../assets/images/logo_Qr.png';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import TermsCondition from './TermsCondition';
import ReactTooltip from "react-tooltip";
import { Link, } from 'react-router-dom';
import $ from 'jquery';
import { T } from 'react-translator-component';
// import FormHook from './hookForm';
class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Sign up',
            loader: false,
            isAddPaymentInfo: false,
            isRegisteredUser: false,
            termsPopup: false,
            formSubmit: true,
            subIssuer: false,
            company_name: '',
            email: '',
            accountActivationKey: '',
            checkAuthenticated: true,
            inviteThroughPB: false,
        };
    }
    componentDidMount() {
        // this.setState({ loader: true })
        window.scrollTo(0, 0);
        const accountActivationKey = functions.getParamValuesByName('accountActivationKey') ? functions.getParamValuesByName('accountActivationKey') : null;
        if (accountActivationKey) {
            this.setState({loader:true})
            api.getInviteUserInfo(accountActivationKey)
                .then(res => {
                    if (res.data && res.data.status == 1) {
                        if (res.data && !res.data.isIssuerAccountUser) {
                            this.setState({ inviteThroughPB: true })
                        }
                        if (res.data.data[0]) {
                            if (!res.data.isIssuerAccountUser) {
                                this.setState({
                                    loader:false,
                                    accountActivationKey: accountActivationKey,
                                    subIssuer: true,
                                    contact_name: res.data.data[0].contact_name,
                                    company_name: res.data.data[0].company_name,
                                    email: res.data.data[0].contact_email
                                })
                            }
                            else {
                                this.setState({
                                    loader:false,
                                    accountActivationKey: accountActivationKey,
                                    subIssuer: true,
                                    company_name: res.data.data[0].issuer.company_name,
                                    email: res.data.data[0].email
                                })
                            }
                        }
                    } else {
                        this.setState({
                            loader:false,
                            checkAuthenticated: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        loader:false,
                        checkAuthenticated: false
                    })
                })
        }

    }
    render() {
        const CurrentTab = functions.getUrlSlice('/', '1')[0];
        $(document.body).removeClass('first_front_page');
        let currentYear = parseInt(new Date().getFullYear().toString().substr(-2));
        let years = [];
        for (var i = currentYear; i < currentYear + 25; i++) {
            years.push(i);
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);
        if (this.state.isRegisteredUser) {
            return <Redirect to='/confirm-signup' />
        }

        if (localStorage.getItem('loginISP_user_sesion') != null) { // If authrized user
            return <Redirect to='/dashboard' />
        }
        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ company_id: this.state.subIssuer ? '1234567890' : '', company_name: this.state.subIssuer ? this.state.company_name : '', contact_name: this.state.inviteThroughPB ? this.state.contact_name : '', email: this.state.subIssuer ? this.state.email : '', password: '', passwordd: '', terms: false }}
                    onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                        this.setState({ errors: '', loader: true, formSubmit: false });
                        toast.dismiss();
                        var PostData = {
                            company_id: values.company_id,
                            company_name: values.company_name.trim(),
                            contact_name: values.contact_name.trim(),
                            email: values.email.trim(),
                            password: values.password,
                            isAddPaymentInfo: this.state.isAddPaymentInfo,
                        };
                        api.signUp(PostData, this.state.accountActivationKey)
                            .then(res => {
                                if (res.data && res.data.success) {
                                    localStorage.setItem('new_email_registered',
                                        JSON.stringify({
                                            email: PostData.email
                                        }));
                                    this.setState({
                                        isRegisteredUser: true,
                                        loader: false
                                    })
                                } else {
                                    if (res.data && res.data.error && res.data.error.email) {
                                        setErrors({ email: res.data.error.email });
                                    } else if (res.data && res.data.error && res.data.error.company_name) {
                                        setErrors({ company_name: res.data.error.company_name });
                                    }
                                    else {
                                        toast.error(res.data.message, { hideProgressBar: true })
                                    }
                                    this.setState({ loader: false });
                                }
                                this.setState({ formSubmit: true });
                            })
                            .catch(err => {
                                toast.error(err.message, { hideProgressBar: true })
                                this.setState({ loader: false, formSubmit: true })
                            });

                        setSubmitting(false);
                    }}

                    validationSchema={
                        Yup.object().shape({
                            company_id: Yup.string().trim()
                                .min('10', Validation_Constants.Min_Company_Id)
                                .max('10', Validation_Constants.Min_Company_Id)
                                .required(Validation_Constants.Required2),
                            company_name: Yup.string().trim()
                                .min('2', Validation_Constants.Min_Company_Name)
                                .max('50', Validation_Constants.Max_Company_Name)
                                .required(Validation_Constants.Required2),
                            contact_name: Yup.string().trim()
                                .min('2', Validation_Constants.Min_Contact_Name)
                                .max('50', Validation_Constants.Max_contact_Name)
                                .required(Validation_Constants.Required2),
                            email: Yup.string().trim()
                                .email(Validation_Constants.Invalid_Email)
                                .required(Validation_Constants.Required2),
                            password: Yup.string()
                                .min(8, Validation_Constants.Min_Password)
                                .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                                .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                                .max(20, Validation_Constants.Max_Password)
                                .required(Validation_Constants.Required2),
                            passwordd: Yup.string()
                                .required(Validation_Constants.Required2)
                                .test('passwords-match', Validation_Constants.Invalid_RePassword, function (value) {
                                    return this.parent.password === value;
                                }),
                            terms: Yup.boolean()
                                .test(
                                    'terms',
                                    Validation_Constants.Empty_Terms,
                                    value => value === true
                                )
                                .required(Validation_Constants.Empty_Terms)
                        })
                    }
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            resetForm,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;
                        return (
                            <div className="wrapper block_element">
                                <div className="inner-pages block_element">
                                    <div className="container">
                                        {loader}
                                        {this.state.checkAuthenticated &&
                                            <div className="bg_color_section block_element sign_up_page sign_up padding_container">
                                                {(!this.state.subIssuer) &&
                                                    <h2>{T("Sign up to continue")}</h2>
                                                }
                                                {(this.state.subIssuer && !this.state.inviteThroughPB) &&
                                                    <h2>{T("Sign up to_invited")} {values.company_name} {T("Smartpages")}</h2>
                                                }
                                                {(this.state.subIssuer && this.state.inviteThroughPB) &&
                                                    <h2>{T("Sign up")}</h2>
                                                }
                                                <form id="sign_up_form signup_page" autoComplete="off" className={this.state.subIssuer ? "signupForm sign_up_form block_element signup_with_placeholder" : "signupForm sign_up_form block_element"} onSubmit={handleSubmit}>
                                                    <div className="form-group">
                                                        <div className="floating-label_group">
                                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                                id="contact_name"
                                                                type="text"
                                                                // readOnly={true}
                                                                value={values.contact_name}
                                                                placeholder={this.state.subIssuer ? "Please enter your name" : " "}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.contact_name && touched.contact_name ? 'inputText error floating-input' : 'floating-input inputText'
                                                                }
                                                            />
                                                            <span className="highlight"></span>
                                                            <label>{T("Contact full name")}</label>
                                                            <div className="error-main">
                                                                {errors.contact_name &&
                                                                    touched.contact_name && <p className="error">{T(errors.contact_name)}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group" data-tip={this.state.subIssuer ? 'You cannot edit this information' : ''}>
                                                        <div className="floating-label_group">
                                                            <input
                                                                disabled={this.state.subIssuer ? true : false}
                                                                id="email"
                                                                type="text"
                                                                value={values.email}
                                                                // readOnly={true}
                                                                placeholder=" "
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.email && touched.email ? 'floating-input inputText error' : 'floating-input inputText'
                                                                }
                                                            />
                                                            <span className="highlight"></span>
                                                            <label>{T("Contact email")}</label>
                                                            <div>
                                                                <div className="error-main">
                                                                    {errors.email &&
                                                                        touched.email && <p className="error email_error_margin">{T(errors.email)}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group" data-tip={(this.state.subIssuer && !this.state.inviteThroughPB) ? 'You cannot edit this information' : ''}>
                                                        <div className="floating-label_group">
                                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                                id="company_name"
                                                                disabled={(this.state.subIssuer && !this.state.inviteThroughPB) ? true : false}
                                                                type="text"
                                                                // readOnly={true}
                                                                value={values.company_name}
                                                                placeholder=" "
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.company_name && touched.company_name ? 'floating-input inputText error' : 'floating-input inputText'
                                                                }
                                                            />
                                                            <span className="highlight"></span>
                                                            <label>{T("Company name")}</label>
                                                            <div className="company_name_error error-main">
                                                                {errors.company_name &&
                                                                    touched.company_name && <p className="error">{T(errors.company_name)}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!this.state.subIssuer &&
                                                        <div className="form-group">
                                                            <div className="floating-label_group">
                                                                <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                                    id="company_id"
                                                                    type="text"
                                                                    // readOnly={true}
                                                                    value={values.company_id}
                                                                    placeholder=" "
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.company_id && touched.company_id ? 'floating-input inputText error' : 'floating-input inputText'
                                                                    }
                                                                />
                                                                <span className="highlight"></span>
                                                                <label>{T("Company ID number")}</label>
                                                                <div className="error-main">
                                                                    {errors.company_id &&
                                                                        touched.company_id && <p className="error">{T(errors.company_id)}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="form-group">
                                                        <div className="floating-label_group">
                                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                                id="password"
                                                                type="password"
                                                                value={values.password}
                                                                readOnly={true}
                                                                placeholder={this.state.subIssuer ? "Please enter your password" : " "}
                                                                onChange={handleChange}
                                                                onFocus={(event) => {
                                                                    $('#password').attr('readonly', false)
                                                                }}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.password && touched.password ? 'floating-input inputText error' : 'floating-input inputText'
                                                                }
                                                            />
                                                            <span className="highlight"></span>
                                                            <label>{T("Password")}</label>
                                                            <div className="error-main">
                                                                {errors.password &&
                                                                    touched.password && <p className="error">{T(errors.password)}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group confPassGroup">
                                                        <div className="floating-label_group">
                                                            <input aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                                id="passwordd"
                                                                type="password"
                                                                value={values.passwordd}
                                                                onFocus={(event) => {
                                                                    $('#passwordd').attr('readonly', false)
                                                                }}
                                                                readOnly={true}
                                                                placeholder={this.state.subIssuer ? "Please confirm your password" : " "}
                                                                onChange={handleChange}                                                              
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.passwordd && touched.passwordd ? 'floating-input inputText error' : 'floating-input inputText'
                                                                }
                                                            />
                                                            <span className="highlight"></span>
                                                            <label>{T("Confirm password")}</label>
                                                            <div className="error-main">
                                                                {errors.passwordd &&
                                                                    touched.passwordd && <p className="error">{T(errors.passwordd)}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label className="container-s"> {T("I agree to the")} <a href="javascript::void(0);" data-toggle="modal" data-target="#termsCondition"> {T("Terms and conditions")}</a>
                                                        <input type="checkbox" //checked="checked"
                                                            id="terms"
                                                            name="terms"
                                                            onChange={handleChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                        <div className="error-main">
                                                            {errors.terms &&
                                                                touched.terms && <p className="error">{T(errors.terms)}</p>
                                                            }
                                                        </div>
                                                    </label>
                                                    <div className="form-group-btn">
                                                        <input type="submit" name="" disabled={(JSON.stringify(errors) != "{}") || values.contact_name == "" || values.email == "" || values.company_name == "" || values.company_id == "" || values.password == "" || values.passwordd == "" || values.terms != true} className={!this.state.loader && (JSON.stringify(errors) == "{}") && values.contact_name != "" && values.email != "" && values.company_name != "" && values.company_id != "" && values.password != "" && values.passwordd != "" && values.terms == true ? 'isActive' : 'isNotActive'} value={T("Sign up")} />
                                                        {/* <input type="submit" value="dksfjldsf"/> */}
                                                    </div>
                                                </form>

                                                {/* <div className="modal term-popup" id="termsCondition">
                                        <TermsCondition />
                                    </div> */}
                                            </div>
                                        }
                                        {!this.state.checkAuthenticated &&
                                            <div className="wrapper block_element">
                                                <div className="bg_color_section block_element thanku_page padding_container">
                                                    {/* <Link to="/"><img src={LogoQr} /></Link> */}
                                                    <a href={Constants.introductionPage}><img src={LogoQr} /></a>
                                                    <h2></h2>
                                                    <div className="confirmation text">
                                                        <span>{T("Your Invitation is expired")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <ReactTooltip />
                            </div>
                            // {/* <!-- wrapper-end --> */ }
                        );
                    }
                    }
                </Formik >
                {/* <Link id="resetHistory" to="/confirm-signup"></Link> */}
            </div >
        );
    }
};

export default SignUp;
