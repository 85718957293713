import React from 'react';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link, } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import $ from 'jquery';
import { Translator, T, TF, LanguageList, Config } from 'react-translator-component';

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Forgot your password?',
            loader: false,
            emailSent: false,
            formSubmit: true,
            submitServError: ''
        };
    }

    render() {
        if (localStorage.getItem('loginISP_user_sesion') != null) { // If unauthrized user
            return <Redirect to='/dashboard' />
        }
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);

        if (this.state.emailSent) {
            document.getElementById('resetHistory').click();
            return (< Redirect to='/password-reset' />)
        }

        return (
            <div>
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
                        this.setState({ errors: '', loader: true, formSubmit: false });
                        toast.dismiss();
                        var PostData = {
                            userRole: "Issuer",
                            email: values.email.trim(),
                        };
                        api.resetPassword(PostData)
                            .then(res => {
                                if (res.data.status == 1) {
                                    document.getElementById('resetHistory').click();
                                    this.setState({ loader: false, emailSent: true, formSubmit: true });
                                } else {
                                    this.setState({ loader: false, emailSent: false, submitServError: Validation_Constants.Email_NotRecognize, formSubmit: true });
                                }
                            }).catch(err => {
                                setErrors({
                                    email:Validation_Constants.Email_NotRecognize
                                })
                                this.setState({ loader: false, formSubmit: true, submitServError: Validation_Constants.Email_NotRecognize });
                            });
                        setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().trim()
                            .email(Validation_Constants.Invalid_Email)
                            .required(Validation_Constants.Required2),
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            resetForm,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                        } = props;

                        return (
                            //  {/* <!-- Header-END --> */}
                            <div className="wrapper block_element">
                                <div className="inner-pages block_element">
                                    <div className="container">
                                        {loader}
                                        <div className="bg_color_section block_element sign_up_page forgot_page padding_container">
                                            <h2>{T("Forgot your password")}?</h2>
                                            <span className="write_down_email">{T("Write down your email so we can send you a new one")}.</span>
                                            <form className="sign_up_form block_element" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <div className="floating-label_group">
                                                        <input id="email"
                                                            type="text"
                                                            value={values.email}
                                                            placeholder=" "
                                                            onChange={(event) => {
                                                                handleChange(event)
                                                                handleBlur(event)
                                                            }}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.email && touched.email ? 'floating-input inputText error' : 'floating-input inputText'
                                                            } />
                                                        {/* <span className="floating-label">Email</span> */}
                                                        <span className="highlight"></span>
                                                        <label for="email">{T("Email")}</label>
                                                        <div className="error-main">
                                                            {errors.email &&
                                                                touched.email && <p className="error">{T(errors.email)}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group-btn">
                                                    <input type="submit" name="" className={(JSON.stringify(errors) =='{}') && !this.state.loader && values.email != "" ? 'isActive' : 'isNotActive'} value={T("Send")} disabled={(JSON.stringify(errors) != "{}") || values.email == ""} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            //  {/* <!-- wrapper-end --> */}
                        );
                    }}
                </Formik>
                <Link id="resetHistory" to="/password-reset"></Link>
            </div >
        );
    }
};

export default ForgetPassword;
